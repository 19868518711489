import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PageService } from '../../shared/services/page.service';
import { DOCUMENT } from '@angular/common';
import { CdkDragDrop, CdkDragMove } from '@angular/cdk/drag-drop';
import { IPage } from 'src/app/shared/models/page/page.model';
import { DialogComponent } from '../../shared/dialogs/dialog/dialog.component';
import { AlertService, AlertType } from '../../shared/services/alert.service';
import { DropInfo } from '../../shared/models/drop-info.model';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { DIALOG_BUTTONS } from '../../shared/constants';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class AdminPagesComponent implements OnInit, OnDestroy {
  readonly maximumNumberOfIndentation: number = 2;
  readonly slugsOfStaticPages: string[] = ['employees', 'projects', 'organization', 'licenses'];
  readonly buttons = DIALOG_BUTTONS;

  public allPages: IPage[] = [];
  public dropTargetIds: string[] = [];
  public lookUp: IPage[] = [];
  public dropActionTodo: DropInfo = null;
  private destroy$ = new Subject<void>();

  constructor(
    public pageService: PageService,
    public dialog: MatDialog,
    public router: Router,
    private alertService: AlertService,
    @Inject(DOCUMENT) private document: Document,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.loadPages();
  }

  private loadPages(): void {
    this.pageService
      .getAllPages()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.allPages = response;
        this.prepareDragDrop(this.allPages);
      });
  }

  private prepareDragDrop(pages: IPage[]): void {
    pages.forEach((page) => {
      this.dropTargetIds.push(page.id.toString());
      this.lookUp[page.id] = page;
      this.prepareDragDrop(page.children);
    });
  }

  public dragMoved(event: CdkDragMove<number>): void {
    const draggedItem: IPage = this.lookUp[event.source.data];
    const targetContainer: Element = this.findTargetContainer(event);

    if (!targetContainer) {
      return;
    }

    this.dropActionTodo = {
      targetId: +targetContainer.getAttribute('data-id'),
    };

    const targetRect: DOMRect = targetContainer.getBoundingClientRect();
    const oneThird: number = targetRect.height / 3;

    if (event.pointerPosition.y - targetRect.top < oneThird) {
      this.dropActionTodo.action = 'before';
    } else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
      this.dropActionTodo.action = 'after';
    } else {
      this.dropActionTodo.action = 'inside';
      this.restrictNumberOfIndentations(draggedItem);
    }

    this.showDragInfo();
  }

  private findTargetContainer(event: CdkDragMove<number>): Element {
    const targetElement: Element = this.document.elementFromPoint(
      event.pointerPosition.x,
      event.pointerPosition.y,
    );

    if (!targetElement) {
      this.clearDragInfo();
      return null;
    }

    const targetContainer: Element = targetElement.classList.contains('item')
      ? targetElement
      : targetElement.closest('.item');

    if (!targetContainer) {
      this.clearDragInfo();
      return null;
    }

    return targetContainer;
  }

  private restrictNumberOfIndentations(draggedItem: IPage): void {
    const targetDepth: number = this.getPageDepth(this.dropActionTodo.targetId, this.allPages, 1);
    if (targetDepth >= this.maximumNumberOfIndentation) {
      this.clearDragInfo(true);
    } else if (targetDepth < this.maximumNumberOfIndentation && draggedItem.children.length) {
      this.clearDragInfo(true);
    }
  }

  private getPageDepth(pageId: number, pages: IPage[], depth: number): number {
    for (const page of pages) {
      if (pageId === page.id) {
        return depth;
      } else {
        const ret: number = this.getPageDepth(pageId, page.children, depth + 1);
        if (ret) {
          return ret;
        }
      }
    }

    return null;
  }

  private showDragInfo(): void {
    this.clearDragInfo();
    if (this.dropActionTodo) {
      this.document
        .getElementById('page-' + this.dropActionTodo.targetId)
        .classList.add('drop-' + this.dropActionTodo.action);
    }
  }

  public drop(event: CdkDragDrop<Array<IPage>>): void {
    if (!this.dropActionTodo) {
      return;
    }

    // get item
    const draggedItemId: number = +event.item.data;
    const draggedItem: IPage = this.lookUp[draggedItemId];

    // remove it from old list
    const oldContainerId: number = event.previousContainer.id ? +event.previousContainer.id : null;
    const oldContainer: IPage[] =
      oldContainerId !== null ? this.lookUp[oldContainerId].children : this.allPages;
    const index = oldContainer.findIndex((c) => c.id === draggedItemId);
    oldContainer.splice(index, 1);

    // add it to new list
    const newContainerId: number = this.getParentNodeId(
      this.dropActionTodo.targetId,
      this.allPages,
      null,
    );
    const newContainer: IPage[] =
      newContainerId !== null ? this.lookUp[newContainerId].children : this.allPages;
    switch (this.dropActionTodo.action) {
      case 'before':
      case 'after':
        const newPosition: number = newContainer.findIndex(
          (c) => c.id === this.dropActionTodo.targetId,
        );
        if (this.dropActionTodo.action === 'before') {
          newContainer.splice(newPosition, 0, draggedItem);
        } else {
          newContainer.splice(newPosition + 1, 0, draggedItem);
        }

        if (newContainerId !== null) {
          this.updatePage(this.lookUp[newContainerId]);
        }

        this.arrangePositioning(newContainerId);
        break;

      case 'inside':
        this.lookUp[this.dropActionTodo.targetId].children.push(draggedItem);
        this.updatePage(this.lookUp[this.dropActionTodo.targetId]);
        this.arrangePositioning(this.dropActionTodo.targetId);
        break;
    }

    if (oldContainerId !== newContainerId) {
      draggedItem.position = 0;
      this.arrangePositioning(oldContainerId);
    }

    this.arrangePositioning(newContainerId);
    this.clearDragInfo(true);
  }

  private getParentNodeId(id: number, pages: IPage[], parentId: number): number {
    for (const node of pages) {
      if (node.id === id) {
        return parentId;
      }

      const ret: number = this.getParentNodeId(id, node.children, node.id);
      if (ret) {
        return ret;
      }
    }

    return null;
  }

  private arrangePositioning(id: number): void {
    let index = 1;
    const pages: IPage[] = !id ? this.allPages : this.lookUp[id].children;
    for (const page of pages) {
      page.position = index;
      index++;
    }
    this.updateNestedPages(pages);
  }

  private updateNestedPages(page: IPage[]): void {
    for (const item of page) {
      this.updatePage(item);
      if (item.children.length) {
        this.updateNestedPages(item.children);
      }
    }
  }

  private clearDragInfo(dropped: boolean = false): void {
    if (dropped) {
      this.dropActionTodo = null;
    }

    this.document
      .querySelectorAll('.drop-before')
      .forEach((element) => element.classList.remove('drop-before'));

    this.document
      .querySelectorAll('.drop-after')
      .forEach((element) => element.classList.remove('drop-after'));

    this.document
      .querySelectorAll('.drop-inside')
      .forEach((element) => element.classList.remove('drop-inside'));
  }

  public toggleVisibility(page: IPage): void {
    page.visible = !page.visible;
    this.updatePage(page);
  }

  public isPageEditable(pageSlug: string): boolean {
    let isEditable: boolean = true;
    for (const slug of this.slugsOfStaticPages) {
      if (slug === pageSlug) {
        isEditable = false;
      }
    }

    return isEditable;
  }

  private updatePage(page: IPage): void {
    this.pageService.updatePage(page).pipe(takeUntil(this.destroy$)).subscribe();
  }

  public openDialog(page: IPage): void {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('ADMIN.PAGES.DELETE_CONFIRMATION').toString(),
          description:
            this.translate.instant('ADMIN.PAGES.DELETE_CONFIRMATION_DESCRIPTION').toString() +
            `${page.title}?`,
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('ADMIN.PAGES.DELETE_BUTTON').toString(),
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.deletePage(page.id);
        }
      });
  }

  private deletePage(pageId: number): void {
    this.pageService
      .deletePageById(pageId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.loadPages();
          this.alertService.showAlert(
            this.translate.instant('ADMIN.PAGES.SUCCESSFULLY_REMOVED').toString(),
            AlertType.success,
          );
        },
        (error) => this.alertService.showAlert(error.error, AlertType.error),
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
