import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ILicense } from '../../shared/models/license.model';
import { MatSort } from '@angular/material/sort';
import { LicenseService } from '../../shared/services/license.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
})
export class LicenseComponent implements OnInit, OnDestroy {
  licenses: Array<ILicense>;
  private destroy$ = new Subject<void>();

  dataSource: MatTableDataSource<ILicense>;
  isLoading: boolean = true;
  sortByColumns: string[];
  public displayedColumns: string[] = ['type', 'action'];

  licenseSubscriptions: Subscription = null;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private licenseService: LicenseService) {}

  ngOnInit(): void {
    this.licenseService
      .getLicenseTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.licenses = response;
        this.licenses.sort((lhs, rhs) => lhs.type.localeCompare(rhs.type));
        this.dataSource = new MatTableDataSource<ILicense>(this.licenses);
        this.isLoading = false;
      });
  }

  downloadLicense(type: string): void {
    this.licenseService.downloadLicense(type);
  }

  isSortingDisabled(column: string): boolean {
    return !this.sortByColumns.some((c) => c === column);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
