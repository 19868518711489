import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminProjectDetail } from '../../../shared/models/project.model';
import { ProjectService } from '../../../shared/services/project.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit {
  project: AdminProjectDetail;
  loading = true;

  displayedColumns = ['name', 'position'];

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.projectService.loadProjectById(this.route.snapshot.params.id).subscribe((result) => {
      this.project = result;
      this.loading = false;
    });
  }
}
