import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AdminProjectDetail,
  AdminProjectDTO,
  AdminProjectResponse,
  IProject,
  ProjectBase,
} from '../models/project.model';
import { Observable, shareReplay } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { LoadingState } from '../enums/loading-state.enum';
import { IPagination, ProjectSummary } from '../models';
import { IPageable } from 'src/app/shared/models/pageable.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private urlBase: string = 'api/projects';

  private projectsSubject: BehaviorSubject<IPagination<IProject>> = new BehaviorSubject<
    IPagination<IProject>
  >(null);
  public projects$: Observable<IPagination<IProject>> = this.projectsSubject
    .asObservable()
    .pipe(shareReplay(1));

  private loadingSubject: BehaviorSubject<LoadingState> = new BehaviorSubject<LoadingState>(
    LoadingState.Start,
  );
  public loading$: Observable<LoadingState> = this.loadingSubject.asObservable();

  constructor(private httpClient: HttpClient) {}

  getAllProjects(params?: HttpParams): Observable<IPagination<IProject>> {
    return this.httpClient.get<IPagination<IProject>>(this.urlBase, {
      params,
    });
  }

  loadProjects(
    { page = 1, size = 10, name = '', field = 'name', order = 'asc' }: IPageable,
    includeArchives = false,
  ) {
    const searchParams = new URLSearchParams();

    searchParams.set('page', (page - 1).toString());
    searchParams.set('size', size.toString());
    name && searchParams.set('name', name);
    searchParams.set('field', field);
    searchParams.set('order', order);
    searchParams.set('includingArchived', includeArchives.toString());

    return this.httpClient.get<AdminProjectResponse>(`${this.urlBase}/v2/getAll?${searchParams}`);
  }

  loadProjectById(id: number) {
    return this.httpClient.get<AdminProjectDetail>(`${this.urlBase}/v2/${id}`);
  }

  createProject(name: string) {
    return this.httpClient.post<void>(`${this.urlBase}/v2/create`, name);
  }

  updateProject(project: AdminProjectDTO) {
    return this.httpClient.put<AdminProjectDetail>(
      `${this.urlBase}/v2/update/${project.id}`,
      project,
    );
  }

  deleteProjectById(id: number): Observable<IProject> {
    return this.httpClient.delete<IProject>(`${this.urlBase}/${id}`);
  }

  setArchivedByProjectId(id: number, archive: boolean): Observable<IProject> {
    return this.httpClient.patch<IProject>(`${this.urlBase}/${id}/archive`, archive);
  }

  getProjectByIdClientUnauthorized(id: number): Observable<IProject> {
    return this.httpClient.get<IProject>(`${this.urlBase}/${id}/client`);
  }

  getProjectsSummaries(params?: HttpParams): Observable<IPagination<ProjectSummary>> {
    return this.httpClient.get<IPagination<ProjectSummary>>(`${this.urlBase}/summaries`, {
      params,
    });
  }

  getProjectsSimple(): Observable<ProjectBase[]> {
    return this.httpClient.get<ProjectBase[]>(`${this.urlBase}/simple`);
  }

  getUtilizationRatesExport(
    fromDate: string,
    toDate: string,
    projectId: string | null,
  ): Observable<any> {
    let params = new HttpParams().set('fromDate', fromDate).set('toDate', toDate);
    if (projectId != null) {
      params = params.set('projectId', projectId);
    }
    return this.httpClient.get(`${this.urlBase}/export`, { params, responseType: 'arraybuffer' });
  }
}
