import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService, ObservablesService, RequestService } from '../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { IAbsenceRequest, IEmployee } from '../../shared/models';
import { Subject, takeUntil } from 'rxjs';
import { VacationAbsenceEnum } from '../../shared/enums';

@Component({
  selector: 'app-vacation-charts',
  templateUrl: './vacation-charts.component.html',
  styleUrls: ['./vacation-charts.component.scss'],
})
export class VacationChartsComponent implements OnInit, OnDestroy {
  public user: IEmployee;
  absences: IAbsenceRequest[] = [];
  public isLoading: boolean = true;
  private destroy$ = new Subject<void>();
  setHeight: boolean = false;

  constructor(
    public loginService: LoginService,
    private requestService: RequestService,
    public translate: TranslateService,
    private observablesService: ObservablesService,
  ) {}

  ngOnInit(): void {
    this.user = this.loginService.getUser();
    this.getDataFromDb();

    this.observablesService.updateCalendar
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getDataFromDb());
  }

  private getDataFromDb = () => {
    const currentYear = new Date().getFullYear();

    this.requestService
      .getAllAbsenceRequestsByUserId(currentYear, this.user.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if(!this.user.contractor) {
          this.absences = data.filter(
            (absence) =>
              absence.vacationType === VacationAbsenceEnum.VACATION ||
              (absence.vacationType === VacationAbsenceEnum.WORK_ON_HOLIDAYS &&
                (absence.usedDays || absence.totalDays)) ||
              absence.usedDays,
          );
          if (this.absences.length > 3) {
            const findItem = this.absences.find(
              (item) =>
                item.vacationType === VacationAbsenceEnum.MATERNITY_PATERNITY ||
                item.vacationType === VacationAbsenceEnum.WORK_ON_HOLIDAYS,
            );
            findItem ? (this.setHeight = true) : (this.setHeight = false);
          }
        }
        if(this.user.contractor){
          this.absences = data.filter(
            (absence) => absence.vacationType === VacationAbsenceEnum.LEAVE_OF_ABSENCE);
        }
        this.requestService.setListOfAbsences(data);
      });
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
