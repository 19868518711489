import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { EmployeeService } from '../../../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { ProjectHistory } from '../../../../shared/models/project-history.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-employee-details-project-history',
  templateUrl: './employee-details-project-history.component.html',
  styleUrls: ['./employee-details-project-history.component.scss'],
})
export class EmployeeDetailsProjectHistoryComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;

  displayedColumns: string[] = ['startDate', 'endDate', 'projectName'];
  @ViewChild(MatSort) sort: MatSort;
  public dataSource: MatTableDataSource<ProjectHistory> = new MatTableDataSource<ProjectHistory>();

  private destroy$ = new Subject<void>();

  private projects: ProjectHistory[] = [];

  private employeeId: number;

  constructor(
    public employeeService: EmployeeService,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<EmployeeDetailsProjectHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public initialData: { employeeId: number },
  ) {
    this.employeeId = initialData.employeeId;
  }

  public initEmployeeProjectHistory() {
    this.employeeService
      .getProjectHistoryByEmployeeId(this.employeeId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((projectHistoryBody) => {
        this.projects = projectHistoryBody;
        this.dataSource = new MatTableDataSource<ProjectHistory>(this.projects);
        this.dataSource.sort = this.sort;
        this.dataSource.sort.sort({
          id: 'startDate',
          start: 'desc',
          disableClear: false,
        });
      });
  }

  closePopup() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.initEmployeeProjectHistory();
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
