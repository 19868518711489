import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '../shared/services/login.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  requestId: number;
  isDennyActive: boolean;
  isUserLogIn: boolean;
  isApproveActive: boolean;
  private destroy$ = new Subject<void>();

  constructor(
    public loginService: LoginService,
    private route: ActivatedRoute,
    public translate: TranslateService,
  ) {
    this.isUserLogIn = this.loginService.isUserLogIn();
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.requestId = params.id;
      this.isDennyActive = params.id > 0 && params.decline === 'true';
      this.isApproveActive = params.id > 0 && params.approve === 'true';
    });
  }

  ngOnInit(): void {
    this.loginService.subscribeAuthState();
  }

  signInWithGoogle(): void {
    this.loginService.logIn();
  }

  refreshToken(): void {
    this.loginService.refreshAuthToken();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.loginService.unSubscribeAuthState();
  }
}
