import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IEmployee } from '../../../shared/models/employee.model';
import { EmployeeService } from '../../../shared/services/employee.service';
import { ObservablesService } from '../../../shared/services/observables.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeDetailsProjectHistoryComponent } from './project-history/employee-details-project-history.component';
import { SkillsDialogComponent } from '../../../user/my-settings/employee-skills/skills-dialog.component';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss'],
})
export class EmployeeDetailsComponent implements OnDestroy {
  public employee: IEmployee;
  public isLoading: boolean = true;
  public photoSrc: string = '/img/defaultAvatar.png';
  private destroy$ = new Subject<void>();

  constructor(
    private employeeService: EmployeeService,
    private observable: ObservablesService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public location: Location,
    private dialog: MatDialog,
  ) {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      // todo FIXME this is bad. subscribe method is called inside subscribe. Use switchMap instead.
      this.loadEmployee(params.id);
    });
  }

  loadEmployee(id: number): void {
    this.employeeService
      .getEmployeeById(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.employee = response;
        this.photoSrc = this.employee?.hasAvatar
          ? '/api/employees/' + this.employee.id + '/photo/' + Date.now() + '.jpg'
          : this.photoSrc;
        this.isLoading = false;
        this.observable.dynamicBreadcrumbs.next([
          {
            label: this.employee.firstNameEn + ' ' + this.employee.lastNameEn,
            url: null,
          },
        ]);
      });
  }

  goToPreviousPage(): void {
    this.location.historyGo(-1);
  }

  public openDialog(): void {
    this.dialog.open(EmployeeDetailsProjectHistoryComponent, {
      width: '500px',
      minHeight: '350px',
      data: {
        employeeId: this.employee.id,
      },
    });
  }

  public openSkillDialog(): void {
    this.dialog.open(SkillsDialogComponent, {
      width: '500px',
      minHeight: '350px',
      data: {
        employeeId: this.employee.id,
        showSubmitForm: false,
      },
    });
  }

  ngOnDestroy(): void {
    this.observable.dynamicBreadcrumbs.next([]);
    this.destroy$.next();
    this.destroy$.complete();
  }
}
