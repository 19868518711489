<mat-spinner *ngIf="isLoading"></mat-spinner>

<div [hidden]="isLoading">
  <h2 class="section-title">
    {{ 'DREAMIX.SEARCH_RESULTS.SEARCH_RESULTS' | translate }} {{ filter }}
  </h2>

  <div class="md-padding" layout="row" layout-wrap *ngFor="let searchItem of searchResults">
    <h3>
      <a [routerLink]="[searchItem.path]">{{ searchItem.title }}</a>
    </h3>
    <p>{{ searchItem.description }}</p>
  </div>

  <div class="md-padding" layout="row" layout-wrap *ngIf="searchResults.length === 0">
    <p>{{ 'DREAMIX.SEARCH_RESULTS.NO_RESULTS_FOR' | translate }} '{{ filter }}'</p>
  </div>
</div>
