import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ProjectDetailsComponent } from './dreamix/projects/project-details/project-details.component';
import { HolidaysComponent } from './admin/holidays/holidays.component';
import { RequestsComponent } from './admin/requests/requests.component';
import { ActionRequestComponent } from './action-request/action-request.component';
import { DreamixBirthdaysComponent } from './admin/dreamix-birthdays/dreamix-birthdays.component';
import { RequestDetailsComponent } from './request-details/request-details.component';
import { ProjectsComponent } from './admin/projects/projects.component';
import { AdminEmployeesComponent } from './admin/employees/employees.component';
import { EditProjectComponent } from './admin/projects/edit-project/edit-project.component';
import { EmployeeDetailsComponent } from './dreamix/employees/employee-details/employee-details.component';
import { MyRequestsComponent } from './user/my-requests/my-requests.component';
import { ReviewedRequestsComponent } from './user/reviewed-requests/reviewed-requests.component';
import { MySettingsComponent } from './user/my-settings/my-settings.component';
import { PageDetailsComponent } from './dreamix/page-details/page-details.component';
import { AdminPagesComponent } from './admin/pages/pages.component';
import { EditPageComponent } from './admin/pages/edit-page/edit-page.component';
import { AdminOvertimesComponent } from './admin/overtimes/overtimes.component';
import { EmployeesComponent } from './dreamix/employees/employees.component';
import { SearchResultsComponent } from './dreamix/search-results/search-results.component';
import { AdminEditEmployeeComponent } from './admin/employees/edit-employee/edit-employee.component';
import { OrganizationComponent } from './dreamix/organization/organization.component';
import { EditLicenseComponent } from './admin/license/edit-license/edit-license.component';
import { AdminLicenseComponent } from './admin/license/admin-license.component';
import { LicenseComponent } from './dreamix/license/license.component';
import { AdminReferralsComponent } from './admin/referrals/referrals.component';
import { OvertimeComponent } from './user/overtime/overtime.component';
import { ScheduleJobComponent } from './user/schedule-job/schedule-job.component';
import { TimeLogComponent } from './user/time-log/time-log.component';
import { ClientHoursComponent } from './admin/client-hours/client-hours.component';
import { ReimbursementRequestsComponent } from './user/reimbursement-requests/reimbursement-requests.component';
import { SubmitReportComponent } from './admin/submit-report/submit-report.component';
import { ApproveReportComponent } from './admin/approve-report/approve-report.component';
import { AllTravelRequestsComponent } from './admin/all-travel-requests/all-travel-requests.component';
import { OvertimesExportExcelComponent } from './admin/overtimes/overtimes-export-excel/overtimes-export-excel.component';
import { ReimbursementsExportExcelComponent } from './admin/overtimes/reimbursements-export-excel/reimbursements-export-excel.component';
import { UserTravelRequestsComponent } from './user/user-travel-requests/user-travel-requests.component';
import { UserHelpComponent } from './user-help/user-help.component';
import { UtilizationRateComponent } from './utilization-rate/utilization-rate.component';
import { KnowbeStatsComponent } from './knowbe-stats/knowbe-stats.component';
import { HeadcountComponent } from './admin/headcount/headcount.component';
import { EmployeeLeavesComponent } from 'src/app/user/employee-leaves/employee-leaves.component';
import { exclusiveUserGuard } from 'src/app/shared/guards/exclusive-user.guard';
import { authGuard } from 'src/app/shared/guards/auth.guard';
import { notAuthGuard } from 'src/app/shared/guards/not-auth.guard';
import { roleGuard } from 'src/app/shared/guards/role.guard';
import { NuggetEventsComponent } from 'src/app/admin/nugget-events/nugget-events.component';
import { NuggetEventsCreateEditComponent } from 'src/app/admin/nugget-events/nugget-events-create-edit/nugget-events-create-edit.component';
import { LandingPageComponent } from './landing-page/landing-page.component';

const routes: Routes = [
  {
    path: 'help',
    component: UserHelpComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [notAuthGuard],
  },
  {
    path: '',
    canActivateChild: [authGuard],
    children: [
      {
        path: '',
        component: LandingPageComponent,
      },
      {
        path: 'vacation',
        component: HomeComponent,
        canActivate: [roleGuard('USER')],
      },
      {
        path: 'dreamix',
        data: { breadcrumb: 'Dreamix', link: false },
        canActivateChild: [roleGuard('USER')],
        children: [
          {
            path: 'page/employees',
            data: { breadcrumb: 'BREADCRUMBS.TEAM', link: true },
            children: [
              {
                path: '',
                data: { breadcrumb: null, link: false },
                component: EmployeesComponent,
              },
              {
                path: ':id',
                data: { breadcrumb: null, link: false },
                component: EmployeeDetailsComponent,
              },
            ],
          },
          {
            path: 'page/projects',
            data: { breadcrumb: 'BREADCRUMBS.PROJECTS', link: true },
            children: [
              {
                path: '',
                data: { breadcrumb: null, link: false },
                component: ProjectsComponent,
              },
              {
                path: ':id',
                data: { breadcrumb: null, link: false },
                component: ProjectDetailsComponent,
              },
            ],
          },
          {
            path: 'page/organization',
            data: { breadcrumb: 'BREADCRUMBS.ORGANIZATION', link: true },
            component: OrganizationComponent,
          },
          {
            path: 'search-results/:filter',
            data: { breadcrumb: 'BREADCRUMBS.SEARCH_RESULTS', link: false },
            component: SearchResultsComponent,
          },
          {
            path: 'page/licenses',
            data: { breadcrumb: 'BREADCRUMBS.LICENSES', link: true },
            children: [
              {
                path: '',
                data: { breadcrumb: null, link: false },
                component: LicenseComponent,
              },
            ],
          },
          {
            path: 'page/submit-report',
            data: { breadcrumb: 'BREADCRUMBS.SUBMIT_REPORT', link: true },
            component: SubmitReportComponent,
          },
          {
            path: 'page/:slug',
            data: { breadcrumb: null, link: true },
            component: PageDetailsComponent,
          },
        ],
      },
      {
        path: 'user',
        data: { breadcrumb: 'BREADCRUMBS.USER', link: false },
        canActivateChild: [roleGuard('USER')],
        children: [
          {
            path: 'my-requests',
            data: { breadcrumb: 'BREADCRUMBS.MY_REQUESTS', link: true },
            component: MyRequestsComponent,
          },
          {
            path: 'reviewed-requests',
            data: {
              breadcrumb: 'BREADCRUMBS.REVIEWED_REQUESTS',
              link: true,
            },
            component: ReviewedRequestsComponent,
          },
          {
            path: 'reimbursement-requests',
            data: {
              breadcrumb: 'BREADCRUMBS.REIMBURSEMENT_REQUESTS',
              link: true,
            },
            component: ReimbursementRequestsComponent,
          },
          {
            path: 'travel-requests',
            data: { breadcrumb: 'Travel requests', link: true },
            component: UserTravelRequestsComponent,
          },
          {
            path: 'schedule-job',
            data: { breadcrumb: 'BREADCRUMBS.SCHEDULE_JOB', link: true },
            component: ScheduleJobComponent,
            canActivate: [exclusiveUserGuard],
          },
          {
            path: 'know-be',
            data: { breadcrumb: 'KnowBe4', link: true },
            component: KnowbeStatsComponent,
          },
          {
            path: 'employee-leaves',
            data: { breadcrumb: 'BREADCRUMBS.EMPLOYEE_LEAVES', link: true },
            component: EmployeeLeavesComponent,
            canActivate: [roleGuard('OPERATIONS_ADMIN')],
          },
          {
            path: 'overtime',
            data: { breadcrumb: 'BREADCRUMBS.SUBMIT_OVERTIME', link: true },
            component: OvertimeComponent,
          },
        ],
      },
      {
        path: 'user/time-log',
        data: {
          breadcrumb: 'BREADCRUMBS.LOG_TIME',
          link: true,
        },
        component: TimeLogComponent,
      },
      {
        path: 'user/my-settings',
        data: { breadcrumb: 'BREADCRUMBS.MY_SETTINGS', link: true },
        component: MySettingsComponent,
      },
      {
        path: 'requests/:id',
        component: RequestDetailsComponent,
        canActivate: [roleGuard('USER')],
      },
      {
        path: 'request/action/:action/:id/:hash',
        component: ActionRequestComponent,
        canActivate: [roleGuard('USER')],
      },
      {
        path: 'lead',
        data: { breadcrumb: 'Lead', link: false },
        canActivateChild: [roleGuard('LEAD')],
        children: [
          {
            path: 'export-hours',
            data: { breadcrumb: 'Export Logged Time', link: true },
            component: ClientHoursComponent,
          },
          {
            path: 'projects',
            data: { breadcrumb: 'BREADCRUMBS.PROJECTS', link: true },
            children: [
              {
                path: '',
                data: { breadcrumb: null, link: false, isLead: true },
                component: ProjectsComponent,
              },
              {
                path: 'update/:id',
                data: { breadcrumb: null, link: false, isLead: true },
                component: EditProjectComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'admin',
        data: { breadcrumb: 'BREADCRUMBS.ADMIN', link: false },
        canActivateChild: [roleGuard('ADMIN')],
        children: [
          {
            path: 'requests',
            data: { breadcrumb: 'BREADCRUMBS.REQUESTS', link: true },
            children: [
              {
                path: '',
                data: { breadcrumb: null, link: false },
                component: RequestsComponent,
              },
              {
                path: ':id',
                data: { breadcrumb: null, link: false },
                component: RequestDetailsComponent,
              },
            ],
          },
          {
            path: 'holidays',
            data: { breadcrumb: 'BREADCRUMBS.HOLIDAYS', link: true },
            component: HolidaysComponent,
          },
          {
            path: 'pages',
            data: { breadcrumb: 'BREADCRUMBS.PAGES', link: true },
            children: [
              {
                path: '',
                data: { breadcrumb: null, link: false },
                component: AdminPagesComponent,
              },
              {
                path: 'edit',
                data: { breadcrumb: null, link: false },
                component: EditPageComponent,
              },
              {
                path: 'edit/:id',
                data: { breadcrumb: null, link: false },
                component: EditPageComponent,
              },
            ],
          },
          {
            path: 'projects',
            data: { breadcrumb: 'BREADCRUMBS.PROJECTS', link: true },
            children: [
              {
                path: '',
                data: { breadcrumb: null, link: false, isAdmin: true },
                component: ProjectsComponent,
              },
              {
                path: 'update/:id',
                data: { breadcrumb: null, link: false },
                component: EditProjectComponent,
              },
            ],
          },
          {
            path: 'employees',
            data: { breadcrumb: 'BREADCRUMBS.TEAM', link: true },
            children: [
              {
                path: '',
                data: { breadcrumb: null, link: false },
                component: AdminEmployeesComponent,
              },
              {
                path: 'update/:id',
                data: { breadcrumb: null, link: false },
                component: AdminEditEmployeeComponent,
              },
              {
                path: ':id',
                component: EmployeeDetailsComponent,
              },
            ],
          },
          {
            path: 'dreamixBirthdays',
            data: {
              breadcrumb: 'BREADCRUMBS.DREAMIX_BIRTHDAYS',
              link: true,
            },
            component: DreamixBirthdaysComponent,
          },
          {
            path: 'reports',
            data: { breadcrumb: 'BREADCRUMBS.REPORTS', link: true },
            component: AdminOvertimesComponent,
          },
          {
            path: 'export-hours',
            data: {
              breadcrumb: 'BREADCRUMBS.EXPORT_LOGGED_TIME',
              link: true,
            },
            component: ClientHoursComponent,
          },
          {
            path: 'licenses',
            data: { breadcrumb: 'BREADCRUMBS.LICENSES', link: true },
            children: [
              {
                path: '',
                data: { breadcrumb: null, link: false },
                component: AdminLicenseComponent,
              },
              {
                path: 'edit',
                data: { breadcrumb: null, link: false },
                component: EditLicenseComponent,
              },
              {
                path: 'edit/:id',
                data: { breadcrumb: null, link: false },
                component: EditLicenseComponent,
              },
            ],
          },
          {
            path: 'approve-report',
            data: { breadcrumb: 'BREADCRUMBS.REPORTS', link: true },
            component: ApproveReportComponent,
          },
          {
            path: 'referrals',
            data: { breadcrumb: 'BREADCRUMBS.REFERRALS', link: true },
            component: AdminReferralsComponent,
          },
          {
            path: '',
            canActivateChild: [roleGuard('OPERATIONS_ADMIN')],
            children: [
              {
                path: 'travel-requests',
                data: { breadcrumb: 'Travel requests', link: true },
                component: AllTravelRequestsComponent,
              },
              {
                path: 'overtimes',
                data: { breadcrumb: 'Overtimes', link: true },
                component: OvertimesExportExcelComponent,
              },
              {
                path: 'reimbursements',
                data: { breadcrumb: 'Reimbursements', link: true },
                component: ReimbursementsExportExcelComponent,
              },
              {
                path: 'utilization-rates',
                data: { breadcrumb: 'BREADCRUMBS.UTILIZATION_RATES', link: true },
                component: UtilizationRateComponent,
              },
              {
                path: 'headcount',
                data: { breadcrumb: 'Headcount', link: true },
                component: HeadcountComponent,
              },
            ],
          },
          {
            path: 'events',
            data: { breadcrumb: 'BREADCRUMBS.EVENTS', link: true },
            children: [
              {
                path: '',
                data: { breadcrumb: null, link: false },
                component: NuggetEventsComponent,
              },
              {
                path: 'create',
                data: { breadcrumb: null, link: false },
                component: NuggetEventsCreateEditComponent,
              },
              {
                path: 'edit/:id',
                data: { breadcrumb: null, link: false },
                component: NuggetEventsCreateEditComponent,
              },
            ],
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

export const routing = RouterModule.forRoot(routes, {
  useHash: true,

  //enables route params as inputs feature in v16
  bindToComponentInputs: true,
});
