<div class="form-group">
  <mat-form-field (click)="startPicker.open()">
    <mat-label>{{ 'ADMIN.REQUESTS.START_DATE' | translate }}</mat-label>
    <input
      matInput
      [matDatepicker]="startPicker"
      [value]="fromDate"
      (dateInput)="handleDatePick($event.value)"
    />
    <mat-datepicker-toggle matIconSuffix [for]="startPicker"></mat-datepicker-toggle>
    <mat-datepicker #startPicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field (click)="endPicker.open()">
    <mat-label>{{ 'ADMIN.REQUESTS.END_DATE' | translate }}</mat-label>
    <input
      matInput
      [matDatepicker]="endPicker"
      [min]="fromDate"
      [value]="toDate"
      (dateInput)="handleDatePick($event.value, false)"
    />
    <mat-datepicker-toggle matIconSuffix [for]="endPicker"></mat-datepicker-toggle>
    <mat-datepicker #endPicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="days-field">
    <mat-label>{{ 'ADMIN.REQUESTS.DAYS' | translate }}</mat-label>
    <input matInput disabled [value]="days || ''" />
  </mat-form-field>
</div>
