<mat-spinner *ngIf="isPageLoading"></mat-spinner>

<div [hidden]="isPageLoading" class="add-log">
  <h2 class="section-title">
    {{ 'LOG_TIME.PAGE_TITLE' | translate }}
  </h2>

  <div class="align-center selection-group">
    <mat-form-field appearance="fill" class="form-item-full no-padding" subscriptSizing="dynamic">
      <mat-label>{{ 'LOG_TIME.SELECT_PROJECT' | translate }}</mat-label>
      <input
        type="text"
        matInput
        placeholder="{{ 'HOME.CALENDAR.SEARCH_PROJECT' | translate }}"
        #filter
        [formControl]="projectControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="changeProject($event)"
        [displayWith]="displayFn"
      >
        <mat-option
          *ngFor="let project of projects | filter: filter.value : 'name'"
          [value]="project"
        >
          {{ project.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-radio-group
      aria-labelledby="month-radio-group-label"
      class="example-radio-group"
      (change)="radioChange($event)"
      color="primary"
    >
      <mat-radio-button checked value="0">
        {{ 'LOG_TIME.CURRENT_MONTH' | translate | lowercase }}</mat-radio-button
      >
      <mat-radio-button value="-1">{{
        'LOG_TIME.PREVIOUS_MONTH' | translate | lowercase
      }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <app-time-log-input
    *ngIf="projectControl.value && !isPageLoading"
    mode="add"
    [employeeId]="user.id"
    [projectId]="projectControl.value.id"
  />
</div>

<div class="log-list" [hidden]="isPageLoading || !projectControl.value">
  <h4 *ngIf="currentMonth">
    {{ 'LOG_TIME.CURRENT_MONTH' | translate }}
  </h4>
  <h4 *ngIf="!currentMonth">
    {{ 'LOG_TIME.PREVIOUS_MONTH' | translate }}
  </h4>

  <app-time-log-table [currentMonth]="currentMonth"></app-time-log-table>
</div>
