import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import * as XLSX from 'xlsx';
import { WorkSheet } from 'xlsx';
import * as FileSaver from 'file-saver';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {EmployeeTeam, IEmployee} from 'src/app/shared/models';
import { DIALOG_BUTTONS } from 'src/app/shared/constants';
import {
  AlertService,
  AlertType,
  EmployeeService,
  VacationHelperService,
} from 'src/app/shared/services';
import { DialogComponent } from 'src/app/shared/dialogs';

@Component({
  selector: 'app-edit-employee',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
})
export class AdminEmployeesComponent implements OnInit, OnDestroy {
  public allEmployees: Array<EmployeeTeam>;
  private destroy$ = new Subject<void>();

  public displayedColumns: string[] = [
    'firstName',
    'lastName',
    'email',
    'positionName',
    'role',
    'daysLeft',
    'action',
  ];
  public dataSource: MatTableDataSource<EmployeeTeam>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public isLoading: boolean = true;
  public filter: string;
  readonly buttons = DIALOG_BUTTONS;

  constructor(
    public dialog: MatDialog,
    public translate: TranslateService,
    public helper: VacationHelperService,
    private alertService: AlertService,
    private employeeService: EmployeeService,
  ) {}

  ngOnInit(): void {
    this.loadEmployees();
  }

  private loadEmployees(): void {
    this.employeeService
      .getAllEmployeesTeam()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.allEmployees = data;

        this.dataSource = new MatTableDataSource<EmployeeTeam>(this.allEmployees);
        this.dataSource.paginator = this.paginator;

        this.isLoading = data.length <= 0;
      });
  }

  public applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private deleteEmployee(id: number): Observable<IEmployee> {
    return this.employeeService.deleteEmployeeById(id).pipe(
      takeUntil(this.destroy$),
      tap(() => {
        this.alertService.showAlert(
          this.translate.instant('ADMIN.EMPLOYEES.SUCCESSFULLY_REMOVED').toString(),
          AlertType.success,
        );
        this.loadEmployees();
      }),
    );
  }

  public openDialog(id: number): void {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('ADMIN.EMPLOYEES.DELETE_CONFIRMATION').toString(),
          description: this.translate
            .instant('ADMIN.EMPLOYEES.DO_YOU_WANT_TO_DELETE_EMPLOYEE')
            .toString(),
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('ADMIN.EMPLOYEES.DELETE').toString(),
        },
      })
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        switchMap((result) => {
          if (result) {
            return this.deleteEmployee(id);
          }
          // Return an Observable of null if the dialog is cancelled
          return of(null);
        }),
      )
      .subscribe();
  }

  public clear(): void {
    this.filter = '';
    this.dataSource.filter = '';
  }

  public exportAll(): void {
    const allSheets: { [sheet: string]: Array<any> | WorkSheet } = {};
    const defaultSheet = 'DaysLeft';
    const totalHeader = 'Total';
    allSheets[defaultSheet] = [];
    const headers = ['Employee', 'E-mail'];
    this.allEmployees.forEach((employee) => {
      const row = {
        Employee: employee.firstNameEn + ' ' + employee.lastNameEn,
        'E-mail': employee.email,
      };

      const daysLeftPerYear = this.helper.distributeDaysLeftPerYear(employee.daysLeft, null);
      daysLeftPerYear.forEach((daysLeft, year) => {
        const yearStr = year.toString();
        row[yearStr] = daysLeft;
        if (!headers.includes(yearStr)) headers.push(yearStr);
      });

      row[totalHeader] = employee.daysLeft;

      allSheets[defaultSheet].push(row);
    });

    headers.push(totalHeader);

    Object.keys(allSheets).forEach((k) => {
      allSheets[k] = XLSX.utils.json_to_sheet(allSheets[k] as Array<any>, {
        header: headers,
      });
    });

    const workbook: XLSX.WorkBook = {
      Sheets: allSheets,
      SheetNames: Object.keys(allSheets),
    };
    const excelBuffer: BlobPart = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, 'Days_left_per_year_report');
  }

  private saveAsExcelFile(buffer: BlobPart, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(
      data,
      fileName + '_' + formatDate(new Date(), 'yyyy-MM-dd', 'en-US') + '.xlsx',
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
