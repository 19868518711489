import { Component, Input } from '@angular/core';
import { LineChartData } from '../../shared/models/knowbe-user-data.model';

@Component({
  selector: 'app-knowbe-dashboard',
  templateUrl: './knowbe-dashboard.component.html',
  styleUrls: ['./knowbe-dashboard.component.scss'],
})
export class KnowbeDashboardComponent {
  @Input() personalRiskScore: number;
  @Input() organizationRiskScore: number;
  @Input() userRiskScoreChartData: LineChartData[];
  @Input() organizationCurrentRiskScoreHistoryChartData: LineChartData[];
  @Input() phishPronePercentage: number;
}
