<mat-spinner *ngIf="isLoading"></mat-spinner>

<div *ngIf="!isLoading">
  <h2 class="section-title">
    {{
      isEditMode
        ? ('ADMIN.LICENSES.EDIT_LICENSE.EDIT' | translate) + ' ' + license.type
        : ('ADMIN.LICENSES.EDIT_LICENSE.CREATE_LICENSE' | translate)
    }}
  </h2>

  <div class="back-link">
    <mat-icon>keyboard_arrow_left</mat-icon>
    <a routerLink="/admin/licenses">{{
      'ADMIN.LICENSES.EDIT_LICENSE.BACK_TO_LICENSE' | translate
    }}</a>
  </div>

  <form (ngSubmit)="submitForm()">
    <div class="form-container">
      <div class="form-column type">
        <mat-form-field color="primary" appearance="fill">
          <mat-error *ngIf="typeControl.invalid">{{
            'ADMIN.LICENSES.EDIT_LICENSE.ERROR.TYPE_REQUIRED' | translate
          }}</mat-error>
          <mat-label>{{ 'ADMIN.LICENSES.EDIT_LICENSE.TYPE' | translate }}</mat-label>
          <input matInput [formControl]="typeControl" [matAutocomplete]="auto" required />
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedLicenseType($event)">
            <ng-container *ngFor="let type of licenseTypes">
              <mat-option [value]="type">
                {{ type }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-column description">
        <mat-form-field color="primary" appearance="fill" class="description">
          <mat-label>{{ 'ADMIN.LICENSES.EDIT_LICENSE.DESCRIPTION' | translate }}</mat-label>
          <input matInput [formControl]="descriptionControl" />
        </mat-form-field>
      </div>
      <div class="form-column file">
        <input matInput hidden readonly required />
        <button mat-flat-button class="file add-button" color="primary">
          {{
            license.multipartFile
              ? license.multipartFile.name.substr(0, 20)
              : ('ADMIN.LICENSES.EDIT_LICENSE.FILE.PLACEHOLDER' | translate)
          }}
          <input
            type="file"
            #UploadFileInput
            id="fileUpload"
            (change)="fileChangeEvent($event)"
            name="fileUpload"
            required
          />
        </button>
      </div>
    </div>

    <div class="form-column">
      <button style="float: right" type="submit" mat-raised-button color="primary">
        {{ 'SHARED.SUBMIT' | translate }}
      </button>
    </div>
  </form>
</div>
