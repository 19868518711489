<div class="login-form align-center" *ngIf="!isUserLogIn">
  <div class="logo">
    <h1>
      <img src="../../assets/img/logo.png" alt="Dreamix Logo" />
    </h1>
  </div>
  <asl-google-signin-button
    class="btn"
    type="standard"
    size="medium"
    text="signin"
  ></asl-google-signin-button>
</div>
<mat-spinner *ngIf="isUserLogIn"></mat-spinner>
