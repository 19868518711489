import { Component, effect, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/shared/material.module';
import { EmployeeLeavesService } from './services/employee-leaves.service';
import { EmployeeLeavesFilterComponent } from './components/employee-leaves-filter/employee-leaves-filter.component';
import { EmployeeLeavesListComponent } from './components/employee-leaves-list/employee-leaves-list.component';

@Component({
  selector: 'app-employee-leaves',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    EmployeeLeavesListComponent,
    EmployeeLeavesFilterComponent,
  ],
  templateUrl: './employee-leaves.component.html',
  styleUrls: ['./employee-leaves.component.scss'],
})
export class EmployeeLeavesComponent {
  displayedColumns = ['name', 'date', 'actions'];

  filters = signal({
    leaveType: 'REGULAR',
    count: '5',
    id: null,
  });

  constructor(public service: EmployeeLeavesService) {
    effect(() => {
      this.service.load(this.filters());
    });
  }

  updateFilter([key, value]: [string, string]) {
    this.filters.update((filters) => {
      filters[key] = value;
      return filters;
    });
  }
}
