import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { catchError, of, Subject, takeUntil } from 'rxjs';
import { DIALOG_BUTTONS } from 'src/app/shared/constants';
import { EmployeeProject } from 'src/app/shared/models';
import { AlertService, AlertType, EmployeeService, LoginService } from 'src/app/shared/services';
import { TimeLogController } from 'src/app/shared/services/time-log.controller';
import { TimeLogService } from 'src/app/shared/services/time-log.service';

@Component({
  selector: 'app-submit-report',
  templateUrl: './submit-report.component.html',
  styleUrls: ['./submit-report.component.scss'],
})
export class SubmitReportComponent implements OnInit, OnDestroy {
  @ViewChild('multipartFile') multipartFile: ElementRef;
  constructor(
    private employeeService: EmployeeService,
    private loginService: LoginService,
    private ctrl: TimeLogController,
    private alertService: AlertService,
    private timeLogService: TimeLogService,
    public translate: TranslateService,
  ) {}

  private destroy$ = new Subject<void>();
  public projects: EmployeeProject[];

  acceptedTypes = {
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  readonly buttons = DIALOG_BUTTONS;

  reportForm: FormGroup;
  private firstDateOfTheMonth = moment().add(-1, 'month').startOf('month');
  minDate = moment().add(-12, 'months').startOf('month');
  maxDate = moment().startOf('month');

  ngOnInit() {
    this.buildForm();
    this.initProjects();
  }

  buildForm(): void {
    this.reportForm = new FormGroup({
      project: new FormControl('', Validators.required),
      dateControl: new FormControl(this.firstDateOfTheMonth),
      multipartFile: new FormControl(null, Validators.required),
    });
  }

  async initProjects(): Promise<void> {
    this.projects = await this.employeeService.loadMyProjects(this.loginService.getUser().id);
    this.projects.sort((a, b) =>
      a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase() ? 1 : -1,
    );
    if (this.projects.length === 1) {
      this.reportForm.controls.project.setValue(this.projects[0].id);
    }
  }

  setMonthAndYear(normalizedMonthAndYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.reportForm.controls.dateControl.value;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.reportForm.controls.dateControl.setValue(ctrlValue);
    datepicker.close();
    this.ctrl.clearEntries();
  }

  onFileSelected(file: File): void {
    if (file == null) {
      this.reportForm.controls.multipartFile.markAsTouched();
    }
    this.reportForm.controls.multipartFile.setValue(file);
  }

  onSubmit(): void {
    if (this.reportForm.valid) {
      const fromDate = moment(this.reportForm.controls.dateControl.value)
        .startOf('month')
        .format('YYYY-MM-DD');
      const toDate = moment(this.reportForm.controls.dateControl.value)
        .endOf('month')
        .format('YYYY-MM-DD');
      const formData: FormData = new FormData();
      formData.append('fromDate', fromDate);
      formData.append('toDate', toDate);
      formData.append('file', new Blob([this.reportForm.controls.multipartFile.value]));

      this.timeLogService
        .submitReport(this.reportForm.controls.project.value, formData)
        .pipe(
          takeUntil(this.destroy$),
          catchError((err) => {
            return of([err]);
          }),
        )
        .subscribe((response) => {
          if (response[0].status == 200) {
            this.alertService.showAlert(response[0].error.text, AlertType.success);
            this.reportForm.controls.multipartFile.reset();
          } else {
            this.reportForm.controls.multipartFile.reset();
            this.alertService.showAlert(response[0].error, AlertType.error);
          }
        });
    }
  }

  displayFn(project: EmployeeProject): string {
    return project ? `${project.name}` : null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
