import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../shared/services/login.service';
import { EmployeeService } from '../../shared/services/employee.service';
import { IEmployee } from '../../shared/models/employee.model';
import { OvertimeRequestFormComponent } from './overtime-request-form/overtime-request-form.component';
import { OvertimeHistoryComponent } from './overtime-history/overtime-history.component';
import { Subscription } from 'rxjs';
import { IOvertime } from 'src/app/shared/models/overtime.model';

@Component({
  selector: 'app-overtime',
  templateUrl: './overtime.component.html',
  styleUrls: ['./overtime.component.scss'],
})
export class OvertimeComponent implements OnInit, OnDestroy {
  @ViewChild(OvertimeRequestFormComponent)
  overtimeRequestFormComponent: OvertimeRequestFormComponent;

  @ViewChild(OvertimeHistoryComponent)
  overtimeHistoryComponent: OvertimeHistoryComponent;

  user: IEmployee;
  subscription: Subscription = new Subscription();

  constructor(
    public loginService: LoginService,
    private employeeService: EmployeeService,
  ) {}

  ngOnInit(): void {
    this.user = this.loginService.getUser();
    // TODO remove dirty fix
    this.updateEmployee();
  }

  public isLoading(): boolean {
    return (
      this.overtimeRequestFormComponent?.isLoading ??
      this.overtimeHistoryComponent?.isLoading ??
      true
    );
  }
  public resetForm = () => {
    this.overtimeRequestFormComponent.resetForm();
  };
  public handleEdit = (overtime: IOvertime) => {
    this.overtimeRequestFormComponent.handleEdit(overtime);
  };
  private updateEmployee(): void {
    this.subscription.add(
      this.employeeService.getEmployeeById(this.user.id).subscribe((employee) => {
        this.user = employee;
        this.loginService.setUser(this.user);
      }),
    );
  }

  overtimeSubmitted(): void {
    this.overtimeHistoryComponent.getOvertimesFromDb();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
