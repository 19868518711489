import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IPage } from 'src/app/shared/models/page/page.model';
import { uniqueValidator } from 'src/app/shared/services/validators/unique.directive';
import { PageService } from '../../../shared/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.scss'],
})
export class EditPageComponent implements OnInit, OnDestroy {
  readonly titleMaxLength: number = 32;
  readonly slugMaxLength: number = 64;
  private destroy$ = new Subject<void>();

  public pageForm: UntypedFormGroup;
  public page: IPage;
  public isEditMode: boolean;
  public allSlugs: string[];
  public slugPattern: RegExp = /^[a-zA-Z0-9](-?[a-zA-Z0-9])*$/;
  public isPreviewMode: boolean = false;

  constructor(
    private pageService: PageService,
    private route: ActivatedRoute,
    public router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    // The validators are updated on loadPage() and getAllSlugs()
    this.pageForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(this.titleMaxLength)]],
      slug: [
        '',
        [
          Validators.required,
          Validators.maxLength(this.slugMaxLength),
          Validators.pattern(this.slugPattern),
        ],
      ],
      description: [''],
    });

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.isEditMode = !!params.id;
      if (this.isEditMode) {
        // todo FIXME this is bad. subscribe method is called inside subscribe. Use switchMap instead.
        this.loadPage(params.id);
      }
    });

    this.getAllSlugs();
  }

  private loadPage(id: number): void {
    this.pageService
      .getPageById(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.page = response;
        this.pageForm.patchValue(this.page);
        if (this.allSlugs) {
          this.allSlugs = this.allSlugs.filter((x) => x !== this.page.slug);
        }
        this.pageForm.controls.slug.setValidators([
          Validators.required,
          Validators.maxLength(this.slugMaxLength),
          uniqueValidator(this.allSlugs),
          Validators.pattern(this.slugPattern),
        ]);
      });
  }

  private getAllSlugs(): void {
    this.pageService
      .getAllPageSlugs()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.allSlugs = this.page ? response.filter((x) => x !== this.page.slug) : response;

        this.pageForm.controls.slug.setValidators([
          Validators.required,
          Validators.maxLength(this.slugMaxLength),
          uniqueValidator(this.allSlugs),
          Validators.pattern(this.slugPattern),
        ]);
      });
  }

  public submitForm(): void {
    if (this.isEditMode) {
      this.page.title = this.pageForm.value.title;
      this.page.slug = this.pageForm.value.slug;
      this.page.description = this.pageForm.value.description;

      this.pageService
        .updatePage(this.page)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.router.navigate(['admin/pages']));
    } else {
      this.pageService
        .createPage(this.pageForm.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.router.navigate(['admin/pages']));
    }
  }

  public getTitleErrorMessage(): string {
    if (this.pageForm.controls.title.hasError('required')) {
      return this.translate.instant('ADMIN.PAGES.EDIT_PAGE.REQUIRED_VALUE').toString();
    } else if (this.pageForm.controls.title.hasError('maxlength')) {
      return (
        this.translate.instant('ADMIN.PAGES.EDIT_PAGE.EXCEEDED_TITLE').toString() +
        `${this.titleMaxLength}` +
        this.translate.instant('ADMIN.PAGES.EDIT_PAGE.CHARACTERS').toString()
      );
    }
  }

  public getSlugErrorMessage(): string {
    if (this.pageForm.controls.slug.hasError('required')) {
      return this.translate.instant('ADMIN.PAGES.EDIT_PAGE.REQUIRED_VALUE').toString();
    } else if (this.pageForm.controls.slug.hasError('notUnique')) {
      return this.translate.instant('ADMIN.PAGES.EDIT_PAGE.NOT_UNIQUE_PAGE_SLUG').toString();
    } else if (this.pageForm.controls.slug.hasError('maxlength')) {
      return (
        this.translate.instant('ADMIN.PAGES.EDIT_PAGE.EXCEEDED_SLUG').toString() +
        `${this.slugMaxLength}` +
        this.translate.instant('ADMIN.PAGES.EDIT_PAGE.CHARACTERS').toString()
      );
    } else if (this.pageForm.controls.slug.hasError('pattern')) {
      return this.translate.instant('ADMIN.PAGES.EDIT_PAGE.WRONG_PATTERN').toString();
    }
  }

  public togglePreview(): void {
    this.isPreviewMode = !this.isPreviewMode;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
