import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReimbursementService {
  private urlBase: string = 'api/reimbursement';

  constructor(private httpClient: HttpClient) {}

  getReimbursementTypes(): Observable<any> {
    return this.httpClient.get(`${this.urlBase}/types`);
  }

  getAllReimbursements(fromDate: string, toDate: string): Observable<any> {
    return this.httpClient.get(`${this.urlBase}/export?fromDate=${fromDate}&toDate=${toDate}`, {
      responseType: 'arraybuffer',
    });
  }

  createReimbursementRequest(payload: any): Observable<any> {
    return this.httpClient.post(`${this.urlBase}/submit`, payload);
  }

  createTravelReimbursementRequest(payload: any): Observable<any> {
    return this.httpClient.post(`${this.urlBase}/submit-travel`, payload);
  }

  updateReimbursementRequest(payload: any, userId: number, requestId: number): Observable<any> {
    return this.httpClient.patch(`${this.urlBase}/${userId}/${requestId}`, payload);
  }

  updateTravelReimbursementRequest(
    payload: any,
    userId: number,
    requestId: number,
  ): Observable<any> {
    return this.httpClient.patch(`${this.urlBase}/${userId}/${requestId}/travel`, payload);
  }

  getAllReimbursementRequests(userId: number): Observable<any> {
    return this.httpClient.get(`${this.urlBase}/${userId}/all`);
  }

  getReimbursementRequestById(userId: number, requestId: number): Observable<any> {
    return this.httpClient.get(`${this.urlBase}/${userId}/${requestId}`);
  }

  deleteReimbursementRequestById(userId: number, requestId: number): Observable<any> {
    return this.httpClient.delete(`${this.urlBase}/${userId}/${requestId}`);
  }

  deleteReimbursementFileByName(
    userId: number,
    requestId: number,
    fileName: string,
  ): Observable<any> {
    return this.httpClient.delete(`${this.urlBase}/${userId}/${requestId}/${fileName}`);
  }
}
