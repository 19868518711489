import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EmployeePartial, EmployeeTeam, IEmployee, IEmployeeNameEmail, IEmployeeSimple } from '../models/';
import { lastValueFrom, Observable } from 'rxjs';
import { IEmployeeDreamixBirthdayModel } from '../models/employee-dreamix-birthday.model';
import { IPagination } from '../models/pagination.model';
import { IReferral } from '../models/referral.model';
import { EmployeeProject } from '../models/project.model';
import { ProjectHistory, ProjectHistoryRequest } from '../models/project-history.model';
import { IManagerSubordinatesModel } from '../models/manager-subordinates.model';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  private urlBase: string = 'api/employees';

  constructor(private httpClient: HttpClient) {}

  public uploadImg(id: number, file: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post<string>(`${this.urlBase}/${id}/photo`, formData);
  }

  getAllEmployees(): Observable<IEmployee[]> {
    return this.httpClient.get<IEmployee[]>(this.urlBase);
  }

  getAllEmployeesSimple(): Observable<IEmployeeSimple[]> {
    return this.httpClient.get<IEmployeeSimple[]>(`${this.urlBase}/simple`);
  }

  getAllEmployeesNameEmail(): Observable<IEmployeeNameEmail[]> {
    return this.httpClient.get<IEmployeeNameEmail[]>(`${this.urlBase}/name-email`);
  }

  getAllEmployeesTeam() : Observable<EmployeeTeam[]> {
    return this.httpClient.get<EmployeeTeam[]>(`${this.urlBase}/team`)
  }

  getActiveEmployeesByProjectId(projectId: number): Observable<IEmployee[]> {
    return this.httpClient.get<IEmployee[]>(`${this.urlBase}/by-project/${projectId}`);
  }

  async loadMyProjects(id: number) {
    return await lastValueFrom(
      this.httpClient.get<Array<EmployeeProject>>(`${this.urlBase}/${id}/projects`),
    );
  }

  getProjectHistoryByEmployeeId(employeeId: number): Observable<ProjectHistory[]> {
    return this.httpClient.get<ProjectHistory[]>(`${this.urlBase}/${employeeId}/projects-history`);
  }

  saveEmployeeProjectHistory(
    employeeId: number,
    projectHistory: ProjectHistoryRequest[],
  ): Observable<ProjectHistoryRequest[]> {
    return this.httpClient.post<ProjectHistoryRequest[]>(
      `${this.urlBase}/${employeeId}/projects-history`,
      projectHistory,
    );
  }

  getAllClientEmployees(projectId: number): Observable<IEmployee[]> {
    return this.httpClient.get<IEmployee[]>(`${this.urlBase}/client/${projectId}`);
  }

  getPartialEmployees(): Observable<EmployeePartial[]> {
    return this.httpClient.get<EmployeePartial[]>(`${this.urlBase}/partial`);
  }

  editEmployee(employee: IEmployee): Observable<IEmployee> {
    return this.httpClient.put<IEmployee>(`${this.urlBase}`, employee);
  }

  getAllEmployeesBirthdays(): Observable<IEmployeeDreamixBirthdayModel[]> {
    return this.httpClient.get<IEmployeeDreamixBirthdayModel[]>(`${this.urlBase}/dreamixBd`);
  }

  getEmployeeById(id: number): Observable<IEmployee> {
    return this.httpClient.get<IEmployee>(`${this.urlBase}/${id}`);
  }

  deleteEmployeeById(id: number): Observable<IEmployee> {
    return this.httpClient.delete<IEmployee>(`${this.urlBase}/${id}`);
  }

  getAllReferrals(params?: HttpParams): Observable<IPagination<IReferral>> {
    return this.httpClient.get<IPagination<IReferral>>(`${this.urlBase}/referrals`, { params });
  }

  getUtilizationRatesExport(
    fromDate: string,
    toDate: string,
    employeeId: string | null,
  ): Observable<any> {
    let params = new HttpParams().set('fromDate', fromDate).set('toDate', toDate);
    if (employeeId != null) {
      params = params.set('employeeId', employeeId);
    }
    return this.httpClient.get(`${this.urlBase}/export`, { params, responseType: 'arraybuffer' });
  }

  getEmployeePicture(userId: number): Observable<any> {
    return this.httpClient.get(`${this.urlBase}/${userId}/photo/${Date.now()}.jpg`, {
      responseType: 'blob',
    });
  }

  getAllManagerSubordinates(managerId: number): Observable<IManagerSubordinatesModel[]> {
    return this.httpClient.get<IManagerSubordinatesModel[]>(
      `${this.urlBase}/getManagerSubordinates/${managerId}`,
    );
  }

  getHeadcount(fromDate: string): Observable<any> {
    return this.httpClient.get(`${this.urlBase}/headcount?fromDate=${fromDate}`, {
      responseType: 'arraybuffer',
    });
  }
}
