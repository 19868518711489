<mat-spinner *ngIf="isLoading"></mat-spinner>

<div [hidden]="isLoading">
  <h2 class="section-title">
    {{ 'ADMIN.DREAMIX_BIRTHDAYS.DREAMIX_BIRTHDAYS' | translate }}
  </h2>

  <div class="table-responsive">
    <table mat-table [dataSource]="birthdays">
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'ADMIN.DREAMIX_BIRTHDAYS.EMPLOYEE_NAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let employee">
          {{ employee.fullName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dreamixBirthday">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'ADMIN.DREAMIX_BIRTHDAYS.DREAMIX_BIRTHDAYS' | translate }}
        </th>
        <td mat-cell *matCellDef="let employee">
          {{ employee.dreamixBirthday | date: 'dd MMMM' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="YearsAtDreamix">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'ADMIN.DREAMIX_BIRTHDAYS.YEARS' | translate }}
        </th>
        <td mat-cell *matCellDef="let employee">
          {{ getYearsFrom(employee.dreamixBirthday) }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        [ngClass]="{
          lessTwoWeeks: checkIfNWeeksBefore(employee, 2),
          lessFourWeeks: checkIfNWeeksBefore(employee, 4)
        }"
        *matRowDef="let employee; columns: displayedColumns"
      ></tr>
    </table>
  </div>
</div>
