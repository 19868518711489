import { formatDate } from '@angular/common';
import { Component, OnDestroy, ViewChild } from '@angular/core';

import { NgForm, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subject, takeUntil, combineLatest } from 'rxjs';
import { IEmployee, IHoliday } from 'src/app/shared/models';
import {
  AlertService,
  AlertType,
  LoginService,
  ObservablesService,
  RequestService,
  SharedDataService,
} from 'src/app/shared/services';
export interface HolidayWorkingRequest {
  id?: number;
  requesterId: IEmployee['id'];
  compensationType: string;
  dates: {
    date: string;
    pattern: number;
  }[];
  days: number;
}
type CompensationTypes = 'PAYMENT' | 'DAYS_OFF';
interface RequestDetails {
  compensationType: CompensationTypes;
  dates: {
    [id: string]: {
      types: {
        morning: boolean;
        afternoon: boolean;
      };
    };
  };
}
type MultiTypes = {
  [key in keyof RequestDetails['dates'][string]['types']]: boolean;
};
@Component({
  selector: 'app-holiday-working-form',
  templateUrl: './holiday-working-form.component.html',
  styleUrls: ['./holiday-working-form.component.scss'],
})
export class HolidayWorkingFormComponent implements OnDestroy {
  @ViewChild('reqForm') reqForm: NgForm;
  @ViewChild('holidayPicker') holidayPicker: MatCalendar<Date>;
  dateClass = (cellDate, view) => {
    // Only highligh dates inside the month view.
    if (view === 'month') {
      const date = cellDate.getTime();
      return this.selectedDates.find((x) => x.getTime() === date)
        ? 'calendar-directive-selected'
        : null;
    }
  };
  private futureHolidaysDates: Array<string>;
  private destroy$ = new Subject<void>();
  public selectedDates: Date[] = [];
  public days: number = 0;
  public requestDetails: RequestDetails = {
    compensationType: null,
    dates: {},
  };
  public multiTypes: MultiTypes = {
    morning: true,
    afternoon: true,
  };
  public requestForm: UntypedFormGroup;

  public manualDaysFormControl: UntypedFormControl;

  private user: IEmployee;
  public allHolidays: Array<IHoliday> = undefined;

  public isLoading: boolean = false;
  constructor(
    public loginService: LoginService,
    public observablesService: ObservablesService,
    public translate: TranslateService,
    private sharedDataService: SharedDataService,
    private requestService: RequestService,
    private alertService: AlertService,
  ) {
    this.user = this.loginService.getUser();
    this.getDataFromDb();
    this.initForm();
  }

  private initForm() {
    this.manualDaysFormControl = new UntypedFormControl('');
    this.requestForm = new UntypedFormGroup({
      manualDaysFormControl: this.manualDaysFormControl,
    });
  }

  public handleSelect = (date, dateIndex) => {
    if (dateIndex == -1) {
      this.requestDetails.dates = {
        ...this.requestDetails.dates,
        [date]: {
          types: {
            morning: true,
            afternoon: true,
          },
        },
      };
    } else {
      delete this.requestDetails.dates[date];
    }
    this.updateDays();
  };
  private getDataFromDb(): void {
    combineLatest([this.sharedDataService.holidaysState$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([holidays]) => {
        this.allHolidays = holidays;
        this.futureHolidaysDates = holidays
          .filter((holiday) => this.isFutureDate(holiday.date))
          .map((holiday) => new Date(holiday.date).toLocaleDateString());
        this.isLoading = !this.allHolidays?.length;
      });
  }
  public handleRadioCheck = (value: CompensationTypes) => {
    this.requestDetails.compensationType = value;
  };
  public dateFilter = (date: Date | null): boolean => {
    if (!date) {
      return false;
    }

    const isFutureDate = this.isFutureDate(date);

    if (!isFutureDate) {
      return false;
    }

    const isWeekend: boolean = date.getDay() === 0 || date.getDay() === 6;

    if (isWeekend) {
      return false;
    }

    const isHoliday: boolean = this.futureHolidaysDates.indexOf(date.toLocaleDateString()) >= 0;
    return isHoliday;
  };

  private updateDays = () => {
    this.days = Object.keys(this.requestDetails.dates).reduce((sum, key) => {
      let currentDayCount = 0;
      const currTypes = this.requestDetails.dates[key].types;
      if (currTypes.afternoon) {
        currentDayCount += 0.5;
      }
      if (currTypes.morning) {
        currentDayCount += 0.5;
      }
      return currentDayCount + sum;
    }, 0);
  };
  private updateMultiDay = (field: string) => {
    const falseFields = Object.keys(this.requestDetails.dates).find((key) => {
      const date = this.requestDetails.dates[key];
      return date.types[field] === false;
    });
    this.multiTypes[field] = !falseFields;
  };
  public updatePartialDays(field: string, checked: boolean, date?: string): void {
    if (date) {
      this.requestDetails.dates[date].types[field] = checked;
      if (!checked) {
        this.multiTypes[field] = checked;
      }
    } else {
      const currentDetails = { ...this.requestDetails };
      this.requestDetails = Object.keys(currentDetails.dates).reduce((obj, key) => {
        return (obj.dates[key].types[field] = checked), obj;
      }, currentDetails);
      this.multiTypes[field] = checked;
    }
    this.updateDays();
    this.updateMultiDay(field);
  }

  public onSubmit(form: UntypedFormGroup): void {
    if (form.valid) {
      this.sendForm();
    }
  }

  private async sendForm(): Promise<void> {
    try {
      const payload = (await new Promise((res, rej) => {
        const data: HolidayWorkingRequest = {
          requesterId: this.user.id,
          dates: Object.keys(this.requestDetails.dates).map((key) => {
            const type = this.requestDetails.dates[key].types;
            let pattern = 0;
            pattern = type.morning ? 1 : 0;
            pattern = type.afternoon ? (pattern === 1 ? 3 : 2) : pattern;
            if (pattern === 0) {
              rej('invalidPattern');
            }
            return {
              date: formatDate(new Date(key), 'yyyy-MM-dd', 'en-US'),
              pattern,
            };
          }),
          compensationType: this.requestDetails.compensationType,
          days: this.days,
        };
        res(data);
      })) as HolidayWorkingRequest;
      this.requestService
        .createHolidayWorkingRequest(payload)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.observablesService.requestFormSent.next(true);

            this.alertService.showAlert(
              this.translate.instant('HOME.REQUEST_FORM.SUCCESSFULLY_SENT').toString(),
              AlertType.success,
            );
            this.resetForm();
          },
          error: (error) => {
            if (error.error) {
              this.alertService.showAlert(error.error, AlertType.error);
            } else {
              this.alertService.showAlert(error.message, AlertType.error);
            }
          },
        });
    } catch (e) {
      if (e === 'invalidPattern') {
        this.alertService.showAlert(
          this.translate.instant('HOME.REQUEST_FORM.NOT_MARKED_MORNING_OR_AFTERNOON').toString(),
          AlertType.error,
        );
      } else {
        //TODO: make this global
        this.alertService.showAlert(
          this.translate.instant('SHARED.ERRORS.TECHNICAL').toString(),
          AlertType.error,
        );
      }
    }
  }

  private resetForm(): void {
    this.requestDetails.dates = {};
    this.selectedDates = [];
    this.days = 0;
    this.reqForm.resetForm();
    this.holidayPicker.updateTodaysDate();
  }

  private isFutureDate(date) {
    const dateFormat = 'YYYY-MM-DD';
    const isFutureDate = moment(date, dateFormat).isSameOrAfter(
      moment(new Date()).format(dateFormat),
    );
    return isFutureDate;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
