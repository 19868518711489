import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Input,
  OnInit,
  signal,
  Signal,
  ViewChild,
} from '@angular/core';

// import { formatDate } from '@angular/common';
import { IPagination } from '../../shared/models/pagination.model';
import { MatSort } from '@angular/material/sort';
import { PageableHelperService } from '../../shared/services/helpers/pageable-helper.service';
import { IPageable } from '../../shared/models/pageable.model';
import { IReferral } from '../../shared/models/referral.model';
import { ReferralsService } from '../../shared/services/referrals.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminReferralsComponent implements OnInit {
  @Input() page?: string;
  @Input() size?: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Table:
  // this must be change when signals work with two way data binding.
  pageable: IPageable;

  // new way of injecting services
  private referralsService = inject(ReferralsService);
  private pageableHelper = inject(PageableHelperService);

  // signals
  tableConfigSignal: Signal<IPagination<IReferral>> = this.referralsService.referrals;
  sortByColumns: Signal<string[]> = signal([]);

  constructor() {
    this.sortByColumns = computed(() => {
      if (!this.tableConfigSignal()) return;
      return this.tableConfigSignal().config.sortColumns;
    });
  }

  ngOnInit(): void {
    const params = this.getHttpParams();
    this.referralsService.fetchReferrals(params);
  }

  fetchReferrals(): void {
    this.referralsService.fetchReferrals(this.getHttpParams());
  }

  getHttpParams(): HttpParams {
    this.pageable = this.pageable
      ? this.pageable
      : this.pageableHelper.getPageable({
          page: this.page,
          size: this.size,
        });
    return this.pageableHelper.getHttpParams(this.pageable);
  }

  isSortingDisabled(column: string): boolean {
    if (!this.sortByColumns()) return;
    return !this.sortByColumns().some((c) => c === column);
  }
}
