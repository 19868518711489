<h2 class="section-title">
  {{
    !inEdit
      ? ('USER.OVERTIME.REQUEST_FORM.SUBMIT_NEW_OVERTIME_REQUEST' | translate)
      : ('USER.OVERTIME.REQUEST_FORM.EDIT_OVERTIME_REQUEST' | translate)
  }}
</h2>
<form
  [formGroup]="requestForm"
  #otReqForm="ngForm"
  class="excel-form"
  (ngSubmit)="onSubmit(requestForm)"
>
  <div class="flex-container">
    <mat-form-field
      color="primary"
      appearance="fill"
      (click)="startPicker.open()"
      class="datePickerInputs"
    >
      <mat-label>{{ 'USER.OVERTIME.REQUEST_FORM.DATE' | translate }}</mat-label>
      <input
        matInput
        [min]="minDate"
        [max]="maxDate"
        [matDatepicker]="startPicker"
        formControlName="dateFormControl"
        readonly
        required
      />
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker #startPicker></mat-datepicker>
      <mat-error *ngIf="requestForm.get('dateFormControl').invalid">{{
        getErrorMessage('date')
      }}</mat-error>
    </mat-form-field>

    <mat-form-field id="hoursInput" color="primary" appearance="fill">
      <mat-label>Hours</mat-label>
      <input matInput type="number" formControlName="hoursFormControl" min="1" max="24" required />
      <mat-error *ngIf="requestForm.get('hoursFormControl').invalid">{{
        getErrorMessage('hours')
      }}</mat-error>
    </mat-form-field>
  </div>

  <mat-form-field appearance="fill" class="form-item-full">
    <mat-label>{{ 'USER.OVERTIME.REQUEST_FORM.OVERTIME_TYPE' | translate }}</mat-label>
    <mat-select formControlName="overtimeTypeFormControl" required>
      <mat-option *ngFor="let type of allOvertimeTypes" [value]="type.value">
        {{ type.viewValue | translate }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="requestForm.get('overtimeTypeFormControl').invalid">{{
      getErrorMessage('overtimeType')
    }}</mat-error>
  </mat-form-field>

  <mat-form-field
    id="compensation-type-form-field"
    class="form-item-full"
    appearance="fill"
    *ngIf="requestForm.get('overtimeTypeFormControl').value === 'TEACHING'"
  >
    <mat-label>{{ 'USER.OVERTIME.REQUEST_FORM.I_WOULD_LIKE' | translate }}</mat-label>
    <mat-select formControlName="compensationTypeFormControl">
      <mat-option *ngFor="let type of allCompensationTypes" [value]="type.value">
        {{ type.viewValue | translate }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="requestForm.get('compensationTypeFormControl').invalid">{{
      getErrorMessage('compensation')
    }}</mat-error>
  </mat-form-field>

  <mat-form-field
    color="primary"
    appearance="fill"
    class="form-item-full"
    *ngIf="requestForm.get('overtimeTypeFormControl').value === 'WORKING'"
  >
    <mat-label>{{ 'USER.OVERTIME.REQUEST_FORM.PROJECT' | translate }}</mat-label>
    <mat-select formControlName="projectsFormControl">
      <mat-option *ngFor="let project of allProjects" [value]="project.id">
        {{ project.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="requestForm.get('projectsFormControl').invalid">{{
      getErrorMessage('project')
    }}</mat-error>
  </mat-form-field>

  <mat-form-field color="primary" appearance="fill" class="form-item-full">
    <mat-label>{{ 'USER.OVERTIME.REQUEST_FORM.APPROVER_EMAIL' | translate }}</mat-label>
    <input
      matInput
      type="text"
      placeholder="approver@dreamix.eu"
      formControlName="emailFormControl"
      [matAutocomplete]="auto"
      required
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let email of allEmailsObservable | async" [value]="email">
        {{ email }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="requestForm.get('emailFormControl').invalid">{{
      getErrorMessage('email')
    }}</mat-error>
  </mat-form-field>

  <div class="radio-buttons-description-holder">
    <div *ngIf="requestForm.get('overtimeTypeFormControl').value === 'WORKING'">
      <mat-radio-group formControlName="requestedByClientFormControl" required>
        <label>{{ 'USER.OVERTIME.REQUEST_FORM.REQUESTED_BY_CLIENT' | translate }}</label>
        <mat-radio-button color="primary" [value]="true">{{
          'USER.OVERTIME.REQUEST_FORM.YES' | translate
        }}</mat-radio-button>
        <mat-radio-button color="primary" [value]="false">{{
          'USER.OVERTIME.REQUEST_FORM.NO' | translate
        }}</mat-radio-button>
      </mat-radio-group>

      <mat-error
        class="radio-button-error"
        *ngIf="requestForm.get('requestedByClientFormControl').invalid"
        >{{ getErrorMessage('radio-buttons') }}</mat-error
      >
    </div>

    <mat-form-field appearance="fill" class="form-item-full">
      <mat-label>{{ 'USER.OVERTIME.REQUEST_FORM.DESCRIPTION' | translate }}</mat-label>
      <textarea
        matInput
        formControlName="descriptionFormControl"
        maxlength="255"
        [required]="requestForm.get('requestedByClientFormControl').value === true"
      ></textarea>
      <mat-hint align="end"
        >{{ requestForm.get('descriptionFormControl').value?.length || 0 }}/255
      </mat-hint>
      <mat-error *ngIf="requestForm.get('descriptionFormControl').invalid">{{
        getErrorMessage('description')
      }}</mat-error>
    </mat-form-field>
  </div>
  <div class="form-buttons align-center">
    <button
      type="submit"
      class="form-item-full"
      mat-raised-button
      color="primary"
      [disabled]="isRequestLoading"
    >
      {{ (this.inEdit ? 'SHARED.EDIT' : 'SHARED.SUBMIT') | translate }}
    </button>
    <button
      *ngIf="this.inEdit"
      class="form-item-full"
      color="warn"
      mat-raised-button
      (click)="this.resetForm()"
    >
      {{ 'SHARED.DIALOGS.DIALOG.CANCEL' | translate }}
    </button>
  </div>
</form>
