import { Component, OnDestroy } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
import * as FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { ReimbursementService } from '../../../shared/services/reimbursement.service';
import { AlertService, AlertType } from '../../../shared/services';

@Component({
  selector: 'app-reimbursements-export-excel',
  templateUrl: './reimbursements-export-excel.component.html',
  styleUrls: ['./reimbursements-export-excel.component.scss'],
})
export class ReimbursementsExportExcelComponent implements OnDestroy {
  reportStartDate: Date | null = null;
  reportEndDate: Date | null = null;
  reimbursementsSubscription!: Subscription;

  constructor(
    private alertService: AlertService,
    public translate: TranslateService,
    private reimbursementService: ReimbursementService,
  ) {}

  submitStartDate(date: MatDatepickerInputEvent<Date>): void {
    this.reportStartDate = date.value;
  }

  submitEndDate(date: MatDatepickerInputEvent<Date>): void {
    this.reportEndDate = date.value;
  }

  onSubmit(): void {
    if (!this.validateInput()) {
      return;
    }

    const fromDate = formatDate(this.reportStartDate, 'yyyy-MM-dd', 'en-US');
    const toDate = formatDate(this.reportEndDate, 'yyyy-MM-dd', 'en-US');
    this.reimbursementsSubscription = this.reimbursementService
      .getAllReimbursements(fromDate, toDate)
      .subscribe(
        (response) => {
          this.saveAsExcelFile(response, 'Report_reimbursements');
        },
        (error) => this.alertService.showAlert(error.message, AlertType.error),
      );
  }

  private validateInput(): boolean {
    if (!this.reportStartDate) {
      this.alertService.showAlert(
        this.translate.instant('ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.FILL_START_DATE').toString(),
        AlertType.error,
      );
      return false;
    } else if (!this.reportEndDate) {
      this.alertService.showAlert(
        this.translate.instant('ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.FILL_END_DATE').toString(),
        AlertType.error,
      );
      return false;
    } else if (this.reportStartDate > this.reportEndDate) {
      this.alertService.showAlert(
        this.translate
          .instant('ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.FILL_VALID_INTERVAL')
          .toString(),
        AlertType.error,
      );
      return false;
    } else {
      return true;
    }
  }

  saveAsExcelFile(excelData: ArrayBuffer, fileName: string): void {
    const data: Blob = new Blob([excelData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(
      data,
      fileName + '_' + formatDate(new Date(), 'yyyy-MM-dd', 'en-US') + '.xlsx',
    );
  }

  ngOnDestroy() {
    if (this.reimbursementsSubscription) {
      this.reimbursementsSubscription.unsubscribe();
    }
  }
}
