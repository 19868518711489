<mat-spinner *ngIf="!tableConfigSignal()"></mat-spinner>

<div [hidden]="!tableConfigSignal()">
  <h2 class="section-title">
    {{ 'ADMIN.REFERRALS.TITLE' | translate }}
  </h2>

  <app-table-wrapper
    (getData)="fetchReferrals()"
    [tableConfig]="tableConfigSignal()"
    [(pageable)]="pageable"
    matSort
    [sort]="sort"
    [isUrlChangeable]="true"
  >
    <ng-container matColumnDef="referralName">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('referralName')"
      >
        {{ 'ADMIN.REFERRALS.REFERRAL' | translate }}
      </th>
      <td mat-cell *matCellDef="let referral">
        {{ referral.referralName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="referralPosition">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('referralPosition')"
      >
        {{ 'ADMIN.REFERRALS.REFERRAL_POSITION' | translate }}
      </th>
      <td mat-cell *matCellDef="let referral">
        {{ referral.referralPosition }}
      </td>
    </ng-container>

    <ng-container matColumnDef="referralStartDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('referralStartDate')"
      >
        {{ 'ADMIN.REFERRALS.REFERRAL_START_DATE' | translate }}
      </th>
      <td mat-cell *matCellDef="let referral">
        {{ referral.referralStartDate | date: 'dd.MM.yyyy' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="referrerName">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('referrerName')"
      >
        {{ 'ADMIN.REFERRALS.REFERRER' | translate }}
      </th>
      <td mat-cell *matCellDef="let referral">
        {{ referral.referrerName }}
      </td>
    </ng-container>
  </app-table-wrapper>
</div>
