<div class="dialog-container">
  <div class="title-holder align-center">
    <h1 mat-dialog-title>
      {{ (showSubmitForm ? 'USER.MY_SETTINGS.ADD_SKILLS' : 'USER.MY_SETTINGS.SKILLS') | translate }}
    </h1>
    <button mat-mini-fab class="close-button" color="primary" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content *ngIf="showSubmitForm">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-form-field class="full-width" color="primary" appearance="fill">
        <mat-label> {{ 'USER.MY_SETTINGS.SKILLS' | translate }}</mat-label>
        <mat-chip-grid #chipGrid>
          <mat-chip-row *ngFor="let skill of selectedSkills" (removed)="removeSkillOfList(skill)">
            {{ skill.name }}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input
          placeholder="{{ 'USER.MY_SETTINGS.ADD_SKILL' | translate }}"
          #skillInput
          [formControl]="skillCtrl"
          [matChipInputFor]="chipGrid"
          [matAutocomplete]="auto"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addSkill($event)"
          (focusout)="clearInputValue()"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          autoActiveFirstOption
          (optionSelected)="selectedSkill($event)"
        >
          <ng-container *ngFor="let skill of filteredSkills | async">
            <mat-option [value]="skill" *ngIf="!isSkillInList(skill)">
              {{ skill.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <div class="inputs-holder">
        <div class="slider-holder">
          <div class="slider-label">
            <label>{{ 'USER.MY_SETTINGS.PROFICIENCY_LEVEL' | translate }}</label>
            <mat-icon
              class="info-icon"
              matTooltip="{{ 'USER.MY_SETTINGS.PROFICIENCY_LEVEL_TOOLTIP' | translate }}"
              [matTooltipPosition]="'after'"
              matTooltipClass="tooltip"
              >info_outlined
            </mat-icon>
          </div>

          <mat-slider min="1" max="5" step="1" showTickMarks discrete [displayWith]="formatLabel">
            <input matSliderThumb formControlName="proficiencyLevel" [min]="1" />
          </mat-slider>
        </div>

        <mat-form-field color="accent" appearance="fill" class="years-of-experience">
          <mat-label>{{ 'USER.MY_SETTINGS.YEARS_OF_EXPERIENCE' | translate }}</mat-label>
          <input matInput type="number" [min]="1" formControlName="yearsOfExperience" />
        </mat-form-field>
      </div>
      <div class="flex-end">
        <button mat-raised-button color="primary" type="submit">
          {{ 'SHARED.SUBMIT' | translate }}
        </button>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-content class="table-holder">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="skillName"
      matSortStart="desc"
    >
      <ng-container matColumnDef="skillName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'USER.MY_SETTINGS.SKILL' | translate }}
        </th>
        <td mat-cell *matCellDef="let skill">{{ skill.skillName }}</td>
      </ng-container>

      <ng-container matColumnDef="proficiencyLevel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'USER.MY_SETTINGS.LEVEL' | translate }}
        </th>
        <td mat-cell *matCellDef="let skill">{{ skill.proficiencyLevel }}</td>
      </ng-container>

      <ng-container matColumnDef="yearsOfExperience">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'USER.MY_SETTINGS.EXPERIENCE' | translate }}
        </th>
        <td mat-cell mat-cell *matCellDef="let skill">{{ skill.yearsOfExperience }}</td>
      </ng-container>

      <ng-container matColumnDef="userAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let skill">
          <div *ngIf="showSubmitForm">
            <mat-icon
              matTooltip="{{ 'SHARED.DELETE' | translate }}"
              (click)="deleteSkill(skill)"
              class="delete-icon"
              >cancel
            </mat-icon>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="10">
          {{ 'USER.REIMBURSEMENT_REQUESTS.NO_DATA' | translate }}
        </td>
      </tr>
    </table>
  </mat-dialog-content>
</div>
