import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { AbstractControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { RequestService } from '../../../shared/services';
import { IRequest } from '../../../shared/models';
import { DialogComponent } from '../../../shared/dialogs';
import { MatSelectChange } from '@angular/material/select';
import { RequestStatusEnum } from '../../../shared/enums/request-status.enum';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';
import { WorkSheet } from 'xlsx';
import { Subject, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DIALOG_BUTTONS } from '../../../shared/constants';
import { VacationSubtypeEnum, VacationTypeEnum } from '../../../shared/enums';
import { HttpParams } from '@angular/common/http';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-requests-filter',
  templateUrl: './requests-filter.component.html',
  styleUrls: ['./requests-filter.component.scss'],
})
export class RequestsFilterComponent implements OnDestroy, OnInit {
  @Output() tableUpdateEvent: EventEmitter<HttpParams> = new EventEmitter();

  // Inputs
  filterForm: FormGroup;
  selectedStartDate: Date;

  availableStatuses: string[] = [
    RequestStatusEnum.PENDING,
    RequestStatusEnum.APPROVED,
    RequestStatusEnum.DECLINED,
  ];
  availableVacationTypes: string[] = [
    VacationTypeEnum.VACATION,
    VacationTypeEnum.SICK_LEAVE,
    VacationTypeEnum.MATERNITY_PATERNITY,
    VacationTypeEnum.HOME_OFFICE,
    VacationTypeEnum.WORK_ON_HOLIDAYS,
    VacationTypeEnum.OTHER_PAID_LEAVE,
    VacationTypeEnum.LEAVE_OF_ABSENCE,
  ];
  public availablePaidVacationSubtypes: Array<string> = [
    VacationSubtypeEnum.MARRIAGE,
    VacationSubtypeEnum.BLOOD_DONATION,
    VacationSubtypeEnum.RELATIVE_DEATH,
    VacationSubtypeEnum.COURT_HEARING,
    VacationSubtypeEnum.MEMBER_OF_GOVERNMENT_MEETING,
    VacationSubtypeEnum.TRAINING_FOR_DISASTER_SITUATION,
    VacationSubtypeEnum.EMPLOYER_NOTICE_FOR_DISMISSAL,
    VacationSubtypeEnum.OTHER_PROJECT,
  ];
  public availableSickLeaveVacationSubtypes: Array<string> = [
    VacationSubtypeEnum.REGULAR_SICK_LEAVE,
    VacationSubtypeEnum.DREAMIX_SICK_LEAVE,
  ];
  availableVacationSubtypes: string[] = [];
  availableFileOptions: string[] = ['No request', 'Request Attached'];

  // Result set
  requests: IRequest[];

  private destroy$ = new Subject<void>();

  constructor(
    private requestService: RequestService,
    public translate: TranslateService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.buildFilterForm();
  }

  public buildFilterForm(): void {
    this.filterForm = new FormGroup({
      fromDate: new UntypedFormControl(),
      toDate: new UntypedFormControl(),
      createdOnDate: new UntypedFormControl(),
      statuses: new UntypedFormControl(),
      vacationTypes: new UntypedFormControl(),
      vacationSubTypes: new UntypedFormControl(),
      hasFile: new UntypedFormControl(),
      requester: new UntypedFormControl(),
      approver: new UntypedFormControl(),
    });
  }

  getStartDate(event: MatDatepickerInputEvent<Date>) {
    this.selectedStartDate = event.value;

    if (!this.selectedStartDate || this.selectedStartDate > this.filterForm.get('toDate').value) {
      this.filterForm.get('toDate').setValue(null);
    }
  }

  endDateFilter = (date: Date | null): boolean =>
    !date || (this.selectedStartDate && date >= this.selectedStartDate);

  public resetFilterForm(): void {
    this.availableVacationSubtypes = [];
    this.filterForm.reset();
    this.selectedStartDate = null;
    this.tableUpdateEvent.emit(this.constructExportParams());
  }

  public search(): void {
    this.tableUpdateEvent.emit(this.constructExportParams());
  }

  public exportExcel(): void {
    this.requestService
      .getAllRequestsFiltered(this.constructExportParams())
      .pipe(takeUntil(this.destroy$))
      .subscribe((page) => {
        if (page.totalElements === 0) {
          this.openRequestsNotFoundDialog();
          return;
        }
        const mappedByTypeRequests: {
          [sheet: string]: Array<any> | WorkSheet;
        } = {};
        page.items.forEach((request) => {
          const type = (request.subType ?? request.vacationType).replace(/[\/?*[]]*/, '');

          const item = {
            status: request.status,
            fromDate: request.fromDate,
            toDate: request.toDate,
            createdOn: request.createdOn,
            resolvedOn: request.resolvedOn,
            days: request.days,
            additionalInfo: request.additionalInfo,
            vacationType: request.subType ?? request.vacationType,
            requesterEmail: request.requester.email,
            fullName: request.requester.firstNameEn + ' ' + request.requester.lastNameEn,
            fullNameBg: request.requester.firstName + ' ' + request.requester.lastName,
            approverEmail: request.approver.email,
          };

          if (!mappedByTypeRequests[type]) {
            mappedByTypeRequests[type] = [];
          }

          if (request.days) {
            mappedByTypeRequests[type].push(item);
          }
        });

        Object.keys(mappedByTypeRequests).forEach((k) => {
          mappedByTypeRequests[k] = XLSX.utils.json_to_sheet(mappedByTypeRequests[k] as Array<any>);
        });

        const workbook: XLSX.WorkBook = {
          Sheets: mappedByTypeRequests,
          SheetNames: Object.keys(mappedByTypeRequests),
        };
        const excelBuffer: BlobPart = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        this.saveAsExcelFile(excelBuffer, 'Report_vacations');
      });
  }

  private constructExportParams(): HttpParams {
    const filter = {
      fromDate: this.extractDateValue(this.filterForm.controls.fromDate),
      toDate: this.extractDateValue(this.filterForm.controls.toDate),
      createdOn: this.extractDateValue(this.filterForm.controls.createdOnDate),

      statuses: this.filterForm.controls.statuses.value?.map(
        (
          status: RequestStatusEnum, // do some magic to convert enum values to keys
        ) => Object.keys(RequestStatusEnum)[Object.values(RequestStatusEnum).indexOf(status)],
      ),
      vacationTypes: this.filterForm.controls.vacationTypes.value?.map(
        (
          type: VacationTypeEnum, // more magic
        ) => Object.keys(VacationTypeEnum)[Object.values(VacationTypeEnum).indexOf(type)],
      ),
      vacationSubtypes: this.filterForm.controls.vacationSubTypes.value?.map(
        (
          type: VacationSubtypeEnum, // avada kedavra
        ) => Object.keys(VacationSubtypeEnum)[Object.values(VacationSubtypeEnum).indexOf(type)],
      ),
      fileIsPresent:
        this.filterForm.controls.hasFile.pristine === true
          ? null
          : this.filterForm.controls.hasFile.value === this.availableFileOptions[1],

      requester: this.filterForm.controls.requester.value?.valueOf(),
      approver: this.filterForm.controls.approver.value?.valueOf(),
    };

    let params = new HttpParams();

    Object.keys(filter).forEach((key) => filter[key] && (params = params.append(key, filter[key])));

    return params;
  }

  private extractDateValue(date: AbstractControl) {
    if (date.value !== null && !isNaN(Date.parse(date.value.toISOString()))) {
      return formatDate(date.value, 'yyyy-MM-dd', 'en-US');
    }

    return null;
  }

  private saveAsExcelFile(buffer: BlobPart, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(
      data,
      fileName + '_' + formatDate(new Date(), 'yyyy-MM-dd', 'en-US') + '.xlsx',
    );
  }

  private openRequestsNotFoundDialog() {
    this.dialog.open(DialogComponent, {
      data: {
        title: this.translate
          .instant('ADMIN.REQUESTS.REQUESTS_EXPORT_EXCEL.NO_REQUESTS_DIALOG_TITLE')
          .toString(),
        description: this.translate
          .instant('ADMIN.REQUESTS.REQUESTS_EXPORT_EXCEL.NO_REQUESTS_DIALOG_DESCRIPTION')
          .toString(),
        sharedButtonClass: DIALOG_BUTTONS.warningButton,
        sharedButtonText: this.translate.instant('SHARED.DIALOGS.DIALOG.CLOSE').toString(),
        showCancelButton: false,
      },
    });
  }

  adaptSubtypes(event: MatSelectChange): void {
    this.availableVacationSubtypes = [];

    if (event.value.includes(VacationTypeEnum.SICK_LEAVE)) {
      this.availableVacationSubtypes = this.availableVacationSubtypes.concat(
        this.availableSickLeaveVacationSubtypes,
      );
    }

    if (event.value.includes(VacationTypeEnum.OTHER_PAID_LEAVE)) {
      this.availableVacationSubtypes = this.availableVacationSubtypes.concat(
        this.availablePaidVacationSubtypes,
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
