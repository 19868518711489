<mat-spinner *ngIf="isLoading"></mat-spinner>
<h2 class="section-title">
  {{ 'ADMIN.UTILIZATION_RATES.TITLE' | translate }}
</h2>
<form [formGroup]="utilizationReportForm" (ngSubmit)="onSubmit()">
  <div class="date-controls">
    <mat-form-field (click)="fromDate.open()" color="primary" appearance="fill">
      <mat-label>
        {{ 'SHARED.COMPONENTS.INPUTS.FROM_DATE' | translate }}
      </mat-label>
      <input
        matInput
        [matDatepicker]="fromDate"
        (dateInput)="getStartDate($event)"
        formControlName="fromDate"
      />
      <mat-error *ngIf="utilizationReportForm.controls.fromDate.errors?.required">{{
        'SHARED.COMPONENTS.INPUTS.START_DATE_REQUIRED' | translate
      }}</mat-error>
      <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
      <mat-datepicker #fromDate color="primary"></mat-datepicker>
    </mat-form-field>

    <mat-form-field (click)="toDate.open()" color="primary" appearance="fill">
      <mat-label>
        {{ 'SHARED.COMPONENTS.INPUTS.TO_DATE' | translate }}
      </mat-label>
      <input matInput [min]="minDate" [matDatepicker]="toDate" formControlName="toDate" />
      <mat-error *ngIf="utilizationReportForm.controls.toDate.errors?.required">{{
        'SHARED.COMPONENTS.INPUTS.END_DATE_REQUIRED' | translate
      }}</mat-error>
      <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
      <mat-datepicker #toDate color="primary"></mat-datepicker>
    </mat-form-field>
  </div>

  <div>
    <label> {{ 'ADMIN.UTILIZATION_RATES.SELECTION_LABEL' | translate }} </label>
    <mat-radio-group color="primary" aria-label="Select an option" formControlName="option">
      <mat-radio-button checked value="employee">
        {{ 'ADMIN.UTILIZATION_RATES.BY_EMPLOYEE' | translate }}</mat-radio-button
      >
      <mat-radio-button value="project">{{
        'ADMIN.UTILIZATION_RATES.BY_PROJECT' | translate
      }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <mat-form-field
    class="autocomplete-input"
    color="primary"
    appearance="fill"
    *ngIf="
      this.utilizationReportForm.controls.option.value === utilizationRateExportOptions.PROJECT
    "
  >
    <mat-label>{{ 'ADMIN.UTILIZATION_RATES.SELECT_PROJECT' | translate }}</mat-label>
    <input
      matInput
      type="text"
      placeholder="Select project"
      formControlName="project"
      [matAutocomplete]="projectAuto"
    />
    <mat-autocomplete
      #projectAuto="matAutocomplete"
      [displayWith]="displayProjectFn"
      (optionSelected)="selectProject($event)"
    >
      <ng-container *ngFor="let project of filteredProjects | async">
        <mat-option [value]="project">
          {{ project.projectName }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
    <mat-error *ngIf="this.utilizationReportForm.get('project').hasError('projectNameMatch')">
      {{ 'SHARED.PROJECT.NAME_REQUIRE_MATCH' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field
    class="autocomplete-input"
    color="primary"
    appearance="fill"
    *ngIf="
      this.utilizationReportForm.controls.option.value === utilizationRateExportOptions.EMPLOYEE
    "
  >
    <mat-label>{{ 'ADMIN.UTILIZATION_RATES.SELECT_EMPLOYEE' | translate }}</mat-label>
    <input
      matInput
      type="text"
      placeholder="Select employee"
      formControlName="employee"
      [matAutocomplete]="employeeAuto"
    />
    <mat-autocomplete
      #employeeAuto="matAutocomplete"
      [displayWith]="displayEmployeeFn"
      (optionSelected)="selectEmployee($event)"
    >
      <ng-container *ngFor="let employee of filteredEmployees | async">
        <mat-option [value]="employee">
          {{ employee.firstNameEn + ' ' + employee.lastNameEn }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
    <mat-error *ngIf="this.utilizationReportForm.get('employee').hasError('employeeNameMatch')">
      {{ 'SHARED.EMPLOYEE.NAME_REQUIRE_MATCH' | translate }}
    </mat-error>
  </mat-form-field>

  <button
    mat-raised-button
    color="primary"
    class="excel-button"
    type="submit"
    [disabled]="this.utilizationReportForm.invalid"
  >
    <mat-icon>download </mat-icon>
    {{ 'ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.EXCEL' | translate }}
  </button>
</form>
