import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {
  MAX_ZOOM,
  MIN_ZOOM,
  CEO_ID,
  DEFAULT_PHOTO,
  DEFAULT_ZOOM,
  DEFAULT_X,
  DEFAULT_Y,
} from './orgination.config';
import { EmployeeService } from '../../shared/services';
import { IManagerSubordinatesModel } from '../../shared/models/manager-subordinates.model';
import { IEmployee } from 'src/app/shared/models';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit {
  private zoomLevel = DEFAULT_ZOOM;
  //Values used to offeset graph/background;
  private currentX = DEFAULT_X;
  private currentY = DEFAULT_Y;

  //Bounds of the graph
  private DELTA_X_CUTOFF = 1000;
  private DELTA_Y_CUTOFF = 1000;

  treeData: TreeNode[] = [];
  @ViewChild('treeWrapper') treeWrapperEl: ElementRef;
  @ViewChild('gridWrapper') gridWrapperEl: ElementRef;

  constructor(
    private renderer: Renderer2,
    public translate: TranslateService,
    private employeeService: EmployeeService,
  ) {}

  ngOnInit() {
    this.employeeService.getAllManagerSubordinates(CEO_ID).subscribe((managerSubordinatesList) => {
      this.fillManagerSubordinatesTree(managerSubordinatesList, this.treeData);
      this.calculateDeltaYCutoff();
    });
  }

  /* Grid functions */

  private calculateDeltaYCutoff = () => {
    const largestRow = this.getTreeLargestRow(this.treeData[0], 0);

    //Give 200px for each node height and 500px buffer
    this.DELTA_Y_CUTOFF = largestRow * 200 + 500;
  };

  public onMouseDown(event: MouseEvent) {
    event.preventDefault();
    let initialX = event.clientX;
    let initialY = event.clientY;

    const onMouseMove = (moveEvent: MouseEvent) => {
      //Find difference between initial mouse movement point and captured event mouse point
      const deltaX = moveEvent.clientX - initialX;
      const deltaY = moveEvent.clientY - initialY;

      //Change initial mouse movement points to avoid stacking
      initialX = moveEvent.clientX;
      initialY = moveEvent.clientY;

      this.mutateDeltas(deltaX, deltaY);
      this.moveDiagram();
      this.moveBackground();
    };

    //Bind events to element only while holding mouse down
    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  private mutateDeltas = (deltaX: number, deltaY: number) => {
    //Add difference in mouse movement to current cords
    const newXCords = deltaX + this.currentX;
    const newYCords = deltaY + this.currentY;

    //Make sure new cordinates within grid's hard cutoff bounds
    if (newXCords >= -this.DELTA_X_CUTOFF && newXCords <= this.DELTA_X_CUTOFF)
      this.currentX = newXCords;
    if (newYCords >= -this.DELTA_Y_CUTOFF && newYCords <= this.DELTA_Y_CUTOFF)
      this.currentY = newYCords;
  };

  private moveDiagram = () => {
    this.renderer.setStyle(
      this.treeWrapperEl.nativeElement,
      'transform',
      `translate(${this.currentX}px, ${this.currentY}px)`,
    );
  };

  private moveBackground = () => {
    this.renderer.setStyle(
      this.gridWrapperEl.nativeElement,
      'background-position-x',
      `${this.currentX / 2}px`,
    );
    this.renderer.setStyle(
      this.gridWrapperEl.nativeElement,
      'background-position-y',
      `${this.currentY / 2}px`,
    );
  };

  private zoom = () => {
    this.renderer.setStyle(this.treeWrapperEl.nativeElement, 'scale', this.zoomLevel);
  };

  private resetDiagram = () => {
    this.currentX = DEFAULT_Y;
    this.currentY = DEFAULT_Y;
    this.moveDiagram();
    this.moveBackground();

    this.zoomLevel = DEFAULT_ZOOM - 0.25;
    this.zoom();
  };

  public mutateZoom = (zoomIn: boolean) => {
    //Apply upper and lowers bound to zoom level;
    if (zoomIn && this.zoomLevel <= MAX_ZOOM) this.zoomLevel += 0.1;
    if (!zoomIn && this.zoomLevel >= MIN_ZOOM) this.zoomLevel -= 0.1;
  };

  public handleZoom = (zoomIn: boolean) => {
    this.mutateZoom(zoomIn);
    this.zoom();
  };

  public handleWheel = (e: WheelEvent) => {
    this.handleZoom(e.deltaY < 0);
  };

  /* Utility node functions */

  //Recursively changes expansion of children nodes of a node;
  public mutateExpansions = (row: TreeNode[], expansion: boolean) => {
    if (!row || !row.length) return;

    row.forEach((node) => {
      this.mutateNodeExpansion(node, expansion);

      this.mutateExpansions(node.children, expansion);
    });
  };

  public mutateExpansionByKeys = (
    node: TreeNode,
    expansion: boolean,
    keys: string[],
    ignoredNodesExpasion?: boolean,
  ) => {
    if (!node || !node.children) return;

    if (keys.find((key) => key === node.key)) this.mutateNodeExpansion(node, expansion);

    node.children.forEach((node) => {
      if (keys.find((key) => key === node.key)) {
        this.mutateNodeExpansion(node, expansion);
        this.mutateExpansionByKeys(node, expansion, keys, ignoredNodesExpasion);
      } else if (ignoredNodesExpasion !== undefined) {
        this.mutateNodeExpansion(node, Boolean(ignoredNodesExpasion));
        this.mutateExpansionByKeys(node, expansion, keys, ignoredNodesExpasion);
      }
    });
  };

  public nodeClick = (node: TreeNode) => {
    this.mutateNodeExpansion(node);

    if (!node.expanded) this.mutateExpansions(node.children, false);
    else
      this.mutateExpansionByKeys(
        this.treeData[0],
        true,
        this.getParentsKeys(node, [node.key]),
        false,
      );
  };

  private mutateNodeExpansion = (node: TreeNode, expansion?: boolean) => {
    if (!node.children || !node.children.length) return;

    node.expanded = expansion !== undefined ? expansion : !node.expanded;
  };

  public isNodeExpanded = (node: TreeNode) => {
    return node.expanded;
  };

  public isRootNode = (node: TreeNode) => {
    return node.data.userId === CEO_ID;
  };

  public isLeafNode = (node: TreeNode) => {
    return !node.children || !node.children.length || !node.expanded;
  };

  private findNodeByEmployee = (employee: IEmployee, node: TreeNode) => {
    if (employee.id === node.data.userId) return node;

    let foundNode;
    node.children.forEach((node) => {
      const n = this.findNodeByEmployee(employee, node);
      if (n?.data.userId === employee.id) foundNode = n;
    });

    return foundNode;
  };

  /* Data handling */

  private fillManagerSubordinatesTree = (
    managerSubordinatesList: IManagerSubordinatesModel[],
    subordinatesTree: TreeNode[],
    parent?: TreeNode,
  ) => {
    if (!managerSubordinatesList || !managerSubordinatesList.length) return;

    managerSubordinatesList.forEach((employee) => {
      const node = {
        key: employee.id.toString(),
        expanded: employee.id === CEO_ID,
        data: {
          userId: employee.id,
          name: employee.fullNameEn,
          role: employee.positionName,
          photo: this.getEmployeePhotoUrl(employee),
          decendants: employee.numberOfSubordinates,
          found: false,
          parent,
        },
        children: [],
      };

      const count = subordinatesTree.push(node);
      this.fillManagerSubordinatesTree(
        employee.subordinatesDtoList,
        subordinatesTree[count - 1].children,
        node,
      );
    });
  };

  private getParentsKeys = (node: TreeNode, keys: string[]): string[] => {
    if (!node.data.parent) return keys;

    return this.getParentsKeys(node.data.parent, [...keys, node.data.parent.key]);
  };

  private getEmployeePhotoUrl = (employee: IManagerSubordinatesModel) => {
    if (!employee.photo) return DEFAULT_PHOTO;
    let blob = this.b64toBlob(employee.photo);
    return URL.createObjectURL(blob);
  };

  private getTreeLargestRow = (node: TreeNode, max: number): number => {
    if (!node) return max;

    const currentMax = Math.max(node.children?.length ?? 0, max);

    return node.children
      ?.map((n) => this.getTreeLargestRow(n, currentMax))
      .reduce((max, result) => Math.max(max, result), currentMax);
  };

  public b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  /* Input Functions */

  public handleEmployeeSelect = (employee: IEmployee) => {
    const node = this.findNodeByEmployee(employee, this.treeData[0]);
    if (!node) return;

    const parentKeys = this.getParentsKeys(node, []);
    this.mutateExpansionByKeys(this.treeData[0], true, parentKeys, false);
    this.applyFoundToNode(node);
    this.resetDiagram();
  };

  private applyFoundToNode = (node: TreeNode) => {
    node.data.found = true;
    setTimeout(() => {
      node.data.found = false;
    }, 10 * 1000);
  };
}
