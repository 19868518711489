import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISearchItem } from '../models/search-item.model';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private urlBase: string = 'api/search';

  constructor(private httpClient: HttpClient) {}

  getSearchedResults(filter: string): Observable<ISearchItem[]> {
    return this.httpClient.get<ISearchItem[]>(`${this.urlBase}/${filter}`);
  }
}
