<!-- Search Input -->
<mat-form-field id="search-input" color="accent" appearance="fill">
  <mat-label>{{ 'SHARED.SEARCH' | translate }}</mat-label>
  <input matInput type="text" (keyup)="onSearchChange($event.target.value)" />
</mat-form-field>
<app-with-loading [data]="reports" [isSignal]="true" [contentTemplateRef]="content">
  <ng-template #content let-item>
    <div>
      <mat-table [dataSource]="item.items">
        <!-- File Name Column -->
        <ng-container matColumnDef="fileName">
          <mat-header-cell *matHeaderCellDef>
            {{ 'ADMIN.APPROVE_REPORT.FILE_NAME' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [matTooltip]="element.fileName">
            <span class="text-truncate">
              {{ element.fileName }}
            </span>
          </mat-cell>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef class="status">
            {{ 'ADMIN.APPROVE_REPORT.STATUS' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="status">
            <mat-chip-listbox>
              <mat-chip-option [ngClass]="getColor(element)">{{ element.status }}</mat-chip-option>
            </mat-chip-listbox>
          </mat-cell>
        </ng-container>

        <!-- Employee Full Name Column -->
        <ng-container matColumnDef="employeeFullName">
          <mat-header-cell *matHeaderCellDef>
            {{ 'ADMIN.APPROVE_REPORT.EMPLOYEE_NAME' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.employeeFullName }}
          </mat-cell>
        </ng-container>

        <!-- Project Name Column -->
        <ng-container matColumnDef="projectName">
          <mat-header-cell *matHeaderCellDef>
            {{ 'SHARED.PROJECT.NAME' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.projectName }}
          </mat-cell>
        </ng-container>

        <!-- Manager Full Name Column -->
        <ng-container matColumnDef="managerFullName">
          <mat-header-cell *matHeaderCellDef>
            {{ 'ADMIN.APPROVE_REPORT.MANAGER_NAME' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.managerFullName }}
          </mat-cell>
        </ng-container>

        <!-- Created On Column -->
        <ng-container matColumnDef="createdOn">
          <mat-header-cell *matHeaderCellDef>
            {{ 'ADMIN.APPROVE_REPORT.CREATED_ON' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.createdOn | date: 'shortDate' }}
          </mat-cell>
        </ng-container>

        <!-- Approve Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>
            {{ 'SHARED.ACTIONS' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let timesheet">
            <button
              mat-button
              color="primary"
              [disabled]="isApproveButtonDisabled(timesheet)"
              [matTooltip]="
                (isApproveButtonDisabled(timesheet) ? 'ADMIN.APPROVE_REPORT.CANNOT_APPROVE' : '')
                  | translate
              "
              (click)="approveTimesheet(timesheet)"
            >
              {{ 'SHARED.APPROVE' | translate }}
            </button>
            <mat-icon
              matTooltip="{{ 'SHARED.DOWNLOAD' | translate }}"
              (click)="downloadTimesheet(timesheet)"
              class="icon download"
              >download
            </mat-icon>
          </mat-cell>
        </ng-container>

        <!-- Table Header and Row Declaration -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>

      <mat-paginator
        [length]="totalSize()"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        (page)="handlePageChange($event)"
      >
      </mat-paginator>
    </div>
  </ng-template>
</app-with-loading>
