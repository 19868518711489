import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KnowBeUserData } from 'src/app/shared/models/knowbe-user-data.model';

@Injectable({
  providedIn: 'root',
})
export class KnowBeService {
  private urlBase: string = 'api/knowbe-training';

  constructor(private httpClient: HttpClient) {}

  public userData(id: number) {
    return this.httpClient.get<KnowBeUserData>(`${this.urlBase}/user-data/${id}`);
  }
}
