<app-travel-requests
  [requestToEdit]="requestToEdit"
  (action)="buttonActionHandler($event)"
></app-travel-requests>
<div class="travel-table-holder">
  <app-travel-requests-table
    #requestTable
    [userView]="true"
    (requestToEdit)="editTravelRequest($event)"
    [currentTripId]="currentTripId"
  ></app-travel-requests-table>
</div>
