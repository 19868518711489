<div class="relative">
  <div class="overlay" *ngIf="loading">
    <mat-spinner class="spinner-reset" [diameter]="64"></mat-spinner>
  </div>

  <p *ngIf="!loading && !nuggets.length">{{ 'ADMIN.EVENTS.NO_EVENT' | translate }}</p>

  <mat-table
    *ngIf="nuggets.length"
    #table
    [dataSource]="nuggets"
    [cdkDropListDisabled]="!draggable"
    cdkDropList
    (cdkDropListDropped)="droppedHandle($event)"
    cdkDropListData="nuggets"
  >
    <ng-container matColumnDef="order" sticky>
      <mat-header-cell *matHeaderCellDef>{{ 'ADMIN.EVENTS.ORDER' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let nugget">
        <mat-icon class="example-drag-cursor">reorder</mat-icon>
        <span>{{ nugget.order }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef>{{ 'ADMIN.EVENTS.TITLE' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let nugget">{{ nugget.title }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <mat-header-cell *matHeaderCellDef>{{
        'ADMIN.EVENTS.START_DATE' | translate
      }}</mat-header-cell>
      <mat-cell *matCellDef="let nugget">{{ nugget.startDate | humanizeDate }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="endDate">
      <mat-header-cell *matHeaderCellDef>{{ 'ADMIN.EVENTS.END_DATE' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let nugget">{{ nugget.endDate | humanizeDate }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let nugget">
        <a [routerLink]="['/admin/events/edit', nugget.id]" class="icon-link">
          <mat-icon [matTooltip]="'ADMIN.EVENTS.EDIT' | translate" class="icon user-edit"
            >edit</mat-icon
          >
        </a>
        <mat-icon
          *ngIf="nuggetEventType !== 'deleted'"
          [matTooltip]="'ADMIN.EVENTS.DELETE' | translate"
          (click)="deleteHandle(nugget.id)"
          class="icon delete"
        >
          cancel
        </mat-icon>
        <mat-icon
          *ngIf="nuggetEventType === 'deleted'"
          [matTooltip]="'ADMIN.EVENTS.ACTIVATE' | translate"
          (click)="activateHandle(nugget.id)"
          class="icon"
        >
          restore
        </mat-icon>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" cdkDrag [cdkDragData]="row"></mat-row>
  </mat-table>
</div>
