import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IEmployee, IPosition } from '../../../../shared/models';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { GenderEnum } from '../../../../shared/enums';
import { combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { DIALOG_BUTTONS } from '../../../../shared/constants';
import { ActivatedRoute } from '@angular/router';
import {
  AlertService,
  AlertType,
  EmployeeService,
  LoginService,
  ObservablesService,
  PositionService,
} from '../../../../shared/services';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith, switchMap } from 'rxjs/operators';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DialogComponent, ImageCropDialogComponent } from '../../../../shared/dialogs';
import { formatDate } from '@angular/common';
import { base64ToFile } from 'ngx-image-cropper';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SkillsDialogComponent } from '../../../../user/my-settings/employee-skills/skills-dialog.component';

@Component({
  selector: 'app-edit-employee-data',
  templateUrl: './edit-employee-data.component.html',
  styleUrls: ['./edit-employee-data.component.scss'],
})
export class EditEmployeeDataComponent implements OnInit, OnDestroy {
  public currentUser: IEmployee;
  public userForm: UntypedFormGroup;

  public allGenders: string[] = [GenderEnum.MALE, GenderEnum.FEMALE];
  public allRoles: string[] = ['USER', 'ADMIN', 'LEAD', 'DEVOPS'];
  public allPositions: Array<IPosition>;

  public filteredReferrers: Observable<IEmployee[]>;
  public allEmployees: IEmployee[];
  @ViewChild('managerList') managerInput: ElementRef<HTMLInputElement>;

  public successfulSave: boolean = false;
  public photoSrc: string;

  public isLoading: boolean = true;
  private destroy$ = new Subject<void>();

  public requiredFirstNameFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('[а-яА-Я\\s]+'),
  ]);

  public firstNameFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.pattern('[а-яА-Я\\s]+'),
  ]);
  public requiredLastNameFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('[а-яА-Я\\s]+'),
  ]);
  public lastNameFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.pattern('[а-яА-Я\\s]+'),
  ]);
  public firstNameEnFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('[a-zA-Z\\s]+'),
  ]);
  public lastNameEnFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('[a-zA-Z\\s]+'),
  ]);
  public managerControl: UntypedFormControl = new UntypedFormControl();
  public filteredManagers: Observable<IEmployee[]>;
  readonly buttons = DIALOG_BUTTONS;

  constructor(
    private route: ActivatedRoute,
    private observable: ObservablesService,
    private employeeService: EmployeeService,
    private positionService: PositionService,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    private alertService: AlertService,
    private readonly formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    public loginService: LoginService,
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.route.params
      .pipe(
        takeUntil(this.destroy$),
        switchMap((params) => this.loadEmployee(params.id)),
      )
      .subscribe();
  }

  private initForm(): void {
    this.userForm = this.formBuilder.group({
      firstNameEn: this.firstNameEnFormControl,
      lastNameEn: this.lastNameEnFormControl,
      phone: ['', [Validators.required]],
      gender: [''],
      birthday: [
        '',
        [
          Validators.pattern(
            '((3[01]|[12][0-9]|0?[1-9])\\.(1[012]|0?[1-9])\\.((?:19|20)\\d{2}))|((3[01]|[12][0-9]|0?[1-9])\\.(1[012]|0?[1-9]))',
          ),
        ],
      ],
      email: [''],
      positionName: [''],
      dreamixBirthday: [''],
      daysLeft: ['', [Validators.pattern('\\d{1,3}(?:\\.\\d(\\d)?)?')]],
      contractPosition: [''],
      hobbies: [''],
      general: [''],
      role: [''],
      subscribedForProject: [''],
      lastDate: [''],
      referrer: [''],
      managerId: [''],
      contractor: [''],
      bgEmployee: [''],
      bdNotificationEnabled: [''],
      city: [''],
      country: [''],
    });
  }

  private loadEmployee(id: number): Observable<void> {
    return combineLatest([
      this.positionService.getAllPositions(),
      this.employeeService.getEmployeeById(id),
      this.employeeService.getAllEmployees(),
      this.employeeService.getEmployeePicture(id),
    ]).pipe(
      takeUntil(this.destroy$),
      map(([positions, emp, employees, picture]) => {
        this.allPositions = positions;
        this.currentUser = emp;
        this.photoSrc = URL.createObjectURL(picture);
        this.observable.dynamicBreadcrumbs.next([
          {
            label: `${this.currentUser.firstNameEn} ${this.currentUser.lastNameEn}`,
            url: null,
          },
        ]);
        this.userForm.setControl(
          'firstName',
          this.currentUser.bgEmployee
            ? this.requiredFirstNameFormControl
            : this.firstNameFormControl,
        );
        this.userForm.setControl(
          'lastName',
          this.currentUser.bgEmployee ? this.requiredLastNameFormControl : this.lastNameFormControl,
        );
        this.userForm.patchValue(this.currentUser);

        this.allEmployees = employees;
        this.filteredReferrers = this.userForm
          .get('referrer')
          .valueChanges.pipe(
            map((emp: string | null) =>
              emp ? this.filterEmployeesByName(emp) : this.allEmployees.slice(),
            ),
          );

        this.filteredManagers = this.managerControl.valueChanges.pipe(
          map((emp: string | null) => (emp ? this.filterEmployeesByName(emp) : employees)),
          startWith(employees),
        );

        this.initManagers(emp);
        this.isLoading = false;
      }),
      map(() => void 0),
    );
  }

  public initManagers = (emp: IEmployee) => {
    this.managerControl.setValue(this.allEmployees.find((e) => e.id === emp.managerId));
    this.userForm.get('managerId').setValue(emp.managerId);
    this.currentUser['managerId'] = emp.managerId;
  };

  public uploadFile(file: File): void {
    this.employeeService
      .uploadImg(this.currentUser.id, file)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.photoSrc = '';
          this.cd.detectChanges();
          this.photoSrc = '/api/employees/' + this.currentUser.id + '/photo/' + Date.now() + '.jpg';
        },
        (error) => {
          this.alertService.showAlert(error.error, AlertType.error);
        },
      );
  }

  public updateEmployee(): void {
    this.userForm.markAllAsTouched();
    if (this.userForm.valid) {
      this.mapFormValuesToCurrentUser();

      this.employeeService
        .editEmployee(this.currentUser)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (emp) => {
            this.successfulSave = true;
            this.currentUser = emp;
            this.userForm.patchValue(this.currentUser);
            this.alertService.showAlert(
              this.translate
                .instant('ADMIN.EMPLOYEES.EDIT_EMPLOYEE.SUCCESSFULLY_UPDATED')
                .toString(),
              AlertType.success,
            );
          },
          (error) => {
            this.alertService.showAlert(error.error, AlertType.error);
          },
        );
    } else {
      this.alertService.showAlert(
        this.translate.instant('ADMIN.EMPLOYEES.EDIT_EMPLOYEE.FILL_FORM').toString(),
        AlertType.error,
      );
    }
  }

  private mapFormValuesToCurrentUser(): void {
    const fieldsToMap = [
      'firstName',
      'lastName',
      'firstNameEn',
      'lastNameEn',
      'phone',
      'gender',
      'birthday',
      'contractPosition',
      'positionName',
      'role',
      'daysLeft',
      'hobbies',
      'general',
      'subscribedForProject',
      'contractor',
      'bgEmployee',
      'bdNotificationEnabled',
      'referrer',
      'managerId',
      'city',
      'country',
    ];

    fieldsToMap.forEach((field) => {
      this.currentUser[field] = this.userForm.value[field];
    });
  }

  public updateLastDate(date: MatDatepickerInputEvent<Date>): void {
    if (!date.value) {
      this.currentUser.lastDate = null;
    } else {
      this.dialog
        .open(DialogComponent, {
          data: {
            title: this.translate
              .instant('ADMIN.EMPLOYEES.EDIT_EMPLOYEE.LAST_DAY_CONFIRMATION')
              .toString(),
            description: this.translate
              .instant('ADMIN.EMPLOYEES.EDIT_EMPLOYEE.LAST_DAY_CONFIRMATION_DESCRIPTION')
              .toString(),
            sharedButtonClass: this.buttons.warningButton,
            sharedButtonText: this.translate
              .instant('ADMIN.EMPLOYEES.EDIT_EMPLOYEE.YES')
              .toString(),
          },
        })
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          if (result) {
            this.currentUser.lastDate = formatDate(date.value, 'yyyy-MM-dd', 'en-US');
          } else {
            this.clearLastDate();
          }
        });
    }
  }

  public clearLastDate(): void {
    this.currentUser.lastDate = null;
    this.userForm.patchValue({ lastDate: '' });
  }

  public openImageCropDialog(event: Event): void {
    this.dialog
      .open(ImageCropDialogComponent, {
        data: {
          title: this.translate.instant('ADMIN.EMPLOYEES.EDIT_EMPLOYEE.IMAGE_CROP').toString(),
          imageChangedEvent: event,
          sharedButtonClass: this.buttons.saveButton,
          sharedButtonText: this.translate.instant('ADMIN.EMPLOYEES.EDIT_EMPLOYEE.SAVE').toString(),
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.uploadFile(
            new File([base64ToFile(result)], this.currentUser.id + '_photo.jpeg', {
              type: 'image/jpeg',
              lastModified: Date.now(),
            }),
          );
        }
      });
  }

  public removeImage(): void {
    this.uploadFile(null);
  }

  public selectUserAutocomplete(
    event: MatAutocompleteSelectedEvent,
    key: 'managerId' | 'referrer',
  ): void {
    const value = event.option.value as IEmployee;
    const manager = key === 'managerId';

    if (manager) {
      this.userForm.get(key).setValue(value.id);
    } else {
      this.currentUser[key] = value;
    }
    (manager ? this.managerControl : this.userForm.get(key)).setValue(value);
  }

  // todo FIXME duplicated code
  // todo create generic component to autocomplete users;
  private filterEmployeesByName(value: string): IEmployee[] {
    if (!value) {
      return this.allEmployees;
    }

    if (typeof value !== 'string') {
      return this.allEmployees.filter((emp) => emp !== value);
    }
    const filterValue = value.toLowerCase();
    return this.allEmployees.filter((emp) =>
      `${emp.firstNameEn} ${emp.lastNameEn}`.toLowerCase().includes(filterValue),
    );
  }

  displayUser(user: IEmployee): string {
    return user ? `${user.firstNameEn} ${user.lastNameEn}` : null;
  }

  public clearAutocomplete(key: 'managerId' | 'referrer'): void {
    const manager = key === 'managerId';
    this.currentUser[key] = null;

    if (manager) this.managerControl.setValue(null);

    this.userForm.patchValue({ [key]: null });
  }

  public openDialog(): void {
    this.dialog.open(SkillsDialogComponent, {
      width: '500px',
      minHeight: '350px',
      data: {
        employeeId: this.currentUser.id,
        showSubmitForm: false,
      },
    });
  }

  ngOnDestroy(): void {
    this.observable.dynamicBreadcrumbs.next([]);
    this.destroy$.next();
    this.destroy$.complete();
  }
}
