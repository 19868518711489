import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AbsenceRequestFormComponent } from './home/absence-request-form/absence-request-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import {
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { routing } from './app.routing';

import { HomeComponent } from './home/home.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from '../environments/environment';
import { VacationChartsComponent } from './home/vacation-charts/vacation-charts.component';
import { MyRequestsComponent } from './user/my-requests/my-requests.component';
import { PendingRequestsSectionComponent } from './home/pending-requests-section/pending-requests-section.component';
import { MaterialModule } from './shared/material.module';
import { ActionRequestComponent } from './action-request/action-request.component';
import { MySettingsComponent } from './user/my-settings/my-settings.component';
import { AdminModule } from './admin/admin.module';
import { ReviewedRequestsComponent } from './user/reviewed-requests/reviewed-requests.component';
import { RequestDetailsComponent } from './request-details/request-details.component';
import { ScheduleJobComponent } from './user/schedule-job/schedule-job.component';
import { EmployeeDetailsComponent } from './dreamix/employees/employee-details/employee-details.component';
import { EmployeesComponent } from './dreamix/employees/employees.component';
import { ProjectDetailsComponent } from './dreamix/projects/project-details/project-details.component';
import { BreadcrumbComponent } from './nav-bar/breadcrumb/breadcrumb.component';
import { PageDetailsComponent } from './dreamix/page-details/page-details.component';
import { SearchComponent } from './nav-bar/search/search.component';
import { SearchResultsComponent } from './dreamix/search-results/search-results.component';
import { SharedModule } from './shared/shared.module';
import { OrganizationComponent } from './dreamix/organization/organization.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { LicenseComponent } from './dreamix/license/license.component';
import { AdminLicenseComponent } from './admin/license/admin-license.component';
import { EditLicenseComponent } from './admin/license/edit-license/edit-license.component';
import { OvertimeComponent } from './user/overtime/overtime.component';
import { OvertimeRequestFormComponent } from './user/overtime/overtime-request-form/overtime-request-form.component';
import { OvertimeHistoryComponent } from './user/overtime/overtime-history/overtime-history.component';
import { OvertimeInformationDialogComponent } from './user/overtime/overtime-history/overtime-history-dialog/overtime-history-dialog.component';
import { ReimbursementRequestsComponent } from './user/reimbursement-requests/reimbursement-requests.component';
import { NullDefaultPipe } from './shared/pipes/null-default.pipe';
import { NgOptimizedImage } from '@angular/common';
import { ApproveReportComponent } from './admin/approve-report/approve-report.component';
import { TimeLogComponent } from './user/time-log/time-log.component';
import { NavLinksComponent } from './nav-bar/nav-links/nav-links.component';
import { HolidayWorkingFormComponent } from './home/holiday-working-form/holiday-working-form.component';
import { UserTravelRequestsComponent } from './user/user-travel-requests/user-travel-requests.component';
import { UserHelpComponent } from './user-help/user-help.component';
import { EmployeeDetailsProjectHistoryComponent } from './dreamix/employees/employee-details/project-history/employee-details-project-history.component';
import { SpinnerOverlayComponent } from './shared/components/spinner-overlay/spinner-overlay.component';
import { UploadTravelDocumentsDialogComponent } from './travel-requests/upload-travel-documents-dialog/upload-travel-documents-dialog.component';
import { KnowbeStatsComponent } from './knowbe-stats/knowbe-stats.component';
import { KnowbePhishingResultPieComponent } from './knowbe-stats/knowbe-phishing-result-pie/knowbe-phishing-result-pie.component';
import { KnowbeRiskScoreGaugeComponent } from './knowbe-stats/knowbe-risk-score-gauge/knowbe-risk-score-gauge.component';
import { KnowbeBarChartComponent } from './knowbe-stats/knowbe-bar-chart/knowbe-bar-chart.component';
import { KnowbeDashboardComponent } from './knowbe-stats/knowbe-dashboard/knowbe-dashboard.component';
import { KnowbeLineChartComponent } from 'src/app/knowbe-stats/knowbe-line-chart/knowbe-line-chart.component';
import { TreeModule } from 'primeng/tree';
import { EmployeeInputComponent } from './dreamix/organization/employee-input/employee-input.component';
import { BannerComponent } from './home/banner/banner.component';
import { CommonProgressComponent } from './home/vacation-charts/common-progress/common-progress.component';
import { RequestsTableComponent } from './home/pending-requests-section/requests-table/requests-table.component';
import { RequestsTableRowComponent } from './home/pending-requests-section/requests-table-row/requests-table-row.component';
import { OtherRequestsTableComponent } from './home/pending-requests-section/other-requests-table/other-requests-table.component';
import { HeadcountComponent } from './admin/headcount/headcount.component';
import { EmployeeLeavesComponent } from 'src/app/user/employee-leaves/employee-leaves.component';
import { UserNavLinksComponent } from './nav-bar/user-nav-links/user-nav-links.component';
import { KnowbeSubordinatesListComponent } from './knowbe-stats/knowbe-subordinates-list/knowbe-subordinates-list.component';
import { KnowbeSubordinateDialogComponent } from './knowbe-stats/knowbe-subordinate-dialog/knowbe-subordinate-dialog.component';
import { SkillsDialogComponent } from './user/my-settings/employee-skills/skills-dialog.component';
import { MatSliderModule } from '@angular/material/slider';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { WallOfFameComponent } from './landing-page/wall-of-fame/wall-of-fame.component';
import { WeeklyNuggetsComponent } from './landing-page/weekly-nuggets/weekly-nuggets.component';

const googleLoginOptions = {
  oneTapEnabled: false,
  scopes: ['profile email https://www.googleapis.com/auth/calendar'],
};

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    AbsenceRequestFormComponent,
    LoginComponent,
    NavBarComponent,
    HomeComponent,
    MyRequestsComponent,
    VacationChartsComponent,
    PendingRequestsSectionComponent,
    ActionRequestComponent,
    MySettingsComponent,
    EmployeeDetailsComponent,
    EmployeesComponent,
    RequestDetailsComponent,
    ReviewedRequestsComponent,
    ScheduleJobComponent,
    ProjectDetailsComponent,
    BreadcrumbComponent,
    PageDetailsComponent,
    SearchComponent,
    SearchResultsComponent,
    NavLinksComponent,
    OrganizationComponent,
    LicenseComponent,
    AdminLicenseComponent,
    EditLicenseComponent,
    OvertimeComponent,
    HolidayWorkingFormComponent,
    OvertimeRequestFormComponent,
    OvertimeHistoryComponent,
    OvertimeInformationDialogComponent,
    ReimbursementRequestsComponent,
    ApproveReportComponent,
    TimeLogComponent,
    UserTravelRequestsComponent,
    UserHelpComponent,
    EmployeeDetailsProjectHistoryComponent,
    SpinnerOverlayComponent,
    UploadTravelDocumentsDialogComponent,
    KnowbeStatsComponent,
    KnowbePhishingResultPieComponent,
    KnowbeRiskScoreGaugeComponent,
    KnowbeBarChartComponent,
    KnowbeLineChartComponent,
    KnowbeDashboardComponent,
    EmployeeInputComponent,
    BannerComponent,
    CommonProgressComponent,
    RequestsTableComponent,
    RequestsTableRowComponent,
    OtherRequestsTableComponent,
    UserNavLinksComponent,
    KnowbeSubordinatesListComponent,
    KnowbeSubordinateDialogComponent,
    SkillsDialogComponent,
    LandingPageComponent,
    WallOfFameComponent,
    WeeklyNuggetsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TreeModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SocialLoginModule,
    routing,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    MaterialModule,
    AdminModule,
    SharedModule.forRoot(),
    ClipboardModule,
    HeadcountComponent,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, './assets/i18n/', `/en.json`),
        deps: [HttpClient],
      },
    }),
    GoogleSigninButtonModule,
    NullDefaultPipe,
    NgOptimizedImage,
    EmployeeLeavesComponent,
    MatSliderModule,
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              window.location.href.includes('dms.dreamix.eu')
                ? '1026810753126-r6rp7mn8c0n50bg8j6bbvreir00ikt9t.apps.googleusercontent.com'
                : '103728556371-7qq16vua2qrg14852bpe498hivrl5pac.apps.googleusercontent.com',
              googleLoginOptions,
            ),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
