import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IRequest } from '../../shared/models';
import { RequestService } from '../../shared/services';
import { MatDialog } from '@angular/material/dialog';
import { DeleteFileEvent, DialogComponent } from '../../shared/dialogs';
import { RequestStatusEnum } from '../../shared/enums/request-status.enum';
import { VacationTypeEnum } from '../../shared/enums';
import { VacationSubtypeEnum } from '../../shared/enums';
import { RequestsHelperService } from '../../shared/services';
import { ActivatedRoute } from '@angular/router';
import { IPagination } from '../../shared/models';
import { MatSort } from '@angular/material/sort';
import { PageableHelperService } from '../../shared/services';
import { IPageable } from '../../shared/models/pageable.model';
import { TranslateService } from '@ngx-translate/core';
import { ImageListDialogComponent } from '../../shared/dialogs';
import { EMPTY, Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { DIALOG_BUTTONS } from '../../shared/constants';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
})
export class RequestsComponent implements OnInit, OnDestroy {
  public tableConfig: IPagination<IRequest>;

  public params: HttpParams = new HttpParams();
  public pageable: IPageable;
  private destroy$ = new Subject<void>();

  public isLoading: boolean = true;
  public sortColumns: string[] = [];
  readonly buttons = DIALOG_BUTTONS;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private requestService: RequestService,
    public dialog: MatDialog,
    private helper: RequestsHelperService,
    private activatedRoute: ActivatedRoute,
    private pageableHelper: PageableHelperService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        switchMap((params: HttpParams) => {
          this.pageable = this.pageableHelper.getPageable(params);
          return this.getRequestsFromDb(this.params);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public getRequests(params: HttpParams): void {
    this.getRequestsFromDb(params).subscribe();
  }

  public getRequestsFromDb(params: HttpParams): Observable<IPagination<IRequest>> {
    this.isLoading = true;
    let pageableParams = this.pageableHelper.getHttpParams(this.pageable);

    // append pageableParams to params
    pageableParams.keys().forEach((param) => {
      params = params.append(param, pageableParams.get(param));
    });

    return this.requestService.getAllRequestsFiltered(params).pipe(
      tap((response) => {
        this.tableConfig = response;
        this.isLoading = false;
      }),
      takeUntil(this.destroy$),
    );
  }

  private deleteRequest(id: number): Observable<IPagination<IRequest>> {
    return this.requestService
      .deleteRequestFromAdmin(id)
      .pipe(switchMap((_) => this.getRequestsFromDb(this.params)));
  }

  public openDialog(request: IRequest): void {
    if (request.status === RequestStatusEnum.DELETED) {
      return;
    }

    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('ADMIN.REQUESTS.DELETE_CONFIRMATION').toString(),
          description: this.translate
            .instant('ADMIN.REQUESTS.DELETE_CONFIRMATION_DESCRIPTION')
            .toString(),
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('ADMIN.REQUESTS.DELETE').toString(),
        },
      })
      .afterClosed()
      .pipe(
        switchMap((result) => (result ? this.deleteRequest(request.id) : EMPTY)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public openPicListDialog(request: IRequest): void {
    let fileList: string[] = request.filename.split(';');
    const dialogRef = this.dialog.open(ImageListDialogComponent, {
      data: { userId: request.requester.id, requestId: request.id, fileList, readOnly: false },
    });

    let fileNameConst;
    dialogRef.componentInstance.deleteFileEvent
      .pipe(
        switchMap((event:DeleteFileEvent) => {
          fileNameConst = event.fileName;
          return this.requestService.deleteFromFileList(request.id, event.fileName);
        }),
        switchMap(() => this.getRequestsFromDb(this.params)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        fileList = fileList.filter((item) => item !== fileNameConst);
        if (fileList.length > 0) {
          dialogRef.componentInstance.data = {
            userId: request.requester.id,
            requestId: request.id.toString(),
            fileList,
            readOnly: false,
          };
        } else {
          dialogRef.close();
        }
      });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        dialogRef.componentInstance.deleteFileEvent.unsubscribe();
      });
  }

  public isRequestUploadMissing(request: IRequest): boolean {
    return (
      !request.filename &&
      request.status === RequestStatusEnum.APPROVED &&
      (request.subType === VacationSubtypeEnum.REGULAR_SICK_LEAVE ||
        (request.vacationType === VacationTypeEnum.OTHER_PAID_LEAVE &&
          request.subType !== VacationSubtypeEnum.OTHER_PROJECT)) &&
      request.requester.bgEmployee
    );
  }

  public applicableForDeletion(request: IRequest): boolean {
    const dateFrom = new Date(request.fromDate).setHours(0);
    const dateNow = new Date(Date.now()).setHours(0);

    return request.status !== 'DELETED' && dateFrom > dateNow;
  }

  public applicableForPDF(vacationType: string, request: IRequest): boolean {
    return (
      (VacationTypeEnum.VACATION === vacationType ||
        VacationTypeEnum.LEAVE_OF_ABSENCE === vacationType ||
        VacationTypeEnum.OTHER_PAID_LEAVE === vacationType ||
        VacationTypeEnum.HOME_OFFICE === vacationType) &&
      request.requester.bgEmployee
    );
  }

  public exportPdf(request: IRequest): void {
    this.helper.exportPdf(request);
  }

  public updateData(params: HttpParams): void {
    this.params = params;
    this.pageable.page = 0;
    this.getRequestsFromDb(this.params).subscribe();
  }

  public isSortingDisabled(column: string): boolean {
    return !this.sortColumns.some((c) => c === column);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
