import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-knowbe-risk-score-gauge',
  templateUrl: './knowbe-risk-score-gauge.component.html',
  styleUrls: ['./knowbe-risk-score-gauge.component.scss'],
})
export class KnowbeRiskScoreGaugeComponent implements AfterViewInit {
  @Input() title: string;
  @Input() data: number;

  @ViewChild('chart') private chartContainer: ElementRef<HTMLDivElement>;

  ngAfterViewInit() {
    this.draw();
  }
  private draw() {
    const container = this.chartContainer.nativeElement;

    const config = {
      width: Math.min(container.getBoundingClientRect().width, 350),
      height: Math.min(container.getBoundingClientRect().width, 350) * 0.75,
      ringInset: 20,
      ringWidth: 50,

      pointerWidth: 10,
      pointerTailLength: 5,
      pointerHeadLengthPercent: 0.6,

      minValue: 0,
      maxValue: 100,

      minAngle: -90,
      maxAngle: 90,

      transitionMs: 3000,

      majorTicks: 5,
      labelFormat: d3.format('d'),
      labelInset: 10,
    };

    const colors = ['#9AD74C', '#F5B658', '#F3722C', '#EA3352', '#A60841'];
    const range = config.maxAngle - config.minAngle;
    const radius = config.width / 2;
    const pointerHeadLength = Math.round(radius * config.pointerHeadLengthPercent);
    const scale = d3.scaleLinear().range([0, 1]).domain([config.minValue, config.maxValue]);
    const ticks = scale.ticks(config.majorTicks);
    const tickData = d3.range(config.majorTicks).map(() => 1 / config.majorTicks);
    const centeredText = `translate(${radius}, ${radius})`;

    const lineData = [
      [config.pointerWidth / 2, 0],
      [0, -pointerHeadLength],
      [-(config.pointerWidth / 2), 0],
      [0, config.pointerTailLength],
      [config.pointerWidth / 2, 0],
    ];

    const arc = d3
      .arc()
      .innerRadius(radius - config.ringWidth - config.ringInset)
      .outerRadius(radius - config.ringInset)
      .startAngle((d, i) => deg2rad(config.minAngle + d * i * range))
      .endAngle((d, i) => deg2rad(config.minAngle + d * (i + 1) * range));

    const svg = d3
      .select(container)
      .append('svg')
      .attr('class', 'gauge')
      .attr('width', config.width)
      .attr('height', config.height);

    // arcs
    svg
      .append('g')
      .attr('class', 'arc')
      .attr('transform', centeredText)
      .selectAll('path')
      .data(tickData)
      .enter()
      .append('path')
      .attr('fill', (_d, i) => colors[i])
      .attr('d', arc);

    // legends
    svg
      .append('g')
      .attr('class', 'label')
      .attr('transform', centeredText)
      .selectAll('text')
      .data(ticks)
      .enter()
      .append('text')
      .attr('x', -10)
      .attr('transform', (d) => {
        const newAngle = config.minAngle + scale(d) * range;

        return `rotate(${newAngle}) translate(0,${config.labelInset - radius})`;
      })
      .text(config.labelFormat);

    // value text
    svg
      .select('g.label')
      .append('text')
      .text(this.data.toFixed(1))
      .style('font-size', '24px')
      .style('font-weight', 'bold')
      .call((g) => {
        g.attr('x', -g.node().getBBox().width / 2);
        g.attr('y', g.node().getBBox().height);
      });

    // pointer
    svg
      .append('g')
      .data([lineData])
      .attr('class', 'pointer')
      .attr('transform', centeredText)
      .append('path')
      .attr('d', d3.line().curve(d3.curveLinear))
      .attr('transform', `rotate(${config.minAngle})`)
      .transition()
      .duration(config.transitionMs)
      .ease(d3.easeElastic)
      .attr('transform', `rotate(${config.minAngle + scale(this.data) * range})`);
  }
}

function deg2rad(deg: number) {
  return (deg * Math.PI) / 180;
}
