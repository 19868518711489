import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MY_FORMATS } from 'src/app/shared/components/absence-datepicker/absence-datepicker.component';
import { AlertService } from 'src/app/shared/services';
import { NuggetEventsService } from 'src/app/shared/services/nugget-events.service';
import { errorMessage } from 'src/app/shared/utils';

@Component({
  selector: 'app-nugget-events-create-edit',
  templateUrl: './nugget-events-create-edit.component.html',
  styleUrls: ['./nugget-events-create-edit.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
})
export class NuggetEventsCreateEditComponent implements OnInit {
  form = new FormGroup({
    id: new FormControl<number>(null),
    title: new FormControl<string>(null, Validators.required),
    startDate: new FormControl<string>(null),
    endDate: new FormControl<string>(null),
    buttonLabel: new FormControl<string>(null),
    buttonLink: new FormControl<string>(null),
    context: new FormControl<string>(null),
    img: new FormControl<string>(null),
    imageOnTheLeft: new FormControl(false),
    order: new FormControl(0),
  });

  constructor(
    private nuggetEventsService: NuggetEventsService,
    public route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private translate: TranslateService,
  ) {}
  ngOnInit() {
    if (!this.route.snapshot.params.id) return;

    this.nuggetEventsService.getEventById(this.route.snapshot.params.id).subscribe((nugget) => {
      this.form.setValue({
        id: nugget.id,
        title: nugget.title,
        startDate: nugget.startDate,
        endDate: nugget.endDate,
        buttonLabel: nugget.buttonLabel,
        buttonLink: nugget.buttonLink,
        context: nugget.context,
        img: nugget.img,
        imageOnTheLeft: nugget.imageOnTheLeft,
        order: nugget.order,
      });
    });
  }

  handleSubmit() {
    if (this.form.invalid) return this.form.markAllAsTouched();

    this.nuggetEventsService
      .createOrUpdate({
        id: this.form.value.id,
        title: this.form.value.title,
        context: this.form.value.context,
        img: this.form.value.img,
        startDate: this.form.value.startDate,
        endDate: this.form.value.endDate,
        buttonLabel: this.form.value.buttonLabel,
        buttonLink: this.form.value.buttonLink,
        imageOnTheLeft: this.form.value.imageOnTheLeft,
        order: this.form.value.order,
      })
      .subscribe({
        next: () => {
          this.alertService.showSuccess(
            this.translate.instant(
              this.route.snapshot.params.id ? 'ADMIN.EVENTS.UPDATED' : 'ADMIN.EVENTS.CREATED',
            ),
          );
          this.router.navigate(['/admin/events']);
        },
        error: (err) => this.alertService.showError(errorMessage(err)),
      });
  }
}
