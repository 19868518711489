<mat-spinner *ngIf="isLoading"></mat-spinner>

<div [hidden]="isLoading">
  <h2 class="section-title">
    {{ 'ADMIN.LICENSES.PAGE_TITLE' | translate }}
  </h2>

  <div class="form-container">
    <button
      type="button"
      mat-raised-button
      color="primary"
      [routerLink]="['/admin/licenses/edit', {}]"
    >
      {{ 'ADMIN.LICENSES.ADD_UNIT' | translate }}
    </button>
  </div>

  <app-table-wrapper
    [tableConfig]="tableConfig"
    (getData)="loadLicenses()"
    [(pageable)]="pageable"
    matSort
    [sort]="sort"
    [isUrlChangeable]="true"
  >
    <ng-container matColumnDef="licenseType">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('licenseType')"
      >
        {{ 'ADMIN.LICENSES.TYPE' | translate }}
      </th>
      <td mat-cell *matCellDef="let license">
        <div id="type">
          {{ license.type }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="licenseDescription">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('licenseDescription')"
      >
        {{ 'ADMIN.LICENSES.DESCRIPTION' | translate }}
      </th>
      <td mat-cell *matCellDef="let license">
        <div id="description">
          {{ license.description || '' }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let license">
        <div>
          <mat-icon
            matTooltip="{{ 'ADMIN.LICENSES.EDIT' | translate }}"
            [routerLink]="['/admin/licenses/edit', license.id]"
            class="icon user-edit"
            >edit
          </mat-icon>
          <mat-icon
            matTooltip="{{ 'ADMIN.LICENSES.DOWNLOAD' | translate }}"
            (click)="downloadLicense(license.id)"
            class="icon download"
            >download
          </mat-icon>
          <mat-icon
            matTooltip="{{ 'ADMIN.LICENSES.DELETE' | translate }}"
            (click)="openDialog(license)"
            class="icon delete"
            >cancel
          </mat-icon>
        </div>
      </td>
    </ng-container>
  </app-table-wrapper>
</div>
