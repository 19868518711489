import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ILicense } from '../../../shared/models/license.model';
import { LicenseService } from '../../../shared/services/license.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-edit-license',
  templateUrl: './edit-license.component.html',
  styleUrls: ['./edit-license.component.scss'],
})
export class EditLicenseComponent implements OnInit, OnDestroy {
  typeControl: UntypedFormControl = new UntypedFormControl('', Validators.required);
  descriptionControl: UntypedFormControl = new UntypedFormControl();

  public isLoading: boolean = true;
  private destroy$ = new Subject<void>();
  isEditMode: boolean;

  license: ILicense = {};
  licenseTypes: string[];

  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private licenseService: LicenseService,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.destroy$),
        switchMap((params) => {
          this.isEditMode = !!params.id;

          if (this.isEditMode) {
            return forkJoin({
              license: this.licenseService.getLicenseById(params.id),
              licenseTypes: this.licenseService.getLicenseTypes(),
            });
          } else {
            return this.licenseService.getLicenseTypes().pipe(
              map((licenseTypes) => ({
                license: null,
                licenseTypes,
              })),
            );
          }
        }),
      )
      .subscribe(({ license, licenseTypes }) => {
        if (license) {
          this.typeControl.setValue(license.type);
          this.descriptionControl.setValue(license.description || '');
          this.license = license;
        }

        this.licenseTypes = licenseTypes.map((el) => el.type);
        this.isLoading = false;
      });
  }

  public submitForm(): void {
    this.isEditMode ? this.updateLicense() : this.createLicense();
  }

  public selectedLicenseType(event: MatAutocompleteSelectedEvent): void {
    this.license.type = event.option.value;
    this.typeControl.setValue(this.license.type);
  }

  public fileChangeEvent(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.license.multipartFile = fileInput.target.files[0];

      // Reset File Input to Select Same file again
      this.uploadFileInput.nativeElement.value = '';
    }
  }

  private createLicense(): void {
    if (this.descriptionControl.valid && this.typeControl.valid && this.license.multipartFile) {
      this.license.description = this.descriptionControl.value;
      this.license.type = this.typeControl.value;

      this.licenseService
        .createLicense(this.license)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.alertService.showAlert(
            this.translate.instant('ADMIN.LICENSES.SUCCESSFULLY_ADDED').toString(),
            AlertType.success,
          );
        });
    }
  }

  private updateLicense(): void {
    if (this.descriptionControl.valid && this.typeControl.valid) {
      this.license.description = this.descriptionControl.value;
      this.license.type = this.typeControl.value;

      this.licenseService
        .updateLicense(this.license)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.alertService.showAlert(
            this.translate.instant('ADMIN.LICENSES.SUCCESSFULLY_UPDATED').toString(),
            AlertType.success,
          );
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
