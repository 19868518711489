import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TimeLogController } from '../../services/time-log.controller';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialogs/dialog/dialog.component';
import { DIALOG_BUTTONS } from '../../constants';
import * as moment from 'moment';
import { TimeLogsData } from '../../models/time-log';

@Component({
  selector: 'app-time-log-table',
  templateUrl: './time-log-table.component.html',
  styleUrls: ['./time-log-table.component.scss'],
})
export class TimeLogTableComponent implements OnInit {
  @Input() currentMonth = true;
  @Input() timeLogDialog = false;
  @Input() dialogData: TimeLogsData;
  editingEntryId: number = -1;
  data: TimeLogsData;

  readonly buttons = DIALOG_BUTTONS;

  get monthNameGetter() {
    const date = this.data.entries[0].date.toString().slice(5, 7);
    return moment()
      .month(Number(date) - 1)
      .format('MMMM');
  }

  constructor(
    public dialog: MatDialog,
    public translate: TranslateService,
    private ctrl: TimeLogController,
  ) {}

  ngOnInit() {
    if (this.dialogData) {
      this.data = this.dialogData;
    } else {
      this.ctrl.data.subscribe((data) => {
        this.data = data;
      });
    }

    this.ctrl.data.subscribe(() => {
      this.editingEntryId = -1;
    });
  }

  async toggleEditingEntry(id: number) {
    if (this.editingEntryId === id) {
      this.editingEntryId = -1;
    } else {
      this.editingEntryId = id;
    }
  }

  async deleteEntry(id: number) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('USER.TRAVEL_REQUESTS.DELETE_CONFIRMATION').toString(),
          description: this.translate.instant('LOG_TIME.DELETE_CONFIRMATION_DESCRIPTION', {
            id: id,
          }),
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('SHARED.DELETE').toString(),
        },
      })
      .afterClosed()
      .subscribe(async (result) => {
        if (result) {
          await this.ctrl.deleteEntry(id);
        }
      });
  }

  get isLoading() {
    return this.ctrl.isLoading();
  }
}
