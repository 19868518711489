import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IPage } from 'src/app/shared/models/page/page.model';
import { PageService } from 'src/app/shared/services/page.service';
import { ObservablesService } from '../../shared/services/observables.service';
import { Subject, takeUntil } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-page-details',
  templateUrl: './page-details.component.html',
  styleUrls: ['./page-details.component.scss'],
})
export class PageDetailsComponent implements OnInit, OnDestroy {
  public page: IPage;
  public isLoading: boolean = true;
  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private observable: ObservablesService,
    private pageService: PageService,
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.destroy$),
        switchMap((params) => this.pageService.getPageBySlug(params.slug)),
      )
      .subscribe((response) => {
        this.page = response;
        this.observable.dynamicBreadcrumbs.next([{ label: this.page.title, url: null }]);
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.observable.dynamicBreadcrumbs.next([]);
    this.destroy$.next();
    this.destroy$.complete();
  }
}
