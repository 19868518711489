import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';
import { firstValueFrom, Subscription } from 'rxjs';
import { ReimbursementService } from '../../shared/services/reimbursement.service';
import { AlertService, AlertType, LoginService } from '../../shared/services';
import { DIALOG_BUTTONS, REIMBURSEMENT_TYPES } from '../../shared/constants';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent } from '../../shared/dialogs';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { IReimbursement } from '../../shared/models/reimbursement.model';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MimeTypes } from 'src/app/shared/enums/mime-types.enum';

@Component({
  selector: 'app-reimbursement-requests',
  templateUrl: './reimbursement-requests.component.html',
  styleUrls: ['./reimbursement-requests.component.scss'],
})
export class ReimbursementRequestsComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  readonly reimbursementTypes = REIMBURSEMENT_TYPES;
  selectedType: string = this.reimbursementTypes.travel;
  allTypes: string[] = [];
  userId: number;
  accommodationFile: File | null = null;
  transportFile: File | null = null;
  additionalFiles: File[] = [];
  uniqueAdditionalFiles: string[] = [];
  additionalFileNames: string[] = [];
  form: FormGroup;
  selectedStartDate: Date;
  readonly buttons = DIALOG_BUTTONS;

  isEditMode: boolean = false;
  dataSource: IReimbursement[] = [];
  requestId: number;

  accommodationFileName = '';
  transportFileName = '';

  reimbursementTypesSubscription!: Subscription;
  createReimbursementSubscription!: Subscription;
  createTravelReimbursementSubscription!: Subscription;
  reimbursementRequestByIdSubscription!: Subscription;
  reimbursementAllRequestsSubscription!: Subscription;

  acceptedMimeTypes = [MimeTypes['.pdf'], MimeTypes['.png'], MimeTypes['.jpeg']];

  constructor(
    private dialog: MatDialog,
    private reimbursementService: ReimbursementService,
    private loginService: LoginService,
    private alertService: AlertService,
    public translate: TranslateService,
  ) {}

  @ViewChild('accommodationFileUpload') accommodationFileUpload: ElementRef;
  @ViewChild('transportFileUpload') transportFileUpload: ElementRef;
  @ViewChild('additionalFilesUpload') additionalFilesUpload: ElementRef;

  async ngOnInit() {
    this.reimbursementTypesSubscription = this.reimbursementService
      .getReimbursementTypes()
      .subscribe((allTypes) => {
        this.allTypes = allTypes;
        this.allTypes.sort((a, b) => a.localeCompare(b));
      });
    this.userId = this.loginService.getUserId();
    this.getAllReimbursements();
    this.resetForm();
  }

  resetForm() {
    this.form = new FormGroup({
      startDate: new FormControl<Date>({ value: null, disabled: false }),
      endDate: new FormControl<Date>({ value: null, disabled: false }),
      destinationFrom: new FormControl<string>(''),
      destinationTo: new FormControl<string>(''),
      round: new FormControl<boolean>(false),
      accommodation: new FormControl<boolean>(false),
      transport: new FormControl<boolean>(false),
      additionalInfo: new FormControl<string>(''),
    });
    this.accommodationFile = null;
    this.transportFile = null;
    this.accommodationFileName = '';
    this.transportFileName = '';
    this.additionalFiles = [];
    this.additionalFileNames = [];
    this.uniqueAdditionalFiles = [];
    this.selectedStartDate = null;
  }

  getStartDate(event: MatDatepickerInputEvent<Date>) {
    this.selectedStartDate = event.value;

    if (
      !this.selectedStartDate ||
      this.selectedStartDate > this.form.get('endDate').value ||
      this.isEditMode
    ) {
      this.form.get('endDate').setValue(null);
    }
  }

  endDateFilter = (date: Date | null): boolean => {
    let endDate = formatDate(date, 'yyyy-MM-dd', 'en-US');
    let startDate = formatDate(this.selectedStartDate, 'yyyy-MM-dd', 'en-US');
    return !endDate || (this.selectedStartDate && endDate >= startDate);
  };

  onAccommodationFileSelected(event: any) {
    this.accommodationFile = event.target['files'][0];
  }

  onTransportFileSelected(event: Event) {
    this.transportFile = event.target['files'][0];
  }

  onAccommodationSelectChange(isChecked: boolean) {
    if (!isChecked) {
      this.accommodationFile = null;
    }
  }

  onAdditionalFilesSelected(event: InputEvent) {
    const files = (event.target as HTMLInputElement).files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!this.uniqueAdditionalFiles.includes(file.name)) {
        this.additionalFiles.push(file);
      }
      this.uniqueAdditionalFiles = this.additionalFiles.map((item) => item.name);
      this.additionalFilesUpload.nativeElement.value = '';
    }
  }

  onTransportSelectChange(isChecked: boolean) {
    if (!isChecked) {
      this.transportFile = null;
    }
  }

  removeUploadedFile(type: string, index?: number) {
    switch (type) {
      case 'accommodationFile': {
        this.accommodationFile = null;
        this.accommodationFileUpload.nativeElement.value = '';
        break;
      }
      case 'transportFile': {
        this.transportFile = null;
        this.transportFileUpload.nativeElement.value = '';
        break;
      }
      case 'additionalFile': {
        this.additionalFiles.splice(index, 1);
        this.uniqueAdditionalFiles.splice(index, 1);
      }
    }
  }

  buildForm() {
    const formData = new FormData();
    formData.append(
      'fromDate',
      formatDate(this.form.controls.startDate.value, 'yyyy-MM-dd', 'en-US'),
    );
    formData.append('toDate', formatDate(this.form.controls.endDate.value, 'yyyy-MM-dd', 'en-US'));
    formData.append('type', this.selectedType);
    formData.append('employeeId', this.userId.toString());
    formData.append('additionalInformation', this.form.controls.additionalInfo.value);

    if (this.selectedType !== this.reimbursementTypes.travel) {
      if (this.additionalFiles.length) {
        for (const additionalFile of this.additionalFiles) {
          formData.append('reimbursementDocument', additionalFile);
        }
      }
    } else {
      formData.append('fromDestination', this.form.controls.destinationFrom.value);
      formData.append('toDestination', this.form.controls.destinationTo.value);
      formData.append('roundTrip', this.form.controls.round.value);
      formData.append('accommodationReimbursement', this.form.controls.accommodation.value);
      if (this.accommodationFile?.name) {
        formData.append(
          'accommodationReimbursementDocument',
          new Blob([this.accommodationFile], {
            type: 'application/octet-stream',
          }),
          this.accommodationFile?.name,
        );
      }
      formData.append('transportReimbursement', this.form.controls.transport.value);
      if (this.transportFile?.name) {
        formData.append(
          'transportReimbursementDocument',
          new Blob([this.transportFile], { type: 'application/octet-stream' }),
          this.transportFile?.name,
        );
      }
      if (this.additionalFiles.length) {
        for (const additionalFile of this.additionalFiles) {
          formData.append(
            'additionalDocument',
            new Blob([additionalFile], { type: 'application/octet-stream' }),
            additionalFile?.name,
          );
        }
      }
    }
    return formData;
  }

  async onSubmit() {
    const formData = this.buildForm();

    if (!this.isFormNotValid()) {
      if (this.selectedType !== this.reimbursementTypes.travel) {
        const sendPostOrPutRequest = !this.isEditMode
          ? this.reimbursementService.createReimbursementRequest(formData)
          : this.reimbursementService.updateReimbursementRequest(
              formData,
              this.userId,
              this.requestId,
            );

        this.createReimbursementSubscription = sendPostOrPutRequest.subscribe(
          () => {
            this.alertService.showAlert(
              this.translate.instant('USER.REIMBURSEMENT_REQUESTS.SUCCESSFUL_SUBMIT_MESSAGE'),
              AlertType.success,
            );
          },
          (error) => this.alertService.showAlert(error.error, AlertType.error),
          () => {
            this.isLoading = false;
            this.isEditMode = false;
            this.getAllReimbursements();
          },
        );
      } else {
        const sendPostOrPutRequest = !this.isEditMode
          ? this.reimbursementService.createTravelReimbursementRequest(formData)
          : this.reimbursementService.updateTravelReimbursementRequest(
              formData,
              this.userId,
              this.requestId,
            );

        this.createTravelReimbursementSubscription = sendPostOrPutRequest.subscribe(
          () => {
            this.alertService.showAlert(
              this.translate.instant('USER.REIMBURSEMENT_REQUESTS.SUCCESSFUL_SUBMIT_MESSAGE'),
              AlertType.success,
            );
          },
          (error) => this.alertService.showAlert(error.error, AlertType.error),
          () => {
            this.isLoading = false;
            this.isEditMode = false;
            this.getAllReimbursements();
          },
        );
      }
    }
    this.resetForm();
  }

  async getAllReimbursements() {
    this.resetForm();

    this.reimbursementAllRequestsSubscription = this.reimbursementService
      .getAllReimbursementRequests(this.userId)
      .subscribe(
        (data) => {
          this.isTableLoading = true;
          this.dataSource = [...data];
          this.dataSource.forEach((item) => {
            const itemYear = item.createdOn.toString().slice(0, 4);
            const itemMonth = item.createdOn.toString().slice(5, 7);
            const currentYear = moment(new Date()).format('YYYY');
            const currentMonth = moment(new Date()).format('MM');

            itemMonth === currentMonth && itemYear === currentYear
              ? (item['isValidDate'] = true)
              : (item['isValidDate'] = false);
          });
        },
        (error) => error,
        () => (this.isTableLoading = false),
      );
  }

  async getReimbursementById(userId: number, requestId: number) {
    this.resetForm();

    this.reimbursementRequestByIdSubscription = this.reimbursementService
      .getReimbursementRequestById(userId, requestId)
      .subscribe((request) => {
        this.isEditMode = true;
        this.requestId = request.id;
        this.selectedType = request.type;
        this.selectedStartDate = request.fromDate;

        this.form.setValue({
          startDate: request.fromDate,
          endDate: request.toDate,
          destinationFrom: request.fromDestination,
          destinationTo: request.toDestination,
          round: request.roundTrip,
          accommodation: !!request.accommodationReimbursementDocumentName,
          transport: !!request.transportReimbursementDocumentName,
          additionalInfo: request.additionalInformation,
        });

        this.additionalFileNames = request.reimbursementDocumentNames;

        if (this.selectedType === this.reimbursementTypes.travel) {
          this.accommodationFileName = request.accommodationReimbursementDocumentName;
          this.transportFileName = request.transportReimbursementDocumentName;
        }

        this.openWarningDialog();
      });
  }

  openWarningDialog() {
    if (
      (this.selectedType === this.reimbursementTypes.travel &&
        !this.transportFileName &&
        !this.accommodationFileName) ||
      (this.selectedType !== this.reimbursementTypes.travel && !this.additionalFileNames.length)
    ) {
      this.dialog.open(DialogComponent, {
        data: {
          title: this.translate.instant('HOME.REQUEST_FORM.WARNING'),
          description: this.translate.instant('USER.REIMBURSEMENT_REQUESTS.WARNING_MESSAGE'),
          sharedButtonClass: this.buttons.warningButton,
          sharedButtonText: this.translate.instant('HOME.REQUEST_FORM.CONTINUE'),
        },
      });
    }
  }

  isFormNotValid(): boolean {
    const isStarEndDatesValid =
      this.form.controls.startDate.valid && this.form.controls.endDate.valid;
    const isFromToDestinationValid =
      this.form.controls.destinationFrom.value && this.form.controls.destinationTo.value;
    const isAccommodationOrTransportValid =
      (this.form.controls.accommodation.value &&
        (this.accommodationFile || this.accommodationFileName)) ||
      (this.form.controls.transport.value && (this.transportFile || this.transportFileName));
    const isAccommodationAndTransportSelected =
      this.form.controls.accommodation.value && this.form.controls.transport.value;
    const isAccommodationOrTransportFileNotValid =
      !(this.accommodationFile || this.accommodationFileName) ||
      !(this.transportFile || this.transportFileName);

    if (this.selectedType === this.reimbursementTypes.travel) {
      return !(
        isStarEndDatesValid &&
        isFromToDestinationValid &&
        isAccommodationOrTransportValid &&
        !(isAccommodationAndTransportSelected && isAccommodationOrTransportFileNotValid)
      );
    } else {
      return !(
        isStarEndDatesValid &&
        (this.additionalFiles.length || this.additionalFileNames.length)
      );
    }
  }

  public getErrorMessage(inputName: string): string {
    switch (inputName) {
      case 'destinationFrom': {
        if (this.form.controls.destinationFrom.hasError('required'))
          return this.translate.instant('USER.REIMBURSEMENT_REQUESTS.DESTINATION_REQUIRED');
        break;
      }
      case 'destinationTo': {
        if (this.form.controls.destinationTo.hasError('required'))
          return this.translate.instant('USER.REIMBURSEMENT_REQUESTS.DESTINATION_REQUIRED');
        break;
      }
      case 'file-upload': {
        return this.translate.instant('USER.REIMBURSEMENT_REQUESTS.FILE_UPLOAD_REQUIRED');
      }
    }
  }

  async editReimbursementRequest(element: IReimbursement) {
    await this.getReimbursementById(this.userId, element.id);
  }

  async deleteReimbursementRequest(element: IReimbursement) {
    await firstValueFrom(
      this.reimbursementService.deleteReimbursementRequestById(this.userId, element.id),
    ).then(() => {
      this.resetForm();
      this.getAllReimbursements();
      this.isEditMode = false;
    });
  }

  async deleteReimbursementFileByName(fileName: string, type: string, index?: number) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('USER.REIMBURSEMENT_REQUESTS.DELETE_CONFIRMATION'),
          description:
            this.translate.instant('ADMIN.PAGES.DELETE_CONFIRMATION_DESCRIPTION') + `${fileName}?`,
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('USER.REIMBURSEMENT_REQUESTS.DELETE'),
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          firstValueFrom(
            this.reimbursementService.deleteReimbursementFileByName(
              this.userId,
              this.requestId,
              fileName,
            ),
          ).then(() => {
            switch (type) {
              case 'accommodationFile': {
                this.accommodationFile = null;
                this.accommodationFileUpload.nativeElement.value = '';
                this.accommodationFileName = '';
                break;
              }
              case 'transportFile': {
                this.transportFile = null;
                this.transportFileUpload.nativeElement.value = '';
                this.transportFileName = '';
                break;
              }
              case 'additionalFile': {
                this.additionalFileNames.splice(index, 1);
              }
            }
          });
        }
      });
  }

  cancelUpdate() {
    this.openWarningDialog();
    this.resetForm();
    this.getAllReimbursements();
    this.isEditMode = false;
  }

  ngOnDestroy() {
    if (this.reimbursementTypesSubscription) {
      this.reimbursementTypesSubscription.unsubscribe();
    }
    if (this.createReimbursementSubscription) {
      this.createReimbursementSubscription.unsubscribe();
    }
    if (this.createTravelReimbursementSubscription) {
      this.createTravelReimbursementSubscription.unsubscribe();
    }
    if (this.reimbursementRequestByIdSubscription) {
      this.reimbursementRequestByIdSubscription.unsubscribe();
    }
    if (this.reimbursementAllRequestsSubscription) {
      this.reimbursementAllRequestsSubscription.unsubscribe();
    }
  }
}
