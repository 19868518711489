import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DIALOG_BUTTONS } from '../../shared/constants';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import * as FileSaver from 'file-saver';
import { AlertService, AlertType } from 'src/app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { TravelRequestService } from 'src/app/shared/services/travel-request.service';
import { MatDialog } from '@angular/material/dialog';
import { EditTravelRequestDialogComponent } from 'src/app/shared/dialogs/edit-travel-request-dialog/edit-travel-request-dialog.component';
import { ITravelRequestPartial } from 'src/app/shared/models/travel-request.model';
import { TravelRequestsTableComponent } from 'src/app/shared/components/travel-requests-table/travel-requests-table.component';

@Component({
  selector: 'app-travel-requests',
  templateUrl: './all-travel-requests.component.html',
  styleUrls: ['./all-travel-requests.component.scss'],
})
export class AllTravelRequestsComponent implements OnInit, OnDestroy {
  public isLoading: boolean = false;
  readonly buttons = DIALOG_BUTTONS;
  exportForm: FormGroup;
  selectedStartDate: Date;
  selectedEndDate: Date;
  currentTripId: number;
  private destroy$ = new Subject<void>();
  @ViewChild(TravelRequestsTableComponent)
  requestTable: TravelRequestsTableComponent;

  constructor(
    private alertService: AlertService,
    public translate: TranslateService,
    public travelRequestService: TravelRequestService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.buildExportForm();
  }

  buildExportForm() {
    this.exportForm = new FormGroup({
      startDate: new FormControl<Date | null>(null, Validators.required),
      endDate: new FormControl<Date | null>({ value: null, disabled: true }, Validators.required),
    });
  }

  getStartDate(event: MatDatepickerInputEvent<Date>) {
    this.selectedStartDate = event.value;

    this.exportForm.controls.endDate.enable();
    if (
      !this.selectedStartDate ||
      this.selectedStartDate > this.exportForm.controls.endDate.value
    ) {
      this.exportForm.controls.endDate.setValue(null);
    }
  }

  endDateFilter = (date: Date | null): boolean =>
    !date || (this.selectedStartDate && date >= this.selectedStartDate);

  onSubmit() {
    const fromDate = formatDate(this.exportForm.controls.startDate.value, 'yyyy-MM-dd', 'en-US');
    const toDate = formatDate(this.exportForm.controls.endDate.value, 'yyyy-MM-dd', 'en-US');
    this.travelRequestService
      .getTravelRequestsFilteredByDate(fromDate, toDate)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.saveAsExcelFile(response, 'Report_travel_requests');
        },
        (error) => this.alertService.showAlert(error.message, AlertType.error),
      );
  }

  saveAsExcelFile(excelData: ArrayBuffer, fileName: string): void {
    const data: Blob = new Blob([excelData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(
      data,
      fileName + '_' + formatDate(new Date(), 'yyyy-MM-dd', 'en-US') + '.xlsx',
    );
  }

  editTravelRequest(data: ITravelRequestPartial): void {
    this.currentTripId = data.businessTripId;
    const dialogRef = this.dialog.open(EditTravelRequestDialogComponent, {
      width: '1200px',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.currentTripId = null;
      if (result !== 'cancel') {
        this.rerenderTable();
      }
    });
  }

  rerenderTable() {
    this.requestTable.getTravelRequests();
    this.requestTable.filterTable();
    this.requestTable.table.renderRows();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
