import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IAllSkills, IEmployeeSkill } from '../../../shared/models/employee-skills.model';
import { EmployeeSkillsService } from '../../../shared/services/employee-skills.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { DialogComponent } from '../../../shared/dialogs';
import { DIALOG_BUTTONS } from '../../../shared/constants';
import { AlertService, AlertType } from '../../../shared/services';

@Component({
  selector: 'app-skills-dialog',
  templateUrl: './skills-dialog.component.html',
  styleUrls: ['./skills-dialog.component.scss'],
})
export class SkillsDialogComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;

  displayedColumns: string[] = ['skillName', 'proficiencyLevel', 'yearsOfExperience', 'userAction'];
  @ViewChild(MatSort) sort: MatSort;
  public dataSource: MatTableDataSource<IAllSkills> = new MatTableDataSource<IAllSkills>();
  readonly buttons = DIALOG_BUTTONS;
  private destroy$ = new Subject<void>();

  employeeId: number;
  showSubmitForm: boolean;

  allSkills: IAllSkills[] = [];
  selectedSkills: IAllSkills[] = [];
  filteredSkills: Observable<IEmployeeSkill[]>;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  skillCtrl = new FormControl('');
  form: FormGroup = new FormGroup({
    skills: new FormControl({ value: this.selectedSkills, disabled: false }),
    proficiencyLevel: new FormControl<Number>({ value: 1, disabled: false }),
    yearsOfExperience: new FormControl<Number>({ value: 1, disabled: false }),
  });

  @ViewChild('skillInput') skillInput: ElementRef<HTMLInputElement>;

  constructor(
    private dialog: MatDialog,
    private employeeSkillsService: EmployeeSkillsService,
    public translate: TranslateService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<SkillsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { employeeId: number; showSubmitForm: boolean },
  ) {
    this.employeeId = data.employeeId;
    this.showSubmitForm = data.showSubmitForm;
  }

  ngOnInit(): void {
    this.employeeSkillsService
      .getAllSkills()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (skills) => {
          this.allSkills = skills;
        },
        () => {},
        () => {
          this.filteredSkills = this.skillCtrl.valueChanges.pipe(
            startWith(''),
            map((skill: IAllSkills | string | null) =>
              skill ? this._filter(skill) : this.allSkills.slice(),
            ),
          );
        },
      );

    this.getEmployeeSkills();
  }

  private getEmployeeSkills() {
    this.employeeSkillsService
      .getEmployeeSkills(this.employeeId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.dataSource = new MatTableDataSource<any>(data);
          this.dataSource.sort = this.sort;
        },
        () => {},
        () => {},
      );
  }

  public isSkillInList(skill: IAllSkills): boolean {
    if (this.selectedSkills) {
      return this.selectedSkills.some((e) => e.id === skill.id);
    }
    return false;
  }

  addSkill(event: MatChipInputEvent): void {
    const input = event.input;
    if (input) {
      input.value = '';
    }
  }

  removeSkillOfList(skill: IAllSkills): void {
    const index = this.selectedSkills.indexOf(skill);
    if (index >= 0) {
      this.selectedSkills.splice(index, 1);
    }
  }

  public clearInputValue() {
    this.skillInput.nativeElement.value = '';
  }

  selectedSkill(event: MatAutocompleteSelectedEvent): void {
    this.selectedSkills.push(event.option.value);
    this.skillInput.nativeElement.value = '';
    this.skillCtrl.setValue(null);
  }

  private _filter(value: IAllSkills | string): any {
    let filterValue: string;

    if (typeof value === 'string') {
      filterValue = value.toLowerCase().trim();
    } else {
      filterValue = value.name.toLowerCase().trim();
    }

    return this.allSkills.filter((skill) => skill.name.toLowerCase().trim().includes(filterValue));
  }

  formatLabel(value: number): string {
    return `${value}`;
  }

  onSubmit() {
    this.selectedSkills.forEach((skill) => {
      const payload = {
        employeeId: this.employeeId,
        skillId: skill.id,
        yearsOfExperience: this.form.controls.yearsOfExperience.value,
        proficiencyLevel: this.form.controls.proficiencyLevel.value,
      };

      this.employeeSkillsService
        .createEmployeeSkill(this.employeeId, payload)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.alertService.showAlert(
              this.translate.instant('ADMIN.LICENSES.SUCCESSFULLY_ADDED'),
              AlertType.success,
            );
          },
          (error) => this.alertService.showAlert(error.error, AlertType.error),
          () => {
            this.getEmployeeSkills();
            this.resetForm();
          },
        );
    });
  }

  deleteSkill(skill: IEmployeeSkill) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('USER.REIMBURSEMENT_REQUESTS.DELETE_CONFIRMATION'),
          description:
            this.translate.instant('ADMIN.PAGES.DELETE_CONFIRMATION_DESCRIPTION') +
            `${skill.skillName}?`,
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('USER.REIMBURSEMENT_REQUESTS.DELETE'),
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.employeeSkillsService
            .deleteEmployeeSkill(this.employeeId, skill.skillId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              () => {
                this.alertService.showAlert(
                  this.translate.instant('ADMIN.LICENSES.SUCCESSFULLY_REMOVED'),
                  AlertType.success,
                );
              },
              (error) => {
                this.alertService.showAlert(error.error, AlertType.error);
              },
              () => {
                this.getEmployeeSkills();
              },
            );
        }
      });
  }

  resetForm() {
    this.selectedSkills = [];
    this.form = new FormGroup({
      skills: new FormControl({ value: this.selectedSkills, disabled: false }),
      proficiencyLevel: new FormControl<Number>({ value: 1, disabled: false }),
      yearsOfExperience: new FormControl<Number>({ value: 1, disabled: false }),
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
