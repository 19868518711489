import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IOvertime } from '../models/overtime.model';
import { IPagination } from '../models/pagination.model';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class OvertimeService {
  private urlBase: string = 'api/overtime';

  constructor(private httpClient: HttpClient) {}

  getAllOvertimes(params?: HttpParams): Observable<IPagination<IOvertime>> {
    return this.httpClient.get<IPagination<IOvertime>>(this.urlBase, {
      params,
    });
  }

  getLast12OvertimesByEmployeeId(): Observable<HttpResponse<IOvertime[]>> {
    return this.httpClient.get<IOvertime[]>(`${this.urlBase}/top-12`, {
      observe: 'response',
    });
  }

  getOvertimeById(id: number): Observable<IOvertime> {
    return this.httpClient.get<IOvertime>(`${this.urlBase}/${id}`);
  }

  createOvertime(overtime: IOvertime): Observable<IOvertime> {
    return this.httpClient.post<IOvertime>(`${this.urlBase}/create2`, overtime);
  }

  deleteOvertime(id: number) {
    return this.httpClient.delete(`${this.urlBase}/${id}/delete`);
  }

  updateOvertime(overtime: IOvertime, id: number): Observable<IOvertime> {
    const overtimeRequestBody = {
      ...overtime,
      id: id
    };
    return this.httpClient.put<IOvertime>(`${this.urlBase}/update`,overtimeRequestBody);
  }

  deleteOvertimeById(id: number, employeeId: number): Observable<IOvertime> {
    return this.httpClient.delete<IOvertime>(`${this.urlBase}/${employeeId}/delete/${id}`);
  }

  getAllOvertimesWithDatesInRange(
    fromDate: Date,
    toDate: Date,
    chosenTypes?: string[],
    filter: string = '',
    params?: HttpParams,
  ): Observable<IPagination<IOvertime>> {
    const from = formatDate(fromDate, 'yyyy-MM-dd', 'en-US');
    const to = formatDate(toDate, 'yyyy-MM-dd', 'en-US');
    return this.httpClient.post<IPagination<IOvertime>>(
      `api/overtime/${from}/${to}?filter=${filter}` + (params ? `&${params}` : ''),
      chosenTypes,
    );
  }

  public getOvertimeRequestsFilteredByDate(
    fromDate: Date,
    toDate: Date,
    chosenTypes?: string[],
  ): Observable<any> {
    const from = formatDate(fromDate, 'yyyy-MM-dd', 'en-US');
    const to = formatDate(toDate, 'yyyy-MM-dd', 'en-US');
    return this.httpClient.post(
      `${this.urlBase}/export?fromDate=${from}&toDate=${to}`,
      chosenTypes,
      {
        responseType: 'arraybuffer',
      },
    );
  }

  getAllOvertimesByEmployee(params?: HttpParams): Observable<IPagination<IOvertime>> {
    return this.httpClient.get<IPagination<IOvertime>>(`${this.urlBase}/overtimes-by-employee`, {
      params,
    });
  }
}
