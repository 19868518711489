<mat-spinner *ngIf="isLoading"></mat-spinner>

<div [hidden]="isLoading">
  <h2 class="section-title">
    {{ 'ADMIN.REQUESTS.ALL_REQUESTS' | translate }}
  </h2>

  <app-requests-filter (tableUpdateEvent)="updateData($event)" />

  <app-table-wrapper
    [tableConfig]="tableConfig"
    (getData)="getRequests(params)"
    [(pageable)]="pageable"
    matSort
    [sort]="sort"
    [isUrlChangeable]="true"
  >
    <ng-container matColumnDef="createdOn">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('createdOn')"
      >
        {{ 'ADMIN.REQUESTS.CREATED' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div>
          <a [routerLink]="['/admin/requests/' + request.id]">
            {{ request.createdOn }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="requesterEmail">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('requesterEmail')"
      >
        {{ 'ADMIN.REQUESTS.REQUESTER' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div>
          <a [routerLink]="['/admin/requests/' + request.id]">
            {{ request.requester.email }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="approverEmail">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('approverEmail')"
      >
        {{ 'ADMIN.REQUESTS.APPROVER' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div>
          <a [routerLink]="['/admin/requests/' + request.id]">
            {{ request.approver.email }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="fromDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('fromDate')"
      >
        {{ 'ADMIN.REQUESTS.START_DATE' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div>
          <a [routerLink]="['/admin/requests/' + request.id]">
            {{ request.fromDate }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="toDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('toDate')"
      >
        {{ 'ADMIN.REQUESTS.END_DATE' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div>
          <a [routerLink]="['/admin/requests/' + request.id]">
            {{ request.toDate }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="days">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isSortingDisabled('days')">
        {{ 'ADMIN.REQUESTS.DAYS' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div>
          <a [routerLink]="['/admin/requests/' + request.id]">
            {{ request.days }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="vacationType">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('vacationType')"
      >
        {{ 'ADMIN.REQUESTS.TYPE' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div
          *ngIf="
            request.vacationType === 'Sick leave' || request.vacationType === 'Other paid leave'
          "
        >
          <a [routerLink]="['/admin/requests/' + request.id]">{{ request.subType }}</a>
        </div>
        <div
          *ngIf="
            request.vacationType !== 'Sick leave' && request.vacationType !== 'Other paid leave'
          "
        >
          <a [routerLink]="['/admin/requests/' + request.id]">{{ request.vacationType }}</a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('status')"
      >
        {{ 'ADMIN.REQUESTS.STATUS' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div>
          <a [routerLink]="['/admin/requests/' + request.id]">
            {{ request.status }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="action" class="download-button">
      <th mat-header-cell *matHeaderCellDef></th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <mat-icon
          matTooltip="{{ 'ADMIN.REQUESTS.REQUEST_DOCUMENT' | translate }}"
          [class.hidden]="!request.filename"
          (click)="openPicListDialog(request)"
          class="icon save"
          >image_search
        </mat-icon>
        <mat-icon
          matTooltip="{{ 'ADMIN.REQUESTS.REQUEST_PDF' | translate }}"
          [class.hidden]="!applicableForPDF(request.vacationType, request)"
          (click)="exportPdf(request)"
          class="icon save"
          >picture_as_pdf</mat-icon
        >
        <mat-icon
          matTooltip="{{ 'ADMIN.REQUESTS.DELETE' | translate }}"
          [class.hidden]="!applicableForDeletion(request)"
          (click)="openDialog(request)"
          class="icon delete"
          >cancel
        </mat-icon>
      </td>
    </ng-container>
  </app-table-wrapper>
</div>
