import { Component, OnDestroy, OnInit } from '@angular/core';
import { EmployeeService } from '../../shared/services/employee.service';
import { IEmployeeDreamixBirthdayModel } from '../../shared/models/employee-dreamix-birthday.model';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-dreamix-birthdays',
  templateUrl: './dreamix-birthdays.component.html',
  styleUrls: ['./dreamix-birthdays.component.scss'],
})
export class DreamixBirthdaysComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  public birthdays: IEmployeeDreamixBirthdayModel[] = [];
  public displayedColumns: string[] = ['fullName', 'dreamixBirthday', 'YearsAtDreamix'];
  public isLoading: boolean = true;

  constructor(
    private employeeService: EmployeeService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.employeeService
      .getAllEmployeesBirthdays()
      .pipe(takeUntil(this.destroy$))
      .subscribe((employeesBirthdays) => {
        this.birthdays = this.sortBirthdays(employeesBirthdays);
        this.isLoading = false;
      });
  }

  private sortBirthdays(
    birthdays: IEmployeeDreamixBirthdayModel[],
  ): IEmployeeDreamixBirthdayModel[] {
    const currentDate: Date = new Date();
    const pastBirthdays: IEmployeeDreamixBirthdayModel[] = [];
    const futureBirthdays: IEmployeeDreamixBirthdayModel[] = [];

    birthdays.forEach((employee) => {
      const date = new Date(employee.dreamixBirthday).setFullYear(currentDate.getFullYear());
      if (date <= currentDate.getTime()) {
        pastBirthdays.push(employee);
      } else {
        futureBirthdays.push(employee);
      }
    });

    pastBirthdays.sort((a, b) => {
      return (
        new Date(a.dreamixBirthday).setFullYear(1970) -
        new Date(b.dreamixBirthday).setFullYear(1970)
      );
    });
    futureBirthdays.sort((a, b) => {
      return (
        new Date(a.dreamixBirthday).setFullYear(1970) -
        new Date(b.dreamixBirthday).setFullYear(1970)
      );
    });

    return futureBirthdays.concat(pastBirthdays);
  }

  public checkIfNWeeksBefore(employee: IEmployeeDreamixBirthdayModel, weeks: number): boolean {
    const afterNWeeks = new Date();
    afterNWeeks.setDate(afterNWeeks.getDate() + weeks * 7);
    const currentDate = new Date();
    const employeeTime = new Date(employee.dreamixBirthday);
    employeeTime.setFullYear(currentDate.getFullYear());
    return (
      currentDate.getTime() <= employeeTime.getTime() &&
      afterNWeeks.getTime() >= employeeTime.getTime()
    );
  }

  public getYearsFrom(date: Date): number {
    const dreamixBirthdayYears = new Date().getFullYear() - new Date(date).getFullYear();
    const dayOfYearIsLargerOrEqual =
      new Date(date).getMonth() > new Date().getMonth() ||
      (new Date(date).getMonth() === new Date().getMonth() &&
        new Date(date).getDate() > new Date().getDate()) ||
      (new Date(date).getMonth() === new Date().getMonth() &&
        new Date(date).getDate() === new Date().getDate());

    return dayOfYearIsLargerOrEqual ? dreamixBirthdayYears : dreamixBirthdayYears + 1;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
