import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatSort, MatSortable } from '@angular/material/sort';
import {
  AlertService,
  AlertType,
  EmployeeService,
  ProjectService,
} from '../../../../shared/services';
import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import {
  ProjectHistory,
  ProjectHistoryRequest,
  ProjectHistoryRow,
} from '../../../../shared/models/project-history.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { IProject, ProjectBase } from '../../../../shared/models';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { formatDateDto } from '../../../../shared/services/helpers/date.helper';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-edit-employee-projects',
  templateUrl: './edit-employee-projects.component.html',
  styleUrls: ['./edit-employee-projects.component.scss'],
})
export class EditEmployeeProjectsComponent implements OnDestroy, OnInit {
  displayedColumns: string[] = ['name', 'startDate', 'endDate', 'percentage', 'actions'];
  dataSource: MatTableDataSource<ProjectHistoryRow>;
  formGroup: FormGroup;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$ = new Subject<void>();

  public maxIndex: number = 0;
  public currentIndex: number;
  public minDate: Date;
  private employeeId: number;
  private employeeProjects: Array<ProjectHistoryRow>;
  public filteredProjects: Observable<ProjectBase[]>;
  private allProjects: Array<ProjectBase>;
  private allAvailableProjects: Array<ProjectBase> = [];

  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private alertService: AlertService,
  ) {
    this.dataSource = new MatTableDataSource<ProjectHistoryRow>([]);
    this.formGroup = this.fb.group({
      name: new FormControl('', [Validators.required, this.projectNameValidator()]),
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl(''),
      percentageInProject: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
    });
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.employeeId = parseInt(params.id, 10);
      this.loadProjects(this.employeeId);
    });
  }

  private loadProjects(employeeId: number): void {
    const params = new HttpParams().set('includingArchived', true);
    forkJoin({
      projectHistory: this.employeeService.getProjectHistoryByEmployeeId(employeeId),
      projects: this.projectService.getAllProjects(params),
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.initAllProjects(response.projects.items);
        this.initEmployeeProjects(response.projectHistory);
        this.dataSource.data = this.employeeProjects;
        this.filteredProjects = this.formGroup.controls.name.valueChanges.pipe(
          map((project: any) => this.filterProjectsByName(project)),
        );
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (row: ProjectHistoryRow, columnName: string) => {
          if (columnName === 'name') {
            return row.project.projectName;
          } else if (columnName === 'startDate') {
            return row.project.startDate;
          } else if (columnName === 'endDate') {
            return row.project.endDate;
          } else if (columnName === 'percentage') {
            return row.project.percentageInProject;
          }
          return row[columnName];
        };
        this.sort.sort({ id: 'startDate', start: 'desc' } as MatSortable);
      });
  }

  private initAllProjects(projects: IProject[]) {
    this.allProjects = projects
      .sort((a, b) => a.projectName.localeCompare(b.projectName))
      .map((project) => this.assembleProject(project));
    this.setAllAvailableProjects();
  }

  private assembleProject(project: IProject): ProjectBase {
    return {
      id: project.id,
      name: project.projectName,
    };
  }

  private initEmployeeProjects(projectHistory: ProjectHistory[]) {
    this.employeeProjects = projectHistory.map((project) =>
      this.assembleProjectHistoryRow(project),
    );
    this.setAllAvailableProjects();
  }

  private assembleProjectHistoryRow(project: ProjectHistory): ProjectHistoryRow {
    return {
      index: this.maxIndex++,
      project: {
        employeeId: this.employeeId,
        projectId: project.projectId,
        projectName: project.projectName,
        startDate: project.startDate ? new Date(project.startDate) : null,
        endDate: project.endDate ? new Date(project.endDate) : null,
        percentageInProject: project.percentageInProject,
        projectArchived: project.projectArchived,
      },
    };
  }

  addRow() {
    let newProject = this.populateNewRecord();
    this.employeeProjects.push(newProject);
    this.editModeOff();
    this.dataSource.data = this.employeeProjects;
    this.setAllAvailableProjects();
  }

  private populateNewRecord() {
    let newProject: ProjectHistoryRow = {
      index: this.maxIndex++,
      project: {
        employeeId: this.employeeId,
        projectId: this.formGroup.get('name').value.id,
        projectName: this.formGroup.get('name').value.name,
        startDate: this.formGroup.get('startDate').value,
        endDate: this.formGroup.get('endDate').value,
        percentageInProject: this.formGroup.get('percentageInProject').value,
      },
    };
    return newProject;
  }

  updateRow() {
    this.populateExistingRecord();
    this.dataSource.data = this.employeeProjects;
    this.editModeOff();
    this.setAllAvailableProjects();
  }

  private populateExistingRecord() {
    let currentProjectRow = this.employeeProjects.find(
      (project) => project.index === this.currentIndex,
    );
    currentProjectRow.project.projectName = this.formGroup.get('name').value.name;
    currentProjectRow.project.projectId = this.formGroup.get('name').value.id;
    currentProjectRow.project.startDate = this.formGroup.get('startDate').value;
    currentProjectRow.project.endDate = this.formGroup.get('endDate').value;
    currentProjectRow.project.percentageInProject = this.formGroup.get('percentageInProject').value;
  }

  editModeOff() {
    this.formGroup.reset();
    this.currentIndex = undefined;
  }

  editModeOn(row: ProjectHistoryRow) {
    this.currentIndex = row.index;
    const project: ProjectBase = {
      id: row.project.projectId,
      name: row.project.projectName,
    };
    this.formGroup.get('name').setValue(project);
    this.formGroup.get('startDate').setValue(row.project.startDate);
    this.formGroup.get('endDate').setValue(row.project.endDate);
    this.formGroup.get('percentageInProject').setValue(row.project.percentageInProject);
  }

  deleteRow(row: ProjectHistoryRow) {
    this.employeeProjects = this.employeeProjects.filter((project) => project.index != row.index);
    this.dataSource.data = this.employeeProjects;
  }

  inEdit(): boolean {
    return this.currentIndex !== undefined;
  }

  getStartDate(event: MatDatepickerInputEvent<Date>) {
    this.minDate = event.value;

    this.formGroup.controls.endDate.enable();
    if (!this.minDate || this.minDate > this.formGroup.controls.endDate.value) {
      this.formGroup.controls.endDate.setValue(null);
    }
  }

  selectProject(event: MatAutocompleteSelectedEvent) {
    const project: ProjectHistory = event.option.value;
    this.formGroup.get('name').setValue(project);
  }

  displayFn(project: ProjectBase): string {
    return project ? `${project.name}` : null;
  }

  private filterProjectsByName(projectName: string): ProjectBase[] {
    if (!projectName || typeof projectName !== 'string') {
      return this.allAvailableProjects;
    }
    const filterValue = projectName.toLowerCase();

    return this.allAvailableProjects.filter((project) =>
      project.name.toLowerCase().includes(filterValue),
    );
  }

  private setAllAvailableProjects() {
    if (this.allProjects && this.employeeProjects) {
      this.allAvailableProjects = [];
      for (const project of this.allProjects) {
        const index = this.employeeProjects.findIndex(
          (pr) => pr.project.projectId === project.id && pr.project.endDate === null,
        );
        if (index === -1) {
          this.allAvailableProjects.push(project);
        }
      }
    }
  }

  private projectNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const selectedProject: ProjectBase = control.value;
      if (selectedProject && this.allProjects) {
        const index = this.allProjects.filter((project) => project.name === selectedProject.name);
        if (!index.length) {
          return { requireMatch: !index.length };
        }
      }
      return null;
    };
  }

  public onSubmit(): void {
    let projectHistory: ProjectHistoryRequest[] = this.employeeProjects.map((projectRow) =>
      this.mapProjectHistoryRequest(projectRow),
    );
    this.employeeService
      .saveEmployeeProjectHistory(this.employeeId, projectHistory)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.alertService.showSuccess(
            'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.EDIT_PROJECTS.SUCCESSFULLY_UPDATED',
          );
        },
        (error) => {
          if (error.error) {
            this.alertService.showAlert(error.error, AlertType.error);
          } else {
            this.alertService.showAlert(error.message, AlertType.error);
          }
        },
      );
  }

  private mapProjectHistoryRequest(project: ProjectHistoryRow): ProjectHistoryRequest {
    return {
      employeeId: project.project.employeeId,
      projectId: project.project.projectId,
      startDate: formatDateDto(project.project.startDate),
      endDate: formatDateDto(project.project.endDate),
      percentageInProject: project.project.percentageInProject,
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
