<h2 class="section-title">
  {{ 'NAV_BAR.HEADCOUNT' | translate }}
</h2>
<div class="excel-form">
  <div class="headcount-component">
    <mat-form-field (click)="picker1.open()" color="accent" appearance="fill">
      <mat-label>{{ 'ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.FROM_DATE' | translate }}</mat-label>
      <input matInput [matDatepicker]="picker1" (dateInput)="submitStartDate($event)" required />
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1 color="primary"></mat-datepicker>
    </mat-form-field>
    <button
      (click)="onSubmit()"
      mat-raised-button
      color="primary"
      class="excel-button"
      type="button"
    >
      <mat-icon>download</mat-icon>{{ 'ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.EXCEL' | translate }}
    </button>
  </div>
</div>
