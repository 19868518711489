<h2 mat-dialog-title>
  {{ data.employeeName }} / <span class="employee-position">{{ data.employeePosition }}</span>
</h2>
<div mat-dialog-content *ngIf="mounted" class="dialog-content">
  <mat-tab-group>
    <mat-tab [label]="'USER.KNOW_BE.DASHBOARD' | translate">
      <ng-template matTabContent>
        <app-knowbe-dashboard
          [phishPronePercentage]="phishPronePercentage"
          [personalRiskScore]="personalRiskScore"
          [organizationRiskScore]="organizationRiskScore"
          [userRiskScoreChartData]="userRiskScoreChartData"
          [organizationCurrentRiskScoreHistoryChartData]="
            organizationCurrentRiskScoreHistoryChartData
          "
        ></app-knowbe-dashboard>
      </ng-template>
    </mat-tab>
    <mat-tab [label]="'USER.KNOW_BE.PHISHING_RESULTS' | translate">
      <ng-template matTabContent>
        <div class="summary">
          <div>
            {{ 'USER.KNOW_BE.PHISHING_EMAILS_RECEIVED' | translate }}
            <strong>{{ phishingTotalCount }}</strong>
          </div>
          <div>
            {{ 'USER.KNOW_BE.PHISHING_EMAILS_REPORTED' | translate }}
            <strong>{{ phishingEmailsReported }}</strong>
          </div>
          <div>
            {{ 'USER.KNOW_BE.PHISH_PRONE_PERCENTAGE' | translate }}
            <strong>{{ phishPronePercentage }}%</strong>
          </div>
        </div>
        <app-knowbe-bar-chart
          *ngIf="phishingChartData"
          [chartData]="phishingChartData"
          [totalCount]="phishingTotalCount"
        ></app-knowbe-bar-chart>
      </ng-template>
    </mat-tab>

    <mat-tab [label]="'USER.KNOW_BE.TRAINING_ASSIGNMENTS' | translate">
      <ng-template matTabContent>
        <div class="summary">
          <div>
            {{ 'USER.KNOW_BE.TOTAL_ASSIGNMENTS' | translate }}
            <strong>{{ trainingTotalCount }}</strong>
          </div>
          <div>
            {{ 'USER.KNOW_BE.TOTAL_ASSIGNMENTS_COMPLETED' | translate }}
            <strong>{{ completedTrainingsCount }}</strong>
          </div>
        </div>
        <app-knowbe-bar-chart
          *ngIf="trainingChartData"
          [chartData]="trainingChartData"
          [totalCount]="trainingTotalCount"
        ></app-knowbe-bar-chart>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
