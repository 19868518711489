<div>
  <h2 class="section-title">
    {{ 'ADMIN.OVERTIMES.TITLE' | translate }}
  </h2>
  <app-overtimes-export-excel
    (filterUpdated)="updateData($event)"
    (fromDateUpdated)="updateFromDate($event)"
    (toDateUpdated)="updateToDate($event)"
    (chosenTypesUpdated)="updateChosenTypes($event)"
  ></app-overtimes-export-excel>

  <h2 class="section-title">
    {{ 'ADMIN.OVERTIMES.REIMBURSEMENTS' | translate }}
  </h2>
  <app-reimbursements-export-excel></app-reimbursements-export-excel>
</div>
