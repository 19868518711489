<h2 class="section-title">
  {{ 'ADMIN.PAGES.PAGES' | translate }}
</h2>

<div class="form-container">
  <button
    type="button"
    mat-raised-button
    (click)="router.navigate(['admin/pages/edit'])"
    color="primary"
  >
    {{ 'ADMIN.PAGES.ADD_PAGE' | translate }}
  </button>
</div>

<div
  cdkDropList
  [id]="null"
  [cdkDropListData]="allPages"
  [cdkDropListConnectedTo]="dropTargetIds"
  (cdkDropListDropped)="drop($event)"
  [cdkDropListSortingDisabled]="true"
>
  <div
    *ngFor="let page of allPages"
    cdkDrag
    [cdkDragData]="page.id"
    (cdkDragMoved)="dragMoved($event)"
  >
    <ng-container *ngTemplateOutlet="tmplNode; context: { page: page }"></ng-container>
  </div>
</div>

<ng-template #tmplNode let-page="page">
  <div class="item" [attr.data-id]="page.id" [attr.id]="'page-' + page.id">
    <div class="page-details">
      <div>{{ page.title }}</div>
      <div class="page-actions">
        <mat-slide-toggle
          color="primary"
          matTooltip="{{ 'ADMIN.PAGES.VISIBILITY' | translate }}"
          (toggleChange)="toggleVisibility(page)"
          [checked]="page.visible"
        ></mat-slide-toggle>
        <mat-icon
          matTooltip="{{ 'ADMIN.PAGES.EDIT' | translate }}"
          [routerLink]="['/admin/pages/edit', page.id]"
          class="icon user-edit"
          [class.invisible]="!isPageEditable(page.slug)"
          >edit
        </mat-icon>
        <mat-icon
          matTooltip="{{ 'ADMIN.PAGES.DELETE' | translate }}"
          (click)="openDialog(page)"
          class="icon delete"
          [class.invisible]="!isPageEditable(page.slug)"
        >
          cancel
        </mat-icon>
      </div>
    </div>

    <div
      class="children"
      cdkDropList
      [id]="page.id"
      [cdkDropListData]="page.children"
      [cdkDropListConnectedTo]="dropTargetIds"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListSortingDisabled]="true"
    >
      <div
        *ngFor="let child of page.children"
        cdkDrag
        [cdkDragData]="child.id"
        (cdkDragMoved)="dragMoved($event)"
      >
        <ng-container *ngTemplateOutlet="tmplNode; context: { page: child }"></ng-container>
      </div>
    </div>
  </div>
</ng-template>
