<mat-spinner *ngIf="isLoading"></mat-spinner>

<div [hidden]="isLoading">
  <h2 class="section-title">
    {{ 'ADMIN.HOLIDAYS.HOLIDAYS' | translate }}
  </h2>

  <div class="form-container">
    <div class="insertForm">
      <mat-form-field
        color="accent"
        appearance="fill"
        (click)="startPicker.open()"
        class="datePickerInputs"
      >
        <mat-label>{{ 'ADMIN.HOLIDAYS.DAY' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="startPicker"
          [(ngModel)]="date"
          [matDatepickerFilter]="isAlreadyAdded"
          (dateChange)="onStartDateSelection()"
          readonly
        />
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field color="accent" appearance="fill">
        <mat-label> {{ 'ADMIN.HOLIDAYS.DESCRIPTION' | translate }}:</mat-label>
        <input matInput type="text" [(ngModel)]="description" id="input-description" />
      </mat-form-field>

      <button
        type="button"
        class="add-button"
        mat-raised-button
        (click)="saveVacationDay()"
        color="primary"
      >
        {{ 'ADMIN.HOLIDAYS.ADD' | translate }}
      </button>
    </div>
  </div>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'ADMIN.HOLIDAYS.DATE' | translate }}
        </th>
        <td mat-cell *matCellDef="let i = index">
          <div *ngIf="!editMode[i]">
            {{ allHolidays[i].date | date: 'dd.MM.yyyy' }}
          </div>
          <div class="date" *ngIf="editMode[i]">
            <input
              matInput
              [matDatepicker]="startPicker"
              [(ngModel)]="allHolidays[i].date"
              [matDatepickerFilter]="isAlreadyAdded"
              readonly
            />
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'ADMIN.HOLIDAYS.DESCRIPTION' | translate }}
        </th>
        <td mat-cell *matCellDef="let i = index">
          <div *ngIf="!editMode[i]">
            {{ allHolidays[i].description }}
          </div>
          <div class="description" *ngIf="editMode[i]">
            <input [(ngModel)]="allHolidays[i].description" />
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let i = index">
          <div *ngIf="!editMode[i]">
            <mat-icon
              matTooltip="{{ 'ADMIN.HOLIDAYS.EDIT' | translate }}"
              (click)="editModeOn(i)"
              class="icon user-edit"
              >edit</mat-icon
            >
            <mat-icon
              matTooltip="{{ 'ADMIN.HOLIDAYS.DELETE' | translate }}"
              (click)="openDialog(i)"
              class="icon delete"
              >cancel</mat-icon
            >
          </div>
          <div *ngIf="editMode[i]">
            <mat-icon
              matTooltip="{{ 'ADMIN.HOLIDAYS.SAVE_CHANGES' | translate }}"
              (click)="update(i)"
              class="icon save"
              >save</mat-icon
            >
            <mat-icon
              matTooltip="{{ 'ADMIN.HOLIDAYS.UNDO' | translate }}"
              (click)="editModeOff(i)"
              class="icon undo"
              >refresh</mat-icon
            >
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="noData">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          {{ 'ADMIN.HOLIDAYS.NO_RECORDS_FOUND' | translate }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="['noData']"
        [hidden]="dataSource && dataSource.data.length > 0"
      ></tr>
    </table>
  </div>
</div>
