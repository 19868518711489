<div class="flex-columns">
  <div class="info info-primary" [ngClass]="{'set-height': setHeight}">
    <p>{{ vacations[type]  }} - {{ daysUsed }} {{ 'HOME.VACATION_CHARTS.DAYS' | translate }}</p>
    <p class="vacation-left"
       *ngIf="vacations[type] === vacations.VACATION || vacations[type] === vacations.WORK_ON_HOLIDAYS">
      {{ daysLeft }} {{ 'HOME.VACATION_CHARTS.LEFT' | translate }}
    </p>
  </div>
  <mat-progress-bar [class]="type" determinate [value]="progress"></mat-progress-bar>
</div>
