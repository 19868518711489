import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import * as FileSaver from 'file-saver';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { formatDate } from '@angular/common';
import { OvertimeTypeEnum } from '../../../shared/enums/overtime-type.enum';
import { OvertimeService } from '../../../shared/services/overtime.service';
import { IPageable } from '../../../shared/models/pageable.model';
import { IOvertime } from '../../../shared/models/overtime.model';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-overtimes-export-excel',
  templateUrl: './overtimes-export-excel.component.html',
  styleUrls: ['./overtimes-export-excel.component.scss'],
})
export class OvertimesExportExcelComponent implements OnDestroy {
  reportStartDate: MatDatepickerInputEvent<Date>;
  reportEndDate: MatDatepickerInputEvent<Date>;
  overtimes: IOvertime[];
  pageable: IPageable;
  private destroy$ = new Subject<void>();

  types: FormControl = new FormControl();
  typeList: string[] = [
    OvertimeTypeEnum.WORKING,
    OvertimeTypeEnum.TEACHING,
    OvertimeTypeEnum.WORK_ON_HOLIDAYS,
  ];
  chosenTypes: string[] = [];

  @Output() filterUpdated: EventEmitter<string> = new EventEmitter();
  @Output() fromDateUpdated: EventEmitter<Date> = new EventEmitter();
  @Output() toDateUpdated: EventEmitter<Date> = new EventEmitter();
  @Output() chosenTypesUpdated: EventEmitter<string[]> = new EventEmitter();
  filter: string;

  constructor(
    private overtimeService: OvertimeService,
    public translate: TranslateService,
    private alertService: AlertService,
  ) {}

  onSubmit(): void {
    if (!this.validateInput()) {
      return;
    }
    const from = new Date(
      this.reportStartDate.value.getFullYear(),
      this.reportStartDate.value.getMonth(),
      this.reportStartDate.value.getDate(),
    );
    const to = new Date(
      this.reportEndDate.value.getFullYear(),
      this.reportEndDate.value.getMonth(),
      this.reportEndDate.value.getDate(),
    );
    this.exportExcel(from, to);
  }

  submitStartDate(date: MatDatepickerInputEvent<Date>): void {
    this.reportStartDate = date;
    this.fromDateUpdated.emit(this.reportStartDate.value);
  }

  submitEndDate(date: MatDatepickerInputEvent<Date>): void {
    this.reportEndDate = date;
    this.toDateUpdated.emit(this.reportEndDate.value);
  }

  onSubmitTypes(data: MatSelectChange): void {
    this.chosenTypes = data.value;
    this.chosenTypesUpdated.emit(this.chosenTypes);
  }

  saveAsExcelFile(excelData: ArrayBuffer, fileName: string): void {
    const data: Blob = new Blob([excelData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(
      data,
      fileName + '_' + formatDate(new Date(), 'yyyy-MM-dd', 'en-US') + '.xlsx',
    );
  }

  private exportExcel(fromDate: Date, toDate: Date): void {
    this.overtimeService
      .getOvertimeRequestsFilteredByDate(fromDate, toDate, this.chosenTypes)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.saveAsExcelFile(response, 'Report_overtimes');
        },
        (error) => this.alertService.showAlert(error.message, AlertType.error),
      );
  }

  private validateInput(): boolean {
    if (!this.reportStartDate) {
      this.alertService.showAlert(
        this.translate.instant('ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.FILL_START_DATE').toString(),
        AlertType.error,
      );
      return false;
    }

    if (!this.reportEndDate) {
      this.alertService.showAlert(
        this.translate.instant('ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.FILL_END_DATE').toString(),
        AlertType.error,
      );
      return false;
    }

    const from = new Date(
      this.reportStartDate.value.getFullYear(),
      this.reportStartDate.value.getMonth() + 1,
      this.reportStartDate.value.getDate(),
    );
    const to = new Date(
      this.reportEndDate.value.getFullYear(),
      this.reportEndDate.value.getMonth() + 1,
      this.reportEndDate.value.getDate(),
    );

    if (from > to) {
      this.alertService.showAlert(
        this.translate
          .instant('ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.FILL_VALID_INTERVAL')
          .toString(),
        AlertType.error,
      );
      return false;
    }

    if (this.chosenTypes.length === 0) {
      this.alertService.showAlert(
        this.translate
          .instant('ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.CHOOSE_AT_LEAST_ONE_TYPE')
          .toString(),
        AlertType.error,
      );
      return false;
    }
    return true;
  }

  search(): void {
    this.filter = this.filter.trim();
    this.filterUpdated.emit(this.filter);
  }

  clear(): void {
    this.filter = '';
    this.search();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
