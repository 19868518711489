<mat-form-field color="primary" appearance="fill">
  <mat-label>{{ 'SHARED.FIND_BY_NAME' | translate }}</mat-label>
  <input
    style="opacity: 1"
    matInput
    type="text"
    [matAutocomplete]="auto2"
    #filter
  />
  <mat-autocomplete
    #auto2="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="_handleSelect($event)"
  >
    <mat-option *ngFor="let employee of allEmployees | filter: filter.value: 'fullName'" [value]="employee">
      {{ employee.fullName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
