<div class="dialog-container">
  <div class="title-holder align-center">
    <h1 mat-dialog-title>
      {{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.PROJECT_HISTORY.TITLE' | translate }}
    </h1>
    <button
      mat-mini-fab
      class="close-button"
      color="primary"
      (click)="closePopup()"
      data-cy="ph-close-button"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="table-holder">
    <mat-table [dataSource]="dataSource" matSort matSortActive="projectName" matSortStart="desc">
      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.PROJECT_HISTORY.FROM' | translate }}
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let project">
          {{ project.startDate | date: 'dd.MM.yyyy' }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.PROJECT_HISTORY.TO' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let project">
          {{ project.endDate | date: 'dd.MM.yyyy' }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="projectName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.PROJECT_HISTORY.PROJECT' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let project">
          {{ project.projectName }}
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let project; columns: displayedColumns"></mat-row>
    </mat-table>
  </mat-dialog-content>
</div>
