import { Component, OnDestroy, OnInit } from '@angular/core';
import { IRequest } from '../../../shared/models/request.model';
import { RequestService } from '../../../shared/services/request.service';
import { LoginService } from '../../../shared/services/login.service';
import { DialogComponent } from '../../../shared/dialogs/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ObservablesService } from '../../../shared/services/observables.service';
import { RequestStatusEnum } from '../../../shared/enums/request-status.enum';
import { catchError, filter, forkJoin, Observable, Subject, takeUntil, throwError } from 'rxjs';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { DIALOG_BUTTONS } from '../../../shared/constants';
import { IWorkOnHolidayRequest } from 'src/app/shared/models/work-on-holiday-request.model';
import { CompensationTypeEnum } from 'src/app/shared/enums/compensation-type.enum';

@Component({
  selector: 'app-requests-table',
  templateUrl: './requests-table.component.html',
  styleUrls: ['./requests-table.component.scss'],
})
export class RequestsTableComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;
  public myRequests: IRequest[] = [];
  public myWorkingOnHolidaysRequests: IWorkOnHolidayRequest[] = [];
  private destroy$ = new Subject<void>();
  readonly buttons = DIALOG_BUTTONS;

  constructor(
    private requestService: RequestService,
    private dialog: MatDialog,
    public loginService: LoginService,
    private alertService: AlertService,
    public observablesService: ObservablesService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.setupRequestsLoading();
  }

  private setupRequestsLoading(): void {
    this.getRequestsFromDbObservable();
    this.observablesService.requestFormSent
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getRequestsFromDbObservable());
  }

  private getRequestsFromDbObservable() {
    return forkJoin({
      myReqs: this.requestService.getPendingRequestsByRequesterId(this.loginService.getUserId()),
      myWorkingOnHolidaysReqs: this.requestService.getAllHolidayWorkingPendingRequests(
        this.loginService.getUserId(),
      ),
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.myRequests = data.myReqs;
        //@ts-ignore
        this.myWorkingOnHolidaysRequests = data.myWorkingOnHolidaysReqs.filter(
          //@ts-ignore
          (request) => new Date(request.date) > new Date(),
        );
        this.isLoading = false;
      });
  }

  public openDialog =
    (request: IRequest | IWorkOnHolidayRequest, isWorkOnHoliday?: boolean) => () => {
      if (request.status === RequestStatusEnum.DELETED) {
        return;
      }

      const _request = isWorkOnHoliday
        ? this.requestService.deleteHolidayWorkingPendingRequest
        : this.requestService.deleteRequest;

      this.dialog
        .open(DialogComponent, {
          data: {
            title: this.translate
              .instant('HOME.PENDING_REQUESTS_SECTION.DELETE_CONFIRMATION')
              .toString(),
            description: this.translate
              .instant('HOME.PENDING_REQUESTS_SECTION.DELETE_CONFIRMATION_DESCRIPTION')
              .toString(),
            sharedButtonClass: this.buttons.deleteButton,
            sharedButtonText: this.translate
              .instant('HOME.PENDING_REQUESTS_SECTION.DELETE')
              .toString(),
          },
        })
        .afterClosed()
        .pipe(
          takeUntil(this.destroy$),
          filter((result) => result),
        )
        .subscribe(() => {
          this.isLoading = true;
          this.deleteObservable(_request(request.id));
        });
    };

  private deleteObservable = (request: Observable<any>) => {
    request
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          this.alertService.showAlert(error.error, AlertType.error);
          this.isLoading = false;
          return throwError(error);
        }),
      )
      .subscribe(() => {
        this.alertService.showAlert(
          this.translate.instant('HOME.PENDING_REQUESTS_SECTION.REQUEST_DELETED').toString(),
          AlertType.success,
        );
        this.observablesService.requestFormSent.next(true);
        this.getRequestsFromDbObservable();
      });
  };

  public getRequestLabel(type: string) {
    return this.translate.instant('HOME.PENDING_REQUESTS_SECTION.HOLIDAY_WORKING_PENDING_REQUEST', {
      type: CompensationTypeEnum[type],
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
