import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  BarChartData,
  KnowBeUserData,
  LineChartData,
} from 'src/app/shared/models/knowbe-user-data.model';

@Component({
  selector: 'app-knowbe-subordinate-dialog',
  templateUrl: './knowbe-subordinate-dialog.component.html',
  styleUrls: ['./knowbe-subordinate-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KnowbeSubordinateDialogComponent {
  mounted = false;
  personalRiskScore: number;
  organizationRiskScore: number;

  phishingChartData: BarChartData[];
  userRiskScoreChartData: LineChartData[];
  organizationCurrentRiskScoreHistoryChartData: LineChartData[];
  phishingEmailsReported: number;
  phishingTotalCount: number;
  phishPronePercentage: number;

  completedTrainingsCount: number;
  trainingChartData: BarChartData[];
  trainingTotalCount: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: KnowBeUserData,
    private translate: TranslateService,
  ) {
    // Charts width calculated wrongly after first render
    // This is a workaround for delaying charts to render
    setTimeout(() => {
      this.mounted = true;
    }, 100);

    this.personalRiskScore = data.userCurrentRiskScore;
    this.organizationRiskScore = data.organizationCurrentRiskScore;

    // map phishing results data
    this.phishingEmailsReported = data.reportedAtCount;
    this.phishingTotalCount = data.phishingTotalCount;
    this.phishPronePercentage = data.userPhishPronePercentage;
    this.phishingChartData = [
      {
        color: '#9AD74C',
        count: data.reportedAtCount,
        percent: data.reportedAtCount / data.phishingTotalCount,
        text: this.translate.instant('USER.KNOW_BE.PHISHING_CHART.REPORTED_EMAILS').toString(),
      },
      {
        color: '#FAAF3B',
        count: data.clickedAtCount,
        percent: data.clickedAtCount / data.phishingTotalCount,
        text: this.translate.instant('USER.KNOW_BE.PHISHING_CHART.OPENED_EMAILS').toString(),
      },
      {
        color: '#FF764C',
        count: data.repliedAtCount,
        percent: data.repliedAtCount / data.phishingTotalCount,
        text: this.translate.instant('USER.KNOW_BE.PHISHING_CHART.REPLIED').toString(),
      },
      {
        color: '#EA3352',
        count: data.attachmentOpenedAtCount,
        percent: data.attachmentOpenedAtCount / data.phishingTotalCount,
        text: this.translate.instant('USER.KNOW_BE.PHISHING_CHART.ATTACHMENT_OPENED').toString(),
      },
      {
        color: '#A60841',
        count: data.qrCodeScannedAtCount,
        percent: data.qrCodeScannedAtCount / data.phishingTotalCount,
        text: this.translate.instant('USER.KNOW_BE.PHISHING_CHART.CODE_SCANNED').toString(),
      },
    ];

    this.userRiskScoreChartData = data.userCurrentRiskScoreHistory.map(({ date, risk_score }) => {
      return { date: new Date(date), risk_score };
    });
    this.organizationCurrentRiskScoreHistoryChartData =
      data.organizationCurrentRiskScoreHistory.map(({ date, risk_score }) => {
        return { date: new Date(date), risk_score };
      });

    // map training assignments data
    this.completedTrainingsCount = data.completedTrainingsCount;
    this.trainingTotalCount =
      data.completedTrainingsCount +
      data.inProgressTrainingsCount +
      data.notStartedTrainingsCount +
      data.passedTrainingsCount +
      data.pastDueTrainingsCount;
    this.trainingChartData = [
      {
        color: '#9AD74C',
        count: data.completedTrainingsCount,
        percent: data.completedTrainingsCount / this.trainingTotalCount,
        text: this.translate.instant('USER.KNOW_BE.TRAINING_CHART.COMPLETED').toString(),
      },
      {
        color: '#FAAF3B',
        count: data.notStartedTrainingsCount,
        percent: data.notStartedTrainingsCount / this.trainingTotalCount,
        text: this.translate.instant('USER.KNOW_BE.TRAINING_CHART.NOT_STARTED').toString(),
      },
      {
        color: '#FF764C',
        count: data.inProgressTrainingsCount,
        percent: data.inProgressTrainingsCount / this.trainingTotalCount,
        text: this.translate.instant('USER.KNOW_BE.TRAINING_CHART.IN_PROGRESS').toString(),
      },
      {
        color: '#9AD74C',
        count: data.passedTrainingsCount,
        percent: data.passedTrainingsCount / this.trainingTotalCount,
        text: this.translate.instant('USER.KNOW_BE.TRAINING_CHART.LONG_PASSED').toString(),
      },
      {
        color: '#A60841',
        count: data.pastDueTrainingsCount,
        percent: data.pastDueTrainingsCount / this.trainingTotalCount,
        text: this.translate.instant('USER.KNOW_BE.TRAINING_CHART.PAST_DUE').toString(),
      },
    ];
  }
}
