import { Component, EnvironmentInjector, Signal, signal, ViewChild } from '@angular/core';
import { OvertimeService } from '../../shared/services/overtime.service';
import { formatDate } from '@angular/common';
import { IOvertime } from '../../shared/models/overtime.model';
import { IPagination } from '../../shared/models/pagination.model';
import { ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { PageableHelperService } from '../../shared/services/helpers/pageable-helper.service';
import { IPageable } from '../../shared/models/pageable.model';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

export interface CompensationType {
  value: string;
  viewValue: string;
  shortViewValue: string;
}

@Component({
  selector: 'app-overtimes',
  templateUrl: './overtimes.component.html',
  styleUrls: ['./overtimes.component.scss'],
})
export class AdminOvertimesComponent {
  // Table:
  tableConfig: Signal<IPagination<IOvertime> | undefined> = signal(undefined);
  filter: string = '';
  fromDate: Date;
  toDate: Date;
  chosenTypes: string[];
  pageable: IPageable;
  sortByColumns: string[] = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private destroy$ = new Subject<void>();

  // Both:
  isLoading: boolean = true;

  allCompensationTypes: CompensationType[] = [
    {
      value: 'PAID_LEAVE',
      viewValue: 'to be compensated with additional paid leave',
      shortViewValue: 'Paid leave',
    },
    {
      value: 'PAYMENT',
      viewValue: 'to receive payment for the overtime hours',
      shortViewValue: 'Payment',
    },
  ];

  constructor(
    private overtimeService: OvertimeService,
    private activatedRoute: ActivatedRoute,
    private pageableHelper: PageableHelperService,
    public translate: TranslateService,
    private envInjector: EnvironmentInjector,
  ) {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.pageable = this.pageableHelper.getPageable(params);
      this.getOvertimesFromDb();
    });
  }

  getOvertimesFromDb(): void {
    const paramsHttpParams = this.pageableHelper.getHttpParams(this.pageable);
    this.tableConfig = toSignal(
      this.overtimeService
        .getAllOvertimesWithDatesInRange(
          this.fromDate || new Date('2000-01-01'),
          this.toDate || new Date('2050-01-01'),
          this.chosenTypes || [],
          this.filter,
          paramsHttpParams,
        )
        .pipe(
          tap((response) => {
            response.rowCss = this.isForCurrentMonth;
            this.sortByColumns = response.config.sortColumns;
            this.isLoading = false;
          }),
        ),
      {
        injector: this.envInjector,
      },
    );
  }

  getShortViewCompensationType(compensationType: string): string {
    let shortView: string = '';

    this.allCompensationTypes.forEach((e) => {
      if (compensationType === e.value) {
        shortView = e.viewValue;
      }
    });

    return shortView;
  }

  isForCurrentMonth(row: IOvertime): boolean {
    return (
      formatDate(row.occurredOn, 'yyyyMM', 'en_US') === formatDate(new Date(), 'yyyyMM', 'en_US')
    );
  }

  isSortingDisabled(column: string): boolean {
    return !this.sortByColumns.some((c) => c === column);
  }

  updateData(filter: string): void {
    this.filter = filter;
    this.pageable.page = 0;
    this.getOvertimesFromDb();
  }

  updateFromDate(fromDate: Date): void {
    this.fromDate = fromDate;
    this.getOvertimesFromDb();
  }

  updateToDate(toDate: Date): void {
    this.toDate = toDate;
    this.getOvertimesFromDb();
  }

  updateChosenTypes(chosenTypes: string[]): void {
    this.chosenTypes = chosenTypes;
    this.getOvertimesFromDb();
  }
}
