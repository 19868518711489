<!-- IF OBSERVABLE -->
<ng-container *ngIf="!isSignal">
  <ng-container *ngIf="data$ | withLoading | async as data">
    <ng-template [ngIf]="data.value">
      <ng-container
        [ngTemplateOutlet]="contentTemplateRef || defaultContentTemplate"
        [ngTemplateOutletContext]="{
          $implicit: data.value
        }"
      >
      </ng-container>
      <ng-template #defaultContentTemplate>
        <ng-content select="[app-with-loading-data-template]"></ng-content>
      </ng-template>
    </ng-template>
    <ng-template [ngIf]="data.error">
      <!-- TODO think about error component or something else-->
      <ng-container
        [ngTemplateOutlet]="errorTemplateRef || defaultErrorContentTemplate"
        [ngTemplateOutletContext]="{
          $implicit: data.error
        }"
      >
      </ng-container>
      <ng-template #defaultErrorContentTemplate>
        <ng-container *ngIf="showErrors">{{ data.error.message }}</ng-container>
      </ng-template>
    </ng-template>
    <ng-template [ngIf]="data.loading">
      <ng-container [ngTemplateOutlet]="loadingTemplateRef || defaultSpinnerContentTemplate">
      </ng-container>
      <ng-template #defaultSpinnerContentTemplate>
        <div class="spinner-container">
          <mat-spinner></mat-spinner>
        </div>
      </ng-template>
    </ng-template>
  </ng-container>
</ng-container>

<!-- IF SIGNAL -->
<ng-container *ngIf="isSignal">
  <ng-template [ngIf]="data()">
    <ng-container
      [ngTemplateOutlet]="contentTemplateRef || defaultContentTemplate"
      [ngTemplateOutletContext]="{
        $implicit: data()
      }"
    >
    </ng-container>
    <ng-template #defaultContentTemplate>
      <ng-content select="[app-with-loading-data-template]"></ng-content>
    </ng-template>
  </ng-template>
  <ng-template [ngIf]="hasError(data)">
    <!-- TODO think about error component or something else-->
    <ng-container
      [ngTemplateOutlet]="errorTemplateRef || defaultErrorContentTemplate"
      [ngTemplateOutletContext]="{
        $implicit: data()
      }"
    >
    </ng-container>
    <ng-template #defaultErrorContentTemplate>
      {{ data() }}
    </ng-template>
  </ng-template>
  <ng-template [ngIf]="data() === undefined">
    <ng-container [ngTemplateOutlet]="loadingTemplateRef || defaultSpinnerContentTemplate">
    </ng-container>
    <ng-template #defaultSpinnerContentTemplate>
      <div class="spinner-container">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
