<h2 class="section-title">
  {{ 'ADMIN.OVERTIMES.TITLE' | translate }}
</h2>

<div class="excel-form">
  <div class="overtimes-component">
    <mat-form-field (click)="picker1.open()" color="accent" appearance="fill">
      <mat-label>{{ 'ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.FROM_DATE' | translate }}</mat-label>
      <input matInput [matDatepicker]="picker1" (dateInput)="submitStartDate($event)" required />
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1 color="primary"></mat-datepicker>
    </mat-form-field>
    <mat-form-field (click)="picker2.open()" color="accent" appearance="fill">
      <mat-label>{{ 'ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.TO_DATE' | translate }}</mat-label>
      <input matInput [matDatepicker]="picker2" (dateInput)="submitEndDate($event)" required />
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2 color="primary"></mat-datepicker>
    </mat-form-field>
    <mat-form-field color="accent" appearance="fill">
      <mat-label>{{ 'ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.TYPE' | translate }}</mat-label>
      <mat-select [formControl]="types" (selectionChange)="onSubmitTypes($event)" multiple>
        <mat-option *ngFor="let type of typeList" [value]="type">{{ type }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button
      (click)="onSubmit()"
      mat-raised-button
      color="primary"
      class="excel-button"
      type="button"
    >
      <mat-icon>download</mat-icon>{{ 'ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.EXCEL' | translate }}
    </button>
    <!--    <mat-form-field color="accent" appearance="fill">-->
    <!--      <mat-label>{{ 'ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.FILTER_BY' | translate }}</mat-label>-->
    <!--      <input matInput [(ngModel)]="filter" (keyup.enter)="search()">-->
    <!--      <mat-icon matSuffix *ngIf="!!filter" (click)="clear()">close</mat-icon>-->
    <!--    </mat-form-field>-->
  </div>
</div>
