import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../shared/services/login.service';
import { EmployeeService } from '../../shared/services/employee.service';
import { EmployeeProject } from 'src/app/shared/models/project.model';
import { FormControl } from '@angular/forms';
import { TimeLogController } from '../../shared/services/time-log.controller';
import { MatRadioChange } from '@angular/material/radio';
import { IEmployee } from '../../shared/models';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-time-log',
  templateUrl: './time-log.component.html',
  styleUrls: ['./time-log.component.scss'],
})
export class TimeLogComponent implements OnInit {
  projects: Array<EmployeeProject>;
  isPageLoading = true;
  projectControl: FormControl = new FormControl();
  currentMonth: boolean = true;
  user: IEmployee;

  constructor(
    public translate: TranslateService,
    public loginService: LoginService,
    private employeeService: EmployeeService,
    private ctrl: TimeLogController,
  ) {
    this.user = this.loginService.getUser();
  }

  async ngOnInit() {
    this.projects = await this.employeeService.loadMyProjects(this.user.id);
    this.projects.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
    if (this.projects.length === 1) {
      this.projectControl.setValue(this.projects[0]);
      const { id } = this.projectControl.value;
      await this.ctrl.changeProject(id);
    }
    this.isPageLoading = false;
  }

  changeProject(event: MatAutocompleteSelectedEvent) {
    const { id } = event.option.value;
    this.ctrl.changeProject(id);
  }

  displayFn(project: EmployeeProject): string {
    return project ? `${project.name}` : null;
  }

  radioChange(event: MatRadioChange) {
    this.currentMonth = event.value == 0 ? true : false;
    this.ctrl.changeMonth(event.value);
  }
}
