<mat-spinner *ngIf="isLoading"></mat-spinner>

<div [hidden]="isLoading">
  <h2 class="section-title">
    {{ 'DREAMIX.EMPLOYEES.ALL_EMPLOYEES' | translate }}
  </h2>

  <div>
    <mat-form-field class="calendar-search-field" appearance="fill">
      <mat-icon matPrefix>search</mat-icon>
      <input
        id="search-term"
        type="text"
        placeholder="Search"
        (keyup)="applyFilter(filter)"
        [(ngModel)]="filter"
        autocomplete="off"
        matInput
      />
      <mat-icon class="close-icon" matSuffix *ngIf="!!filter" (click)="clear()">close</mat-icon>
    </mat-form-field>
  </div>

  <div class="table-responsive">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="name"
      matSortDisableClear
      matSortDirection="asc"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'DREAMIX.EMPLOYEES.EMPLOYEE_NAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let employee">
          <div class="table-data-holder">
            <a [routerLink]="['/dreamix/page/employees/', employee.id]">
              <img
                class="avatar"
                *ngIf="employee.hasAvatar"
                [src]="'/api/employees/' + this.employee.id + '/photo/1.jpg'"
                onerror="this.src='/img/defaultAvatar.png'"
              />
              <img class="avatar" *ngIf="!employee.hasAvatar" [src]="'/img/defaultAvatar.png'" />
            </a>
            <a [routerLink]="['/dreamix/page/employees/', employee.id]" class="employee-name">
              {{ employee.firstNameEn }} {{ employee.lastNameEn }}
            </a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="contacts">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'DREAMIX.EMPLOYEES.CONTACTS' | translate }}
        </th>
        <td mat-cell *matCellDef="let employee">
          <div class="table-data-holder">
            <button mat-icon-button [matMenuTriggerFor]="phoneMenu">
              <mat-icon>phone</mat-icon>
            </button>
            <mat-menu #phoneMenu="matMenu" xPosition="before">
              <a href="tel:{{ employee.phone }}" mat-menu-item>{{
                'DREAMIX.EMPLOYEES.CALL' | translate
              }}</a>
              <button
                mat-menu-item
                [cdkCopyToClipboard]="employee.phone"
                (click)="openSnackBar(translate.instant('DREAMIX.EMPLOYEES.COPIED').toString())"
              >
                {{ 'DREAMIX.EMPLOYEES.COPY' | translate }}
              </button>
            </mat-menu>
            <button mat-icon-button [matMenuTriggerFor]="mailMenu">
              <mat-icon>mail</mat-icon>
            </button>
            <mat-menu #mailMenu="matMenu" xPosition="before">
              <a href="mailto:{{ employee.email }}" mat-menu-item>{{
                'DREAMIX.EMPLOYEES.WRITE' | translate
              }}</a>
              <button
                mat-menu-item
                [cdkCopyToClipboard]="employee.email"
                (click)="openSnackBar(translate.instant('DREAMIX.EMPLOYEES.COPIED').toString())"
              >
                {{ 'DREAMIX.EMPLOYEES.COPY' | translate }}
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="projects">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'DREAMIX.EMPLOYEES.PROJECTS' | translate }}
        </th>
        <td mat-cell *matCellDef="let employee">
          <div *ngIf="hasActiveProjects(employee)" class="table-data-holder">
            <mat-chip-listbox>
              <mat-chip-option
                selectable="false"
                [routerLink]="['/dreamix/page/projects/', getLastActiveProject(employee)?.id]"
              >
                {{ getLastActiveProject(employee)?.name }}
              </mat-chip-option>
              <mat-chip-option
                *ngIf="getAllButLastActiveProjects(employee).length > 0"
                selectable="false"
                [matMenuTriggerFor]="projectsMenu"
              >
                +
                {{ getAllButLastActiveProjects(employee).length }}
                more
              </mat-chip-option>
              <mat-menu #projectsMenu="matMenu" xPosition="before">
                <a
                  *ngFor="let project of getAllButLastActiveProjects(employee)"
                  mat-menu-item
                  [routerLink]="['/dreamix/page/projects/', project.id]"
                >
                  {{ project.name }}
                </a>
              </mat-menu>
            </mat-chip-listbox>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'DREAMIX.EMPLOYEES.POSITION' | translate }}
        </th>
        <td mat-cell *matCellDef="let employee">
          <a [routerLink]="['/dreamix/page/employees/', employee.id]">{{
            employee.positionName
          }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="noData">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          {{ 'DREAMIX.EMPLOYEES.NO_RECORDS_FOUND' | translate }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="['noData']"
        [hidden]="dataSource && dataSource.data.length > 0"
      ></tr>
    </table>
  </div>

  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="[pageSize]"
    (page)="pageEvent = $event; updateQueryParameters()"
    showFirstLastButtons
  ></mat-paginator>
</div>
