<div class="gap">
  <app-employee-search (onselect)="update('id', $event)"></app-employee-search>
  <mat-form-field>
    <mat-label>{{ 'ADMIN.OVERTIMES.TYPE' | translate }}</mat-label>
    <mat-select [value]="filters.leaveType" (selectionChange)="update('leaveType', $event.value)">
      <!-- For some reason translates not showing until uset interact with mat-select -->
      <mat-option value="WORK_ON_HOLIDAYS">{{
        'HOME.CALENDAR.WORKING_ON_HOLIDAYS' | translate
      }}</mat-option>
      <mat-option value="REGULAR">{{ 'HOME.CALENDAR.REGULAR' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'ADMIN.PROJECTS.SIZE' | translate }}</mat-label>
    <mat-select [value]="filters.count" (selectionChange)="update('count', $event.value)">
      <mat-option value="5">5</mat-option>
      <mat-option value="10">10</mat-option>
      <mat-option value="15">15</mat-option>
      <mat-option value="20">20</mat-option>
    </mat-select>
  </mat-form-field>
</div>
