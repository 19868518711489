import { Component, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';
import { LineChartData } from 'src/app/shared/models/knowbe-user-data.model';

@Component({
  selector: 'app-knowbe-line-chart',
  templateUrl: './knowbe-line-chart.component.html',
  styleUrls: ['./knowbe-line-chart.component.scss'],
})
export class KnowbeLineChartComponent implements AfterViewInit {
  @Input() userData: LineChartData[];
  @Input() organizationData: LineChartData[];

  @ViewChild('chart') private chartContainer: ElementRef<HTMLDivElement>;

  constructor(private translate: TranslateService) {}

  ngAfterViewInit(): void {
    this.draw();
  }

  private draw(): void {
    const margin = { top: 10, right: 150, bottom: 40, left: 70 };
    const element: HTMLDivElement = this.chartContainer.nativeElement;
    const width = element.getBoundingClientRect().width;
    const height = element.getBoundingClientRect().height;

    const x = d3.scaleUtc(
      d3.extent(this.organizationData.concat(this.userData), (d) => d.date),
      [margin.left, width - margin.right],
    );

    const y = d3.scaleLinear([0, 100], [height - margin.bottom, margin.top]);

    const line = d3
      .line<{ date: Date; risk_score: number }>()
      .x((d) => x(d.date))
      .y((d) => y(d.risk_score));

    const svg = d3
      .select(element)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .attr('style', 'max-width: 100%; height: auto; height: intrinsic;');

    svg
      .append('g')
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(
        d3
          .axisBottom(x)
          .tickPadding(10)
          .ticks(width / 80)
          .tickFormat((date: Date) => {
            const formatMonthYear = d3.timeFormat('%Y/%b');
            const formatMonthDay = d3.timeFormat('%d %b');
            const tickCount = d3.timeMonth.count(x.domain()[0], x.domain()[1]);

            return tickCount > 1 ? formatMonthYear(date) : formatMonthDay(date);
          })
          .tickSizeOuter(0),
      )
      .selectAll('text')
      .attr('transform', `rotate(-30)`)
      .attr('x', -20)
      .attr('y', 10)
      .style('font-size', '0.625rem');

    svg
      .append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(y).tickValues([0, 20, 40, 60, 80, 100]))
      .call((g) => g.selectAll('text').style('font-size', '0.875rem'))
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('x2', width - margin.left - margin.right)
          .attr('stroke-opacity', 0.1),
      )
      .call((g) =>
        g
          .append('text')
          .attr('x', -margin.left)
          .attr('dy', -50)
          .attr('fill', 'currentColor')
          .attr('transform', `rotate(-90)`)
          .style('font-size', '1.25rem')
          .style('font-weight', 'bold')
          .text(this.translate.instant('USER.KNOW_BE.RISK_SCORE')),
      );

    svg
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', '#9AD74C')
      .attr('stroke-width', 2)
      .attr('d', line(this.userData));

    svg
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', '#FF764C')
      .attr('stroke-width', 2)
      .attr('d', line(this.organizationData));

    const legend = svg
      .append('g')
      .attr('transform', `translate(${width - margin.right + 20},${margin.top})`);

    const legendData = [
      { label: 'User Data', color: '#9AD74C' },
      { label: 'Organization Data', color: '#FF764C' },
    ];

    legend
      .selectAll('rect')
      .data(legendData)
      .enter()
      .append('rect')
      .attr('x', 0)
      .attr('y', (_, i) => i * 30 + 6)
      .attr('width', 36)
      .attr('height', 4)
      .attr('fill', (d) => d.color);

    legend
      .selectAll('text')
      .data(legendData)
      .enter()
      .append('text')
      .attr('x', 44)
      .attr('y', (_, i) => i * 30 + 9)
      .text((d) => d.label)
      .attr('fill', 'currentColor')
      .style('font-size', '0.875rem')
      .attr('alignment-baseline', 'middle');
  }
}
