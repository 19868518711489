export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDffhIhHJYURynad1sj1h4vdPiki0PGgNk',
    authDomain: 'dreamix-management-system.firebaseapp.com',
    databaseURL: 'https://dreamix-management-system.firebaseio.com',
    projectId: 'dreamix-management-system',
    storageBucket: 'dreamix-management-system.appspot.com',
    messagingSenderId: '1026810753126',
    appId: '1:1026810753126:web:54de00ba6b09a126807da7',
    measurementId: 'G-9LW3PNDB2T',
  },
  google: {
    calendarApiKey: 'AIzaSyDffhIhHJYURynad1sj1h4vdPiki0PGgNk',
    clientId: '1026810753126-r6rp7mn8c0n50bg8j6bbvreir00ikt9t.apps.googleusercontent.com',
  },
};
