import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullDefault',
  standalone: true,
})
export class NullDefaultPipe implements PipeTransform {
  transform(value: any): any {
    return value === null || value === undefined ? 'N/A' : value;
  }
}
