import {Component, Input, OnChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {VacationTypeEnum} from 'src/app/shared/enums';

@Component({
  selector: 'app-common-progress',
  templateUrl: './common-progress.component.html',
  styleUrls: ['./common-progress.component.scss'],
})
export class CommonProgressComponent implements OnChanges {
  @Input() type: keyof VacationTypeEnum;
  @Input() daysUsed: number;
  @Input() daysTotal: number;
  @Input() setHeight: boolean;

  public vacations = VacationTypeEnum;
  public progress: number = null;
  public daysLeft: number;

  constructor(public translate: TranslateService) {
  }

  ngOnChanges() {
    this.calculateProgress();
  }

  public calculateProgress() {
    const percentageUsed = (this.daysUsed / this.daysTotal) * 100;
    this.progress = Math.round(100 - percentageUsed);
    this.daysLeft = this.daysTotal - this.daysUsed;
  };
}
