<mat-spinner *ngIf="isLoading"></mat-spinner>

<div [hidden]="isLoading">
  <h2 class="section-title">
    {{ 'ADMIN.EMPLOYEES.ALL_EMPLOYEES' | translate }}
  </h2>

  <div id="header">
    <div>
      <mat-form-field class="calendar-search-field" appearance="fill">
        <mat-icon matPrefix>search</mat-icon>
        <input
          id="search-term"
          type="text"
          placeholder="Search"
          (keyup)="applyFilter(filter)"
          [(ngModel)]="filter"
          autocomplete="off"
          matInput
        />
        <mat-icon class="close-icon" matSuffix *ngIf="!!filter" (click)="clear()">close</mat-icon>
      </mat-form-field>
    </div>

    <button (click)="exportAll()" mat-raised-button color="primary" type="button">
      {{ 'ADMIN.EMPLOYEES.EXPORT' | translate }}
    </button>
  </div>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'ADMIN.EMPLOYEES.FIRST_NAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let employee">
          {{ employee.firstNameEn }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'ADMIN.EMPLOYEES.LAST_NAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let employee">
          {{ employee.lastNameEn }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'ADMIN.EMPLOYEES.EMAIL' | translate }}
        </th>
        <td mat-cell *matCellDef="let employee">
          {{ employee.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="positionName">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'ADMIN.EMPLOYEES.POSITION_NAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let employee">
          {{ employee.positionName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'ADMIN.EMPLOYEES.ROLE' | translate }}
        </th>
        <td mat-cell *matCellDef="let employee">
          {{ employee.role }}
        </td>
      </ng-container>

      <ng-container matColumnDef="daysLeft">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'ADMIN.EMPLOYEES.DAYS_LEFT' | translate }}
        </th>
        <td mat-cell *matCellDef="let employee">
          {{ employee.daysLeft }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let employee">
          <mat-icon
            matTooltip="{{ 'ADMIN.EMPLOYEES.MODIFY_EMPLOYEE' | translate }}"
            [routerLink]="['/admin/employees/update', employee.id]"
            class="icon user-edit"
            >edit
          </mat-icon>
          <mat-icon
            matTooltip="{{ 'ADMIN.EMPLOYEES.DELETE_EMPLOYEE' | translate }}"
            (click)="openDialog(employee.id)"
            class="icon delete"
            >cancel
          </mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="noData">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          {{ 'ADMIN.EMPLOYEES.NO_RECORDS_FOUND' | translate }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="['noData']"
        [hidden]="dataSource && dataSource.data.length > 0"
      ></tr>
    </table>
  </div>

  <mat-paginator [pageSizeOptions]="[10]" showFirstLastButtons></mat-paginator>
</div>
