import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services';

// least privilege to most privilege respectively
const roles = ['DEVOPS', 'USER', 'LEAD', 'ADMIN', 'OPERATIONS_ADMIN'] as const;
type UserRole = (typeof roles)[number];

export const roleGuard: (role: UserRole) => CanActivateFn = (role) => () => {
  const loginService = inject(LoginService);
  const router = inject(Router);

  const roleIndex = roles.findIndex((x) => x === role);
  const userRoleIndex = roles.findIndex((x) => x === loginService.getUserRole());

  if (userRoleIndex < roleIndex) {
    router.navigate(['user/time-log']);
    return false;
  }

  return true;
};
