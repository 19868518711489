import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EmployeeSearchComponent } from 'src/app/shared/components/employee-search/employee-search.component';
import { MaterialModule } from 'src/app/shared/material.module';

@Component({
  selector: 'app-employee-leaves-filter',
  standalone: true,
  imports: [CommonModule, MaterialModule, EmployeeSearchComponent, TranslateModule],
  templateUrl: './employee-leaves-filter.component.html',
  styleUrls: ['./employee-leaves-filter.component.scss'],
})
export class EmployeeLeavesFilterComponent {
  @Input() filters = { id: null, leaveType: 'REGULAR', count: '5' };
  @Output() onchange = new EventEmitter();

  update(key: string, value: string) {
    this.onchange.emit([key, value]);
  }
}
