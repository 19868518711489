import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true,
    },
  ],
})
export class EditorComponent implements ControlValueAccessor {
  @Input() _value;
  @Output() setup: EventEmitter<any> = new EventEmitter();

  editorIsActive = false;

  public editorConfig: Record<string, any> | undefined = {
    paste_as_text: true,
    height: 500,
    plugins:
      'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table code help wordcount',
    toolbar:
      'undo redo | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
    menu: {
      file: { title: 'File', items: '' },
      edit: {
        title: 'Edit',
        items: 'undo redo | cut copy paste | selectall | searchreplace',
      },
      view: {
        title: 'View',
        items: 'code | visualchars visualblocks | spellchecker | preview fullscreen',
      },
      insert: {
        title: 'Insert',
        items:
          'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime',
      },
      format: {
        title: 'Format',
        items:
          'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat',
      },
      tools: {
        title: 'Tools',
        items: 'spellchecker spellcheckerlanguage | code wordcount',
      },
      table: {
        title: 'Table',
        items: 'inserttable | cell row column | tableprops deletetable',
      },
      help: { title: 'Help', items: '' },
    },
    file_picker_callback(cb: any): void {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.onchange = () => {
        const file = input.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
          cb(e.target.result, { title: file.name });
        };
        reader.readAsDataURL(file);
      };
      input.click();
    },

    setup: (editor) => {
      editor.on('SkinLoaded', () => {
        setTimeout(() => {
          this.setup.emit();
        }, 0);
      });
    },
  };

  onChange: any = () => {};
  onTouched: any = () => {};

  get value(): any {
    return this._value;
  }

  set value(val: any) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  constructor(public translate: TranslateService) {}

  updateChanges(): void {
    this.onChange(this.value);
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
      this.updateChanges();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  openEditor() {
    this.editorIsActive = true;
  }
}
