<mat-tab-group #tabs animationDuration="0ms" class="forms-group" [selectedIndex]="isUserRoleLead ? 1 : 0">
  <mat-tab label="{{ 'HOME.PENDING_REQUESTS_SECTION.MY_PENDING_REQUESTS' | translate }}">
    <div class="requests">
      <app-requests-table/>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'HOME.PENDING_REQUESTS_SECTION.OTHERS_PENDING_REQUESTS' | translate }}">
    <div class="requests">
      <app-other-requests-table/>
    </div>
  </mat-tab>
</mat-tab-group>
