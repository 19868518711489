<mat-spinner *ngIf="isLoading"></mat-spinner>

<div [hidden]="isLoading">
  <h2 class="section-title">
    {{ 'USER.REVIEWED_REQUESTS.REVIEWED_REQUESTS' | translate }}
  </h2>

  <mat-form-field id="compensation-type-form-field" appearance="fill">
    <mat-label>{{ 'USER.REVIEWED_REQUESTS.FOR_YEAR' | translate }}</mat-label>
    <mat-select [formControl]="yearFormControl" (selectionChange)="updateRequests($event)">
      <mat-option *ngFor="let year of allYears" [value]="year">
        {{ year }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <app-table-wrapper
    [tableConfig]="tableConfig"
    (getData)="getRequestsFromDb(currentYear)"
    [(pageable)]="pageable"
    matSort
    [sort]="sort"
  >
    <ng-container matColumnDef="createdOn">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('createdOn')"
      >
        {{ 'USER.REVIEWED_REQUESTS.CREATED' | translate }}
      </th>
      <td mat-cell *matCellDef="let request">
        <div>
          <a [routerLink]="['/requests/', request.id]">
            {{ request.createdOn }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="requesterEmail">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('requesterEmail')"
      >
        {{ 'USER.REVIEWED_REQUESTS.REQUESTER' | translate }}
      </th>
      <td mat-cell *matCellDef="let request">
        <div>
          <a [routerLink]="['/requests/', request.id]">
            {{ request.requester.email }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="fromDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('fromDate')"
      >
        {{ 'USER.REVIEWED_REQUESTS.START_DATE' | translate }}
      </th>
      <td mat-cell *matCellDef="let request">
        <div>
          <a [routerLink]="['/requests/', request.id]">
            {{ request.fromDate }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="toDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('toDate')"
      >
        {{ 'USER.REVIEWED_REQUESTS.END_DATE' | translate }}
      </th>
      <td mat-cell *matCellDef="let request">
        <div>
          <a [routerLink]="['/requests/', request.id]">
            {{ request.toDate }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="days">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isSortingDisabled('days')">
        {{ 'USER.REVIEWED_REQUESTS.DAYS' | translate }}
      </th>
      <td mat-cell *matCellDef="let request">
        <div>
          <a [routerLink]="['/requests/', request.id]">
            {{ request.days }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="vacationType">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('vacationType')"
      >
        {{ 'USER.REVIEWED_REQUESTS.REASON' | translate }}
      </th>
      <td mat-cell *matCellDef="let request">
        <div
          *ngIf="
            request.vacationType === 'Sick leave' || request.vacationType === 'Other paid leave'
          "
        >
          <a [routerLink]="['/requests/', request.id]">{{ request.subType }}</a>
        </div>
        <div
          *ngIf="
            request.vacationType !== 'Sick leave' && request.vacationType !== 'Other paid leave'
          "
        >
          <a [routerLink]="['/requests/', request.id]">{{ request.vacationType }}</a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="resolvedOn">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('resolvedOn')"
      >
        {{ 'USER.REVIEWED_REQUESTS.RESOLVED_ON' | translate }}
      </th>
      <td mat-cell *matCellDef="let request">
        <div>
          <a [routerLink]="['/requests/', request.id]">
            {{ request.resolvedOn }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('status')"
      >
        {{ 'USER.REVIEWED_REQUESTS.STATUS' | translate }}
      </th>
      <td mat-cell *matCellDef="let request">
        <div>
          <a [routerLink]="['/requests/', request.id]">
            {{ request.status }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="action" class="download-button">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let request">
        <div *ngIf="applicableForModification(request)">
          <mat-icon
            matTooltip="{{ 'USER.REVIEWED_REQUESTS.APPROVE' | translate }}"
            aria-label="Approve"
            [class.invisible]="request.status === 'APPROVED'"
            (click)="openDialogApprove(request)"
            class="icon save"
            >check</mat-icon
          >
          <mat-icon
            matTooltip="{{ 'USER.REVIEWED_REQUESTS.DECLINE' | translate }}"
            aria-label="Decline"
            [class.invisible]="request.status === 'DECLINED'"
            (click)="openDialogDecline(request)"
            class="icon delete"
            >close</mat-icon
          >
        </div>
      </td>
    </ng-container>
  </app-table-wrapper>
</div>
