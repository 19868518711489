<mat-card class="container">
  <mat-card-header>
    <mat-card-title>{{ 'USER.KNOW_BE.RISK_SCORE_HISTORY' | translate }}</mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>

  <mat-card-content>
    <div #chart class="chart-container"></div>
  </mat-card-content>
</mat-card>
