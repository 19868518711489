import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IHoliday } from '../../shared/models/holiday.model';
import { HolidaysService } from '../../shared/services/holidays.service';
import { DialogComponent } from '../../shared/dialogs/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService, AlertType } from '../../shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Subject, mergeMap, takeUntil, tap } from 'rxjs';
import { SharedDataService } from '../../shared/services/shared-data.service';
import { DIALOG_BUTTONS } from '../../shared/constants';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.scss'],
})
export class HolidaysComponent implements OnInit, OnDestroy {
  public date: Date;
  public dateString: string;
  public description: string;
  public allHolidays: Array<IHoliday>;
  private currentHoliday: IHoliday;
  public editMode: Array<boolean>;
  public currentFile: File;
  private destroy$ = new Subject<void>();

  public displayedColumns: string[] = ['date', 'description', 'action'];
  public dataSource: MatTableDataSource<IHoliday>;
  @ViewChild('csvFile') csvFileUpload: ElementRef;

  public isLoading: boolean = true;
  readonly buttons = DIALOG_BUTTONS;

  constructor(
    private holidayService: HolidaysService,
    public dialog: MatDialog,
    private alertService: AlertService,
    public translate: TranslateService,
    private sharedDataService: SharedDataService,
  ) {
    this.isAlreadyAdded = this.isAlreadyAdded.bind(this);
  }

  ngOnInit(): void {
    this.loadHolidays();
  }

  private loadHolidays(): void {
    this.sharedDataService.holidaysState$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.allHolidays = response;

      this.allHolidays.sort((a, b) => {
        if (a.date !== b.date) {
          return a.date < b.date ? 1 : -1;
        }
        return 0;
      });

      this.editMode = new Array<boolean>(this.allHolidays.length);

      for (let i = 0; i < this.editMode.length; i++) {
        this.editMode[i] = false;
      }

      this.dataSource = new MatTableDataSource<IHoliday>(this.allHolidays);
      this.isLoading = false;
    });
  }

  public editModeOn(rowIndex: string): void {
    for (let i = 0; i < this.editMode.length; i++) {
      if (this.editMode[i] === true) {
        this.editModeOff(i);
      }
    }
    /*not sure if this is the optimal way to pass an object by value, i'm taking suggestions*/
    this.currentHoliday = JSON.parse(JSON.stringify(this.allHolidays[rowIndex]));
    this.editMode[rowIndex] = true;
  }

  public editModeOff(rowIndex: number): void {
    this.allHolidays[rowIndex] = this.currentHoliday;
    this.editMode[rowIndex] = false;
  }

  public update(rowIndex: string): void {
    if (
      this.isValidVacationDay(
        this.allHolidays[rowIndex].date,
        this.allHolidays[rowIndex].description,
      )
    ) {
      this.holidayService
        .updateHoliday(this.allHolidays[rowIndex].id, {
          ...this.allHolidays[rowIndex],
          date: formatDate(this.allHolidays[rowIndex].date, 'yyyy-MM-dd', 'en-US'),
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.editMode[rowIndex] = false;
          this.alertService.showAlert(
            this.translate.instant('ADMIN.HOLIDAYS.SUCCESSFULLY_UPDATED').toString(),
            AlertType.success,
          );
          this.sharedDataService.triggerFetchHolidays();
        });
    }
  }

  public saveVacationDay(): void {
    if (this.isValidVacationDay(this.date, this.description)) {
      this.saveVacationDayToDb();
    }
  }

  private isValidVacationDay(date: Date, description: string): boolean {
    if (!date || !description) {
      this.alertService.showAlert(
        this.translate.instant('ADMIN.HOLIDAYS.DATE_DESCRIPTION_REQUIRED').toString(),
        AlertType.error,
      );
      return false;
    }
    return true;
  }

  private saveVacationDayToDb(): void {
    const currentHoliday = {
      description: this.description,
      date: this.dateString,
    };
    this.holidayService
      .createHoliday(currentHoliday)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.alertService.showAlert(
          this.translate.instant('ADMIN.HOLIDAYS.SUCCESSFULLY_ADDED').toString(),
          AlertType.success,
        );
        this.sharedDataService.triggerFetchHolidays();
        this.date = null;
        this.description = null;
        this.dateString = null;
      });
  }

  public openDialog(index: number): void {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('ADMIN.HOLIDAYS.DELETE_CONFIRMATION').toString(),
          description: this.translate
            .instant('ADMIN.HOLIDAYS.DELETE_CONFIRMATION_DESCRIPTION')
            .toString(),
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('ADMIN.HOLIDAYS.DELETE').toString(),
        },
      })
      .afterClosed()
      .pipe(
        mergeMap((result) =>
          result
            ? this.holidayService.deleteHolidayById(this.allHolidays[index].id).pipe(
                tap(() => {
                  this.alertService.showAlert(
                    this.translate.instant('ADMIN.HOLIDAYS.SUCCESSFULLY_REMOVED').toString(),
                    AlertType.success,
                  );
                  this.sharedDataService.triggerFetchHolidays();
                }),
              )
            : EMPTY,
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public isAlreadyAdded(date: Date | null): boolean {
    if (!date) {
      return false;
    }

    const isAdded: boolean = this.allHolidays.some(
      (h) => new Date(h.date).toLocaleDateString() === date.toLocaleDateString(),
    );
    return !isAdded;
  }

  onStartDateSelection(): void {
    this.dateString = formatDate(this.date, 'yyyy-MM-dd', 'en-US');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
