import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatBytes',
})
export class FormatBytesPipe implements PipeTransform {
  transform(value: number) {
    if (value === 0) return '0 B';

    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(value) / Math.log(k));

    const output = value / Math.pow(k, i);

    return `${Math.floor(output)}${sizes[i]}`;
  }
}
