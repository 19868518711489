import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LicenseService } from '../../shared/services/license.service';
import { TranslateService } from '@ngx-translate/core';
import { ILicense } from '../../shared/models/license.model';
import { catchError, Observable, Subject, Subscription, takeUntil, tap, throwError } from 'rxjs';
import { IPagination } from '../../shared/models/pagination.model';
import { IPageable } from '../../shared/models/pageable.model';
import { MatSort } from '@angular/material/sort';
import { PageableHelperService } from '../../shared/services/helpers/pageable-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DialogComponent } from '../../shared/dialogs/dialog/dialog.component';
import { AlertService, AlertType } from '../../shared/services/alert.service';
import { map, switchMap } from 'rxjs/operators';
import { DIALOG_BUTTONS } from '../../shared/constants';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-admin-license',
  templateUrl: './admin-license.component.html',
  styleUrls: ['./admin-license.component.scss'],
})
export class AdminLicenseComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  tableConfig: IPagination<ILicense>;
  pageable: IPageable;
  isLoading: boolean = true;
  sortByColumns: string[];

  licenseSubscriptions: Subscription = null;
  readonly buttons = DIALOG_BUTTONS;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private licenseService: LicenseService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private httpHelper: PageableHelperService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.destroy$),
        switchMap((params) => {
          this.pageable = this.httpHelper.getPageable(params);
          const queryParams = this.httpHelper.getHttpParams(this.pageable);
          return this.licenseService.getLicenses(queryParams);
        }),
      )
      .subscribe((response) => {
        this.tableConfig = response;
        this.sortByColumns = response.config.sortColumns;
        this.isLoading = false;
      });
  }

  isSortingDisabled(column: string): boolean {
    return !this.sortByColumns.some((c) => c === column);
  }

  openDialog(license: ILicense): void {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('ADMIN.LICENSES.DELETE_CONFIRMATION').toString(),
          description: this.translate
            .instant('ADMIN.LICENSES.DELETE_CONFIRMATION_DESCRIPTION')
            .toString(),
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('ADMIN.LICENSES.DELETE_BUTTON').toString(),
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.onDeleteLicense(license);
        }
      });
  }

  onDeleteLicense(license: ILicense): void {
    this.deleteLicense(license)
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.loadLicenses()),
      )
      .subscribe();
  }

  downloadLicense(id: number): void {
    const params = new HttpParams().set('licenseId', id);
    this.licenseService.downloadLicenseByParams(params);
  }

  private deleteLicense(license: ILicense): Observable<void> {
    return this.licenseService.deleteLicenseById(license.id).pipe(
      takeUntil(this.destroy$),
      tap(() => {
        this.alertService.showAlert(
          this.translate.instant('ADMIN.LICENSES.SUCCESSFULLY_REMOVED').toString(),
          AlertType.success,
        );
      }),
      catchError((error) => {
        this.alertService.showAlert(error.error, AlertType.error);
        return throwError(error);
      }),
      map(() => {}),
    );
  }

  loadLicenses(): Observable<IPagination<ILicense>> {
    const params = this.httpHelper.getHttpParams(this.pageable);
    return this.licenseService.getLicenses(params).pipe(
      takeUntil(this.destroy$),
      tap((response) => {
        this.tableConfig = response;
        this.sortByColumns = response.config.sortColumns;
        this.isLoading = false;
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
