import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subject, forkJoin, takeUntil } from 'rxjs';
import { EmployeeService, ProjectService } from 'src/app/shared/services';
import { EmployeePartial, ProjectSummary } from 'src/app/shared/models';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-employee',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
})
export class EmployeesComponent implements OnInit, OnDestroy {
  allEmployees: Array<EmployeePartial>;
  allProjects: Array<ProjectSummary>;
  private destroy$ = new Subject<void>();

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageEvent: PageEvent;
  displayedColumns: string[] = ['name', 'contacts', 'projects', 'position'];
  dataSource: MatTableDataSource<EmployeePartial>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isLoading: boolean = true;
  filter: string;

  constructor(
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private location: Location,
    private employeeService: EmployeeService,
    private projectService: ProjectService,
  ) {}

  ngOnInit() {
    this.route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
      const pageIndex = Number(paramMap.get('page')) - 1;
      const pageSize = Number(paramMap.get('size'));
      if (pageSize) {
        this.pageSize = pageSize;
        this.paginator.pageSize = pageSize;
      }
      if (pageIndex) {
        this.pageIndex = pageIndex;
        this.paginator.pageIndex = pageIndex;
      }
      this.paginator.pageSizeOptions = [pageSize];
    });
    forkJoin([
      this.projectService.getProjectsSummaries(new HttpParams()),
      this.employeeService.getPartialEmployees(),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([projects, employees]) => {
        this.allProjects = projects.items;
        this.allEmployees = employees;
        this.allEmployees.sort((a, b) =>
          `${a.firstNameEn} ${a.lastNameEn}`.localeCompare(`${b.firstNameEn} ${b.lastNameEn}`),
        );

        this.dataSource = new MatTableDataSource<EmployeePartial>(this.allEmployees);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (row: EmployeePartial, columnName: string) => {
          if (columnName === 'name') return `${row.firstNameEn} ${row.lastNameEn}`;
          return row[columnName];
        };

        this.isLoading = employees.length <= 0;
      });
  }

  updateQueryParameters(): void {
    this.location.go(
      this.location.path().split('?')[0],
      `?page=${this.paginator.pageIndex + 1}&size=${this.paginator.pageSize}`,
    );
  }

  applyFilter(filter: string): void {
    this.dataSource.filter = filter.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clear(): void {
    this.filter = '';
    this.dataSource.filter = '';
  }

  getAllActiveProjects(employee: EmployeePartial): ProjectSummary[] {
    if (Array.isArray(employee.projectIds)) {
      return this.allProjects.filter((x) => employee.projectIds.includes(x.id));
    }
    return [];
  }

  hasActiveProjects(employee: EmployeePartial): boolean {
    const projects = this.allProjects.filter((x) => employee.projectIds.includes(x.id));
    return projects.length ? true : false;
  }

  getLastActiveProject(employee: EmployeePartial): ProjectSummary {
    const activeProjects = this.getAllActiveProjects(employee);
    return activeProjects[activeProjects.length - 1];
  }

  getAllButLastActiveProjects(employee: EmployeePartial): ProjectSummary[] {
    const activeProjects = this.getAllActiveProjects(employee);
    return activeProjects.slice(0, activeProjects.length - 1);
  }

  openSnackBar(message: string): void {
    this._snackBar.open(message, null, {
      duration: 2000,
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
