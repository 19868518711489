import { NgModule } from '@angular/core';
import { HolidaysComponent } from './holidays/holidays.component';
import { RequestsFilterComponent } from './requests/requests-filter/requests-filter.component';
import { AdminEmployeesComponent } from './employees/employees.component';
import { ProjectsComponent } from './projects/projects.component';
import { RequestsComponent } from './requests/requests.component';
import { DreamixBirthdaysComponent } from './dreamix-birthdays/dreamix-birthdays.component';
import { MaterialModule } from '../shared/material.module';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { routing } from '../app.routing';
import { EditProjectComponent } from './projects/edit-project/edit-project.component';
import { AdminOvertimesComponent } from './overtimes/overtimes.component';
import { AdminPagesComponent } from './pages/pages.component';
import { EditPageComponent } from './pages/edit-page/edit-page.component';
import { AdminEditEmployeeComponent } from './employees/edit-employee/edit-employee.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AdminReferralsComponent } from './referrals/referrals.component';
import { OvertimesExportExcelComponent } from './overtimes/overtimes-export-excel/overtimes-export-excel.component';
import { ClientHoursComponent } from './client-hours/client-hours.component';
import { SubmitReportComponent } from './submit-report/submit-report.component';
import { ReimbursementsExportExcelComponent } from './overtimes/reimbursements-export-excel/reimbursements-export-excel.component';
import { AllTravelRequestsComponent } from './all-travel-requests/all-travel-requests.component';
import { EditEmployeeProjectsComponent } from './employees/edit-employee/projects/edit-employee-projects.component';
import { EditEmployeeDataComponent } from './employees/edit-employee/data/edit-employee-data.component';
import { UtilizationRateComponent } from '../utilization-rate/utilization-rate.component';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { NuggetEventsComponent } from './nugget-events/nugget-events.component';
import { NuggetEventsCreateEditComponent } from './nugget-events/nugget-events-create-edit/nugget-events-create-edit.component';
import { NuggetEventsTableComponent } from './nugget-events/nugget-events-table/nugget-events-table.component';

@NgModule({
  declarations: [
    DreamixBirthdaysComponent,
    HolidaysComponent,
    ProjectsComponent,
    AdminEmployeesComponent,
    RequestsComponent,
    RequestsFilterComponent,
    EditProjectComponent,
    AdminOvertimesComponent,
    OvertimesExportExcelComponent,
    AdminPagesComponent,
    EditPageComponent,
    AdminEditEmployeeComponent,
    AdminReferralsComponent,
    ClientHoursComponent,
    SubmitReportComponent,
    ReimbursementsExportExcelComponent,
    AllTravelRequestsComponent,
    UtilizationRateComponent,
    EditEmployeeProjectsComponent,
    EditEmployeeDataComponent,
    NuggetEventsComponent,
    NuggetEventsCreateEditComponent,
    NuggetEventsTableComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule.forRoot(),
    routing,
    TranslateModule,
  ],
  providers: [DatePipe, { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }],
})
export class AdminModule {}
