<h2 class="section-title events-title">
  <span>{{ 'ADMIN.EVENTS.EVENTS' | translate }}</span>
  <a
    type="button"
    mat-raised-button
    [routerLink]="['/admin/events/create']"
    color="primary"
    class="uppercase"
  >
    {{ 'ADMIN.EVENTS.CREATE' | translate }}
  </a>
</h2>

<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" class="events-tabs">
  <mat-tab [label]="'Active Events'">
    <ng-template matTabContent>
      <div class="events-tab">
        <app-nugget-events-table nuggetEventType="active"></app-nugget-events-table>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab [label]="'Upcoming Events'">
    <ng-template matTabContent>
      <div class="events-tab">
        <app-nugget-events-table nuggetEventType="upcoming"></app-nugget-events-table>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab [label]="'Past Events'">
    <ng-template matTabContent>
      <div class="events-tab">
        <app-nugget-events-table nuggetEventType="past"></app-nugget-events-table>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab [label]="'Deleted Events'">
    <ng-template matTabContent>
      <div class="events-tab">
        <app-nugget-events-table nuggetEventType="deleted"></app-nugget-events-table>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
