import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NuggetEvent, NuggetEventType } from 'src/app/shared/models/nugget-event.model';

@Injectable({
  providedIn: 'root',
})
export class NuggetEventsService {
  constructor(private httpClient: HttpClient) {}

  getEventById(id: string) {
    return this.httpClient.get<NuggetEvent>(`api/events/${id}`);
  }

  getNuggetEvents: Record<NuggetEventType, () => Observable<NuggetEvent[]>> = {
    active: () => this.httpClient.get<NuggetEvent[]>('api/events/active'),
    deleted: () => this.httpClient.get<NuggetEvent[]>('api/events/deleted'),
    past: () => this.httpClient.get<NuggetEvent[]>('api/events/past'),
    upcoming: () => this.httpClient.get<NuggetEvent[]>('api/events/upcoming'),
  };
  reorder(nuggets: NuggetEvent[]) {
    return this.httpClient.put<void>(`api/events/reorder`, nuggets);
  }

  createOrUpdate(nugget: NuggetEvent) {
    if (nugget.id) {
      return this.httpClient.put(`api/events/${nugget.id}`, nugget);
    } else {
      return this.httpClient.post(`api/events`, nugget);
    }
  }

  delete(id: number) {
    return this.httpClient.delete(`api/events/${id}`);
  }

  activate(id: number) {
    return this.httpClient.put(`api/events/activate/${id}`, {});
  }
}
