<tr [class.loading]="loading">
  <td class="title">
    <a class="flex-columns" [routerLink]="link"
      >{{ title }}
      <p *ngIf="subTitle">{{ subTitle }}</p></a
    >
  </td>
  <div class="right-content">
    <td class="period">
      <a [routerLink]="link">
        {{ fromDate | date: 'dd.MM.yy' }}
        <p *ngIf="toDate">- {{ toDate | date: 'dd.MM.yy' }}</p>
      </a>
    </td>
    <td class="actions">
      <mat-icon
        [matTooltipDisabled]="loading"
        matTooltip="{{ 'HOME.PENDING_REQUESTS_SECTION.DELETE' | translate }}"
        class="action delete-action"
        [class.loading]="loading"
        (click)="deleteAction()"
        >clear</mat-icon
      >
      <mat-icon
        [matTooltipDisabled]="loading"
        *ngIf="approveAction"
        matTooltip="{{ 'HOME.PENDING_REQUESTS_SECTION.APPROVE' | translate }}"
        class="action approve-action"
        [class.loading]="loading"
        (click)="approveAction()"
        >check</mat-icon
      >
    </td>
  </div>
</tr>
