<div *ngIf="!isPreviewMode">
  <h2 class="section-title">
    {{
      isEditMode && page
        ? ('ADMIN.PAGES.EDIT_PAGE.EDIT' | translate) + ' ' + page.title
        : ('ADMIN.PAGES.EDIT_PAGE.CREATE_PAGE' | translate)
    }}
  </h2>
  <div class="back-link">
    <mat-icon>keyboard_arrow_left</mat-icon>
    <a routerLink="/admin/pages">{{ 'ADMIN.PAGES.EDIT_PAGE.BACK_TO_PAGES' | translate }}</a>
  </div>
  <form [formGroup]="pageForm" (ngSubmit)="submitForm()">
    <mat-form-field color="primary" appearance="fill">
      <mat-label>{{ 'ADMIN.PAGES.EDIT_PAGE.PAGE_TITLE' | translate }}</mat-label>
      <input
        matInput
        type="text"
        placeholder="{{ 'ADMIN.PAGES.EDIT_PAGE.PAGE_TITLE_PLACEHOLDER' | translate }}"
        formControlName="title"
        required
      />
      <mat-error *ngIf="pageForm.get('title').errors">{{ getTitleErrorMessage() }}</mat-error>
    </mat-form-field>

    <mat-form-field color="primary" appearance="fill">
      <mat-label>{{ 'ADMIN.PAGES.EDIT_PAGE.PAGE_SLUG' | translate }}</mat-label>
      <input
        matInput
        type="text"
        placeholder="{{ 'ADMIN.PAGES.EDIT_PAGE.PAGE_SLUG_PLACEHOLDER' | translate }}"
        formControlName="slug"
        required
      />
      <mat-error *ngIf="pageForm.get('slug').errors">{{ getSlugErrorMessage() }}</mat-error>
    </mat-form-field>

    <div class="mat-form-field-wrapper">
      <mat-label>{{ 'ADMIN.PAGES.EDIT_PAGE.CONTENT' | translate }}</mat-label>
      <app-editor formControlName="description"></app-editor>
    </div>

    <div class="page-action-buttons">
      <button
        id="preview-button"
        type="button"
        mat-raised-button
        color="primary"
        (click)="togglePreview()"
      >
        {{ 'ADMIN.PAGES.EDIT_PAGE.PREVIEW' | translate }}
      </button>
      <button id="create-button" type="submit" mat-raised-button color="primary">
        {{
          isEditMode && page
            ? ('ADMIN.PAGES.EDIT_PAGE.UPDATE' | translate)
            : ('ADMIN.PAGES.EDIT_PAGE.CREATE' | translate)
        }}
      </button>
    </div>
  </form>
</div>

<div *ngIf="isPreviewMode" class="wrapper-preview">
  <div
    class="warning"
    (click)="togglePreview()"
    matTooltip="{{ 'ADMIN.PAGES.EDIT_PAGE.CLOSE_PREVIEW' | translate }}"
  >
    <a>{{ 'ADMIN.PAGES.EDIT_PAGE.PAGE_PREVIEW' | translate }}</a>
    <mat-icon>close</mat-icon>
  </div>
  <h2 class="section-title">
    {{ pageForm.controls.title.value }}
  </h2>
  <div id="page-description" [innerHTML]="pageForm.controls.description.value | safeHtml"></div>
</div>
