<mat-spinner *ngIf="isLoading"></mat-spinner>
<div [hidden]="isLoading">
  <h2 class="section-title">
    {{ 'ADMIN.SCHEDULE_JOB.TITLE' | translate }}
  </h2>
  <table mat-table [dataSource]="dataSource">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <ng-container matColumnDef="job-name">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'ADMIN.SCHEDULE_JOB.NAME' | translate }}
      </th>
      <td mat-cell *matCellDef="let jobName">{{ jobName }}</td>
    </ng-container>

    <ng-container matColumnDef="start-button">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'ADMIN.SCHEDULE_JOB.ACTIVATE' | translate }}
      </th>
      <td class="start-button" mat-cell *matCellDef="let jobName">
        <button mat-raised-button color="primary" (click)="createScheduledJob(jobName)">
          {{ 'ADMIN.SCHEDULE_JOB.START' | translate }}
        </button>
      </td>
    </ng-container>
  </table>
</div>
