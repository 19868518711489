import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { LoadingState } from '../enums/loading-state.enum';

/**
 * `WithLoadingPipe` is an Angular Pipe that transforms an Observable by adding loading and error states.
 *
 * It returns an Observable that emits objects with `loading`, `value`, and `error` properties:
 * - `loading`: `true` initially, `false` when the Observable emits a value or an error occurs.
 * - `value`: The value emitted by the original Observable, `undefined` during loading.
 * - `error`: Included only if an error occurs during the execution of the Observable.
 *
 * If the input is not an Observable, it is returned as is.
 *
 * Example: `observable | withLoading | async`
 * Example: Please check project.details.component.ts to see the usage.
 */

@Pipe({
  name: 'withLoading',
  standalone: true,
})
export class WithLoadingPipe implements PipeTransform {
  transform(val) {
    return isObservable(val) ? withLoadingFn(val) : val;
  }
}

export interface ResponseState<T> {
  value?: T;
  loading?: boolean;
  error?: string;
}

export function withLoadingFn<T>(value: Observable<T>): Observable<ResponseState<T>> {
  return value.pipe(
    map((value: any) => ({
      loading: value.type === LoadingState.Start,
      value: value.type ? value.value : value,
    })),
    startWith({ loading: true }),
    catchError((error) => of({ loading: false, error })),
  );
}
