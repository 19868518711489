<mat-spinner *ngIf="isLoading"></mat-spinner>
<div [hidden]="isLoading">
  <h2 class="section-title">
    {{ 'USER.OVERTIME.OVERTIME_HISTORY.OVERTIME_HISTORY' | translate }}
  </h2>
  <app-table-wrapper
    [tableConfig]="tableConfig"
    (getData)="getOvertimesFromDb()"
    [(pageable)]="pageable"
    matSort
    [sort]="sort"
  >
    <ng-container matColumnDef="occurredOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'USER.OVERTIME.OVERTIME_HISTORY.DATE' | translate }}
      </th>
      <td mat-cell *matCellDef="let overtime" (click)="openDialog(overtime)">
        <div>
          {{ overtime.occurredOn | date: 'dd/MM/yyyy' }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="hours">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>
        {{ 'USER.OVERTIME.OVERTIME_HISTORY.HOURS' | translate }}
      </th>
      <td mat-cell *matCellDef="let overtime" (click)="openDialog(overtime)">
        <div>
          {{ overtime.hours }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>
        {{ 'USER.OVERTIME.OVERTIME_HISTORY.TYPE' | translate }}
      </th>
      <td mat-cell *matCellDef="let overtime" (click)="openDialog(overtime)">
        <div>
          {{ 'USER.OVERTIME.OVERTIME_HISTORY.' + overtime.overtimeType | translate }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>
        {{ 'SHARED.ACTIONS' | translate }}
      </th>
      <td mat-cell *matCellDef="let overtime">
        <div class="align-center actions">
          <mat-icon
            matTooltip="{{ 'SHARED.EDIT' | translate }}"
            class="icon"
            (click)="handleEdit(overtime)"
            [ngClass]="{ 'disabled': !isValidDate(overtime) }"
          >edit</mat-icon>
          <mat-icon
            matTooltip="{{ 'SHARED.DELETE' | translate }}"
            class="icon delete"
            (click)="handleDelete(overtime)"
            [class.disabled]="!isValidDate(overtime)"
          >cancel</mat-icon>
        </div>
      </td>
    </ng-container>
  </app-table-wrapper>
</div>
