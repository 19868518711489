import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KnowbeSubordinateDialogComponent } from 'src/app/knowbe-stats/knowbe-subordinate-dialog/knowbe-subordinate-dialog.component';
import { KnowBeUserData } from 'src/app/shared/models/knowbe-user-data.model';

@Component({
  selector: 'app-knowbe-subordinates-list',
  templateUrl: './knowbe-subordinates-list.component.html',
  styleUrls: ['./knowbe-subordinates-list.component.scss'],
})
export class KnowbeSubordinatesListComponent implements OnInit {
  @Input() subordinates: KnowBeUserData[];

  filteredSubOrdinates: KnowBeUserData[] = [];

  public displayedColumns: string[] = [
    'employeeName',
    'userCurrentRiskScore',
    'pastDueTrainingsCount',
    'employeePosition',
    'action',
  ];

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.filteredSubOrdinates = structuredClone(this.subordinates);
  }

  applyFilter(filter: string): void {
    this.filteredSubOrdinates = this.subordinates.filter((subordinate) =>
      subordinate.employeeName.toLowerCase().includes(filter.trim().toLowerCase()),
    );
  }

  openDialog(employee: KnowBeUserData) {
    this.dialog.open(KnowbeSubordinateDialogComponent, { data: employee });
  }
}
