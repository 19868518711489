import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { IPagination } from '../models/pagination.model';
import { ILicense } from '../models/license.model';
import * as fileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  private urlBase: string = 'api/licenses';

  constructor(private httpClient: HttpClient) {}

  getLicenses(params?: HttpParams): Observable<IPagination<ILicense>> {
    return this.httpClient.get<IPagination<ILicense>>(`${this.urlBase}`, {
      params,
    });
  }

  getLicenseById(id: number): Observable<ILicense> {
    return this.httpClient.get<ILicense>(`${this.urlBase}/${id}`);
  }

  getLicenseTypes(): Observable<Array<ILicense>> {
    return this.httpClient.get<Array<ILicense>>(`${this.urlBase}/types`);
  }

  downloadLicense(type: string): void {
    this.getLicenseByType(type).subscribe(
      (data) => {
        let fileName = 'file.txt';
        const contentDisposition = data.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }

        fileSaver.saveAs(data.body, fileName);
      },
      (err) => {
        console.error(err);
      },
    );
  }

  getLicenseByType(type: string): Observable<HttpResponse<Blob>> {
    const uri = `${this.urlBase}/download/${type}`;
    return this.httpClient.get(uri, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  downloadLicenseByParams(params: HttpParams): void {
    this.getLicenseByParams(params).subscribe((data) => {
      let fileName = 'file.txt';
      const contentDisposition = data.headers.get('Content-Disposition');
      if (contentDisposition) {
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }
      fileSaver.saveAs(data.body, fileName);
    });
  }

  getLicenseByParams(params: HttpParams): Observable<HttpResponse<Blob>> {
    const uri = `${this.urlBase}/download`;
    return this.httpClient.get(uri, {
      responseType: 'blob',
      observe: 'response',
      params,
    });
  }

  updateLicense(license: ILicense): Observable<any> {
    const data: FormData = new FormData();
    data.append('type', license.type);
    data.append('description', license.description);
    data.append('multipartFile', license.multipartFile ? license.multipartFile : new File([], ''));
    data.append('id', license.id.toString());
    return this.httpClient.put(`${this.urlBase}/update`, data);
  }

  createLicense(license: ILicense): Observable<HttpEvent<{}>> {
    const data: FormData = new FormData();
    data.append('type', license.type);
    data.append('description', license.description);
    data.append('multipartFile', license.multipartFile);
    const newRequest = new HttpRequest('POST', `${this.urlBase}/add`, data, {
      reportProgress: true,
      responseType: 'text',
    });
    return this.httpClient.request(newRequest);
  }

  deleteLicenseById(licenseId: number): Observable<ILicense> {
    return this.httpClient.delete<ILicense>(`${this.urlBase}/${licenseId}`);
  }
}
