<h2 class="section-title">{{ 'ADMIN.EVENTS.EDIT' | translate }}</h2>

<div class="container">
  <form (ngSubmit)="handleSubmit()">
    <div class="back-link">
      <mat-icon>keyboard_arrow_left</mat-icon>
      <a routerLink="/admin/events">{{ 'ADMIN.EVENTS.BACK' | translate }}</a>
    </div>
    <div class="columns">
      <mat-form-field>
        <mat-label>{{ 'ADMIN.EVENTS.NAME' | translate }}</mat-label>
        <input matInput type="text" [formControl]="form.controls.title" />
        <mat-error
          *ngIf="
            form.controls.title.invalid &&
            (form.controls.title.dirty || form.controls.title.touched)
          "
        >
          <ng-container *ngIf="form.controls.title.errors?.required">
            {{ 'SHARED.VALIDATION.REQUIRED' | translate }}.
          </ng-container>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'ADMIN.EVENTS.START_DATE' | translate }}</mat-label>
        <input matInput [matDatepicker]="startPicker" [formControl]="form.controls.startDate" />
        <mat-datepicker-toggle matIconSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'ADMIN.EVENTS.END_DATE' | translate }}</mat-label>
        <input matInput [matDatepicker]="endPicker" [formControl]="form.controls.endDate" />
        <mat-datepicker-toggle matIconSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="columns">
      <mat-form-field>
        <mat-label>{{ 'ADMIN.EVENTS.BUTTON_LABEL' | translate }}</mat-label>
        <input matInput type="text" [formControl]="form.controls.buttonLabel" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'ADMIN.EVENTS.BUTTON_LINK' | translate }}</mat-label>
        <input matInput type="text" [formControl]="form.controls.buttonLink" />
      </mat-form-field>
    </div>

    <div>
      <div class="uploader">
        <app-image-upload-base64 [controller]="form.controls.img"></app-image-upload-base64>
        <mat-slide-toggle [formControl]="form.controls.imageOnTheLeft">
          {{ 'ADMIN.EVENTS.IMAGE_LEFT' | translate }}
        </mat-slide-toggle>
      </div>
    </div>

    <div>
      <mat-label class="block">{{ 'ADMIN.EVENTS.CONTENT' | translate }}</mat-label>
      <app-editor [formControl]="form.controls.context"></app-editor>
    </div>

    <button type="submit" mat-raised-button color="primary" class="create-button">
      {{
        (!route.snapshot.params.id ? 'ADMIN.EVENTS.CREATE_FORM' : 'ADMIN.EVENTS.UPDATE_FORM')
          | translate
      }}
    </button>
  </form>

  <div class="preview">
    <h4>Preview</h4>
    <app-weekly-nugget [nugget]="form.value"></app-weekly-nugget>
  </div>
</div>
