<form [formGroup]="filterForm" (ngSubmit)="search()" class="filter">
  <div class="filter-fields">
    <mat-form-field (click)="fromDatePicker.open()" color="primary" appearance="fill">
      <mat-label>{{ 'ADMIN.REQUESTS.REQUESTS_EXPORT_EXCEL.FROM_DATE' | translate }} </mat-label>
      <input
        matInput
        [matDatepicker]="fromDatePicker"
        (dateInput)="getStartDate($event)"
        formControlName="fromDate"
      />
      <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #fromDatePicker color="primary"></mat-datepicker>
    </mat-form-field>

    <mat-form-field (click)="toDatePicker.open()" color="primary" appearance="fill">
      <mat-label>{{ 'ADMIN.REQUESTS.REQUESTS_EXPORT_EXCEL.TO_DATE' | translate }} </mat-label>
      <input
        matInput
        [matDatepicker]="toDatePicker"
        [matDatepickerFilter]="endDateFilter"
        formControlName="toDate"
      />
      <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #toDatePicker color="primary"></mat-datepicker>
    </mat-form-field>

    <mat-form-field (click)="createdOnDatePicker.open()" color="primary" appearance="fill">
      <mat-label
        >{{ 'ADMIN.REQUESTS.REQUESTS_EXPORT_EXCEL.CREATED_ON_DATE' | translate }}
      </mat-label>
      <input matInput [matDatepicker]="createdOnDatePicker" formControlName="createdOnDate" />
      <mat-datepicker-toggle matSuffix [for]="createdOnDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #createdOnDatePicker color="primary"></mat-datepicker>
    </mat-form-field>

    <mat-form-field color="primary" appearance="fill">
      <mat-label>{{ 'ADMIN.REQUESTS.STATUS' | translate }} </mat-label>
      <mat-select multiple formControlName="statuses">
        <mat-select-trigger>
          {{ filterForm.controls.statuses.value ? filterForm.controls.statuses.value[0] : '' }}
          <span
            *ngIf="filterForm.controls.statuses.value?.length > 1"
            class="example-additional-selection"
          >
            (+{{ filterForm.controls.statuses.value.length - 1 }}
            {{ filterForm.controls.statuses.value?.length === 2 ? 'other' : 'others' }})
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let status of availableStatuses" [value]="status"
          >{{ status }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="primary" appearance="fill">
      <mat-label>{{ 'ADMIN.REQUESTS.TYPE' | translate }} </mat-label>
      <mat-select
        formControlName="vacationTypes"
        multiple
        (selectionChange)="adaptSubtypes($event)"
      >
        <mat-select-trigger>
          {{
            filterForm.controls.vacationTypes.value
              ? filterForm.controls.vacationTypes.value[0]
              : ''
          }}
          <span
            *ngIf="filterForm.controls.vacationTypes.value?.length > 1"
            class="example-additional-selection"
          >
            (+{{ filterForm.controls.vacationTypes.value.length - 1 }}
            {{ filterForm.controls.vacationTypes.value?.length === 2 ? 'other' : 'others' }})
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let type of availableVacationTypes" [value]="type"
          >{{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="primary" appearance="fill">
      <mat-label>{{ 'ADMIN.REQUESTS.SUBTYPE' | translate }} </mat-label>
      <mat-select
        formControlName="vacationSubTypes"
        multiple
        [disabled]="availableVacationSubtypes.length === 0"
      >
        <mat-select-trigger>
          {{
            filterForm.controls.vacationSubTypes.value
              ? filterForm.controls.vacationSubTypes.value[0]
              : ''
          }}
          <span
            *ngIf="filterForm.controls.vacationSubTypes.value?.length > 1"
            class="example-additional-selection"
          >
            (+{{ filterForm.controls.vacationSubTypes.value.length - 1 }}
            {{ filterForm.controls.vacationSubTypes.value?.length === 2 ? 'other' : 'others' }})
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let type of availableVacationSubtypes" [value]="type"
          >{{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field color="primary" appearance="fill">
      <mat-label>{{ 'ADMIN.REQUESTS.REQUESTER' | translate }} </mat-label>
      <input matInput formControlName="requester" (keyup.enter)="search()" />
    </mat-form-field>

    <mat-form-field color="primary" appearance="fill">
      <mat-label>{{ 'ADMIN.REQUESTS.APPROVER' | translate }} </mat-label>
      <input matInput formControlName="approver" (keyup.enter)="search()" />
    </mat-form-field>

    <mat-form-field color="primary" appearance="fill">
      <mat-label>{{ 'ADMIN.REQUESTS.ATTACHMENTS' | translate }} </mat-label>
      <mat-select formControlName="hasFile">
        <mat-option *ngFor="let type of availableFileOptions" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="filter-operations">
    <button
      (click)="exportExcel()"
      mat-raised-button
      color="primary"
      class="excel-button"
      type="button"
    >
      <mat-icon>download</mat-icon>
      {{ 'ADMIN.REQUESTS.REQUESTS_EXPORT_EXCEL.EXCEL' | translate }}
    </button>

    <button (click)="search()" mat-raised-button color="primary" type="button">
      <mat-icon>search</mat-icon>
      {{ 'ADMIN.REQUESTS.REQUESTS_EXPORT_EXCEL.SEARCH' | translate }}
    </button>

    <button (click)="resetFilterForm()" mat-raised-button color="primary" type="button">
      <mat-icon>restart_alt</mat-icon>
      {{ 'ADMIN.REQUESTS.REQUESTS_EXPORT_EXCEL.RESET' | translate }}
    </button>
  </div>
</form>
