import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Subscription } from 'rxjs';
import { ScheduleJobService } from '../../shared/services/schedule-job.service';
import { AlertService, AlertType } from '../../shared/services/alert.service';

@Component({
  selector: 'app-schedule-job',
  templateUrl: './schedule-job.component.html',
  styleUrls: ['./schedule-job.component.scss'],
})
export class ScheduleJobComponent implements OnInit, OnDestroy {
  scheduledJobsSubscription!: Subscription;
  isLoading: boolean = true;
  displayedColumns: string[] = ['job-name', 'start-button'];
  dataSource: string[] = [];

  constructor(
    public translate: TranslateService,
    private scheduledJobsService: ScheduleJobService,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    this.scheduledJobsSubscription = this.scheduledJobsService
      .getAllScheduledJobs()
      .subscribe((scheduledJobs) => {
        this.dataSource = [...scheduledJobs];
        this.isLoading = false;
      });
  }

  async createScheduledJob(jobName: string) {
    try {
      this.isLoading = true;
      await firstValueFrom(this.scheduledJobsService.createScheduledJob(jobName)).then(
        (message) => {
          this.alertService.showAlert(message, AlertType.success);
        },
      );
    } catch (error) {
      this.alertService.showAlert(error.error, AlertType.error);
    } finally {
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    if (this.scheduledJobsSubscription) {
      this.scheduledJobsSubscription.unsubscribe();
    }
  }
}
