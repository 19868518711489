<mat-spinner *ngIf="isLoading"></mat-spinner>
<div [hidden]="isLoading">
  <h2 class="section-title">
    <span *ngIf="!isEditMode">{{ 'USER.REIMBURSEMENT_REQUESTS.TITLE' | translate }}</span>
    <span *ngIf="isEditMode">{{ 'USER.REIMBURSEMENT_REQUESTS.UPDATE_TITLE' | translate }}</span>
  </h2>

  <div class="select-type">
    <mat-form-field appearance="fill">
      <mat-label
        >{{ 'USER.REIMBURSEMENT_REQUESTS.SELECT_TYPE' | translate }}
        <mat-icon
          matTooltip="{{ 'USER.TRAVEL_REQUESTS.EDIT.TYPE' | translate }}"
          [matTooltipPosition]="'after'"
          matTooltipClass="tooltip"
          class="info-icon"
          *ngIf="isEditMode"
          >info_outlined
        </mat-icon>
      </mat-label>
      <mat-select [(ngModel)]="selectedType" [disabled]="isEditMode">
        <mat-option *ngFor="let type of allTypes" [value]="type">{{ type }}</mat-option>
      </mat-select>
    </mat-form-field>
    <label *ngIf="isEditMode" class="icon save"> </label>
  </div>

  <form [formGroup]="form" class="reimbursement-form">
    <div class="date-picker-holder">
      <mat-form-field (click)="startDate.open()" color="accent" appearance="fill">
        <mat-label>
          {{ 'USER.REVIEWED_REQUESTS.START_DATE' | translate }}
        </mat-label>
        <input
          matInput
          [matDatepicker]="startDate"
          (dateInput)="getStartDate($event)"
          required
          formControlName="startDate"
        />
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate color="primary"></mat-datepicker>
      </mat-form-field>
      <mat-form-field (click)="endDate.open()" appearance="fill">
        <mat-label color="primary">
          {{ 'USER.REVIEWED_REQUESTS.END_DATE' | translate }}
        </mat-label>
        <input
          matInput
          [matDatepicker]="endDate"
          [matDatepickerFilter]="endDateFilter"
          formControlName="endDate"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate color="primary"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="selectedType === reimbursementTypes.travel">
        <mat-label>
          {{ 'USER.REIMBURSEMENT_REQUESTS.FROM' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="destinationFrom" required />
        <mat-error *ngIf="form.controls.destinationFrom.invalid">{{
          getErrorMessage('destinationFrom')
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="selectedType === reimbursementTypes.travel">
        <mat-label>
          {{ 'USER.REIMBURSEMENT_REQUESTS.TO' | translate }}
        </mat-label>
        <input matInput type="text" formControlName="destinationTo" required />
        <mat-error *ngIf="form.controls.destinationTo.invalid">{{
          getErrorMessage('destinationTo')
        }}</mat-error>
      </mat-form-field>
      <section class="round-section" *ngIf="selectedType === reimbursementTypes.travel">
        <mat-slide-toggle color="primary" formControlName="round">Round</mat-slide-toggle>
      </section>
    </div>
    <div class="reimbursement-types-holder" *ngIf="selectedType === reimbursementTypes.travel">
      <p
        class="text-left border-bottom"
        [ngClass]="{
          error: !form.controls.accommodation.value && !form.controls.transport.value
        }"
      >
        {{ 'USER.REIMBURSEMENT_REQUESTS.SUB_TITLE' | translate }}
        <span>*</span>
      </p>
      <section class="reimbursement-types-section">
        <div class="reimbursement-types">
          <p>
            <mat-checkbox
              color="primary"
              formControlName="accommodation"
              (change)="onAccommodationSelectChange($event.checked)"
            >
              {{ 'USER.REIMBURSEMENT_REQUESTS.ACCOMMODATION' | translate }}
            </mat-checkbox>
          </p>
          <div *ngIf="form.controls.accommodation.value">
            <div class="upload-file-holder">
              <div class="file-upload">
                <button
                  mat-raised-button
                  color="primary"
                  class="upload-btn"
                  (click)="accommodationFileInput.click()"
                  [disabled]="accommodationFileName || accommodationFile?.name"
                >
                  <mat-icon>cloud_upload</mat-icon>
                  <span>{{ 'USER.REIMBURSEMENT_REQUESTS.UPLOAD_FILE' | translate }}</span>
                </button>
                <input
                  type="file"
                  #accommodationFileInput
                  class="file-input"
                  #accommodationFileUpload
                  (change)="onAccommodationFileSelected($event)"
                  [disabled]="accommodationFileName || accommodationFile?.name"
                  [accept]="acceptedMimeTypes"
                  required
                />

                <div class="file-names" *ngIf="accommodationFile?.name">
                  <span>{{ accommodationFile?.name }}</span>
                  <mat-icon (click)="removeUploadedFile('accommodationFile')">cancel</mat-icon>
                </div>
                <div class="file-names" *ngIf="isEditMode && accommodationFileName">
                  <span>{{ accommodationFileName }}</span>
                  <mat-icon
                    (click)="
                      deleteReimbursementFileByName(accommodationFileName, 'accommodationFile')
                    "
                    >cancel
                  </mat-icon>
                </div>
                <mat-error
                  class="file-names error"
                  *ngIf="!accommodationFileName && !accommodationFile?.name"
                  >{{ getErrorMessage('file-upload') }}</mat-error
                >
                <mat-error
                  class="file-names error no-padding-top"
                  *ngIf="!accommodationFileName && !accommodationFile?.name"
                  >{{ 'USER.REIMBURSEMENT_REQUESTS.MEDIA_TYPES_WARNING' | translate }}</mat-error
                >
              </div>
            </div>
          </div>
        </div>
        <div class="reimbursement-types">
          <p>
            <mat-checkbox
              formControlName="transport"
              color="primary"
              (change)="onTransportSelectChange($event.checked)"
              >{{ 'USER.REIMBURSEMENT_REQUESTS.TRANSPORT' | translate }}</mat-checkbox
            >
          </p>
          <div *ngIf="form.controls.transport.value">
            <div class="upload-file-holder">
              <div class="file-upload">
                <button
                  mat-raised-button
                  color="primary"
                  class="upload-btn"
                  (click)="transportFileInput.click()"
                  [disabled]="transportFileName || transportFile?.name"
                >
                  <mat-icon>cloud_upload</mat-icon>
                  <span>{{ 'USER.REIMBURSEMENT_REQUESTS.UPLOAD_FILE' | translate }}</span>
                </button>
                <input
                  #transportFileInput
                  type="file"
                  class="file-input"
                  #transportFileUpload
                  (change)="onTransportFileSelected($event)"
                  [disabled]="transportFileName || transportFile?.name"
                  [accept]="acceptedMimeTypes"
                  required
                />
                <div class="file-names" *ngIf="transportFile?.name">
                  <span>{{ transportFile?.name }}</span>
                  <mat-icon (click)="removeUploadedFile('transportFile')">cancel</mat-icon>
                </div>
                <div class="file-names" *ngIf="isEditMode && transportFileName">
                  <span>{{ transportFileName }}</span>
                  <mat-icon
                    (click)="deleteReimbursementFileByName(transportFileName, 'transportFile')"
                    >cancel
                  </mat-icon>
                </div>
                <mat-error
                  class="file-names error"
                  *ngIf="!transportFileName && !transportFile?.name"
                  >{{ getErrorMessage('file-upload') }}</mat-error
                >
                <mat-error
                  class="file-names error no-padding-top"
                  *ngIf="!transportFileName && !transportFile?.name"
                  >{{ 'USER.REIMBURSEMENT_REQUESTS.MEDIA_TYPES_WARNING' | translate }}</mat-error
                >
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      class="additional-holder"
      [ngClass]="{ travel: selectedType === reimbursementTypes.travel }"
    >
      <mat-form-field appearance="fill">
        <mat-label *ngIf="selectedType !== reimbursementTypes.travel">
          {{ 'USER.REIMBURSEMENT_REQUESTS.EXPENSE_DOCUMENTS' | translate }}
        </mat-label>
        <mat-label *ngIf="selectedType === reimbursementTypes.travel">
          {{ 'USER.REIMBURSEMENT_REQUESTS.ADDITIONAL_EXPENSE_DOCUMENTS' | translate }}
        </mat-label>
        <input
          matInput
          type="text"
          formControlName="additionalInfo"
          placeholder="{{
            'USER.REIMBURSEMENT_REQUESTS.EXPENSE_DOCUMENTS_PLACEHOLDER' | translate
          }}"
        />
      </mat-form-field>

      <div class="upload-file-holder">
        <div class="file-upload">
          <button
            mat-raised-button
            color="primary"
            (click)="additionalFileInput.click()"
            class="upload-btn"
          >
            <mat-icon>cloud_upload</mat-icon>
            <span>{{ 'USER.REIMBURSEMENT_REQUESTS.UPLOAD_FILE' | translate }}</span>
          </button>
          <input
            type="file"
            class="file-input"
            #additionalFileInput
            multiple
            #additionalFilesUpload
            (change)="onAdditionalFilesSelected($event)"
            [accept]="acceptedMimeTypes"
            required
          />

          <div class="file-names multiple" *ngIf="additionalFiles?.length">
            <div class="display-files" *ngFor="let file of additionalFiles; let i = index">
              <span>{{ file.name }}</span>
              <mat-icon (click)="removeUploadedFile('additionalFile', i)">cancel</mat-icon>
            </div>
          </div>

          <div class="file-names multiple" *ngIf="additionalFileNames.length">
            <div class="display-files" *ngFor="let file of additionalFileNames; let i = index">
              <span>{{ file }}</span>
              <mat-icon (click)="deleteReimbursementFileByName(file, 'additionalFile', i)"
                >cancel
              </mat-icon>
            </div>
          </div>
          <div
            class="file-names"
            *ngIf="
              selectedType === reimbursementTypes.travel &&
              !additionalFiles?.length &&
              !additionalFileNames.length
            "
          >
            {{ 'USER.REIMBURSEMENT_REQUESTS.ADDITIONAL_FILE_UPLOAD' | translate }}
          </div>
          <div
            class="file-names no-padding-top"
            *ngIf="
              selectedType === reimbursementTypes.travel &&
              !additionalFiles?.length &&
              !additionalFileNames.length
            "
          >
            {{ 'USER.REIMBURSEMENT_REQUESTS.MEDIA_TYPES_WARNING' | translate }}
          </div>
          <mat-error
            class="file-names error"
            *ngIf="
              selectedType !== reimbursementTypes.travel &&
              !additionalFiles?.length &&
              !additionalFileNames.length
            "
            >{{ getErrorMessage('file-upload') }}</mat-error
          >
          <mat-error
            class="file-names error no-padding-top"
            *ngIf="
              selectedType !== reimbursementTypes.travel &&
              !additionalFiles?.length &&
              !additionalFileNames.length
            "
            >{{ 'USER.REIMBURSEMENT_REQUESTS.MEDIA_TYPES_WARNING' | translate }}</mat-error
          >
        </div>
      </div>
    </div>

    <div class="button-holder">
      <button *ngIf="isEditMode" mat-button class="cancel-button" (click)="cancelUpdate()">
        <span>{{ 'SHARED.CANCEL' | translate }}</span>
      </button>
      <button
        (click)="onSubmit()"
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="isFormNotValid()"
      >
        <span>{{ (!isEditMode ? 'SHARED.SUBMIT' : 'SHARED.UPDATE') | translate }}</span>
      </button>
    </div>
  </form>

  <div class="reimbursement-table-holder">
    <h2 class="section-title">
      {{ 'USER.REIMBURSEMENT_REQUESTS.ALL_REQUESTS' | translate }}
    </h2>
    <app-reimbursement-requests-table
      (requestToEdit)="editReimbursementRequest($event)"
      (requestToDelete)="deleteReimbursementRequest($event)"
      [isEditMode]="isEditMode"
      [data]="dataSource"
      [isLoading]="isTableLoading"
    ></app-reimbursement-requests-table>
  </div>
</div>
