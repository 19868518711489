<mat-tab-group animationDuration="0ms">
  <mat-tab label="{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.EDIT_USER' | translate }}">
    <ng-template matTabContent>
      <app-edit-employee-data />
    </ng-template>
  </mat-tab>
  <mat-tab label="{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.EDIT_PROJECTS.TITLE' | translate }}">
    <ng-template matTabContent>
      <app-edit-employee-projects />
    </ng-template>
  </mat-tab>
</mat-tab-group>
