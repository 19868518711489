<mat-spinner *ngIf="isLoading"></mat-spinner>

<div [hidden]="isLoading">
  <h2 class="section-title">
    {{ 'DREAMIX.LICENSES.PAGE_TITLE' | translate }}
  </h2>

  <div class="table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let license">
          {{ license.type }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let license">
          <mat-icon
            matTooltip="{{ 'SHARED.DOWNLOAD' | translate }}"
            (click)="downloadLicense(license.type)"
            class="icon download"
            >download
          </mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
