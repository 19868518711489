import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import * as FileSaver from 'file-saver';
import { EmployeeProject } from 'src/app/shared/models/project.model';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { IEmployee } from 'src/app/shared/models/employee.model';
import { RequestService } from 'src/app/shared/services/request.service';
import { TimeLogController } from 'src/app/shared/services/time-log.controller';
import { LoginService } from 'src/app/shared/services';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

export const MONTH_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-client-hours',
  templateUrl: './client-hours.component.html',
  styleUrls: ['./client-hours.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MONTH_FORMATS },
  ],
})
export class ClientHoursComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  projectsControl: FormControl = new FormControl();
  employeesControl: FormControl = new FormControl({
    value: [],
    disabled: true,
  });
  private firstDateOfTheMonth = moment().add(-1, 'month').startOf('month');
  minDate = moment().add(-12, 'months').startOf('month');
  maxDate = moment().startOf('month');
  dateControl = new FormControl(this.firstDateOfTheMonth);

  projectList: EmployeeProject[] = [];
  employeeList: IEmployee[] = [];
  isLoading = false;

  constructor(
    public translate: TranslateService,
    public loginService: LoginService,
    private alertService: AlertService,
    private employeeService: EmployeeService,
    private requestService: RequestService,
    private ctrl: TimeLogController,
  ) {}

  ngOnInit(): void {
    this.initProjects();
  }

  async initProjects(): Promise<void> {
    this.projectList = await this.employeeService.loadMyProjects(this.loginService.getUser().id);
    this.projectList.sort((a, b) =>
      a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase() ? 1 : -1,
    );
    if (this.projectList.length === 1) {
      this.projectsControl.setValue(this.projectList[0]);
      this.updateEmployeesDropdownStatus(this.projectList[0].id);
    }
  }

  onSubmit(): void {
    if (!this.validateInput()) {
      return;
    }

    this.saveAsExcelFile();
  }

  setMonthAndYear(normalizedMonthAndYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.dateControl.value;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.dateControl.setValue(ctrlValue);
    datepicker.close();
  }

  onProjectChange(event: MatAutocompleteSelectedEvent): void {
    const { id } = event.option.value;
    this.updateEmployeesDropdownStatus(id);
    this.ctrl.clearEntries();
  }

  updateEmployeesDropdownStatus(id) {
    this.employeeService
      .getAllClientEmployees(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((employees) => {
        this.employeeList = employees.sort((a, b) =>
          a.firstNameEn + a.lastNameEn > b.firstNameEn + b.lastNameEn ? 1 : -1,
        );
        if (this.employeeList.length) {
          this.employeesControl.enable();
        } else {
          this.employeesControl.disable();
        }
      });
  }

  private async saveAsExcelFile(): Promise<void> {
    this.isLoading = true;
    const fromDate = moment(this.dateControl.value).startOf('month').format('YYYY-MM-DD');
    const toDate = moment(this.dateControl.value).endOf('month').format('YYYY-MM-DD');
    try {
      const buffer = await lastValueFrom(
        this.requestService.downloadLoggedHours(fromDate, toDate, this.projectsControl.value.id),
      );
      const data: Blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });
      FileSaver.saveAs(
        data,
        `Workreport_${this.projectsControl.value.name}_${this.dateControl.value.format(
          'MM/YYYY',
        )}.xlsx`,
      );
    } catch (error) {
      this.alertService.showAlert(
        this.translate.instant('ADMIN.CLIENT_HOURS.EXPORT_ERROR').toString(),
        AlertType.error,
      );
    } finally {
      this.isLoading = false;
    }
  }

  private validateInput(): boolean {
    if (!this.projectsControl.valid || !this.dateControl.valid) {
      this.alertService.showAlert(
        this.translate.instant('ADMIN.CLIENT_HOURS.FILL_DATE_AND_PROJECT').toString(),
        AlertType.error,
      );
      return false;
    }
    return true;
  }

  displayFn(project: EmployeeProject): string {
    return project ? `${project.name}` : null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
