import { Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { IOvertime } from 'src/app/shared/models/overtime.model';
import { IPageable } from 'src/app/shared/models/pageable.model';
import { IPagination } from 'src/app/shared/models/pagination.model';
import { PageableHelperService } from 'src/app/shared/services/helpers/pageable-helper.service';
import { OvertimeService } from 'src/app/shared/services/overtime.service';
import { OvertimeInformationDialogComponent } from './overtime-history-dialog/overtime-history-dialog.component';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { DialogComponent } from 'src/app/shared/dialogs';
import { AlertService, AlertType } from 'src/app/shared/services';
import { DIALOG_BUTTONS } from 'src/app/shared/constants';
import * as moment from 'moment';

@Component({
  selector: 'app-ot-history',
  templateUrl: './overtime-history.component.html',
  styleUrls: ['./overtime-history.component.scss'],
})
export class OvertimeHistoryComponent implements OnInit, OnDestroy {
  @Input() handleEdit: (overtime: IOvertime) => void;
  @Input() resetForm: () => void;
  public tableConfig: IPagination<IOvertime>;
  public editOvertime: IOvertime = null;
  public pageable: IPageable;
  public isLoading: boolean = true;
  private destroy$ = new Subject<void>();
  subscription: Subscription = new Subscription();

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private overtimeService: OvertimeService,
    private pageableHelper: PageableHelperService,
    public translate: TranslateService,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.pageable = this.pageableHelper.getPageable();
    this.pageable.size = 6;
    this.getOvertimesFromDb();
  }

  getOvertimesFromDb(): void {
    this.isLoading = true;
    const params = this.pageableHelper.getHttpParams(this.pageable);
    this.subscription.add(
      this.overtimeService.getAllOvertimesByEmployee(params).subscribe((response) => {
        this.tableConfig = response;
        this.isLoading = false;
      }),
    );
  }

  openDialog(overtime: IOvertime) {
    this.dialog.open(OvertimeInformationDialogComponent, { data: overtime });
  }

  public handleDelete(overtime: IOvertime) {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('USER.OVERTIME.OVERTIME_HISTORY.DELETE_TITLE').toString(),
          description: this.translate
            .instant('USER.OVERTIME.OVERTIME_HISTORY.DELETE_DESCRIPTION')
            .toString(),
          sharedButtonText: this.translate.instant('SHARED.DELETE').toString(),
          sharedButtonClass: DIALOG_BUTTONS.deleteButton,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.overtimeService
            .deleteOvertimeById(overtime.id, overtime.employeeId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              () => {
                this.alertService.showAlert(
                  this.translate
                    .instant('USER.OVERTIME.OVERTIME_HISTORY.DELETE_SUCCESSFUL')
                    .toString(),
                  AlertType.success,
                );
                this.getOvertimesFromDb();
                this.resetForm();
              },
              (error) => {
                if (error.error) {
                  this.alertService.showAlert(error.error, AlertType.error);
                } else {
                  this.alertService.showAlert(error.message, AlertType.error);
                }
              },
            );
        }
      });
  }

  public isValidDate(item: IOvertime): boolean {
    const itemDate = moment(item.occurredOn);
    const currentDate = moment();
    const isCurrentMonthAndYear = itemDate.isSame(currentDate, 'month') && itemDate.isSame(currentDate, 'year');
    const isFutureDate = itemDate.isAfter(currentDate);

    return isCurrentMonthAndYear || isFutureDate;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
