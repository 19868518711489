import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IRequest } from '../../shared/models/request.model';
import { LoginService } from '../../shared/services/login.service';
import { RequestService } from '../../shared/services/request.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/dialogs/dialog/dialog.component';
import { RequestStatusEnum } from '../../shared/enums/request-status.enum';
import { AlertService, AlertType } from '../../shared/services/alert.service';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { IPagination } from '../../shared/models/pagination.model';
import { PageableHelperService } from '../../shared/services/helpers/pageable-helper.service';
import { IPageable } from '../../shared/models/pageable.model';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { DIALOG_BUTTONS } from '../../shared/constants';

@Component({
  selector: 'app-reviewed-requests',
  templateUrl: './reviewed-requests.component.html',
  styleUrls: ['./reviewed-requests.component.scss'],
})
export class ReviewedRequestsComponent implements OnInit, OnDestroy {
  public yearFormControl: UntypedFormControl;
  public allYears: number[];
  public currentYear: number;
  private destroy$ = new Subject<void>();

  public tableConfig: IPagination<IRequest>;

  @ViewChild('file') fileUpload: ElementRef;

  public pageable: IPageable;
  public isLoading: boolean = true;

  public sortByColumns: string[];
  readonly buttons = DIALOG_BUTTONS;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public loginService: LoginService,
    private requestService: RequestService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private pageableHelper: PageableHelperService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.yearFormControl = new UntypedFormControl(this.currentYear);
    this.allYears = new Array(5).fill(1).map((_, index) => this.currentYear - index);
    this.pageable = this.pageableHelper.getPageable();

    this.getRequestsFromDb(this.currentYear);
  }

  public updateRequests(data: MatSelectChange): void {
    this.currentYear = data.value;
    this.getRequestsFromDb(this.currentYear);
  }

  public getRequestsFromDb(year: number): void {
    this.isLoading = true;
    const params = this.pageableHelper.getHttpParams(this.pageable);

    this.requestService
      .getRequestsByApproverIdAndStatusNotDeleted(this.loginService.getUserId(), year, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.tableConfig = response;
        this.sortByColumns = response.config.sortColumns;
        this.isLoading = false;
      });
  }

  public applicableForModification(request: IRequest): boolean {
    if (request.status === 'PENDING') return true;

    const dateFrom = new Date(request.fromDate).setHours(0);
    const dateNow = new Date(Date.now()).setHours(0);

    return dateFrom > dateNow;
  }

  public openDialogApprove(request: IRequest): void {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('USER.REVIEWED_REQUESTS.APPROVE_CONFIRMATION').toString(),
          description: this.translate
            .instant('USER.REVIEWED_REQUESTS.APPROVE_CONFIRMATION_DESCRIPTION')
            .toString(),
          sharedButtonClass: this.buttons.warningButton,
          sharedButtonText: this.translate.instant('USER.REVIEWED_REQUESTS.APPROVE').toString(),
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          request.status = RequestStatusEnum.APPROVED;
          this.actionRequest(request.id, true);
        }
      });
  }

  public openDialogDecline(request: IRequest): void {
    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('USER.REVIEWED_REQUESTS.DECLINE_CONFIRMATION').toString(),
          description: this.translate
            .instant('USER.REVIEWED_REQUESTS.DECLINE_CONFIRMATION_DESCRIPTION')
            .toString(),
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('USER.REVIEWED_REQUESTS.DECLINE').toString(),
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          request.status = RequestStatusEnum.DECLINED;
          this.actionRequest(request.id, false);
        }
      });
  }

  private actionRequest(id: number, approveAction: boolean): void {
    this.requestService
      .actionRequestWithoutHash(id, approveAction)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          if (approveAction) {
            this.alertService.showAlert(
              this.translate.instant('USER.REVIEWED_REQUESTS.REQUEST_APPROVED').toString(),
              AlertType.success,
            );
          } else {
            this.alertService.showAlert(
              this.translate.instant('USER.REVIEWED_REQUESTS.REQUEST_DECLINED').toString(),
              AlertType.error,
            );
          }

          this.getRequestsFromDb(this.currentYear);
        },
        (error) => {
          this.alertService.showAlert(error.error, AlertType.error);
          this.getRequestsFromDb(this.currentYear);
        },
      );
  }

  public isSortingDisabled(column: string): boolean {
    return !this.sortByColumns.some((c) => c === column);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
