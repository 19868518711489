import { Component, Input, OnInit } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { IEmployeeSimple } from 'src/app/shared/models';
import { EmployeeService } from 'src/app/shared/services';

@Component({
  selector: 'app-employee-input',
  templateUrl: './employee-input.component.html',
})

//TODO: Generalize this;
export class EmployeeInputComponent implements OnInit {
  @Input() handleSelect: (e: IEmployeeSimple) => void;
  public allEmployees: IEmployeeSimple[];


  constructor(
    public translate: TranslateService,
    private employeeService: EmployeeService,
  ) {}

  ngOnInit() {
    this.employeeService.getAllEmployeesSimple().subscribe((data: IEmployeeSimple[]) => {
      this.allEmployees = data;
    });
  }

  displayFn(user: IEmployeeSimple): string {
    return user ? `${user.fullName}` : null;
  }

  public _handleSelect = (e: MatAutocompleteSelectedEvent) => {
    const val: IEmployeeSimple = e.option.value;
    this.handleSelect(val);
  };
}
