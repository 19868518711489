<div class="flex-columns wrapper">
  <h3 class="title">{{ 'HOME.VACATION_CHARTS.ABSENCES' | translate }}</h3>
  <div [ngClass]="{'charts-wrapper': absences.length <= 3,'charts-wrapper columns': absences.length > 3
}">
    <app-common-progress
      *ngFor="let absence of absences"
      [setHeight]="setHeight"
      [type]="absence.vacationType"
      [daysUsed]="absence.usedDays"
      [daysTotal]="absence.totalDays"></app-common-progress>
  </div>
</div>
