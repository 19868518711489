import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, switchMap, tap } from 'rxjs';
import { NuggetEvent, NuggetEventType } from 'src/app/shared/models/nugget-event.model';
import { AlertService } from 'src/app/shared/services';
import { NuggetEventsService } from 'src/app/shared/services/nugget-events.service';
import { errorMessage } from 'src/app/shared/utils';

@Component({
  selector: 'app-nugget-events-table',
  templateUrl: './nugget-events-table.component.html',
  styleUrls: ['./nugget-events-table.component.scss'],
})
export class NuggetEventsTableComponent implements OnInit {
  @Input({ required: true }) nuggetEventType!: NuggetEventType;

  nuggets: NuggetEvent[] = [];

  displayedColumns = ['title', 'startDate', 'endDate', 'actions'];
  draggable = false;
  loading = true;

  reload$ = new Subject<void>();

  constructor(
    private nuggetEventsService: NuggetEventsService,
    private alertService: AlertService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    if (this.nuggetEventType === 'active') {
      this.draggable = true;
      this.displayedColumns = ['order', 'title', 'startDate', 'endDate', 'actions'];
    }

    this.reload$
      .pipe(
        tap(() => (this.loading = true)),
        switchMap(() => this.nuggetEventsService.getNuggetEvents[this.nuggetEventType]()),
      )
      .subscribe((nuggets) => {
        this.nuggets = nuggets;
        this.loading = false;
      });

    this.reload$.next();
  }

  droppedHandle(event: CdkDragDrop<NuggetEvent[]>) {
    moveItemInArray(this.nuggets, event.previousIndex, event.currentIndex);
    const reordered = this.nuggets.map((nugget, i) => ({ ...nugget, order: i + 1 }));

    this.nuggetEventsService.reorder(reordered).subscribe({
      next: () => {
        this.reload$.next();
        this.alertService.showSuccess(this.translate.instant('ADMIN.EVENTS.REORDERED_SUCCESS'));
      },
      error: (err) => this.alertService.showError(errorMessage(err)),
    });
  }

  deleteHandle(id: number) {
    this.loading = true;
    this.nuggetEventsService.delete(id).subscribe({
      next: () => {
        this.reload$.next();
        this.alertService.showSuccess(this.translate.instant('ADMIN.EVENTS.DELETED_SUCCESS'));
      },
      error: (err) => this.alertService.showError(errorMessage(err)),
    });
  }

  activateHandle(id: number) {
    this.loading = true;
    this.nuggetEventsService.activate(id).subscribe({
      next: () => {
        this.reload$.next();
        this.alertService.showSuccess(this.translate.instant('ADMIN.EVENTS.REACTIVATED_SUCCESS'));
      },
      error: (err) => this.alertService.showError(errorMessage(err)),
    });
  }
}
