<div class="form-container form-container-padding">
  <form [formGroup]="formGroup">
    <div class="inputs-wrapper">
      <mat-form-field color="primary" appearance="fill">
        <mat-label>{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.PROJECTS' | translate }}</mat-label>
        <input
          matInput
          type="text"
          placeholder="Add project"
          formControlName="name"
          [matAutocomplete]="projectAuto"
        />
        <mat-autocomplete
          #projectAuto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="selectProject($event)"
        >
          <ng-container *ngFor="let project of filteredProjects | async">
            <mat-option [value]="project">
              {{ project.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
        <mat-error *ngIf="formGroup.get('name').hasError('required')">
          {{ 'SHARED.PROJECT.NAME_REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="formGroup.get('name').hasError('requireMatch')">
          {{ 'SHARED.PROJECT.NAME_REQUIRE_MATCH' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>
          {{ 'SHARED.PROJECT.START_DATE' | translate }}
        </mat-label>
        <input
          matInput
          [matDatepicker]="startDate"
          formControlName="startDate"
          (dateInput)="getStartDate($event)"
        />
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate color="primary"></mat-datepicker>
        <mat-error *ngIf="formGroup.get('startDate').hasError('required')">
          {{ 'SHARED.PROJECT.START_DATE_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>
          {{ 'SHARED.PROJECT.END_DATE' | translate }}
        </mat-label>
        <input matInput [min]="minDate" [matDatepicker]="endDate" formControlName="endDate" />
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate color="primary"></mat-datepicker>
      </mat-form-field>
      <mat-form-field color="primary" appearance="fill">
        <mat-label> {{ 'SHARED.PROJECT.PERCENTAGE_IN_PROJECT' | translate }}</mat-label>
        <input type="number" matInput matNativeControl formControlName="percentageInProject" />
        <mat-error *ngIf="formGroup.get('percentageInProject').errors?.required">{{
          'SHARED.PROJECT.PERCENTAGE_IN_PROJECT_REQUIRED' | translate
        }}</mat-error>
        <mat-error *ngIf="formGroup.get('percentageInProject').errors?.overallPercentage">{{
          'SHARED.PROJECT.OVERALL_PERCENTAGE_IN_PROJECT_VALIDATION' | translate
        }}</mat-error>
        <mat-error *ngIf="formGroup.get('percentageInProject').errors?.max">{{
          'SHARED.PROJECT.PERCENTAGE_IN_PROJECT_VALIDATION' | translate
        }}</mat-error>
        <mat-error *ngIf="formGroup.get('percentageInProject').errors?.min">{{
          'SHARED.PROJECT.PERCENTAGE_IN_PROJECT_VALIDATION' | translate
        }}</mat-error>
      </mat-form-field>
      <div [hidden]="inEdit()">
        <button
          mat-raised-button
          color="primary"
          class="add-button"
          (click)="addRow()"
          [disabled]="formGroup.invalid"
        >
          {{ 'SHARED.ADD' | translate }}
        </button>
      </div>
      <div [hidden]="!inEdit()">
        <button
          mat-raised-button
          color="primary"
          class="add-button"
          (click)="updateRow()"
          [disabled]="formGroup.invalid"
        >
          {{ 'SHARED.UPDATE' | translate }}
        </button>
      </div>
    </div>
  </form>
  <div class="table-container">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          'SHARED.PROJECT.NAME' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.project.projectName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          'SHARED.PROJECT.START_DATE' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.project.startDate | date: 'dd/MM/yyyy' }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          'SHARED.PROJECT.END_DATE' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.project.endDate | date: 'dd/MM/yyyy' }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="percentage">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          'SHARED.PROJECT.PERCENTAGE_IN_PROJECT' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.project.percentageInProject }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef />
        <mat-cell *matCellDef="let row">
          <mat-icon class="icon" (click)="editModeOn(row)">edit</mat-icon>
          <mat-icon class="icon delete" (click)="deleteRow(row)" [class.is-hidden]="inEdit()"
            >cancel</mat-icon
          >
          <mat-icon
            class="icon"
            (click)="editModeOff()"
            [class.is-hidden]="row.index !== currentIndex"
            >refresh</mat-icon
          >
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <div class="buttons-wrapper-right margin-24-top">
    <button mat-raised-button color="primary" [disabled]="inEdit()" (click)="onSubmit()">
      {{ 'SHARED.SUBMIT' | translate }}
    </button>
  </div>
</div>
