import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';
import {
  BarChartData,
  KnowBeUserData,
  LineChartData,
} from '../shared/models/knowbe-user-data.model';
import { KnowBeService } from '../shared/services/know-be.service';
import { LoginService } from '../shared/services/login.service';

@Component({
  selector: 'app-knowbe-stats',
  templateUrl: './knowbe-stats.component.html',
  styleUrls: ['./knowbe-stats.component.scss'],
})
export class KnowbeStatsComponent {
  personalRiskScore: number;
  organizationRiskScore: number;

  phishingChartData: BarChartData[];
  userRiskScoreChartData: LineChartData[];
  organizationCurrentRiskScoreHistoryChartData: LineChartData[];
  phishingEmailsReported: number;
  phishingTotalCount: number;
  phishPronePercentage: number;

  completedTrainingsCount: number;
  trainingChartData: BarChartData[];
  trainingTotalCount: number;
  subordinates: KnowBeUserData[];
  subordinatesPastDueCount: number;

  constructor(
    private knowBeService: KnowBeService,
    private loginService: LoginService,
    private translate: TranslateService,
  ) {
    this.knowBeService
      .userData(this.loginService.getUserId())
      .pipe(
        tap((userData) => {
          this.subordinates = userData.userPhishingCampaignsData;
          this.subordinatesPastDueCount = this.subordinates.filter(
            ({ pastDueTrainingsCount }) => pastDueTrainingsCount,
          ).length;

          // map dashboard data
          this.personalRiskScore = userData.userCurrentRiskScore;
          this.organizationRiskScore = userData.organizationCurrentRiskScore;

          // map phishing results data
          this.phishingEmailsReported = userData.reportedAtCount;
          this.phishingTotalCount = userData.phishingTotalCount;
          this.phishPronePercentage = userData.userPhishPronePercentage;
          this.phishingChartData = [
            {
              color: '#9AD74C',
              count: userData.reportedAtCount,
              percent: userData.reportedAtCount / userData.phishingTotalCount,
              text: this.translate
                .instant('USER.KNOW_BE.PHISHING_CHART.REPORTED_EMAILS')
                .toString(),
            },
            {
              color: '#FAAF3B',
              count: userData.clickedAtCount,
              percent: userData.clickedAtCount / userData.phishingTotalCount,
              text: this.translate.instant('USER.KNOW_BE.PHISHING_CHART.OPENED_EMAILS').toString(),
            },
            {
              color: '#FF764C',
              count: userData.repliedAtCount,
              percent: userData.repliedAtCount / userData.phishingTotalCount,
              text: this.translate.instant('USER.KNOW_BE.PHISHING_CHART.REPLIED').toString(),
            },
            {
              color: '#EA3352',
              count: userData.attachmentOpenedAtCount,
              percent: userData.attachmentOpenedAtCount / userData.phishingTotalCount,
              text: this.translate
                .instant('USER.KNOW_BE.PHISHING_CHART.ATTACHMENT_OPENED')
                .toString(),
            },
            {
              color: '#A60841',
              count: userData.qrCodeScannedAtCount,
              percent: userData.qrCodeScannedAtCount / userData.phishingTotalCount,
              text: this.translate.instant('USER.KNOW_BE.PHISHING_CHART.CODE_SCANNED').toString(),
            },
          ];

          this.userRiskScoreChartData = userData.userCurrentRiskScoreHistory.map(
            ({ date, risk_score }) => {
              return { date: new Date(date), risk_score };
            },
          );
          this.organizationCurrentRiskScoreHistoryChartData =
            userData.organizationCurrentRiskScoreHistory.map(({ date, risk_score }) => {
              return { date: new Date(date), risk_score };
            });

          // map training assignments data
          this.completedTrainingsCount = userData.completedTrainingsCount;
          this.trainingTotalCount =
            userData.completedTrainingsCount +
            userData.inProgressTrainingsCount +
            userData.notStartedTrainingsCount +
            userData.passedTrainingsCount +
            userData.pastDueTrainingsCount;
          this.trainingChartData = [
            {
              color: '#9AD74C',
              count: userData.completedTrainingsCount,
              percent: userData.completedTrainingsCount / this.trainingTotalCount,
              text: this.translate.instant('USER.KNOW_BE.TRAINING_CHART.COMPLETED').toString(),
            },
            {
              color: '#FAAF3B',
              count: userData.notStartedTrainingsCount,
              percent: userData.notStartedTrainingsCount / this.trainingTotalCount,
              text: this.translate.instant('USER.KNOW_BE.TRAINING_CHART.NOT_STARTED').toString(),
            },
            {
              color: '#FF764C',
              count: userData.inProgressTrainingsCount,
              percent: userData.inProgressTrainingsCount / this.trainingTotalCount,
              text: this.translate.instant('USER.KNOW_BE.TRAINING_CHART.IN_PROGRESS').toString(),
            },
            {
              color: '#EA3352',
              count: userData.passedTrainingsCount,
              percent: userData.passedTrainingsCount / this.trainingTotalCount,
              text: this.translate.instant('USER.KNOW_BE.TRAINING_CHART.LONG_PASSED').toString(),
            },
            {
              color: '#A60841',
              count: userData.pastDueTrainingsCount,
              percent: userData.pastDueTrainingsCount / this.trainingTotalCount,
              text: this.translate.instant('USER.KNOW_BE.TRAINING_CHART.PAST_DUE').toString(),
            },
          ];
        }),
      )
      .subscribe();
  }
}
