import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-knowbe-phishing-result-pie',
  templateUrl: './knowbe-phishing-result-pie.component.html',
  styleUrls: ['./knowbe-phishing-result-pie.component.scss'],
})
export class KnowbePhishingResultPieComponent implements AfterViewInit {
  @Input() data: number;

  @ViewChild('chart') private chartContainer: ElementRef<HTMLDivElement>;

  constructor(private translate: TranslateService) {}

  ngAfterViewInit(): void {
    this.draw();
  }

  private draw(): void {
    const container = this.chartContainer.nativeElement;

    const width = Math.min(container.getBoundingClientRect().width, 350);
    const height = Math.min(container.getBoundingClientRect().height, 350);
    const donutWidth = 50;
    const radius = Math.min(width, height) / 2;
    const dataset = [this.data, 100 - this.data];

    const colors = d3.scaleOrdinal().range([selectColor(dataset[0]), 'gray']);

    const pie = d3.pie();

    const chartData = pie(dataset);

    const arc = d3
      .arc()
      .innerRadius(radius - donutWidth)
      .outerRadius(radius);

    const svg = d3
      .select(container)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height + 100])
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`);

    svg
      .selectAll('svg')
      .data(chartData)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (_: number, i: number) => colors(i));

    svg
      .append('text')
      .attr('dy', '.35em')
      .attr('text-anchor', 'middle')
      .attr('style', 'font-family:Manrope')
      .attr('font-size', '32px')
      .attr('font-weight', 'bold')
      .attr('fill', 'black')
      .text(`${dataset[0]}%`);

    svg
      .append('text')
      .attr('x', -150)
      .attr('y', 180)
      .text(this.translate.instant('USER.KNOW_BE.PHISH_PRONE_PERCENTAGE'))
      .style('font-size', '1rem')
      .attr('alignment-baseline', 'middle');

    svg
      .append('circle')
      .attr('cx', -170)
      .attr('cy', 178)
      .attr('r', 6)
      .style('fill', selectColor(dataset[0]));
  }
}

function selectColor(value: number): string {
  if (value <= 20) {
    return '#9AD74C';
  }
  if (value <= 40) {
    return '#F5B658';
  }
  if (value <= 60) {
    return '#F3722C';
  }
  if (value <= 80) {
    return '#EA3352';
  }
  return '#A60841';
}
