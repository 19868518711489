import { Component, OnDestroy, OnInit } from '@angular/core';
import { EmployeeService } from '../../shared/services';
import { LoginService } from '../../shared/services';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GenderEnum } from '../../shared/enums';
import { ImageCropDialogComponent } from '../../shared/dialogs';
import { MatDialog } from '@angular/material/dialog';
import { base64ToFile } from 'ngx-image-cropper';
import { AlertService, AlertType } from '../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { IEmployee } from '../../shared/models';
import { switchMap } from 'rxjs/operators';
import { Subject, takeUntil } from 'rxjs';
import { DIALOG_BUTTONS } from '../../shared/constants';
import { SkillsDialogComponent } from './employee-skills/skills-dialog.component';

@Component({
  selector: 'app-my-settings',
  templateUrl: './my-settings.component.html',
  styleUrls: ['./my-settings.component.scss'],
})
export class MySettingsComponent implements OnInit, OnDestroy {
  public currentUser: IEmployee;
  avatarUrlToShow: string;

  public allGenders: string[] = [GenderEnum.MALE, GenderEnum.FEMALE];

  public settingsForm: UntypedFormGroup;
  public requiredFirstNameFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('[а-яА-Я\\s]+'),
  ]);
  public firstNameFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.pattern('[а-яА-Я\\s]+'),
  ]);
  public requiredLastNameFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('[а-яА-Я\\s]+'),
  ]);
  public lastNameFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.pattern('[а-яА-Я\\s]+'),
  ]);
  public firstNameEnFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('[a-zA-Z\\s]+'),
  ]);
  public lastNameEnFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('[a-zA-Z\\s]+'),
  ]);
  public birthdayFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.pattern(
      '((3[01]|[12][0-9]|0?[1-9])\\.(1[012]|0?[1-9])\\.((?:19|20)\\d{2}))|((3[01]|[12][0-9]|0?[1-9])\\.(1[012]|0?[1-9]))',
    ),
  ]);
  public phoneFormControl: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

  public successfulSave: boolean = false;

  public isLoading: boolean = false;
  private destroy$ = new Subject<void>();
  readonly buttons = DIALOG_BUTTONS;

  constructor(
    private employeeService: EmployeeService,
    public loginService: LoginService,
    public dialog: MatDialog,
    private alertService: AlertService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.currentUser = this.loginService.getUser();
    this.loginService
      .getAvatarUrlToShow()
      .pipe(takeUntil(this.destroy$))
      .subscribe((url) => {
        this.avatarUrlToShow = url;
      });

    this.settingsForm = new UntypedFormGroup({
      firstName: this.currentUser.bgEmployee
        ? this.requiredFirstNameFormControl
        : this.firstNameFormControl,
      lastName: this.currentUser.bgEmployee
        ? this.requiredLastNameFormControl
        : this.lastNameFormControl,
      firstNameEn: this.firstNameEnFormControl,
      lastNameEn: this.lastNameEnFormControl,
      birthday: this.birthdayFormControl,
      phone: this.phoneFormControl,
    });
  }

  public uploadFile(file: File): void {
    this.employeeService
      .uploadImg(this.currentUser.id, file)
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.employeeService.getEmployeeById(this.loginService.getUserId())),
      )
      .subscribe(
        (user) => this.loginService.setUser(user),
        (error) => this.alertService.showAlert(error.error, AlertType.error),
      );
  }

  public openImageCropDialog(event: Event): void {
    this.dialog
      .open(ImageCropDialogComponent, {
        data: {
          title: this.translate.instant('USER.MY_SETTINGS.IMAGE_CROP').toString(),
          imageChangedEvent: event,
          sharedButtonClass: this.buttons.saveButton,
          sharedButtonText: this.translate.instant('USER.MY_SETTINGS.SAVE').toString(),
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          if (result) {
            this.uploadFile(
              new File([base64ToFile(result)], this.currentUser.id + '_photo.jpeg', {
                type: 'image/jpeg',
                lastModified: Date.now(),
              }),
            );
          }
        },
        () => {},
        () => {
          this.loginService.setAvatarUrlToShow(this.currentUser.avatarUrl);
        },
      );
  }

  public removeImage(): void {
    this.uploadFile(null);
  }

  public updateEmployee(): void {
    this.settingsForm.markAllAsTouched();
    if (this.settingsForm.valid) {
      this.employeeService
        .editEmployee(this.currentUser)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (emp) => {
            this.successfulSave = true;
            this.currentUser = emp;
            this.alertService.showAlert(
              this.translate.instant('USER.MY_SETTINGS.SUCCESSFULLY_UPDATED').toString(),
              AlertType.success,
            );
          },
          (error) => {
            this.alertService.showAlert(error.error, AlertType.error);
          },
        );
    } else {
      this.alertService.showAlert(
        this.translate.instant('USER.MY_SETTINGS.FILL_FORM').toString(),
        AlertType.error,
      );
    }
  }

  public openDialog(): void {
    this.dialog.open(SkillsDialogComponent, {
      width: '500px',
      minHeight: '350px',
      data: {
        employeeId: this.currentUser.id,
        showSubmitForm: true,
      },
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
