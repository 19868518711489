import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../shared/services';

@Component({
  selector: 'app-pending-requests-section',
  templateUrl: './pending-requests-section.component.html',
  styleUrls: ['./pending-requests-section.component.scss'],
})
export class PendingRequestsSectionComponent implements OnInit {
  isUserRoleLead: boolean;

  constructor(private loginService: LoginService) {}

  ngOnInit(): void {
    this.isUserRoleLead = this.loginService.isUserRoleLead() || this.loginService.isUserRoleAdmin();
  }
}
