import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScheduleJobService {
  private urlBase: string = 'api/scheduled-jobs';

  constructor(private httpClient: HttpClient) {}

  getAllScheduledJobs(): Observable<any> {
    return this.httpClient.get(`${this.urlBase}`);
  }

  createScheduledJob(jobName: string): Observable<string> {
    return this.httpClient.post(`${this.urlBase}/${jobName}/start`, null, {
      responseType: 'text',
    });
  }
}
