import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MaterialModule } from 'src/app/shared/material.module';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarHelper } from '../../services/helpers/calendar-helper.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-absence-datepicker',
  standalone: true,
  imports: [CommonModule, MaterialModule, MatMomentDateModule, TranslateModule],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  ],
  templateUrl: './absence-datepicker.component.html',
  styleUrls: ['./absence-datepicker.component.scss'],
})
export class AbsenceDatepickerComponent {
  @Input() days!: number;
  @Input() fromDate!: moment.Moment;
  @Input() toDate!: moment.Moment;
  @Output() datechanged = new EventEmitter<
    { date: moment.Moment; morning: boolean; afternoon: boolean }[]
  >();

  minDate = moment();

  constructor(private calendarHelper: CalendarHelper) {}

  handleDatePick(date: moment.Moment, from = true) {
    if (from) {
      this.fromDate = date;
    } else {
      this.toDate = date;
    }

    const workingDays = this.calendarHelper.calculateWorkingDays(this.fromDate, this.toDate);

    this.datechanged.emit(workingDays);
  }
}
