<div class="page-loader" *ngIf="loading">
  <mat-spinner class="spinner-reset"></mat-spinner>
</div>

<div *ngIf="!loading">
  <h2 class="section-title">
    {{ project.name }}
  </h2>

  <div
    [innerHTML]="
      project.description || 'DREAMIX.PROJECTS.PROJECT_DETAILS.NO_DESCRIPTION'
        | translate
        | safeHtml
    "
  ></div>

  <div class="manager">
    <div class="flex-columns" *ngIf="project.manager">
      <strong>{{ 'DREAMIX.PROJECTS.PROJECT_DETAILS.PARTNERSHIP_MANAGER' | translate }}</strong>
      <a [routerLink]="['/dreamix/page/employees/', project.manager.id]">
        {{ project.manager.fullName }}</a
      >
    </div>
    <div class="flex-columns" *ngIf="project.engineeringManager">
      <strong>{{ 'DREAMIX.PROJECTS.PROJECT_DETAILS.PROJECT_EM' | translate }}</strong>
      <a [routerLink]="['/dreamix/page/employees/', project.engineeringManager.id]">{{
        project.engineeringManager.fullName
      }}</a>
    </div>
    <div class="flex-columns" *ngIf="project.lead">
      <strong>{{ 'DREAMIX.PROJECTS.PROJECT_DETAILS.PROJECT_LEAD' | translate }}</strong>
      <a [routerLink]="['/dreamix/page/employees/', project.lead.id]">{{
        project.lead.fullName
      }}</a>
    </div>
  </div>

  <div *ngIf="project.employees?.length">
    <strong>{{ 'DREAMIX.PROJECTS.PROJECT_DETAILS.TEAM' | translate }}</strong>
    <mat-table [dataSource]="project.employees" class="full-width-table">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>{{
          'DREAMIX.PROJECTS.PROJECT_DETAILS.NAME' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let employee">
          <a [routerLink]="['/dreamix/page/employees/', employee.id]" class="cell-link">
            {{ employee.fullName }}
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="position">
        <mat-header-cell *matHeaderCellDef>{{
          'DREAMIX.PROJECTS.PROJECT_DETAILS.POSITION' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let employee">
          {{ employee.position }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let employee; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
