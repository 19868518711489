<div class="form-container">
  <form [formGroup]="requestForm" #reqForm="ngForm" (ngSubmit)="onSubmit(requestForm)">
    <mat-form-field color="primary" appearance="fill" class="form-item-full">
      <mat-label>{{ 'HOME.REQUEST_FORM.APPROVER_EMAIL' | translate }}</mat-label>
      <input
        matInput
        type="text"
        placeholder="approver@dreamix.eu"
        [formControl]="emailFormControl"
        [matAutocomplete]="auto"
        required
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let email of allEmailsObservable | async" [value]="email">
          {{ email }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="emailFormControl.invalid">{{ getErrorMessage('email') }}</mat-error>
    </mat-form-field>

    <div class="holiday-details align-center">
      <mat-form-field
        color="primary"
        appearance="fill"
        (click)="startPicker.open()"
        class="date-picker-inputs"
      >
        <mat-label>{{ 'HOME.REQUEST_FORM.START_DATE' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="startPicker"
          [matDatepickerFilter]="user.bgEmployee ? isWorkingDay : undefined"
          [formControl]="startDateFormControl"
          (dateChange)="onStartDateSelection()"
          readonly
          required
        />
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
        <mat-error *ngIf="startDateFormControl.invalid">{{
          getErrorMessage('startDate')
        }}</mat-error>
      </mat-form-field>

      <mat-form-field
        color="primary"
        appearance="fill"
        (click)="endPicker.open()"
        class="date-picker-inputs"
      >
        <mat-label>{{ 'HOME.REQUEST_FORM.END_DATE' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="endPicker"
          [matDatepickerFilter]="user.bgEmployee ? isWorkingDay : undefined"
          [min]="startDateFormControl.value"
          [formControl]="endDateFormControl"
          (dateChange)="onEndDateSelection()"
          readonly
          required
        />
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker [disabled]="!startDateFormControl.value"></mat-datepicker>
        <mat-error *ngIf="endDateFormControl.invalid">{{ getErrorMessage('endDate') }}</mat-error>
      </mat-form-field>

      <mat-form-field color="primary" appearance="fill" id="daysInput">
        <mat-label>{{ 'HOME.HOLIDAY_WORKING_FORM.DAYS' | translate }}</mat-label>
        <input matInput type="text" [formControl]="manualDaysFormControl" />
      </mat-form-field>
    </div>

    <div class="half-days-holder">
      <mat-checkbox
        color="primary"
        (change)="toggleVacationDetails()"
        [checked]="showVacationDetails"
        [disabled]="!endDateFormControl.value || disableVacationToggle"
      >
        {{ 'HOME.REQUEST_FORM.HALF_DAYS' | translate }}
      </mat-checkbox>
    </div>

    <div class="vacation-details" *ngIf="showVacationDetails">
      <div class="vacationDayHeader" *ngIf="workingDays.length !== 1">
        <span>&nbsp;</span>
        <mat-checkbox
          color="primary"
          [checked]="allMornings"
          (change)="updatePartialDays('morning', $event.checked)"
          >{{ 'HOME.REQUEST_FORM.MORNING' | translate }}</mat-checkbox
        >
        <mat-checkbox
          color="primary"
          [checked]="allAfternoons"
          (change)="updatePartialDays('afternoon', $event.checked)"
          >{{ 'HOME.REQUEST_FORM.AFTERNOON' | translate }}</mat-checkbox
        >
      </div>
      <div *ngFor="let day of workingDays; let i = index" class="vacationDay">
        <span>{{ day.day | date: 'dd MMMM' }}</span>
        <mat-checkbox
          color="primary"
          [checked]="workingDays[i].morning"
          (change)="updatePartialDays('morning', $event.checked, i)"
          >{{ 'HOME.REQUEST_FORM.MORNING' | translate }}</mat-checkbox
        >
        <mat-checkbox
          color="primary"
          [checked]="workingDays[i].afternoon"
          (change)="updatePartialDays('afternoon', $event.checked, i)"
          >{{ 'HOME.REQUEST_FORM.AFTERNOON' | translate }}</mat-checkbox
        >
      </div>
    </div>

    <div [ngClass]="{ 'additional-holder': showVacationDetails }">
      <mat-form-field appearance="fill" class="form-item-full">
        <mat-label>{{ 'HOME.REQUEST_FORM.REASON_FOR_ABSENCE' | translate }}</mat-label>
        <mat-select [formControl]="vacationTypeFormControl" required>
          <mat-option
            *ngFor="let type of allVacationTypes"
            [value]="type"
            (click)="closeVacationDetails()"
          >
            {{ type }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="vacationTypeFormControl.invalid">{{
          getErrorMessage('vacation')
        }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="form-item-full" *ngIf="isPaidLeave()">
        <mat-label>{{ 'HOME.REQUEST_FORM.PAID_LEAVE_TYPE' | translate }}</mat-label>
        <mat-select [formControl]="paidLeaveFormControl">
          <mat-option *ngFor="let type of allPaidLeave" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="paidLeaveFormControl.invalid">{{
          getErrorMessage('paidLeave')
        }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="form-item-full" *ngIf="isSickLeave()">
        <mat-label>{{ 'HOME.REQUEST_FORM.SICK_LEAVE_TYPE' | translate }}</mat-label>
        <mat-select [formControl]="sickLeaveFormControl">
          <mat-option
            *ngFor="let type of allSickLeaveTypes"
            [value]="type"
            (click)="closeVacationDetails()"
          >
            {{ type }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="sickLeaveFormControl.invalid">{{
          getErrorMessage('sickLeave')
        }}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="form-item-full additional-information">
        <mat-label>{{ 'HOME.REQUEST_FORM.ADDITIONAL_INFORMATION' | translate }}</mat-label>
        <textarea matInput [formControl]="additionalInfoFormControl" maxlength="255"></textarea>
        <mat-hint align="end">{{ additionalInfoFormControl.value?.length || 0 }}/255</mat-hint>
      </mat-form-field>
    </div>

    <div class="submit-button-holder">
      <button
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="disableSubmit"
        class="full-width"
      >
        {{ 'SHARED.SUBMIT' | translate }}
      </button>
    </div>
  </form>
</div>
