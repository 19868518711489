import { Component, ViewChild } from '@angular/core';
import { TravelRequestsTableComponent } from 'src/app/shared/components/travel-requests-table/travel-requests-table.component';
import { ITravelRequestPartial } from 'src/app/shared/models/travel-request.model';
import { LoginService } from 'src/app/shared/services';

@Component({
  selector: 'app-user-travel-requests',
  templateUrl: './user-travel-requests.component.html',
  styleUrls: ['./user-travel-requests.component.scss'],
})
export class UserTravelRequestsComponent {
  requestToEdit: ITravelRequestPartial;
  currentTripId: number;
  @ViewChild(TravelRequestsTableComponent)
  requestTable: TravelRequestsTableComponent;

  constructor(public loginService: LoginService) {}

  editTravelRequest(travelRequest: ITravelRequestPartial) {
    this.currentTripId = travelRequest.businessTripId;
    this.requestToEdit = travelRequest;
  }

  buttonActionHandler(event) {
    this.requestToEdit = null;
    this.currentTripId = null;
    if (event != 'cancel') {
      this.requestTable.getTravelRequests();
      this.requestTable.table.renderRows();
    }
  }
}
