import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IRequest } from '../../../shared/models/request.model';
import { RequestService } from '../../../shared/services/request.service';
import { LoginService } from '../../../shared/services/login.service';
import { DialogComponent } from '../../../shared/dialogs/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ObservablesService } from '../../../shared/services/observables.service';
import { catchError, filter, Subject, takeUntil, throwError } from 'rxjs';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DIALOG_BUTTONS } from '../../../shared/constants';
import { CompensationTypeEnum } from 'src/app/shared/enums/compensation-type.enum';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-other-requests-table',
  templateUrl: './other-requests-table.component.html',
  styleUrls: ['./other-requests-table.component.scss'],
})
export class OtherRequestsTableComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;
  public requests: IRequest[] = [];
  private destroy$ = new Subject<void>();
  readonly buttons = DIALOG_BUTTONS;
  @ViewChild('tabs', { static: false }) tabs: MatTabGroup;

  constructor(
    private requestService: RequestService,
    private dialog: MatDialog,
    public loginService: LoginService,
    private alertService: AlertService,
    public observablesService: ObservablesService,
    private datePipe: DatePipe,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.setupRequestsLoading();
  }

  private setupRequestsLoading(): void {
    this.getRequestsFromDbObservable();
    this.observablesService.requestFormSent
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getRequestsFromDbObservable());
  }

  private getRequestsFromDbObservable() {
    this.requestService
      .getPendingRequestsByApproverId(this.loginService.getUserId())
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.requests = data.body;
        this.isLoading = false;
      });
  }

  public applicableForModification(request: IRequest): boolean {
    if (request.status === 'PENDING') return true;

    const dateFrom = new Date(request.fromDate).setHours(0);
    const dateNow = new Date(Date.now()).setHours(0);

    return dateFrom > dateNow;
  }

  public openDialog = (request: IRequest, approve?: boolean) => () => {
    const requestString: string =
      request.vacationType +
      ' by ' +
      request.requester.firstNameEn +
      ' ' +
      request.requester.lastNameEn +
      ' for ' +
      this.datePipe.transform(request.fromDate, 'dd.MM.yy') +
      ' - ' +
      this.datePipe.transform(request.toDate, 'dd.MM.yy');

    const message = approve ? 'APPROVE' : 'DECLINE';

    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate
            .instant(`HOME.PENDING_REQUESTS_SECTION.${message}_CONFIRMATION`)
            .toString(),
          description:
            this.translate
              .instant(`HOME.PENDING_REQUESTS_SECTION.${message}_CONFIRMATION_DESCRIPTION`)
              .toString() +
            requestString +
            ') ?',
          sharedButtonClass:
            message === 'APPROVE' ? this.buttons.warningButton : this.buttons.deleteButton,
          sharedButtonText: this.translate
            .instant(`HOME.PENDING_REQUESTS_SECTION.${message}`)
            .toString(),
        },
      })
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        filter((result) => result),
      )
      .subscribe(() => {
        this.isLoading = true;
        this.actionRequestObservable(request.id, Boolean(approve));
      });
  };

  public applicableForDeletion(request: IRequest): boolean {
    const dateFrom = new Date(request.fromDate).setHours(0);
    const dateNow = new Date(Date.now()).setHours(0);

    return request.status !== 'DELETED' && dateFrom < dateNow;
  }

  private actionRequestObservable(id: number, approveAction: boolean) {
    return this.requestService
      .actionRequestWithoutHash(id, approveAction)
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          this.isLoading = false;
          this.alertService.showAlert(error.error, AlertType.error);
          return throwError(error);
        }),
      )
      .subscribe(() => {
        this.alertService.showAlert(
          approveAction
            ? this.translate.instant('HOME.PENDING_REQUESTS_SECTION.REQUEST_APPROVED').toString()
            : this.translate.instant('HOME.PENDING_REQUESTS_SECTION.REQUEST_DECLINED').toString(),
          AlertType.success,
        );
        if (approveAction) {
          this.observablesService.updateCalendar.next(true);
        }

        this.observablesService.requestFormSent.next(true);
        this.getRequestsFromDbObservable();
      });
  }

  public getRequestLabel(type: string) {
    return this.translate.instant('HOME.PENDING_REQUESTS_SECTION.HOLIDAY_WORKING_PENDING_REQUEST', {
      type: CompensationTypeEnum[type],
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
