import { Component, OnDestroy, ViewChild } from '@angular/core';
import { SearchService } from '../../shared/services/search.service';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ISearchItem } from '../../shared/models/search-item.model';
import { SearchTypeEnum } from '../../shared/enums/search-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnDestroy {
  public isLoading: boolean = true;
  public searchResults: Array<ISearchItem> = [];
  public filter: string;
  private destroy$ = new Subject<void>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public dataSource: MatTableDataSource<ISearchItem>;

  constructor(
    public searchService: SearchService,
    private route: ActivatedRoute,
    public translate: TranslateService,
  ) {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.filter = decodeURI(params.filter);
      // todo FIXME this is bad. subscribe method is called inside subscribe. Use switchMap instead.
      this.searchPages();
    });
  }

  private static customizeSearchItem(searchItem: ISearchItem): ISearchItem {
    if (searchItem.type === SearchTypeEnum.PAGE) {
      searchItem.path = '/dreamix/page/' + searchItem.path;
    }

    if (searchItem.type === SearchTypeEnum.EMPLOYEE) {
      searchItem.path = '/dreamix/page/employees/' + searchItem.path;
    }

    if (searchItem.type === SearchTypeEnum.PROJECT) {
      searchItem.path = '/dreamix/page/projects/' + searchItem.path;
    }
    return searchItem;
  }

  public searchPages(): void {
    this.searchService
      .getSearchedResults(this.filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.searchResults = response;
        this.searchResults.forEach((e) => SearchResultsComponent.customizeSearchItem(e));

        this.dataSource = new MatTableDataSource<ISearchItem>(this.searchResults);
        this.dataSource.paginator = this.paginator;

        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
