import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, computed, signal } from '@angular/core';
import { MaterialModule } from 'src/app/shared/material.module';
import { IEmployeeSimple } from 'src/app/shared/models';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-employee-search',
  standalone: true,
  imports: [CommonModule, MaterialModule, TranslateModule],
  templateUrl: './employee-search.component.html',
  styleUrls: ['./employee-search.component.scss'],
})
export class EmployeeSearchComponent implements OnInit {
  @Output() onselect = new EventEmitter<number>();

  employeeList = signal<IEmployeeSimple[]>([]);
  query = signal('');

  filteredEmployee = computed(() =>
    this.employeeList().filter((employee) =>
      employee.fullName.toLocaleLowerCase().includes(this.query().toLocaleLowerCase()),
    ),
  );

  constructor(
    public service: EmployeeService,
    public http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.service.getAllEmployeesSimple().subscribe((data) => this.employeeList.set(data));
  }

  select(name: string) {
    const id = this.employeeList().find((employee) => employee.fullName === name).id;

    this.onselect.emit(id);
  }
}
