<mat-spinner *ngIf="isLoading"></mat-spinner>

<div [hidden]="isLoading">
  <h2 class="section-title">
    {{ 'ADMIN.TRAVEL_REQUESTS.TITLE' | translate }}
  </h2>

  <form [formGroup]="exportForm" (ngSubmit)="onSubmit()" class="excel-form">
    <mat-form-field (click)="startDate.open()" color="accent" appearance="fill">
      <mat-label>
        {{ 'ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.FROM_DATE' | translate }}
      </mat-label>
      <input
        matInput
        [matDatepicker]="startDate"
        (dateInput)="getStartDate($event)"
        required
        formControlName="startDate"
      />
      <mat-error *ngIf="exportForm.controls.startDate.errors?.required">{{
        'USER.TRAVEL_REQUESTS.EXPORT.START_DATE' | translate
      }}</mat-error>
      <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
      <mat-datepicker #startDate color="primary"></mat-datepicker>
    </mat-form-field>
    <mat-form-field (click)="endDate.open()" color="accent" appearance="fill">
      <mat-label>
        {{ 'ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.TO_DATE' | translate }}
      </mat-label>
      <input
        matInput
        [matDatepicker]="endDate"
        [matDatepickerFilter]="endDateFilter"
        [min]="selectedStartDate"
        formControlName="endDate"
        required
      />
      <mat-error *ngIf="exportForm.controls.endDate.errors?.required">{{
        'USER.TRAVEL_REQUESTS.EXPORT.END_DATE' | translate
      }}</mat-error>
      <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
      <mat-datepicker #endDate color="primary"></mat-datepicker>
    </mat-form-field>
    <button
      mat-raised-button
      color="primary"
      class="excel-button"
      type="submit"
      [disabled]="this.exportForm.invalid"
    >
      <mat-icon>download </mat-icon>
      {{ 'ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.EXCEL' | translate }}
    </button>
  </form>

  <div class="travel-table-holder">
    <app-travel-requests-table
      #requestTable
      [userView]="false"
      [currentTripId]="currentTripId"
      (requestToEdit)="editTravelRequest($event)"
    ></app-travel-requests-table>
  </div>
</div>
