import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPosition } from '../models/position.model';

@Injectable({
  providedIn: 'root',
})
export class PositionService {
  private urlBase: string = 'api/positions';

  constructor(private httpClient: HttpClient) {}

  getAllPositions(): Observable<IPosition[]> {
    return this.httpClient.get<IPosition[]>(this.urlBase);
  }
}
