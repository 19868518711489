import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import {
  AbstractControl,
  NgForm,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, firstValueFrom, Observable, Subject, takeUntil } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DIALOG_BUTTONS } from 'src/app/shared/constants';
import { DialogComponent } from 'src/app/shared/dialogs/dialog/dialog.component';
import {
  GenderEnum,
  VacationAbsenceEnum,
  VacationSubtypeEnum,
  VacationTypeEnum,
} from 'src/app/shared/enums';
import {
  EmployeePartial,
  IAbsenceRequest,
  IEmployee,
  IHoliday,
  IRequest,
} from 'src/app/shared/models';
import {
  AlertService,
  AlertType,
  EmployeeService,
  LoginService,
  ObservablesService,
  RequestService,
  SharedDataService,
} from 'src/app/shared/services';

interface PartialDays {
  day: Date;
  morning: boolean;
  afternoon: boolean;
}

@Component({
  selector: 'app-absence-request-form',
  templateUrl: './absence-request-form.component.html',
  styleUrls: ['./absence-request-form.component.scss'],
})
export class AbsenceRequestFormComponent implements OnInit, OnDestroy {
  @ViewChild('reqForm') reqForm: NgForm;
  private destroy$ = new Subject<void>();
  listOfAbsences: IAbsenceRequest[] = [];

  // FORM ELEMENTS
  public requestForm: UntypedFormGroup;
  public emailFormControl: UntypedFormControl;
  public startDateFormControl: UntypedFormControl;
  public endDateFormControl: UntypedFormControl;
  public manualDaysFormControl: UntypedFormControl;
  public vacationTypeFormControl: UntypedFormControl;
  public additionalInfoFormControl: UntypedFormControl;
  public paidLeaveFormControl: UntypedFormControl;
  public sickLeaveFormControl: UntypedFormControl;

  // FROM BACK-END
  user: IEmployee;
  public allHolidays: Array<IHoliday>;
  public allVacationTypes: string[] = [];
  public allEmployees: Array<EmployeePartial> = [];
  public allEmails: Array<string> = [];
  public allEmailsObservable: Observable<Array<string>>;

  public showVacationDetails: boolean = false;
  public disableVacationToggle: boolean = false;
  public workingDays: Array<PartialDays> = [];
  public allMornings: boolean = true;
  public allAfternoons: boolean = true;

  public allPaidLeave: Array<string> = [
    VacationSubtypeEnum.MARRIAGE,
    VacationSubtypeEnum.BLOOD_DONATION,
    VacationSubtypeEnum.RELATIVE_DEATH,
    VacationSubtypeEnum.COURT_HEARING,
    VacationSubtypeEnum.MEMBER_OF_GOVERNMENT_MEETING,
    VacationSubtypeEnum.TRAINING_FOR_DISASTER_SITUATION,
    VacationSubtypeEnum.EMPLOYER_NOTICE_FOR_DISMISSAL,
    VacationSubtypeEnum.OTHER_PROJECT,
  ];
  public allSickLeaveTypes: Array<string> = [
    VacationSubtypeEnum.REGULAR_SICK_LEAVE,
    VacationSubtypeEnum.DREAMIX_SICK_LEAVE,
  ];
  public isLoading: boolean = true;
  disableSubmit: boolean = false;
  readonly buttons = DIALOG_BUTTONS;

  constructor(
    public loginService: LoginService,
    public observablesService: ObservablesService,
    public translate: TranslateService,
    private sharedDataService: SharedDataService,
    private dialog: MatDialog,
    private requestService: RequestService,
    private alertService: AlertService,
    private employeeService: EmployeeService,
  ) {}

  async ngOnInit() {
    this.isWorkingDay = this.isWorkingDay.bind(this);
    this.user = this.loginService.getUser();
    this.initializeFormControl();
    this.getDataFromDb();
    this.getListOfAbsences();
  }

  private getDataFromDb(): void {
    combineLatest([
      this.sharedDataService.holidaysState$,
      this.employeeService.getPartialEmployees(),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([holidays, employees]) => {
        this.allHolidays = holidays;
        this.allEmployees = employees;
        this.allEmails = employees.map((emp) => emp.email).sort((a, b) => a.localeCompare(b));

        this.allEmailsObservable = this.emailFormControl.valueChanges.pipe(
          startWith(''),
          map((input) =>
            this.allEmails.filter(
              (email) => !input || email.toLowerCase().includes(input.toLowerCase()),
            ),
          ),
        );
        this.isLoading = !(holidays.length > 0 && employees.length > 0);
      });
  }

  private getListOfAbsences() {
    this.requestService
      .getListOfAbsences()
      .pipe(takeUntil(this.destroy$))
      .subscribe((list) => {
        this.listOfAbsences = list.filter(
          (absence) =>
            !(
              (absence.vacationType === VacationAbsenceEnum.VACATION ||
                absence.vacationType === VacationAbsenceEnum.WORK_ON_HOLIDAYS) &&
              absence.totalDays - absence.usedDays === 0
            ),
        );

        const types = this.listOfAbsences.map((item) => VacationTypeEnum[item.vacationType]);

        this.allVacationTypes = this.user.contractor
          ? [VacationTypeEnum.LEAVE_OF_ABSENCE]
          : [...types];
      });
  }

  private initializeFormControl(): void {
    this.emailFormControl = new UntypedFormControl('', [
      Validators.required,
      Validators.email,
      this.dreamixEmailValidator(),
    ]);
    this.startDateFormControl = new UntypedFormControl('', Validators.required);
    this.endDateFormControl = new UntypedFormControl('', Validators.required);
    this.manualDaysFormControl = new UntypedFormControl('', [
      Validators.required,
      Validators.min(0.5),
    ]);
    this.manualDaysFormControl.disable();
    this.vacationTypeFormControl = new UntypedFormControl('', Validators.required);
    this.additionalInfoFormControl = new UntypedFormControl('');
    this.paidLeaveFormControl = new UntypedFormControl('');
    this.sickLeaveFormControl = new UntypedFormControl('');

    this.requestForm = new UntypedFormGroup({
      emailFormControl: this.emailFormControl,
      startDateFormControl: this.startDateFormControl,
      endDateFormControl: this.endDateFormControl,
      vacationTypeFormControl: this.vacationTypeFormControl,
      additionalInfoFormControl: this.additionalInfoFormControl,
      manualDaysFormControl: this.manualDaysFormControl,
      paidLeaveFormControl: this.paidLeaveFormControl,
      sickLeaveFormControl: this.sickLeaveFormControl,
    });
  }

  private dreamixEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const correctManagerEmail =
        control.value === '' ? false : this.allEmails.includes(this.emailFormControl.value);
      return !correctManagerEmail ? { correct: { value: control.value } } : null;
    };
  }

  public isWorkingDay(date: Date | null): boolean {
    if (!date) {
      return false;
    }
    const isWeekend: boolean = date.getDay() === 0 || date.getDay() === 6;
    const isHoliday: boolean = this.allHolidays.some(
      (h) => new Date(h.date).toLocaleDateString() === date.toLocaleDateString(),
    );
    return !isWeekend && !isHoliday;
  }

  public onStartDateSelection(): void {
    this.endDateFormControl.reset();
    this.workingDays = [];
    this.manualDaysFormControl.setValue(0);
  }

  public onEndDateSelection(): void {
    const start: Date = this.startDateFormControl.value;
    const end: Date = this.endDateFormControl.value;
    let workingDaysInRange = 0;

    this.workingDays = [];
    const iterableDate = new Date(start);
    while (iterableDate <= end) {
      if (this.isWorkingDay(iterableDate)) {
        this.workingDays.push({
          day: new Date(iterableDate),
          morning: true,
          afternoon: true,
        });
        workingDaysInRange++;
      }
      iterableDate.setDate(iterableDate.getDate() + 1);
    }

    this.manualDaysFormControl.setValue(workingDaysInRange);
  }

  public toggleVacationDetails(): void {
    this.showVacationDetails = !this.showVacationDetails;
    if (!this.showVacationDetails) {
      this.workingDays.forEach((e) => {
        e.afternoon = true;
        e.morning = true;
      });
      this.manualDaysFormControl.setValue(this.calculateVacationDays());
    }
  }

  public closeVacationDetails(): void {
    if (
      this.vacationTypeFormControl.value === VacationTypeEnum.MATERNITY_PATERNITY ||
      (this.vacationTypeFormControl.value === VacationTypeEnum.LEAVE_OF_ABSENCE &&
        !this.user.contractor) ||
      (this.vacationTypeFormControl.value === VacationTypeEnum.SICK_LEAVE &&
        this.sickLeaveFormControl.value === VacationSubtypeEnum.REGULAR_SICK_LEAVE)
    ) {
      this.updatePartialDays('morning', true);
      this.updatePartialDays('afternoon', true);
      this.showVacationDetails = false;
      this.disableVacationToggle = true;
    } else {
      this.disableVacationToggle = false;
    }
  }

  public updatePartialDays(field: string, checked: boolean, index?: number): void {
    if (index !== undefined) {
      this.workingDays[index][field] = checked;
      this.allMornings = !this.workingDays.some((e) => !e.morning);
      this.allAfternoons = !this.workingDays.some((e) => !e.afternoon);
    } else {
      if (field === 'morning') {
        this.allMornings = checked;
      } else {
        this.allAfternoons = checked;
      }
      this.workingDays.forEach((e) => (e[field] = checked));
    }

    this.manualDaysFormControl.setValue(this.calculateVacationDays());
  }

  private calculateVacationDays(): number {
    let workingHalves = 0;
    this.workingDays.forEach((e) => {
      if (e.morning) workingHalves++;
      if (e.afternoon) workingHalves++;
    });
    return workingHalves / 2;
  }

  public getErrorMessage(formName: string): string {
    switch (formName) {
      case 'email': {
        if (this.emailFormControl.hasError('required'))
          return this.translate.instant('HOME.REQUEST_FORM.EMAIL_REQUIRED').toString();
        if (this.emailFormControl.hasError('email'))
          return this.translate.instant('HOME.REQUEST_FORM.INVALID_EMAIL').toString();
        if (!this.allEmails.includes(this.emailFormControl.value))
          return this.translate.instant('HOME.REQUEST_FORM.INVALID_DREAMIX_EMAIL').toString();
        break;
      }

      case 'startDate': {
        if (this.startDateFormControl.hasError('required'))
          return this.translate.instant('HOME.REQUEST_FORM.START_DATE_REQUIRED').toString();
        break;
      }

      case 'endDate': {
        if (this.startDateFormControl.hasError('required'))
          return this.translate.instant('HOME.REQUEST_FORM.END_DATE_REQUIRED').toString();
        break;
      }

      case 'vacation': {
        if (this.vacationTypeFormControl.hasError('required'))
          return this.translate.instant('HOME.REQUEST_FORM.REASON_FOR_ABSENCE_REQUIRED').toString();
        break;
      }

      case 'paidLeave': {
        if (this.paidLeaveFormControl.hasError('required'))
          return this.translate.instant('HOME.REQUEST_FORM.PAID_LEAVE_REQUIRED').toString();
        break;
      }

      case 'sickLeave': {
        if (this.sickLeaveFormControl.hasError('required'))
          return this.translate.instant('HOME.REQUEST_FORM.SICK_LEAVE_REQUIRED').toString();
        break;
      }
    }
  }

  public onSubmit(form: UntypedFormGroup): void {
    if (form.valid && !this.isInvalidCase()) {
      if (this.isPastDate()) {
        this.dialog
          .open(DialogComponent, {
            data: {
              title: this.translate.instant('HOME.REQUEST_FORM.WARNING').toString(),
              description: this.translate
                .instant('HOME.REQUEST_FORM.WARNING_DESCRIPTION')
                .toString(),
              sharedButtonClass: this.buttons.warningButton,
              sharedButtonText: this.translate.instant('HOME.REQUEST_FORM.CONTINUE').toString(),
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) this.sendForm();
          });
      } else if (!this.validBeforePeriod()) {
        this.dialog
          .open(DialogComponent, {
            data: {
              title: this.translate.instant('HOME.REQUEST_FORM.WARNING').toString(),
              description: this.translate
                .instant('HOME.REQUEST_FORM.WARNING_DESCRIPTION_FOR_ABSENCE_DAYS')
                .toString(),
              sharedButtonClass: this.buttons.warningButton,
              sharedButtonText: this.translate.instant('HOME.REQUEST_FORM.CONTINUE').toString(),
            },
          })
          .afterClosed()
          .pipe(takeUntil(this.destroy$))
          .subscribe((result) => {
            if (result) this.sendForm();
          });
      } else {
        this.sendForm();
        // If the request was a Dreamix Sick Leave, show reminder alert
        if (
          this.sickLeaveFormControl.value &&
          this.sickLeaveFormControl.value === VacationSubtypeEnum.DREAMIX_SICK_LEAVE
        ) {
          this.dialog.open(DialogComponent, {
            data: {
              title: this.translate.instant('HOME.REQUEST_FORM.DMX_SICK_DAYS_SUCCESS_DIALOG.TITLE'),
              description: this.translate.instant(
                'HOME.REQUEST_FORM.DMX_SICK_DAYS_SUCCESS_DIALOG.DESCRIPTION',
              ),
              sharedButtonText: 'OK',
              showCancelButton: false,
            },
          });
        }
      }
    }
  }

  private isInvalidCase(): boolean {
    if (this.isIncorrectMaternityPaternity()) {
      return true;
    } else if (this.isIncorrectSickLeave()) {
      this.alertService.showAlert(
        this.translate.instant('HOME.REQUEST_FORM.DREAMIX_SICK_LEAVE').toString(),
        AlertType.error,
      );
      return true;
    } else if (this.isExceedingPeriod()) {
      this.alertService.showAlert(
        this.translate.instant('HOME.REQUEST_FORM.REQUEST_EXCEEDS_ALLOWED_LENGTH').toString(),
        AlertType.error,
      );
      return true;
    } else if (this.hasEmptyDays()) {
      this.alertService.showAlert(
        this.translate.instant('HOME.REQUEST_FORM.NOT_MARKED_MORNING_OR_AFTERNOON').toString(),
        AlertType.error,
      );
      return true;
    } else if (this.isHalfDayRegularSickLeave()) {
      this.alertService.showAlert(
        this.translate.instant('HOME.REQUEST_FORM.REGULAR_SICK_LEAVE').toString(),
        AlertType.error,
      );
      return true;
    } else {
      return false;
    }
  }

  private isIncorrectSickLeave(): boolean {
    return (
      this.vacationTypeFormControl.value === VacationTypeEnum.SICK_LEAVE &&
      this.sickLeaveFormControl.value === VacationSubtypeEnum.DREAMIX_SICK_LEAVE &&
      this.manualDaysFormControl.value > 2
    );
  }

  private isHalfDayRegularSickLeave(): boolean {
    return (
      this.vacationTypeFormControl.value === VacationTypeEnum.SICK_LEAVE &&
      this.sickLeaveFormControl.value === VacationSubtypeEnum.REGULAR_SICK_LEAVE &&
      !Number.isInteger(this.manualDaysFormControl.value)
    );
  }

  private isExceedingPeriod(): boolean {
    return this.getPattern().length > 1000;
  }

  private hasEmptyDays(): boolean {
    return this.getPattern().indexOf('0') >= 0;
  }

  private isIncorrectMaternityPaternity(): boolean {
    const calendarPeriod =
      (this.endDateFormControl.value.getTime() - this.startDateFormControl.value.getTime()) /
      (1000 * 3600 * 24);

    if (
      this.vacationTypeFormControl.value === VacationTypeEnum.MATERNITY_PATERNITY &&
      this.user.gender === GenderEnum.MALE &&
      calendarPeriod > 15
    ) {
      this.alertService.showAlert(
        this.translate.instant('HOME.REQUEST_FORM.PATERNITY_LEAVE').toString(),
        AlertType.error,
      );
      return true;
    }

    if (
      this.vacationTypeFormControl.value === VacationTypeEnum.MATERNITY_PATERNITY &&
      this.user.gender === GenderEnum.FEMALE &&
      calendarPeriod > 730
    ) {
      this.alertService.showAlert(
        this.translate.instant('HOME.REQUEST_FORM.MATERNITY_LEAVE').toString(),
        AlertType.error,
      );
      return true;
    }

    return false;
  }

  private validBeforePeriod(): boolean {
    const currentDate = new Date();

    const durationDuringRequest = this.manualDaysFormControl.value;
    const durationBeforeRequest = Math.ceil(
      (this.startDateFormControl.value.getTime() - currentDate.getTime()) / 86400000,
    );
    return (
      durationBeforeRequest / durationDuringRequest >= 2 ||
      this.vacationTypeFormControl.value === VacationTypeEnum.SICK_LEAVE
    );
  }

  private isPastDate(): boolean {
    return (
      formatDate(new Date(), 'yyyyMMdd', 'en_US') >
      formatDate(new Date(this.startDateFormControl.value), 'yyyyMMdd', 'en_US')
    );
  }

  private async sendForm(): Promise<void> {
    const fromDateStruct = new Date(
      this.startDateFormControl.value.getFullYear(),
      this.startDateFormControl.value.getMonth(),
      this.startDateFormControl.value.getDate(),
    );
    const toDateStruct = new Date(
      this.endDateFormControl.value.getFullYear(),
      this.endDateFormControl.value.getMonth(),
      this.endDateFormControl.value.getDate(),
    );

    const approver = this.allEmployees.filter((e) => e.email === this.emailFormControl.value).pop();
    const approverFullModel = await firstValueFrom(
      this.employeeService.getEmployeeById(approver.id),
    );
    const payload: IRequest = {
      requester: this.user,
      approver: approverFullModel,
      fromDate: formatDate(fromDateStruct, 'yyyy-MM-dd', 'en-US'),
      toDate: formatDate(toDateStruct, 'yyyy-MM-dd', 'en-US'),
      days: this.manualDaysFormControl.value,
      vacationType: this.vacationTypeFormControl.value,
      pattern: this.getPattern(),
      additionalInfo: this.additionalInfoFormControl.value,
      subType: this.paidLeaveFormControl.value
        ? this.paidLeaveFormControl.value
        : this.sickLeaveFormControl.value,
    };

    this.disableSubmit = true;

    this.requestService
      .createRequest(payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.observablesService.requestFormSent.next(true);

          this.alertService.showAlert(
            this.translate.instant('HOME.REQUEST_FORM.SUCCESSFULLY_SENT').toString(),
            AlertType.success,
          );
          this.resetForm();
        },
        (error) => {
          if (error.error) {
            error.error.error
              ? this.alertService.showAlert(error.error.error, AlertType.error)
              : this.alertService.showAlert(error.error, AlertType.error);
          } else {
            this.alertService.showAlert(error.message, AlertType.error);
          }
          this.disableSubmit = false;
        },
        () => (this.disableSubmit = false),
      );
  }

  public isPaidLeave(): boolean {
    if (this.vacationTypeFormControl.value === VacationTypeEnum.OTHER_PAID_LEAVE) {
      this.requestForm.get('paidLeaveFormControl').setValidators(Validators.required);
      this.requestForm.get('paidLeaveFormControl').updateValueAndValidity();
      return true;
    } else {
      this.requestForm.get('paidLeaveFormControl').clearValidators();
      this.requestForm.get('paidLeaveFormControl').setValue(null);
      this.requestForm.get('paidLeaveFormControl').updateValueAndValidity();
      return false;
    }
  }

  private getPattern(): string {
    let result: string = '';
    this.workingDays.forEach((e) => {
      let status: number = 0;
      status += e.morning ? 1 : 0;
      status += e.afternoon ? 2 : 0;
      result += '' + status;
    });
    return result;
  }

  private resetForm(): void {
    this.showVacationDetails = false;
    this.workingDays = [];
    this.reqForm.resetForm();
  }

  public isSickLeave(): boolean {
    if (this.vacationTypeFormControl.value === VacationTypeEnum.SICK_LEAVE) {
      this.requestForm.get('sickLeaveFormControl').setValidators(Validators.required);
      this.requestForm.get('sickLeaveFormControl').updateValueAndValidity();
      return true;
    } else {
      this.requestForm.get('sickLeaveFormControl').clearValidators();
      this.requestForm.get('sickLeaveFormControl').setValue(null);
      this.requestForm.get('sickLeaveFormControl').updateValueAndValidity();
      return false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
