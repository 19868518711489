<table *ngIf="requests.length" class="othersRequests">
  <app-requests-table-row
    *ngFor="let request of requests"
    [title]="request.requester.firstNameEn + ' ' + request.requester.lastNameEn"
    [subTitle]="request.vacationType"
    [link]="'/requests/' + request.id"
    [fromDate]="request.fromDate"
    [toDate]="request.toDate"
    [deleteAction]="openDialog(request)"
    [approveAction]="openDialog(request, true)"
    [loading]="isLoading"
  />
</table>
<div *ngIf="requests.length === 0">
  {{ 'HOME.PENDING_REQUESTS_SECTION.NO_PENDING_REQUESTS_FOR_RESOLUTION' | translate }}
</div>
