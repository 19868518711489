import * as moment from 'moment';
import { CommonModule } from '@angular/common';
import { Component, Inject, computed, signal } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaterialModule } from 'src/app/shared/material.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { AbsenceDatepickerComponent } from 'src/app/shared/components/absence-datepicker/absence-datepicker.component';
import { AbsenceHalfDaysSelectComponent } from 'src/app/shared/components/absence-half-days-select/absence-half-days-select.component';
import {
  VACATION_TYPES,
  VACATION_PAID_TYPES,
  VACATION_SICK_TYPES,
  VacationType,
  VacationSubType,
} from 'src/app/shared/constants';
import { EmployeeLeavesService } from 'src/app/user/employee-leaves/services/employee-leaves.service';
import { EmployeeLeave } from 'src/app/user/employee-leaves/services/employee-leaves.types';
import { TranslateModule } from '@ngx-translate/core';
import { reduceVacationDaysToNumber } from 'src/app/shared/utils';
import { CalendarHelper } from 'src/app/shared/services/helpers/calendar-helper.service';

@Component({
  selector: 'app-employees-edit-modal-item',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    MatMomentDateModule,
    AbsenceHalfDaysSelectComponent,
    AbsenceDatepickerComponent,
    TranslateModule,
  ],
  templateUrl: './employee-leaves-edit-modal.component.html',
  styleUrls: ['./employee-leaves-edit-modal.component.scss'],
})
export class EmployeeLeavesEditModalComponent {
  vacationTypes = VACATION_TYPES;
  vacationSicktypes = VACATION_SICK_TYPES;
  vacationPaidtypes = VACATION_PAID_TYPES;

  vacationType = signal<VacationType>(null);
  vacationSubtype = signal<VacationSubType>(null);
  info = signal('');

  vacationDays = signal<{ date: moment.Moment; morning: boolean; afternoon: boolean }[]>(
    this.calendarHelper.calculateWorkingDays(
      this.data.fromDate,
      this.data.toDate,
      this.data.pattern,
    ),
  );

  totalDays = computed(() => reduceVacationDaysToNumber(this.vacationDays()));

  hasVacationSubTypes = computed(() =>
    ['OTHER_PAID_LEAVE', 'SICK_LEAVE'].includes(this.vacationType()),
  );

  constructor(
    public service: EmployeeLeavesService,
    public ref: MatDialogRef<EmployeeLeavesEditModalComponent>,
    public snackbar: MatSnackBar,
    private calendarHelper: CalendarHelper,
    @Inject(MAT_DIALOG_DATA) public data: EmployeeLeave,
  ) {
    this.vacationType.set(data.vacationType);
    this.vacationSubtype.set(data.vacationSubtype);
  }

  updateVacationType() {
    this.service
      .updateVacationType(
        this.data,
        this.vacationType(),
        this.hasVacationSubTypes() ? this.vacationSubtype() : null,
        this.info(),
      )
      .subscribe({
        next: () => {
          this.ref.close();
          this.snackbar.open('Successfully updated', null, {
            duration: 3000,
            panelClass: ['success-snackbar'],
          });
        },
        error: (err) => {
          this.snackbar.open(err.error || 'There was an error', null, {
            duration: 3000,
            panelClass: ['error-snackbar'],
          });
        },
      });
  }

  updatePeriod() {
    this.service.updatePeriod(this.data, this.vacationDays()).subscribe({
      next: () => {
        this.ref.close();
        this.snackbar.open('Successfully updated', null, {
          duration: 3000,
          panelClass: ['success-snackbar'],
        });
      },
      error: (err) => {
        this.snackbar.open(err.error || 'There was an error', null, {
          duration: 3000,
          panelClass: ['error-snackbar'],
        });
      },
    });
  }
}
