import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import * as moment from 'moment';
import { map, tap } from 'rxjs';
import { VacationType, VacationSubType } from 'src/app/shared/constants';
import { EmployeeLeave } from 'src/app/user/employee-leaves/services/employee-leaves.types';
import { reduceVacationDaysToNumber } from 'src/app/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class EmployeeLeavesService {
  leaves = signal<EmployeeLeave[]>([]);

  constructor(private http: HttpClient) {}

  load(filters: { leaveType: string; count: string; id?: number }) {
    if (!filters.id) return;

    return this.http
      .get<EmployeeLeave[]>(
        `/api/edit-request/list/${filters.id}?type=${filters.leaveType}&count=${filters.count}`,
      )
      .subscribe({
        next: (result) => {
          this.leaves.set(
            result.map((leave) => {
              leave.toDate = moment(leave.toDate, 'YYYY-MM-DD');
              leave.fromDate = moment(leave.fromDate, 'YYYY-MM-DD');

              return leave;
            }),
          );
        },
        error: (err) => console.log(err),
      });
  }

  decline(item: EmployeeLeave) {
    return this.http.patch(`/api/edit-request/decline`, item).subscribe({
      next: () => this.leaves.update((leaves) => leaves.filter((leave) => leave.id !== item.id)),
      error: (err) => console.log(err),
    });
  }

  updateVacationType(
    item: EmployeeLeave,
    type: VacationType,
    subtype?: VacationSubType,
    info?: string,
  ) {
    const searchParams = new URLSearchParams();

    if (item.vacationType) searchParams.set('vacationType', item.vacationType);
    if (item.vacationSubtype) searchParams.set('vacationSubtype', item.vacationSubtype);

    const dto = { ...item } satisfies EmployeeLeave;

    if (info) dto.additionalInfo = info;
    if (type) dto.vacationType = type;
    if (subtype) dto.vacationSubtype = subtype;

    return this.http
      .patch<EmployeeLeave>(`/api/edit-request/type/vacation?${searchParams}`, dto)
      .pipe(
        map((updatedLeave) => {
          updatedLeave.fromDate = moment(updatedLeave.fromDate, 'YYYY-MM-DD');
          updatedLeave.toDate = moment(updatedLeave.toDate, 'YYYY-MM-DD');

          return updatedLeave;
        }),
        tap((updatedLeave) => {
          this.leaves.update((leaves) =>
            leaves.map((leave) => (leave.id === updatedLeave.id ? updatedLeave : leave)),
          );
        }),
      );
  }

  updatePeriod(
    item: EmployeeLeave,
    vacationDays: { date: moment.Moment; morning: boolean; afternoon: boolean }[],
  ) {
    const dto = {
      ...item,
      days: reduceVacationDaysToNumber(vacationDays),
      fromDate: vacationDays[0].date.format('YYYY-MM-DD') as any,
      toDate: vacationDays[vacationDays.length - 1].date.format('YYYY-MM-DD') as any,
      pattern: vacationDays
        .map((day) => {
          if (day.morning && day.afternoon) return '3';
          if (day.morning) return '1';
          if (day.afternoon) return '2';

          return '0';
        })
        .join(''),
    } satisfies EmployeeLeave;

    return this.http.patch<EmployeeLeave>('/api/edit-request/period', dto).pipe(
      map((updatedLeave) => {
        updatedLeave.fromDate = moment(updatedLeave.fromDate, 'YYYY-MM-DD');
        updatedLeave.toDate = moment(updatedLeave.toDate, 'YYYY-MM-DD');

        return updatedLeave;
      }),
      tap((updatedLeave) => {
        this.leaves.update((leaves) =>
          leaves.map((leave) => (leave.id === updatedLeave.id ? updatedLeave : leave)),
        );
      }),
    );
  }
}
