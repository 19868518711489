<mat-tab-group>
  <mat-tab [label]="'USER.KNOW_BE.DASHBOARD' | translate">
    <app-knowbe-dashboard
      [phishPronePercentage]="phishPronePercentage"
      [personalRiskScore]="personalRiskScore"
      [organizationRiskScore]="organizationRiskScore"
      [userRiskScoreChartData]="userRiskScoreChartData"
      [organizationCurrentRiskScoreHistoryChartData]="organizationCurrentRiskScoreHistoryChartData"
    ></app-knowbe-dashboard>
  </mat-tab>

  <mat-tab [label]="'USER.KNOW_BE.PHISHING_RESULTS' | translate">
    <ng-template matTabContent>
      <div class="summary">
        <div>
          {{ 'USER.KNOW_BE.PHISHING_EMAILS_RECEIVED' | translate }}
          <strong>{{ phishingTotalCount }}</strong>
        </div>
        <div>
          {{ 'USER.KNOW_BE.PHISHING_EMAILS_REPORTED' | translate }}
          <strong>{{ phishingEmailsReported }}</strong>
        </div>
        <div>
          {{ 'USER.KNOW_BE.PHISH_PRONE_PERCENTAGE' | translate }}
          <strong>{{ phishPronePercentage }}%</strong>
        </div>
      </div>
      <app-knowbe-bar-chart
        *ngIf="phishingChartData"
        [chartData]="phishingChartData"
        [totalCount]="phishingTotalCount"
      ></app-knowbe-bar-chart>
    </ng-template>
  </mat-tab>

  <mat-tab [label]="'USER.KNOW_BE.TRAINING_ASSIGNMENTS' | translate">
    <ng-template matTabContent>
      <div class="summary">
        <div>
          {{ 'USER.KNOW_BE.TOTAL_ASSIGNMENTS' | translate }}
          <strong>{{ trainingTotalCount }}</strong>
        </div>
        <div>
          {{ 'USER.KNOW_BE.TOTAL_ASSIGNMENTS_COMPLETED' | translate }}
          <strong>{{ completedTrainingsCount }}</strong>
        </div>
      </div>
      <app-knowbe-bar-chart
        *ngIf="trainingChartData"
        [chartData]="trainingChartData"
        [totalCount]="trainingTotalCount"
      ></app-knowbe-bar-chart>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="subordinates?.length">
    <ng-template mat-tab-label>
      <span>My Team Risk Score</span>
      <span class="past-due-count" matTooltip="Past dues" *ngIf="subordinatesPastDueCount">{{
        subordinatesPastDueCount
      }}</span>
    </ng-template>
    <app-knowbe-subordinates-list [subordinates]="subordinates"></app-knowbe-subordinates-list>
  </mat-tab>
</mat-tab-group>
