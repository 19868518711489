export const MAX_ZOOM = 1.5;
export const MIN_ZOOM = 0.6;

export const DEFAULT_ZOOM = 1;

export const DEFAULT_X = 0;
export const DEFAULT_Y = 0;

//Get this from single source of truth (Env?)
export const CEO_ID = 57;
export const DEFAULT_PHOTO = "https://dms.dreamix.eu/img/defaultAvatar.png"