<table *ngIf="myRequests.length || myWorkingOnHolidaysRequests.length" class="myRequests">
  <app-requests-table-row
    *ngFor="let request of myRequests"
    [title]="request.vacationType"
    [link]="'/requests/' + request.id"
    [fromDate]="request.fromDate"
    [toDate]="request.toDate"
    [deleteAction]="openDialog(request)"
    [loading]="isLoading"
  />
  <app-requests-table-row
    *ngFor="let request of myWorkingOnHolidaysRequests"
    [title]="getRequestLabel(request.compensationType)"
    [fromDate]="request.date"
    [deleteAction]="openDialog(request, true)"
    [loading]="isLoading"
  />
</table>
<div *ngIf="myRequests.length === 0 && myWorkingOnHolidaysRequests.length === 0">
  {{ 'HOME.PENDING_REQUESTS_SECTION.NO_PENDING_REQUESTS' | translate }}
</div>
