<div class="form-container">
  <form
    [formGroup]="requestForm"
    #reqForm="ngForm"
    *ngIf="!isLoading"
    (ngSubmit)="onSubmit(requestForm)"
    id="holiday-working-form"
  >
    <div>
      <div class="holiday-details align-center">
        <button
          class="new-menu-holder"
          mat-button
          [matMenuTriggerFor]="appMenu"
          (click)="$event.preventDefault()"
        >
          {{ 'HOME.HOLIDAY_WORKING_FORM.SELECT_HOLIDAYS' | translate }}
        </button>
        <mat-menu #appMenu="matMenu" class="drop-calendar align-center">
          <div (click)="$event.stopPropagation()">
            <mat-calendar
              [dateClass]="dateClass"
              #holidayPicker
              appMultiselect
              [handleSelect]="handleSelect"
              [dateFilter]="dateFilter"
              [selectedDates]="selectedDates"
            ></mat-calendar>
          </div>
        </mat-menu>

        <mat-form-field appearance="fill" color="primary" id="daysInput">
          <mat-label>
            {{ 'HOME.HOLIDAY_WORKING_FORM.DAYS' | translate }}
          </mat-label>
          <input matInput type="text" [value]="days" disabled required />
        </mat-form-field>
      </div>

      <div class="vacation-details" *ngIf="selectedDates.length">
        <div class="vacationDayHeader" *ngIf="selectedDates.length !== 1">
          <span>&nbsp;</span>
          <mat-checkbox
            color="primary"
            [checked]="multiTypes.morning"
            (change)="updatePartialDays('morning', $event.checked)"
            >{{ 'HOME.REQUEST_FORM.MORNING' | translate }}</mat-checkbox
          >
          <mat-checkbox
            color="primary"
            [checked]="multiTypes.afternoon"
            (change)="updatePartialDays('afternoon', $event.checked)"
            >{{ 'HOME.REQUEST_FORM.AFTERNOON' | translate }}</mat-checkbox
          >
        </div>
        <div *ngFor="let day of selectedDates; let i = index" class="vacationDay">
          <span>{{ day | date: 'dd MMMM' }}</span>
          <mat-checkbox
            color="primary"
            [checked]="requestDetails.dates[day].types.morning"
            (change)="updatePartialDays('morning', $event.checked, day)"
            >{{ 'HOME.REQUEST_FORM.MORNING' | translate }}</mat-checkbox
          >
          <mat-checkbox
            color="primary"
            [checked]="requestDetails.dates[day].types.afternoon"
            (change)="updatePartialDays('afternoon', $event.checked, day)"
            >{{ 'HOME.REQUEST_FORM.AFTERNOON' | translate }}</mat-checkbox
          >
        </div>
      </div>
      <p class="radio-title">
        {{ 'HOME.HOLIDAY_WORKING_FORM.SELECT_PREFERENCE' | translate }}
      </p>
      <mat-radio-group aria-label="Select an option">
        <div class="radio-group align-center">
          <mat-radio-button
            color="primary"
            value="PAID_LEAVE"
            (change)="handleRadioCheck($event.value)"
            >{{ 'HOME.HOLIDAY_WORKING_FORM.DAYS_OFF' | translate }}</mat-radio-button
          >
          <mat-radio-button
            color="primary"
            value="PAYMENT"
            (change)="handleRadioCheck($event.value)"
            >{{ 'HOME.HOLIDAY_WORKING_FORM.PAYMENT' | translate }}</mat-radio-button
          >
        </div>
      </mat-radio-group>
    </div>

    <div class="submit-button-holder">
      <button
        type="submit"
        color="primary"
        class="form-item-full"
        [disabled]="!selectedDates.length || !days || !requestDetails.compensationType"
        mat-raised-button
      >
        {{ 'SHARED.SUBMIT' | translate }}
      </button>
    </div>
  </form>
</div>
