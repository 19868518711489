<mat-card>
      <mat-card-header>
        <app-employee-leaves-filter
          [filters]="filters()"
          (onchange)="updateFilter($event)"
        ></app-employee-leaves-filter>
      </mat-card-header>
      <mat-card-content>
        <app-employee-leaves-list [leaves]="service.leaves()"></app-employee-leaves-list>
      </mat-card-content>
    </mat-card>
  