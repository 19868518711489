import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { IRequest } from '../../shared/models/request.model';
import { LoginService } from '../../shared/services/login.service';
import { RequestService } from '../../shared/services/request.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/dialogs/dialog/dialog.component';
import { RequestStatusEnum } from '../../shared/enums/request-status.enum';
import { VacationTypeEnum } from '../../shared/enums/vacation-type.enum';
import { VacationSubtypeEnum } from '../../shared/enums/vacation-subtype.enum';
import { RequestsHelperService } from '../../shared/services/helpers/RequestsHelper.service';
import { AlertService, AlertType } from '../../shared/services/alert.service';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { IPagination } from '../../shared/models/pagination.model';
import { PageableHelperService } from '../../shared/services/helpers/pageable-helper.service';
import { IPageable } from '../../shared/models/pageable.model';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import {
  DeleteFileEvent,
  ImageListDialogComponent,
} from '../../shared/dialogs/image-list-dialog/image-list-dialog.component';
import { Subject, takeUntil } from 'rxjs';
import { DIALOG_BUTTONS } from '../../shared/constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-my-requests',
  templateUrl: './my-requests.component.html',
  styleUrls: ['./my-requests.component.scss'],
})
export class MyRequestsComponent implements OnInit, OnDestroy {
  public yearFormControl: UntypedFormControl;
  public allYears: number[];
  public currentYear: number;
  public tableConfig: IPagination<IRequest>;
  private destroy$ = new Subject<void>();

  @ViewChild('file') fileUpload: ElementRef;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public pageable: IPageable;
  public isLoading: boolean = false;
  public sortByColumns: string[];
  readonly buttons = DIALOG_BUTTONS;

  constructor(
    public loginService: LoginService,
    private requestService: RequestService,
    private dialog: MatDialog,
    private helper: RequestsHelperService,
    private alertService: AlertService,
    private pageableHelper: PageableHelperService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.yearFormControl = new UntypedFormControl(this.currentYear);
    this.allYears = new Array(5).fill(1).map((_, index) => this.currentYear - index);
    this.pageable = this.pageableHelper.getPageable();

    this.getRequestsFromDb(this.currentYear);
  }

  public updateRequests(data: MatSelectChange): void {
    this.currentYear = data.value;
    this.getRequestsFromDb(this.currentYear);
  }

  public getRequestsFromDb(year: number): void {
    this.isLoading = true;
    const params = this.pageableHelper.getHttpParams(this.pageable);

    this.requestService
      .getRequestsByRequesterId(this.loginService.getUserId(), year, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.tableConfig = response;
        this.sortByColumns = response.config.sortColumns;
        this.isLoading = false;
      });
  }

  public exportPdf(request: IRequest): void {
    this.helper.exportPdf(request);
  }

  public openDialog(request: IRequest): void {
    if (request.status === RequestStatusEnum.DELETED) {
      return;
    }

    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('USER.MY_REQUESTS.DELETE_CONFIRMATION').toString(),
          description: this.translate
            .instant('USER.MY_REQUESTS.DELETE_CONFIRMATION_DESCRIPTION')
            .toString(),
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('USER.MY_REQUESTS.DELETE_REQUEST').toString(),
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.isLoading = true;
          // todo FIXME this is bad. subscribe method is called inside subscribe. Use switchMap instead.
          this.deleteRequest(request);
        }
      });
  }

  public openPicListDialog(request: IRequest): void {
    let fileList: string[] = request.filename.split(';');
    const dialogRef = this.dialog.open(ImageListDialogComponent, {
      data: { userId: request.requester.id, requestId: request.id, fileList, readOnly: false },
    });

    dialogRef.componentInstance.deleteFileEvent.subscribe((event: DeleteFileEvent) => {
      this.isLoading = true;
      this.requestService
        .deleteFromFileList(request.id, event.fileName)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.alertService.showAlert(
            this.translate.instant('USER.MY_REQUESTS.SUCCESSFULLY_DELETED_FILE').toString(),
            AlertType.success,
          );
          // todo FIXME this is bad. subscribe method is called inside subscribe. Use switchMap instead.
          this.getRequestsFromDb(this.currentYear);
          fileList.splice(event.index, 1);
          if (fileList.length > 0) {
            dialogRef.componentInstance.data = {
              requestId: request.id.toString(),
              fileList,
              readOnly: false,
            };
          } else {
            dialogRef.close();
          }
        });
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        dialogRef.componentInstance.deleteFileEvent.unsubscribe();
      });
  }

  public uploadFile(event: any, id: number): void {
    this.isLoading = true;
    if (event.target.files?.length === 0) return;

    this.requestService
      .uploadFileList(id, event.target.files)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.isLoading = false;
          if (event.target.files.length) {
            this.alertService.showAlert(
              this.translate.instant('USER.MY_REQUESTS.SUCCESSFULLY_UPLOADED').toString(),
              AlertType.success,
            );
            this.getRequestsFromDb(this.currentYear);
          }
        },
        (error) => {
          this.isLoading = false;
          if (error.status) {
            if (error.error) {
              this.alertService.showAlert(error.error, AlertType.error);
            } else {
              this.alertService.showAlert(error.message, AlertType.error);
            }
          } else {
            error.statusText = 'File too large!';
            this.alertService.showAlert(error.statusText, AlertType.error);
          }
        },
      );
  }

  private deleteRequest(request: IRequest): void {
    this.requestService
      .deleteRequest(request.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isLoading = false;
        this.alertService.showAlert(
          this.translate.instant('USER.MY_REQUESTS.SUCCESSFULLY_DELETED').toString(),
          AlertType.success,
        );
        // todo FIXME this is bad. subscribe method is called inside subscribe. Use switchMap instead.
        this.getRequestsFromDb(this.currentYear);
      });
  }

  public isRequestUploadMissing(request: IRequest): boolean {
    return (
      request.filename === null &&
      request.status === RequestStatusEnum.APPROVED &&
      (request.subType === VacationSubtypeEnum.REGULAR_SICK_LEAVE ||
        (request.vacationType === VacationTypeEnum.OTHER_PAID_LEAVE &&
          request.subType !== VacationSubtypeEnum.OTHER_PROJECT)) &&
      request.requester.bgEmployee
    );
  }

  public applicableForDeletion(request: IRequest): boolean {
    const fromDate = new Date(request.fromDate).setHours(0, 0, 0, 0);
    const dateNow = new Date().setHours(0, 0, 0, 0);

    return request.status !== 'DELETED' && fromDate > dateNow;
  }

  public applicableForPDF(vacationType: string, request: IRequest): boolean {
    return (
      (VacationTypeEnum.VACATION === vacationType ||
        VacationTypeEnum.LEAVE_OF_ABSENCE === vacationType ||
        VacationTypeEnum.OTHER_PAID_LEAVE === vacationType ||
        VacationTypeEnum.HOME_OFFICE === vacationType) &&
      request.requester.bgEmployee
    );
  }

  public isSortingDisabled(column: string): boolean {
    return !this.sortByColumns.some((c) => c === column);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
