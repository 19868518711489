import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import {
  Component,
  Input,
  Inject,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterViewChecked,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TimeLogEntry, TimeLogEntryMode, TimeLogLoader } from '../../models/time-log';
import { TimeLogController } from '../../services/time-log.controller';
import * as moment from 'moment';
import { HolidaysService } from '../../services';
import { IHoliday } from '../../models';
import { lastValueFrom } from 'rxjs';
import { getDateRange } from '../../utils';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'app-time-log-input',
  templateUrl: './time-log-input.component.html',
  styleUrls: ['./time-log-input.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'ja-JP' }],
})
export class TimeLogInputComponent implements OnInit, AfterViewChecked {
  @Input() mode: TimeLogEntryMode = 'add';
  @Input() projectId: number;
  @Input() employeeId: number;
  @Input() entry?: TimeLogEntry;
  isLoading = true;
  maxHours = 8;
  selectedDays = [];
  form: FormGroup;
  allHolidays: Array<IHoliday> = [];
  @ViewChild('timeLogForm') timeLogForm: NgForm;
  maxLoggedDay: string;
  minLoggedDay: string;

  constructor(
    public translate: TranslateService,
    private ctrl: TimeLogController,
    private holidayService: HolidaysService,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private changeDetection: ChangeDetectorRef,
  ) {
    this.isWorkingDay = this.isWorkingDay.bind(this);
  }

  async ngOnInit() {
    this.allHolidays = await lastValueFrom(this.holidayService.getAllHolidays());
    this.form = new FormGroup({
      description: new FormControl(this.entry?.description || '', Validators.required),
      hours: new FormControl<number | null>(this.entry?.hours || null, Validators.required),
      startDate: new FormControl<Date>(this.entry?.startDate || new Date(), Validators.required),
      endDate: new FormControl<Date>(this.entry?.endDate || new Date(), Validators.required),
    });
    this.isLoading = false;
    this.handleDatePick();
    this._locale = 'en-GB';
    this._adapter.setLocale(this._locale);
    this.minLoggedDay = moment(this.form.controls.startDate.value)
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    this.maxLoggedDay = this.setMaxLoggedDay(this.form.controls.startDate.value);
  }

  ngAfterViewChecked() {
    this.changeDetection.detectChanges();
  }

  private setMaxLoggedDay = (startDate: string) =>
    moment(startDate).endOf('month').format('YYYY-MM-DD');

  handleDatePick(fromDate = false) {
    const startDate = this.form.controls.startDate.value;

    if (fromDate) {
      this.form.controls.endDate.setValue(startDate);
    }

    this.selectedDays = getDateRange(startDate, this.form.controls.endDate.value).filter(
      (dateStr: string) => this.isWorkingDay(moment(dateStr, 'YYYY-MM-DD').toDate()),
    );

    this.maxLoggedDay = this.setMaxLoggedDay(startDate);
  }

  async submit() {
    const dates = this.selectedDays.map((item) => ({
      date: item,
      description: this.form.controls.description.value,
      hours: this.form.controls.hours.value,
    }));

    this.form.disable();

    let success: boolean | Error;

    if (this.mode === 'add') {
      success = await this.ctrl.addEntry({
        employeeId: this.employeeId,
        projectId: this.projectId,
        dates,
      });
    } else {
      success = await this.ctrl.updateEntry(this.entry!.id, {
        description: this.form.controls.description.value,
        hours: this.form.controls.hours.value,
      });
    }

    this.form.enable();
    if (typeof success === 'boolean') {
      this.timeLogForm.resetForm({
        description: '',
        hours: null,
        date: new Date(),
      });
    }
  }

  get isWorking() {
    if (this.mode === 'add') {
      return this.ctrl.isLoading(TimeLogLoader.add);
    } else {
      this.form.controls.startDate.disable();
      this.form.controls.endDate.disable();
      return this.ctrl.isLoading(TimeLogLoader.edit);
    }
  }

  public isWorkingDay(date: Date | null): boolean {
    if (!date) {
      return false;
    }
    const isWeekend: boolean = date.getDay() === 0 || date.getDay() === 6;
    return !isWeekend;
  }

  public getErrorMessage(inputName: string): string {
    switch (inputName) {
      case 'description': {
        if (this.form.controls.description.hasError('required'))
          return this.translate.instant('LOG_TIME.INPUT.DESCRIPTION_REQUIRED');
        break;
      }

      case 'hours': {
        if (this.form.controls.hours.hasError('required'))
          return this.translate.instant('LOG_TIME.INPUT.HOURS_REQUIRED');
        if (this.form.controls.hours.hasError('min'))
          return this.translate.instant('LOG_TIME.INPUT.HOURS_REQUIRED');
        if (this.form.controls.hours.hasError('max'))
          return this.translate.instant('LOG_TIME.INPUT.HOURS_MAX');
        break;
      }

      case 'startDate': {
        if (this.form.controls.description.hasError('required'))
          return this.translate.instant('LOG_TIME.INPUT.START_DATE_REQUIRED');
        break;
      }

      case 'endDate': {
        if (this.form.controls.description.hasError('required'))
          return this.translate.instant('LOG_TIME.INPUT.END_DATE_REQUIRED');
        break;
      }
    }
  }

  getLoggedDateClass = (date: Date) => {
    const formattedDate = (d) => moment(d).format('YYYY-MM-DD');

    return (
      this.ctrl.data.value.entries.find(
        (entryDate) => formattedDate(date) === formattedDate(entryDate.date),
      ) && 'logged-date'
    );
  };
}
