<div class="table-responsive">
  <mat-form-field class="calendar-search-field" appearance="fill">
    <mat-icon matPrefix>search</mat-icon>
    <input
      id="search-term"
      type="text"
      placeholder="Search"
      (keyup)="applyFilter($event.target.value)"
      autocomplete="off"
      matInput
    />
  </mat-form-field>
  <table mat-table [dataSource]="filteredSubOrdinates">
    <ng-container matColumnDef="employeeName">
      <th mat-header-cell *matHeaderCellDef>{{ 'USER.KNOW_BE.NAME' | translate }}</th>
      <td mat-cell *matCellDef="let employee">{{ employee.employeeName }}</td>
    </ng-container>

    <ng-container matColumnDef="employeePosition">
      <th mat-header-cell *matHeaderCellDef>{{ 'USER.KNOW_BE.POSITION' | translate }}</th>
      <td mat-cell *matCellDef="let employee">{{ employee.employeePosition }}</td>
    </ng-container>

    <ng-container matColumnDef="userCurrentRiskScore">
      <th mat-header-cell *matHeaderCellDef>{{ 'USER.KNOW_BE.RISK_SCORE' | translate }}</th>
      <td mat-cell *matCellDef="let employee">{{ employee.userCurrentRiskScore }}</td>
    </ng-container>

    <ng-container matColumnDef="pastDueTrainingsCount">
      <th mat-header-cell *matHeaderCellDef>{{ 'USER.KNOW_BE.OVERDUE' | translate }}</th>
      <td mat-cell *matCellDef="let employee">{{ employee.pastDueTrainingsCount }}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let employee">
        <button mat-stroked-button (click)="openDialog(employee)">
          {{ 'USER.KNOW_BE.REPORTS' | translate }}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
