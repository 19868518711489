<mat-spinner *ngIf="isLoading"></mat-spinner>
<div *ngIf="!isLoading" class="form-container-padding">
  <form [formGroup]="userForm" (ngSubmit)="updateEmployee()">
    <div class="form-container">
      <div class="form-column">
        <div class="avatarSection">
          <br />
          <div>
            <img
              class="avatar"
              [src]="photoSrc"
              alt=""
              onerror="this.src='https://dms.dreamix.eu/img/defaultAvatar.png'"
            />
            <mat-icon
              matTooltip="{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.REMOVE_AVATAR' | translate }}"
              class="icon delete"
              (click)="removeImage()"
              >cancel
            </mat-icon>
          </div>
          <br />
          <button type="button" mat-raised-button (click)="fileInput.click()" color="primary">
            {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.SELECT_AVATAR' | translate }}
          </button>
          <input hidden (change)="openImageCropDialog($event)" #fileInput type="file" id="file" />

          <button type="button" mat-raised-button (click)="openDialog()" color="primary">
            {{ 'USER.MY_SETTINGS.SKILLS' | translate }}
          </button>
        </div>
      </div>

      <div class="form-column">
        <mat-form-field color="accent" appearance="fill">
          <mat-label> {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.FIRST_NAME_EN' | translate }}</mat-label>
          <input required matInput formControlName="firstNameEn" />
          <mat-error *ngIf="userForm.get('firstNameEn').errors?.required">{{
            'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.FIRST_NAME_EN_IS_REQUIRED' | translate
          }}</mat-error>
          <mat-error *ngIf="userForm.get('firstNameEn').errors?.pattern"
            >{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.FIRST_NAME_LATIN' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field color="accent" appearance="fill">
          <mat-label> {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.LAST_NAME_EN' | translate }}</mat-label>
          <input required matInput formControlName="lastNameEn" />
          <mat-error *ngIf="userForm.get('lastNameEn').errors?.required">{{
            'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.LAST_NAME_EN_IS_REQUIRED' | translate
          }}</mat-error>
          <mat-error *ngIf="userForm.get('lastNameEn').errors?.pattern"
            >{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.LAST_NAME_LATIN' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field color="accent" appearance="fill">
          <mat-label>{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.PHONE' | translate }}</mat-label>
          <input required matInput formControlName="phone" placeholder="0XXXXXXXXX" />
          <mat-error *ngIf="userForm.get('phone').errors?.required">{{
            'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.PHONE_IS_REQUIRED' | translate
          }}</mat-error>
          <mat-error *ngIf="userForm.get('phone').errors?.pattern"
            >{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.PHONE_FORMAT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field color="accent" appearance="fill">
          <mat-label> {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.EMAIL' | translate }}</mat-label>
          <input readonly disabled matInput [value]="userForm.get('email').value" />
        </mat-form-field>

        <mat-form-field color="accent" appearance="fill">
          <mat-label> {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.GENDER' | translate }}</mat-label>
          <mat-select formControlName="gender">
            <mat-option *ngFor="let gender of allGenders" [value]="gender">
              {{ gender }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field color="accent" appearance="fill">
          <mat-label>{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.BIRTHDAY' | translate }}</mat-label>
          <input matInput formControlName="birthday" />
          <mat-error *ngIf="userForm.get('birthday').errors?.pattern">
            {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.BIRTH_DATE_FORMAT_ERROR' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field color="accent" appearance="fill">
          <mat-label> {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.POSITION' | translate }}</mat-label>
          <mat-select formControlName="positionName">
            <mat-option *ngFor="let position of allPositions" [value]="position.positionName">
              {{ position.positionName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field color="primary" appearance="fill">
          <mat-label>{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.REFERRER' | translate }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="referrer"
            [matAutocomplete]="auto1"
            #managerList
          />
          <mat-datepicker-toggle matSuffix (click)="clearAutocomplete('referrer')">
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <mat-autocomplete
            #auto1="matAutocomplete"
            [displayWith]="displayUser"
            (optionSelected)="selectUserAutocomplete($event, 'referrer')"
          >
            <mat-option *ngFor="let manager of filteredReferrers | async" [value]="manager">
              {{ manager.firstNameEn + ' ' + manager.lastNameEn }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field color="primary" appearance="fill">
          <mat-label>{{ 'USER.MY_SETTINGS.LEAD' | translate }}</mat-label>
          <input matInput type="text" [formControl]="managerControl" [matAutocomplete]="auto2" />
          <mat-datepicker-toggle matSuffix (click)="clearAutocomplete('managerId')">
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <mat-autocomplete
            #auto2="matAutocomplete"
            [displayWith]="displayUser"
            (optionSelected)="selectUserAutocomplete($event, 'managerId')"
          >
            <mat-option *ngFor="let manager of filteredManagers | async" [value]="manager">
              {{ manager.firstNameEn + ' ' + manager.lastNameEn }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="form-column">
        <mat-form-field color="accent" appearance="fill">
          <mat-label> {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.FIRST_NAME' | translate }}</mat-label>
          <input [required]="currentUser.bgEmployee" matInput formControlName="firstName" />
          <mat-error *ngIf="userForm.get('firstName').errors?.required">{{
            'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.FIRST_NAME_IS_REQUIRED' | translate
          }}</mat-error>
          <mat-error *ngIf="userForm.get('firstName').errors?.pattern"
            >{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.FIRST_NAME_CYRILLIC' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field color="accent" appearance="fill">
          <mat-label> {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.LAST_NAME' | translate }}</mat-label>
          <input [required]="currentUser.bgEmployee" matInput formControlName="lastName" />
          <mat-error *ngIf="userForm.get('lastName').errors?.required">{{
            'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.LAST_NAME_IS_REQUIRED' | translate
          }}</mat-error>
          <mat-error *ngIf="userForm.get('lastName').errors?.pattern"
            >{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.LAST_NAME_CYRILLIC' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field color="accent" appearance="fill">
          <mat-label> {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.ROLE' | translate }}</mat-label>
          <mat-select formControlName="role">
            <mat-option
              *ngFor="let role of allRoles"
              [value]="role"
              [disabled]="loginService.user.id === currentUser.id"
            >
              {{ role }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field color="accent" appearance="fill">
          <mat-label>
            {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.CONTRACT_POSITION' | translate }}</mat-label
          >
          <input matInput formControlName="contractPosition" />
        </mat-form-field>

        <mat-form-field color="accent" appearance="fill">
          <mat-label> {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.CITY' | translate }}</mat-label>
          <input matInput formControlName="city" />
        </mat-form-field>

        <mat-form-field color="accent" appearance="fill">
          <mat-label> {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.COUNTRY' | translate }}</mat-label>
          <input matInput formControlName="country" />
        </mat-form-field>

        <mat-form-field color="accent" appearance="fill">
          <mat-label>{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.DAYS_LEFT' | translate }}</mat-label>
          <input matInput formControlName="daysLeft" />
          <mat-error *ngIf="userForm.get('daysLeft').errors?.pattern">{{
            'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.DAYS_LEFT_FORMAT_ERROR' | translate
          }}</mat-error>
        </mat-form-field>

        <mat-form-field color="accent" appearance="fill" (click)="picker1.open()">
          <mat-label>{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.LAST_DATE' | translate }}</mat-label>
          <input
            matInput
            name="lastDate"
            formControlName="lastDate"
            [matDatepicker]="picker1"
            (dateInput)="updateLastDate($event)"
          />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker-toggle matSuffix (click)="clearLastDate()">
            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker1 color="primary"></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="form-column"></div>

      <div class="form-double-column">
        <mat-form-field color="accent" appearance="fill">
          <mat-label>{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.HOBBIES' | translate }}</mat-label>
          <textarea matInput formControlName="hobbies" name="hobbies" maxlength="512"></textarea>
          <mat-hint align="end">{{ currentUser.hobbies?.length || 0 }}/512</mat-hint>
        </mat-form-field>
      </div>

      <div class="form-triple-column">
        <div class="mat-form-field-wrapper">
          <mat-label>{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.DESCRIPTION' | translate }}</mat-label>
          <app-editor formControlName="general" name="description"></app-editor>
        </div>
      </div>

      <div class="form-triple-column">
        <mat-checkbox formControlName="subscribedForProject" color="primary">
          {{
            'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.NOTIFICATION_EMAIL_FOR_APPROVING_LEAVE_REQUESTS'
              | translate
          }}
        </mat-checkbox>
      </div>

      <div class="form-triple-column">
        <mat-checkbox formControlName="contractor" color="primary">
          {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.CONTRACTOR' | translate }}
        </mat-checkbox>
      </div>

      <div class="form-triple-column">
        <mat-checkbox formControlName="bgEmployee" color="primary">
          {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.BG_EMPLOYEE' | translate }}
        </mat-checkbox>
      </div>

      <div class="form-triple-column">
        <mat-checkbox formControlName="bdNotificationEnabled" color="primary"
          >{{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.BD_NOTIFICATION' | translate }}
        </mat-checkbox>
      </div>

      <div class="form-triple-column" style="text-align: right">
        <button mat-raised-button type="submit" color="primary">
          {{ 'ADMIN.EMPLOYEES.EDIT_EMPLOYEE.SAVE' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
