import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-nugget-events',
  templateUrl: './nugget-events.component.html',
  styleUrls: ['./nugget-events.component.scss'],
})
export class NuggetEventsComponent {
  constructor(public translate: TranslateService) {}
}
