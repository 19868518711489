import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MaterialModule } from 'src/app/shared/material.module';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmployeeLeavesService } from 'src/app/user/employee-leaves/services/employee-leaves.service';
import { EmployeeLeave } from 'src/app/user/employee-leaves/services/employee-leaves.types';
import { EmployeeLeavesEditModalComponent } from 'src/app/user/employee-leaves/components/employee-leaves-edit-modal/employee-leaves-edit-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-employee-leaves-list',
  standalone: true,
  imports: [CommonModule, MaterialModule, TranslateModule],
  templateUrl: './employee-leaves-list.component.html',
  styleUrls: ['./employee-leaves-list.component.scss'],
})
export class EmployeeLeavesListComponent {
  @Input({ required: true }) leaves!: EmployeeLeave[];

  displayedColumns: string[] = ['name', 'type', 'date', 'actions'];

  constructor(
    public snackbar: MatSnackBar,
    public service: EmployeeLeavesService,
    public dialog: MatDialog,
  ) {}

  handleDecline(item: EmployeeLeave) {
    this.service.decline(item).add(() => {
      this.snackbar.open('Successfully declined', null, {
        duration: 3000,
        panelClass: ['success-snackbar'],
      });
    });
  }

  handleEdit(item: EmployeeLeave) {
    this.dialog.open(EmployeeLeavesEditModalComponent, {
      data: item,
    });
  }
}
