<mat-spinner *ngIf="isLoading()" />
<div [hidden]="isLoading()">
  <div class="home-grid">
    <div>
      <app-pending-requests-section />
      <ng-container>
        <app-vacation-charts />
      </ng-container>
    </div>
    <mat-tab-group animationDuration="0ms" class="forms-group" color="red">
      <mat-tab label="{{ 'HOME.TABS.ABSENCE' | translate }}">
        <app-absence-request-form></app-absence-request-form>
      </mat-tab>
      <mat-tab label="{{ 'HOME.TABS.HOLIDAY' | translate }}">
        <app-holiday-working-form></app-holiday-working-form>
      </mat-tab>
    </mat-tab-group>
  </div>
  <app-calendar />
</div>
