import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { BarChartData } from '../../shared/models/knowbe-user-data.model';

@Component({
  selector: 'app-knowbe-bar-chart',
  templateUrl: './knowbe-bar-chart.component.html',
  styleUrls: ['./knowbe-bar-chart.component.scss'],
})
export class KnowbeBarChartComponent implements AfterViewInit {
  @Input() chartData: BarChartData[];
  @Input() title: string;
  @Input() totalCount: number;

  @ViewChild('barChart') private chartContainer: ElementRef;

  constructor() {}

  ngAfterViewInit() {
    this.draw();
  }

  private draw() {
    const element = this.chartContainer.nativeElement;

    // set the dimensions and margins of the graph
    const margin = { top: 60, right: 120, bottom: 40, left: 160 };
    const width = element.offsetWidth - margin.left - margin.right;
    const height = 420 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    const svg = d3.select(element)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
      
    // X axis
    const x = d3.scaleLinear()
      .domain([ 0, 1 ])
      .range([ 0, width ]);
    svg.append('g')
      .attr('class', 'axis extend-ticks')
      .attr('transform', 'translate(0,' + height + ')')
      .call(d3.axisBottom(x).ticks(10, '%'))
      .style('font-family', 'Manrope')
      .style('font-size', 14);

    // Y axis
    const y = d3.scaleBand()
      .range([ 0, height ])
      .domain(this.chartData.map((d) => d.text))
      .padding(.4)
    svg.append('g')
      .attr('class', 'axis')
      .call(d3.axisLeft(y))
      .style('font-family', 'Manrope')
      .style('font-size', 14);

    // Vertical lines
    svg.selectAll('.extend-ticks .tick')
      .append('line')
      .attr('y1', 0)
      .attr('y2', -height);

    // Adjust line color
    svg.selectAll('.axis .tick line, .axis > path')
      .style('stroke', 'var(--main-color2)');

    // Bars
    svg.selectAll()
      .data(this.chartData)
      .enter()
      .append('rect')
      .attr('x', 1)
      .attr('y', (d) => y(d.text))
      .attr('width', (d) => x(d.percent) || 3)
      .attr('height', y.bandwidth())
      .attr('fill', (d) => d.color);

    // Count
    svg.selectAll()
      .data(this.chartData)
      .enter()
      .append('text')
      .attr('x', (d) => x(d.percent) + 15)
      .attr('y', (d) => y(d.text) + 25)
      .text((d) => `${d.count} / ${this.totalCount}`)
      .style('font-size', 21)
      .style('font-weight', 700);
  }
}
