<mat-card class="full-height">
  <mat-card-header>
    <mat-card-title>{{ title | translate }}</mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div #chart></div>
    <div class="gradient"></div>
    <div class="legend float-left">{{ 'USER.KNOW_BE.PERFECT' | translate }}</div>
    <div class="legend float-right">{{ 'USER.KNOW_BE.HIGHLY_UNSATISFACTORY' | translate }}</div>
  </mat-card-content>
</mat-card>
