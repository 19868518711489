import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { MaterialModule } from 'src/app/shared/material.module';
import { type Moment } from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-absence-half-days-select',
  standalone: true,
  imports: [CommonModule, MaterialModule, TranslateModule],
  templateUrl: './absence-half-days-select.component.html',
  styleUrls: ['./absence-half-days-select.component.scss'],
})
export class AbsenceHalfDaysSelectComponent {
  @Input({ required: true }) vacationDays!: {
    date: Moment;
    morning: boolean;
    afternoon: boolean;
  }[];
  @Output() dayschange = new EventEmitter<
    { date: Moment; morning: boolean; afternoon: boolean }[]
  >();

  toggle = signal(false);

  constructor(public snackbar: MatSnackBar) {}

  handleToggle() {
    this.toggle.update((x) => !x);

    if (!this.toggle()) {
      this.dayschange.emit(
        this.vacationDays.map((day) => {
          day.morning = true;
          day.afternoon = true;

          return day;
        }),
      );
    }
  }

  handleHalfDays(index: number, event: any, morning = true) {
    this.vacationDays[index][morning ? 'morning' : 'afternoon'] = event.checked;

    if (!this.vacationDays[index].morning && !this.vacationDays[index].afternoon) {
      this.snackbar.open('must select at least either morning or afternoon', null, {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    }

    this.dayschange.emit(this.vacationDays);
  }
}
