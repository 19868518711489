import { Component, computed, DestroyRef, inject, OnInit } from '@angular/core';
import { ReportService } from '../../shared/services/report.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Timesheet } from '../../shared/models/timesheet';
import { TimesheetStatus } from '../../shared/enums/timesheet-status.enum';
import { debounceTime, filter, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DIALOG_BUTTONS } from '../../shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/dialogs/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-approve-report',
  templateUrl: './approve-report.component.html',
  styleUrls: ['./approve-report.component.scss'],
})
export class ApproveReportComponent implements OnInit {
  // data
  reports = inject(ReportService).reports;
  displayedColumns: string[] = [
    'fileName',
    'status',
    'employeeFullName',
    'projectName',
    'managerFullName',
    'createdOn',
    'actions',
  ];

  // services
  private reportService = inject(ReportService);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private destroyRef = inject(DestroyRef);
  private dialog: MatDialog = inject(MatDialog);

  // states
  isLoading = computed(() => this.reports() === undefined);
  totalSize = computed(() => this.reports().totalElements);

  searchChanged = new Subject<string>();
  latestSearchString = '';

  pageIndex: number = 0;
  pageSize: number = 10;
  readonly buttons = DIALOG_BUTTONS;

  constructor(public translate: TranslateService) {
    this.reportService.fetchReports(this.pageSize, this.pageIndex);
  }

  ngOnInit() {
    this.subscribeToSearchEvent();
  }

  isApproveButtonDisabled(timesheet: Timesheet): boolean {
    const invalidStatutes = [TimesheetStatus.APPROVED, TimesheetStatus.DECLINED];
    return invalidStatutes.includes(timesheet.status);
  }

  subscribeToSearchEvent(): void {
    this.searchChanged
      .pipe(
        debounceTime(300), // Wait for 300ms pause in events
        tap(() => this.resetPaginationParams()),
        tap((searchString) => {
          if (searchString === '') {
            this.reportService.fetchReports(this.pageSize, this.pageIndex);
          }
        }), // If the text is erased, continue from where you left off.
        filter((searchString) => searchString.length > 2), // Only consider if length > 2
        tap((searchString) => {
          this.sendSearchRequest(searchString);
        }),
      )
      .subscribe();
  }

  handlePageChange(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    const searchString = this.latestSearchString || undefined;
    this.reportService.fetchReports(this.pageSize, this.pageIndex, searchString);
  }

  onSearchChange(searchString: string) {
    this.searchChanged.next(searchString);
  }

  resetPaginationParams(): void {
    this.pageIndex = 0;
    this.pageSize = 10;
    this.latestSearchString = '';
  }

  sendSearchRequest(searchString: string): void {
    this.reportService.fetchReports(10, 0, searchString);
    this.latestSearchString = searchString;
  }

  approveTimesheet(timesheet: Timesheet) {
    this.reportService
      .approveTimesheet(timesheet.id)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() =>
          this.reportService.fetchReports(this.pageSize, this.pageIndex, this.latestSearchString),
        ),
      )
      .subscribe(
        () => {
          this.snackBar.open(
            this.translate
              .instant('ADMIN.APPROVE_REPORT.TIMESHEET_APPROVED', {
                name: timesheet.employeeFullName,
              })
              .toString(),
            '',
            { duration: 3000 },
          );
        },
        (error) => {
          this.showDialog(error, timesheet.fileName);
        },
      );
  }

  downloadTimesheet(timesheet: Timesheet): void {
    this.reportService
      .downloadTimesheet(timesheet.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (data) => {
          const blob = new Blob([data], {
            type: 'application/octet-stream',
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', timesheet.fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        (error) => {
          this.showDialog(error, timesheet.fileName);
        },
      );
  }

  private showDialog(error: any, timesheetName: string) {
    const title = this.translate.instant('SHARED.ERROR').toString();
    const description =
      error.status === 404
        ? this.translate
            .instant('ADMIN.APPROVE_REPORT.TIMESHEET_ERROR', {
              name: timesheetName,
            })
            .toString()
        : error.message;
    const sharedButtonClass = this.buttons.deleteButton;
    const sharedButtonText = 'OK';
    const showCancelButton = false;

    this.dialog.open(DialogComponent, {
      data: {
        title,
        description,
        sharedButtonClass,
        sharedButtonText,
        showCancelButton,
      },
    });
  }

  getColor(timesheet: Timesheet): string {
    return timesheet.status.toLowerCase();
  }
}
