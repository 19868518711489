<mat-spinner *ngIf="isLoading"></mat-spinner>
<app-submit-report />
<div>
  <h2 class="section-title">
    {{ 'ADMIN.CLIENT_HOURS.TITLE' | translate }}
  </h2>

  <div class="excel-form">
    <div class="client-hours-component">
      <mat-form-field (click)="picker1.open()" color="accent" appearance="fill">
        <mat-label>{{ 'ADMIN.CLIENT_HOURS.MONTH' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="picker1"
          [formControl]="dateControl"
          [min]="minDate"
          [max]="maxDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker
          #picker1
          startView="multi-year"
          color="primary"
          (monthSelected)="setMonthAndYear($event, picker1)"
        ></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="form-item-full no-padding" subscriptSizing="dynamic">
        <mat-label>{{ 'ADMIN.CLIENT_HOURS.PROJECTS' | translate }}</mat-label>
        <input
          type="text"
          matInput
          placeholder="{{ 'ADMIN.CLIENT_HOURS.PROJECTS' | translate }}"
          #filter
          [formControl]="projectsControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onProjectChange($event)"
          [displayWith]="displayFn"
        >
          <mat-option
            *ngFor="let project of projectList | filter: filter.value : 'name'"
            [value]="project"
          >
            {{ project.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <button
        (click)="onSubmit()"
        mat-raised-button
        color="primary"
        type="button"
        class="excel-button"
        [disabled]="!projectsControl.value || !dateControl.value || isLoading"
      >
        <mat-icon>download</mat-icon>
        {{ 'ADMIN.CLIENT_HOURS.EXPORT' | translate }}
      </button>
    </div>
  </div>

  <h2 class="section-title">
    {{ 'ADMIN.CLIENT_HOURS.SUB_TITLE' | translate }}
  </h2>

  <app-calendar [isCalendarHome]="false" />
</div>
