import { NativeDateAdapter } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  constructor(@Inject(PLATFORM_ID) platformId) {
    super('bg', new Platform(platformId));
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}
