import { Component, Input } from '@angular/core';
import { VacationTypeEnum } from 'src/app/shared/enums';
import { vacationIcons } from '../calendar.config';
import * as moment from 'moment';
import { keepObjKeysOrder } from 'src/app/shared/utils';

@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
})
export class CalendarHeaderComponent {
  @Input() onMonthChange: () => void;
  @Input() handleLoading: (val: boolean) => void;
  @Input() isCalendarHome = true;

  public currentMonthTitle: string;

  public calendarMonthNumberOffSet: number = 0;
  public calendarYearNumberOffSet: number = 0;

  public vacationTypes = VacationTypeEnum;
  public vacationIcons = vacationIcons;

  public enumOrder = keepObjKeysOrder;

  public changeMonth(increment: number): void {
    this.handleLoading(true);
    this.updateOffsets(increment);
    this.onMonthChange();
  }

  public updateOffsets(increment: number): void {
    if (increment === 0) {
      this.calendarYearNumberOffSet = 0;
      this.calendarMonthNumberOffSet = 0;
      return;
    }

    const currentMonth = new Date().getMonth();

    if (currentMonth + this.calendarMonthNumberOffSet === 0 && increment === -1) {
      this.calendarYearNumberOffSet += -1;
      this.calendarMonthNumberOffSet = 11 - currentMonth;
    } else if (currentMonth + this.calendarMonthNumberOffSet === 11 && increment === 1) {
      this.calendarYearNumberOffSet += 1;
      this.calendarMonthNumberOffSet = 0 - currentMonth;
    } else {
      this.calendarMonthNumberOffSet += increment;
    }
  }

  public getMonthDates = () => {
    const firstDateCurrentMonth = moment()
      .add(this.calendarMonthNumberOffSet, 'months')
      .add(this.calendarYearNumberOffSet, 'years')
      .startOf('month');

    const lastDateCurrentMonth = moment()
      .add(this.calendarMonthNumberOffSet, 'months')
      .add(this.calendarYearNumberOffSet, 'years')
      .endOf('month');

    return [firstDateCurrentMonth, lastDateCurrentMonth];
  };
}
