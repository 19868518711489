import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { TimeLogService } from './time-log.service';
import {
  TimeLogLoader,
  AddEntryPayload,
  TimeLogEntryAction,
  UpdateEntryPayload,
  TimeLogsData,
} from '../models/time-log';
import { LoginService } from './login.service';
import * as moment from 'moment';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';

const initialData: TimeLogsData = {
  entries: [],
  totalMonthWorkingHours: null,
  totalWorkingHours: null,
};

@Injectable({
  providedIn: 'root',
})
export class TimeLogController {
  // TODO: those are a bit loose now, might have some scoping down :)

  private loaders: Set<TimeLogLoader> = new Set();
  loading = new BehaviorSubject<Set<TimeLogLoader>>(this.loaders);
  data = new BehaviorSubject<TimeLogsData>(initialData);
  actions = new EventEmitter<TimeLogEntryAction>();

  projectId: number = -1;
  private monthOffset = 0;

  constructor(
    private loginService: LoginService,
    private timeLogService: TimeLogService,
    private alertService: AlertService,
    private translate: TranslateService,
  ) {
    this.actions.subscribe((action) => {
      if (action.event === 'success') {
        let message: string = '';
        switch (action.mode) {
          case 'add':
            message = 'added';
            break;
          case 'edit':
            message = 'edited';
            break;
          case 'delete':
            message = 'deleted';
            break;
          default:
            message = 'success';
        }
        this.alertService.showAlert(
          this.translate.instant('LOG_TIME.SUBMIT_SUCCESS', {
            action: message,
          }),
          AlertType.success,
        );
      } else if (action.event === 'error') {
        this.alertService.showAlert(action.payload.error.details, AlertType.error);
      }
    });
  }

  async changeProject(projectId: number) {
    this.projectId = projectId;
    await this.loadEntries();
  }

  async changeMonth(monthOffset: number) {
    this.monthOffset = monthOffset;
    await this.loadEntries();
  }

  async clearEntries() {
    this.data.next(initialData);
  }

  async handleSelect(userId: number, projectId: number, start: moment.Moment, end: moment.Moment) {
    this.startLoader(TimeLogLoader.entries);

    try {
      let startDate = start.startOf('month').format('YYYY-MM-DD');
      let endDate = end.endOf('month').format('YYYY-MM-DD');

      const data = await this.timeLogService.loadEntries(
        projectId,
        userId ? userId : this.loginService.user.id,
        startDate,
        endDate,
      );

      this.data.next(data);
    } catch (err) {
      this.actions.emit({
        mode: TimeLogLoader.add,
        event: 'error',
        payload: err,
      });
    } finally {
      this.stopLoader(TimeLogLoader.entries);
    }
  }

  async loadEntries(userId?: number) {
    if (this.projectId < 0) {
      throw new Error('projectId is not set');
    }

    this.startLoader(TimeLogLoader.entries);

    try {
      let start = moment().add(this.monthOffset, 'months').startOf('month').format('YYYY-MM-DD');
      let end = moment().add(this.monthOffset, 'months').endOf('month').format('YYYY-MM-DD');
      const data = await this.timeLogService.loadEntries(
        this.projectId,
        userId ? userId : this.loginService.user.id,
        start,
        end,
      );
      this.data.next(data);
    } catch (err) {
      this.actions.emit({
        mode: TimeLogLoader.add,
        event: 'error',
        payload: err,
      });
    } finally {
      this.stopLoader(TimeLogLoader.entries);
    }
  }

  // crud
  async addEntry(payload: AddEntryPayload): Promise<boolean | Error> {
    this.startLoader(TimeLogLoader.add);
    this.actions.emit({ mode: TimeLogLoader.add, event: 'start' });

    try {
      await this.timeLogService.addEntry(payload);
      this.actions.emit({ mode: TimeLogLoader.add, event: 'success' });
      await this.loadEntries();
    } catch (err) {
      this.actions.emit({
        mode: TimeLogLoader.add,
        event: 'error',
        payload: err,
      });
      return err;
    } finally {
      this.stopLoader(TimeLogLoader.add);
    }

    return true;
  }

  async updateEntry(entryId: number, payload: UpdateEntryPayload): Promise<boolean | Error> {
    this.startLoader(TimeLogLoader.edit);
    this.actions.emit({ mode: TimeLogLoader.edit, event: 'start' });

    try {
      await this.timeLogService.updateEntry(this.loginService.user.id, entryId, payload);
      this.actions.emit({ mode: TimeLogLoader.edit, event: 'success' });
      await this.loadEntries();
    } catch (err) {
      this.actions.emit({
        mode: TimeLogLoader.edit,
        event: 'error',
        payload: err,
      });
      return err;
    } finally {
      this.stopLoader(TimeLogLoader.edit);
    }

    return true;
  }

  async deleteEntry(id: number) {
    this.startLoader(TimeLogLoader.delete);
    this.actions.emit({ mode: TimeLogLoader.delete, event: 'start' });

    try {
      await this.timeLogService.deleteEntry(id);
      this.actions.emit({ mode: TimeLogLoader.delete, event: 'success' });

      await this.loadEntries();
    } catch (err) {
      this.actions.emit({
        mode: TimeLogLoader.delete,
        event: 'error',
        payload: err,
      });

      return err;
    } finally {
      this.stopLoader(TimeLogLoader.delete);
    }
  }

  // loaders
  startLoader(action: TimeLogLoader) {
    this.loaders.add(action);
    this.loading.next(this.loaders);
  }

  stopLoader(action: TimeLogLoader) {
    this.loaders.delete(action);
    this.loading.next(this.loaders);
  }

  isLoading(loaders?: TimeLogLoader | Array<TimeLogLoader> | null): boolean {
    if (loaders) {
      if (Array.isArray(loaders)) {
        return !![...this.loaders].filter((l) => loaders.includes(l)).length;
      } else {
        return this.loaders.has(loaders);
      }
    } else {
      return !!this.loaders.size;
    }
  }
}
