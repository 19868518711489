import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IOvertime} from 'src/app/shared/models/overtime.model';

@Component({
  selector: 'app-ot-history-dialog',
  templateUrl: 'overtime-history-dialog.component.html',
  styleUrls: ['overtime-history-dialog.component.scss'],
})
export class OvertimeInformationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public overtime: IOvertime, public dialogRef: MatDialogRef<OvertimeInformationDialogComponent>) {
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
