<app-spinner-overlay *ngIf="isLoading"></app-spinner-overlay>

<div [hidden]="!tableConfig">
  <h2 class="section-title">
    {{ 'USER.MY_REQUESTS.ALL_MY_REQUESTS' | translate }}
  </h2>

  <mat-form-field id="compensation-type-form-field" appearance="fill">
    <mat-label>{{ 'USER.MY_REQUESTS.FOR_YEAR' | translate }}</mat-label>
    <mat-select [formControl]="yearFormControl" (selectionChange)="updateRequests($event)">
      <mat-option *ngFor="let year of allYears" [value]="year">
        {{ year }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <app-table-wrapper
    [tableConfig]="tableConfig"
    (getData)="getRequestsFromDb(currentYear)"
    [(pageable)]="pageable"
    matSort
    [sort]="sort"
  >
    <ng-container matColumnDef="createdOn">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('createdOn')"
      >
        {{ 'USER.MY_REQUESTS.CREATED' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div>
          <a [routerLink]="['/requests/', request.id]">{{ request.createdOn }}</a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="approverEmail">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('approverEmail')"
      >
        {{ 'USER.MY_REQUESTS.APPROVER' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div>
          <a [routerLink]="['/requests/', request.id]">{{ request.approver.email }}</a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="fromDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('fromDate')"
      >
        {{ 'USER.MY_REQUESTS.START_DATE' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div>
          <a [routerLink]="['/requests/', request.id]">{{ request.fromDate }}</a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="toDate">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('toDate')"
      >
        {{ 'USER.MY_REQUESTS.END_DATE' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div>
          <a [routerLink]="['/requests/', request.id]">{{ request.toDate }}</a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="days">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="isSortingDisabled('days')">
        {{ 'USER.MY_REQUESTS.DAYS' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div>
          <a [routerLink]="['/requests/', request.id]">{{ request.days }}</a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="vacationType">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('vacationType')"
      >
        {{ 'USER.MY_REQUESTS.REASON' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div>
          <a
            [routerLink]="['/requests/', request.id]"
            *ngIf="
              request.vacationType === 'Sick leave' || request.vacationType === 'Other paid leave'
            "
          >
            {{ request.subType }}
          </a>
          <a
            [routerLink]="['/requests/', request.id]"
            *ngIf="
              request.vacationType !== 'Sick leave' && request.vacationType !== 'Other paid leave'
            "
          >
            {{ request.vacationType }}
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="additionalInfo">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('additionalInfo')"
      >
        {{ 'USER.MY_REQUESTS.ADDITIONAL_INFORMATION' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div>
          <a [routerLink]="['/requests/', request.id]">{{ request.additionalInfo }}</a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled('status')"
      >
        {{ 'USER.MY_REQUESTS.STATUS' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div>
          <a [routerLink]="['/requests/', request.id]">{{ request.status }}</a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="action" class="download-button">
      <th mat-header-cell *matHeaderCellDef></th>
      <td
        mat-cell
        *matCellDef="let request"
        [ngClass]="{
          'upload-missing': isRequestUploadMissing(request)
        }"
      >
        <div *ngIf="request.status !== 'DECLINED'">
          <mat-icon
            matTooltip="{{ 'USER.MY_REQUESTS.REQUEST_DOCUMENT' | translate }}"
            *ngIf="request.filename"
            (click)="openPicListDialog(request)"
            class="icon save"
            >image_search</mat-icon
          >
          <input
            (change)="uploadFile($event, request.id)"
            type="file"
            name="file{{ request.id }}"
            class="file"
            id="file{{ request.id }}"
            multiple
          />
          <label for="file{{ request.id }}" class="icon save">
            <mat-icon
              matTooltip="{{ 'USER.MY_REQUESTS.UPLOAD_DOCUMENT' | translate }}"
              *ngIf="
                (request.subType === 'Regular sick leave' ||
                  request.vacationType === 'Other paid leave' ||
                  request.vacationType === 'Maternity / Paternity') &&
                request.requester.bgEmployee
              "
              class="icon save"
              >cloud_upload</mat-icon
            >
          </label>
          <mat-icon
            matTooltip="{{ 'USER.MY_REQUESTS.REQUEST_PDF' | translate }}"
            *ngIf="applicableForPDF(request.vacationType, request)"
            (click)="exportPdf(request)"
            class="icon save"
            >picture_as_pdf</mat-icon
          >
          <mat-icon
            matTooltip="{{ 'USER.MY_REQUESTS.DELETE_REQUEST' | translate }}"
            *ngIf="applicableForDeletion(request)"
            (click)="openDialog(request)"
            class="icon delete"
            >cancel
          </mat-icon>
        </div>
      </td>
    </ng-container>
  </app-table-wrapper>
</div>
