import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EmployeeService } from '../../../shared/services/employee.service';
import { TranslateService } from '@ngx-translate/core';
import { IEmployee, IEmployeeNameEmail } from '../../../shared/models/employee.model';
import { LoginService } from '../../../shared/services/login.service';
import { IOvertime } from '../../../shared/models/overtime.model';
import { OvertimeService } from '../../../shared/services/overtime.service';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { ProjectBase } from '../../../shared/models/project.model';
import { ProjectService } from '../../../shared/services/project.service';
import * as moment from 'moment';

export interface OvertimeType {
  value?: string;
  viewValue?: string;
}

export interface CompensationType {
  value?: string;
  viewValue?: string;
  shortViewValue?: string;
}

@Component({
  selector: 'app-overtime-request-form',
  templateUrl: './overtime-request-form.component.html',
  styleUrls: ['./overtime-request-form.component.scss'],
})
export class OvertimeRequestFormComponent implements OnInit, OnDestroy {
  @Output() submitForm = new EventEmitter();

  @ViewChild('otReqForm') reqForm: NgForm;

  private destroy$ = new Subject<void>();
  currentOvertime: IOvertime = undefined;
  inEdit: boolean = false;
  isRequestLoading: boolean = false;
  requestForm: FormGroup;
  allProjects: Array<ProjectBase>;
  allEmployees: Array<IEmployeeNameEmail> = [];
  allEmails: Array<string> = [];
  allEmailsObservable: Observable<Array<string>>;
  allOvertimeTypes: OvertimeType[] = [
    {
      value: 'WORKING',
      viewValue: 'USER.OVERTIME.REQUEST_FORM.WORKING_TYPE',
    },
    {
      value: 'TEACHING',
      viewValue: 'USER.OVERTIME.REQUEST_FORM.TEACHING_TYPE',
    },
  ];

  isLoading = true;
  allCompensationTypes: CompensationType[] = [
    {
      value: 'PAID_LEAVE',
      viewValue: 'USER.OVERTIME.REQUEST_FORM.PAID_LEAVE_COMPENSATION',
      shortViewValue: 'Paid Leave',
    },
    {
      value: 'PAYMENT',
      viewValue: 'USER.OVERTIME.REQUEST_FORM.PAYMENT_COMPENSATION',
      shortViewValue: 'Payment',
    },
  ];
  today: Date = new Date();
  // FROM BACK-END
  minDate: Date = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
  maxDate: Date = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    this.today.getDate() + 7,
  );
  user: IEmployee;

  constructor(
    private projectService: ProjectService,
    private employeeService: EmployeeService,
    private overtimeService: OvertimeService,
    private alertService: AlertService,
    private loginService: LoginService,
    private translate: TranslateService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.initializeFormControl();
    this.setConditionalValidators();
    this.getDataFromDb();
    this.user = this.loginService.getUser();
  }

  getErrorMessage(formName: string): string {
    switch (formName) {
      case 'email': {
        if (this.requestForm.get('emailFormControl').hasError('required'))
          return this.translate.instant('USER.OVERTIME.REQUEST_FORM.EMAIL_REQUIRED').toString();
        if (this.requestForm.get('emailFormControl').hasError('email'))
          return this.translate.instant('USER.OVERTIME.REQUEST_FORM.INVALID_EMAIL').toString();
        if (!this.allEmails.includes(this.requestForm.get('emailFormControl').value))
          return this.translate
            .instant('USER.OVERTIME.REQUEST_FORM.INVALID_DREAMIX_EMAIL')
            .toString();
        break;
      }

      case 'date': {
        if (this.requestForm.get('dateFormControl').hasError('required'))
          return this.translate.instant('USER.OVERTIME.REQUEST_FORM.DATE_REQUIRED').toString();
        break;
      }
      case 'project': {
        if (this.requestForm.get('projectsFormControl').hasError('required'))
          return this.translate.instant('USER.OVERTIME.REQUEST_FORM.PROJECT_REQUIRED').toString();
        break;
      }

      case 'hours': {
        if (this.requestForm.get('hoursFormControl').hasError('required'))
          return this.translate.instant('USER.OVERTIME.REQUEST_FORM.HOURS_REQUIRED').toString();
        if (this.requestForm.get('hoursFormControl').hasError('pattern')) {
          return this.translate.instant('USER.OVERTIME.REQUEST_FORM.HOURS_WHOLE_NUMBER').toString();
        }
        if (this.requestForm.get('hoursFormControl').hasError('min')) {
          return this.translate.instant('USER.OVERTIME.REQUEST_FORM.HOURS_MIN').toString();
        }
        if (this.requestForm.get('hoursFormControl').hasError('max')) {
          return this.translate.instant('USER.OVERTIME.REQUEST_FORM.HOURS_MAX').toString();
        }
        break;
      }
      case 'description': {
        if (this.requestForm.get('descriptionFormControl').hasError('required'))
          return this.translate
            .instant('USER.OVERTIME.REQUEST_FORM.DESCRIPTION_REQUIRED')
            .toString();
        break;
      }

      case 'radio-buttons': {
        if (this.requestForm.get('requestedByClientFormControl').hasError('required'))
          return this.translate
            .instant('USER.OVERTIME.REQUEST_FORM.RADIO_BUTTON_REQUIRED')
            .toString();
        break;
      }

      case 'overtimeType': {
        if (this.requestForm.get('overtimeTypeFormControl').hasError('required'))
          return this.translate
            .instant('USER.OVERTIME.REQUEST_FORM.OVERTIME_TYPE_REQUIRED')
            .toString();
        break;
      }

      case 'compensation': {
        if (this.requestForm.get('compensationTypeFormControl').hasError('required'))
          return this.translate
            .instant('USER.OVERTIME.REQUEST_FORM.COMPENSATION_REQUIRED')
            .toString();
        break;
      }
    }
  }

  onSubmit(form: FormGroup): void {
    if (form.valid) {
      this.sendForm();
    }
  }

  private setConditionalValidators(): void {
    const description = this.requestForm.get('descriptionFormControl');
    const project = this.requestForm.get('projectsFormControl');
    const requested = this.requestForm.get('requestedByClientFormControl');
    const compensation = this.requestForm.get('compensationTypeFormControl');

    this.requestForm
      .get('requestedByClientFormControl')
      .valueChanges.subscribe((requestedByClientFormControl) => {
        if (requestedByClientFormControl === true) {
          description.setValidators([Validators.required]);
        } else {
          description.setValidators(null);
        }
        description.updateValueAndValidity();
      });

    this.requestForm.get('overtimeTypeFormControl').valueChanges.subscribe((overtime) => {
      if (overtime === 'TEACHING') {
        project.setValidators(null);
        project.setValue(null);
        requested.setValue(null);
        compensation.setValue(null);
        compensation.setValidators([Validators.required]);
      }
      if (overtime === 'WORKING') {
        project.setValidators([Validators.required]);
        compensation.setValidators(null);
        compensation.setValue('PAYMENT');
        requested.setValidators([Validators.required]);

        if (this.user.projects !== null) {
          project.setValue(this.user.projects[0].id);
        }
      }
      project.updateValueAndValidity();
      compensation.updateValueAndValidity();
    });
  }

  private sendForm(): void {
    this.isRequestLoading = true;
    const ocurredOnDate = this.requestForm.get('dateFormControl').value;
    const createdOnDate = this.inEdit ? this.currentOvertime.createdOn : new Date(Date.now());
    const payload: IOvertime = {
      createdOn: moment(createdOnDate).format('YYYY-MM-DD'),
      employeeId: this.user.id,
      description: this.requestForm.get('descriptionFormControl').value,
      approver: this.allEmployees
        .filter((e) => e.email === this.requestForm.get('emailFormControl').value)
        .pop(),
      occurredOn: moment(ocurredOnDate).format('YYYY-MM-DD'),
      project: null,
      hours: this.requestForm.get('hoursFormControl').value,
      compensationType: this.requestForm.get('compensationTypeFormControl').value,
      requestedByClient: this.requestForm.get('requestedByClientFormControl').value,
      overtimeType: this.requestForm.get('overtimeTypeFormControl').value,
    };

    if (this.requestForm.get('projectsFormControl').value !== null) {
      payload.project = this.allProjects
        .filter((p) => p.id === this.requestForm.get('projectsFormControl').value)
        .pop();
    }

    this.overtimeService[this.inEdit ? 'updateOvertime' : 'createOvertime'](
      payload,
      this.currentOvertime?.id,
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.alertService.showAlert(
            this.translate.instant('USER.OVERTIME.REQUEST_FORM.SUCCESSFULLY_SENT').toString(),
            AlertType.success,
          );
          this.resetForm();
          this.submitForm.emit(true);
          this.isRequestLoading = false;
        },
        (error) => {
          if (error.error) {
            if (error.error.details) {
              this.alertService.showAlert(error.error.details, AlertType.error);
            } else if (error.error.error) {
              this.alertService.showAlert(error.error.error, AlertType.error);
            } else {
              this.alertService.showAlert(error.error, AlertType.error);
            }
          } else {
            this.alertService.showAlert(error.message, AlertType.error);
          }
          this.isRequestLoading = false;
        },
      );
  }

  public resetForm(): void {
    this.inEdit = false;
    this.reqForm.resetForm();
  }

  private getDataFromDb(): void {
    forkJoin({
      employees: this.employeeService.getAllEmployeesNameEmail(),
      projects: this.projectService.getProjectsSimple(),
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.allEmployees = data.employees;
        this.allEmails = data.employees
          .map((emp) => {
            return emp.email;
          })
          .sort((a, b) => a.localeCompare(b));

        this.allEmailsObservable = this.requestForm.get('emailFormControl').valueChanges.pipe(
          startWith(''),
          map((input) =>
            this.allEmails.filter((email) => {
              if (!input) return true;
              else return email.toLowerCase().includes(input.toLowerCase());
            }),
          ),
        );

        this.allProjects = data.projects.map((project: ProjectBase) => ({
          id: project.id,
          name: project.name,
        }));

        this.isLoading = false;
      });
  }

  private initializeFormControl(): void {
    this.requestForm = this.fb.group({
      emailFormControl: new FormControl('', [
        Validators.required,
        Validators.email,
        this.dreamixEmailValidator(),
      ]),
      dateFormControl: new FormControl('', Validators.required),
      hoursFormControl: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(1),
        Validators.max(24),
      ]),
      descriptionFormControl: new FormControl(''),
      projectsFormControl: new FormControl(''),
      requestedByClientFormControl: new FormControl(null),
      overtimeTypeFormControl: new FormControl('', Validators.required),
      compensationTypeFormControl: new FormControl(''),
    });
  }

  private dreamixEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const correctManagerEmail =
        control.value === ''
          ? false
          : this.allEmails.includes(this.requestForm.get('emailFormControl').value);
      return !correctManagerEmail ? { correct: { value: control.value } } : null;
    };
  }

  public handleEdit = (overtime: IOvertime) => {
    this.currentOvertime = overtime;
    this.requestForm.setValue(
      {
        emailFormControl: overtime.approver.email,
        hoursFormControl: overtime.hours,
        dateFormControl: moment(overtime.occurredOn, 'YYYY-MM-DD').toDate(),
        overtimeTypeFormControl: overtime.overtimeType,
        compensationTypeFormControl: overtime.compensationType,
        projectsFormControl: overtime.project?.id ?? {},
        requestedByClientFormControl: overtime.requestedByClient,
        descriptionFormControl: overtime.description,
      },
      {
        emitEvent: true,
      },
    );
    this.inEdit = true;
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
