<div class="toggle-container">
  <mat-slide-toggle
    color="primary"
    matTooltip="{{ 'HOME.REQUEST_FORM.TOGGLE_HALF_DAYS' | translate }}"
    [checked]="toggle()"
    (toggleChange)="handleToggle()"
    class="toggle"
  >
    {{ 'HOME.REQUEST_FORM.HALF_DAYS' | translate }}
  </mat-slide-toggle>
  <div *ngIf="toggle()">
    <table>
      <tbody>
        <tr *ngFor="let day of vacationDays; let i = index">
          <td>
            <span>{{ day.date.format('MMM DD') }}</span>
          </td>
          <td>
            <mat-checkbox
              color="primary"
              [checked]="day.morning"
              (change)="handleHalfDays(i, $event)"
            >
              {{ 'HOME.REQUEST_FORM.MORNING' | translate }}
            </mat-checkbox>
          </td>
          <td>
            <mat-checkbox
              color="primary"
              [checked]="day.afternoon"
              (change)="handleHalfDays(i, $event, false)"
            >
              {{ 'HOME.REQUEST_FORM.AFTERNOON' | translate }}
            </mat-checkbox>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
