import { ChangeDetectionStrategy, Component, Input, Signal, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingWithValue } from '../../types/loading-with-value.type';
@Component({
  selector: 'app-with-loading',
  templateUrl: './with-loading.component.html',
  styleUrls: ['./with-loading.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WithLoadingComponent<T> {
  @Input() data$: Observable<LoadingWithValue<T>>;
  @Input() data: Signal<T>;
  @Input() contentTemplateRef: TemplateRef<{ $implicit: T }>;
  @Input() errorTemplateRef: TemplateRef<{ $implicit: any }>;
  @Input() loadingTemplateRef: TemplateRef<{ $implicit: any }>;
  @Input() isSignal: boolean = false;
  @Input() showErrors: boolean = true;

  hasError(data): boolean {
    return !data;
  }
}
