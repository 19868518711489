import { Component, OnDestroy } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
import * as FileSaver from 'file-saver';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {AlertService, AlertType, EmployeeService} from '../../shared/services';
import {MaterialModule} from "../../shared/material.module";

@Component({
  selector: 'app-headcount',
  templateUrl: './headcount.component.html',
  styleUrls: ['./headcount.component.scss'],
  imports: [
    MaterialModule,
    TranslateModule
  ],
  standalone: true
})
export class HeadcountComponent implements OnDestroy {
  reportStartDate: Date | null = null;
  headcountSubscription!: Subscription;

  constructor(
    private alertService: AlertService,
    public translate: TranslateService,
    private employeeService: EmployeeService,
  ) {}

  submitStartDate(date: MatDatepickerInputEvent<Date>): void {
    this.reportStartDate = date.value;
  }

  onSubmit(): void {
    if (!this.validateInput()) {
      return;
    }

    const fromDate = formatDate(this.reportStartDate, 'yyyy-MM-dd', 'en-US');
    this.headcountSubscription = this.employeeService
      .getHeadcount(fromDate)
      .subscribe(
        (response) => {
          this.saveAsExcelFile(response, 'Report_headcount');
        },
        (error) => this.alertService.showAlert(error.message, AlertType.error),
      );
  }

  private validateInput(): boolean {
    if (!this.reportStartDate) {
      this.alertService.showAlert(
        this.translate.instant('ADMIN.OVERTIMES.OVERTIMES_EXPORT_EXCEL.FILL_START_DATE').toString(),
        AlertType.error,
      );
      return false;
    } else {
      return true;
    }
  }

  saveAsExcelFile(excelData: ArrayBuffer, fileName: string): void {
    const data: Blob = new Blob([excelData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(
      data,
      fileName + '_' + formatDate(new Date(), 'yyyy-MM-dd', 'en-US') + '.xlsx',
    );
  }

  ngOnDestroy() {
    if (this.headcountSubscription) {
      this.headcountSubscription.unsubscribe();
    }
  }
}
