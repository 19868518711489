import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-requests-table-row',
  templateUrl: './requests-table-row.component.html',
  styleUrls: ['./requests-table-row.component.scss'],
})
export class RequestsTableRowComponent {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() link: string;
  @Input() loading: boolean;

  @Input() fromDate: string;
  @Input() toDate: string;

  @Input() deleteAction: Function;
  @Input() approveAction: Function;
}
