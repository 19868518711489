import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {IAbsenceRequest, IPagination, IRequest} from '../models';
import {HolidayWorkingRequest} from 'src/app/home/holiday-working-form/holiday-working-form.component';
import {IWorkOnHolidayRequest} from '../models/work-on-holiday-request.model';
import {ICalendarRequest} from '../models/request-calendar.model';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  private urlBase: string = 'api/requests';
  private urlV2Base: string = 'api/v2/requests';
  private workOnHolidaysUrlBase: string = 'api/work-holidays-requests';
  private listOfAbsences$ = new BehaviorSubject<IAbsenceRequest[]>([]);

  constructor(private httpClient: HttpClient) {
  }

  public getAllRequests(filter: string, params?: HttpParams): Observable<IPagination<IRequest>> {
    return this.httpClient.get<IPagination<IRequest>>(`${this.urlBase}/filter/${filter}`, {
      params,
    });
  }

  public getAllMonthRequests(currentMonth: string): Observable<IRequest[]> {
    return this.httpClient.get<IRequest[]>(`${this.urlBase}/month_requests/${currentMonth}`);
  }

  public getAllMonthRequestsV2(currentMonth: string): Observable<ICalendarRequest[]> {
    return this.httpClient.get<ICalendarRequest[]>(
      `${this.urlV2Base}/month_requests/${currentMonth}`,
    );
  }

  public getAllClientMonthRequests(currentMonth: string): Observable<IRequest[]> {
    return this.httpClient.get<IRequest[]>(`${this.urlBase}/month_requests/${currentMonth}/client`);
  }

  public getAllRequestsFiltered(params: HttpParams): Observable<IPagination<IRequest>> {
    return this.httpClient.get<IPagination<IRequest>>(`api/requests/filtered`, {params});
  }

  public getAllAbsenceRequestsByUserId(currentYear: number, userId: number): Observable<IAbsenceRequest[]> {
    return this.httpClient.get<IAbsenceRequest[]>(`api/requests/summary/${currentYear}/${userId}`);
  }

  public getAllWorkOnHolidayRequestsForUserId(
    requesterId: number,
  ): Observable<IWorkOnHolidayRequest[]> {
    return this.httpClient.get<IWorkOnHolidayRequest[]>(
      `api/work-holidays-requests/${requesterId}`,
    );
  }

  public getRequestsByRequesterId(
    id: number,
    year: number,
    params?: HttpParams,
  ): Observable<IPagination<IRequest>> {
    return this.httpClient.get<IPagination<IRequest>>(
      `${this.urlBase}/requests_by_requester/${id}/${year}`,
      {params},
    );
  }

  public getRequestsByApproverIdAndStatusNotDeleted(
    id: number,
    year: number,
    params?: HttpParams,
  ): Observable<IPagination<IRequest>> {
    return this.httpClient.get<IPagination<IRequest>>(
      `${this.urlBase}/requests_by_approver/${id}/${year}`,
      {params},
    );
  }

  public getPendingRequestsByRequesterId(id: number): Observable<IRequest[]> {
    return this.httpClient.get<IRequest[]>(`${this.urlBase}/pending_requests_by_requester/${id}`);
  }

  public getPendingRequestsByApproverId(id: number): Observable<HttpResponse<IRequest[]>> {
    return this.httpClient.get<IRequest[]>(`${this.urlBase}/pending_requests_by_approver/${id}`, {
      observe: 'response',
    });
  }

  public actionRequest(id: number, action: boolean, hash: string): Observable<IRequest> {
    return this.httpClient.get<IRequest>(`${this.urlBase}/action/${id}/${action}/${hash}`);
  }

  public actionRequestWithoutHash(id: number, action: boolean): Observable<IRequest> {
    return this.httpClient.get<IRequest>(`${this.urlBase}/action/${id}/${action}`);
  }

  public updateActionRequest(id: number, gapiId: string): Observable<IRequest> {
    return this.httpClient.get<IRequest>(`${this.urlBase}/action_update/${id}/${gapiId}`);
  }

  public createRequest(request: IRequest): Observable<IRequest> {
    return this.httpClient.post<IRequest>(`${this.urlBase}`, request);
  }

  public createHolidayWorkingRequest(
    request: HolidayWorkingRequest,
  ): Observable<HolidayWorkingRequest> {
    return this.httpClient.post<HolidayWorkingRequest>(`${this.workOnHolidaysUrlBase}`, request);
  }

  public getAllHolidayWorkingPendingRequests(id: number): Observable<HolidayWorkingRequest[]> {
    return this.httpClient.get<HolidayWorkingRequest[]>(
      `${this.workOnHolidaysUrlBase}/not-processed/${id}`,
    );
  }

  public deleteHolidayWorkingPendingRequest = (id: number): Observable<any> => {
    return this.httpClient.delete<HolidayWorkingRequest>(`${this.workOnHolidaysUrlBase}/${id}`);
  };

  public deleteRequest = (id: number): Observable<IRequest> => {
    return this.httpClient.delete<IRequest>(`${this.urlBase}/${id}`);
  };

  public deleteRequestFromAdmin(id: number): Observable<IRequest> {
    return this.httpClient.delete<IRequest>(`${this.urlBase}/admin/${id}`);
  }

  public uploadFile(id: number, file: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post<string>(`${this.urlBase}/${id}/upload_file`, formData);
  }

  public uploadFileList(id: number, fileList: File[]): Observable<string> {
    const formData: FormData = new FormData();
    for (const file of fileList) {
      formData.append('fileList', file);
    }
    return this.httpClient.post<string>(`${this.urlBase}/${id}/upload_file_list`, formData);
  }

  public deleteFromFileList(requestId: number, fileName: string): Observable<string> {
    return this.httpClient.delete<string>(`${this.urlBase}/${requestId}/delete_file/${fileName}`);
  }

  public loadFile(fileName: string): Observable<string> {
    const httpOptions = {
      responseType: 'arraybuffer' as 'json',
    };
    return this.httpClient.get<string>(`static/requests/load_file/${fileName}`, httpOptions);
  }

  public getRequestById(id: number): Observable<IRequest> {
    return this.httpClient.get<IRequest>(`${this.urlBase}/${id}`);
  }

  public downloadLoggedHours(
    fromDate: string,
    toDate: string,
    projectId: number,
  ): Observable<Blob> {
    const url = `api/time-logging/projects/${projectId}/download?fromDate=${fromDate}&toDate=${toDate}`;
    const httpOptions = {
      responseType: 'arraybuffer' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      }),
    };

    return this.httpClient.get<Blob>(url, httpOptions);
  }

  setListOfAbsences(list: IAbsenceRequest[]) {
    this.listOfAbsences$.next(list);
  }

  getListOfAbsences() {
    return this.listOfAbsences$.asObservable();
  }
}
