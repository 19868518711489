<h1 mat-dialog-title>{{ data.requester.fullName }}</h1>
<mat-tab-group dynamicHeight>
  <mat-tab label="Edit Leave Type">
    <div mat-dialog-content>
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ 'HOME.REQUEST_FORM.LEAVE_TYPE' | translate }}</mat-label>
          <mat-select [value]="vacationType()" (selectionChange)="vacationType.set($event.value)">
            <mat-option *ngFor="let type of vacationTypes" [value]="type">
              {{ 'VACATION_TYPES.' + type | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="hasVacationSubTypes()">
          <mat-label>{{ 'HOME.REQUEST_FORM.SUB_LEAVE_TYPE' | translate }}</mat-label>
          <mat-select
            [value]="vacationSubtype()"
            (selectionChange)="vacationSubtype.set($event.value)"
            *ngIf="vacationType() === 'SICK_LEAVE'"
          >
            <mat-option *ngFor="let subtype of vacationSicktypes" [value]="subtype">
              {{ 'VACATION_TYPES.' + subtype | translate }}
            </mat-option>
          </mat-select>
          <mat-select
            [value]="vacationSubtype()"
            (selectionChange)="vacationSubtype.set($event.value)"
            *ngIf="vacationType() === 'OTHER_PAID_LEAVE'"
          >
            <mat-option *ngFor="let subtype of vacationPaidtypes" [value]="subtype">
              {{ 'VACATION_TYPES.' + subtype | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!hasVacationSubTypes()">
          <mat-label>{{ 'HOME.REQUEST_FORM.SUB_LEAVE_TYPE' | translate }}</mat-label>
          <mat-select disabled></mat-select>
        </mat-form-field>
      </div>
      <mat-form-field class="full-width">
        <mat-label>{{ 'HOME.REQUEST_FORM.ADDITIONAL_INFORMATION' | translate }}</mat-label>
        <textarea matInput [value]="info()" (input)="info.set($event.target.value)"></textarea>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="ref.close()">{{ 'SHARED.CLOSE' | translate }}</button>
      <button mat-raised-button color="primary" (click)="updateVacationType()">
        {{ 'SHARED.SUBMIT' | translate }}
      </button>
    </div>
  </mat-tab>
  <mat-tab label="Edit Leave Period">
    <div mat-dialog-content>
      <app-absence-datepicker
        [days]="totalDays()"
        [fromDate]="data?.fromDate"
        [toDate]="data?.toDate"
        (datechanged)="vacationDays.set($event)"
      ></app-absence-datepicker>

      <app-absence-half-days-select
        [vacationDays]="vacationDays()"
        (dayschange)="vacationDays.set($event)"
      ></app-absence-half-days-select>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="ref.close()">Close</button>
      <button mat-raised-button color="primary" (click)="updatePeriod()">
        {{ 'SHARED.SUBMIT' | translate }}
      </button>
    </div>
  </mat-tab>
</mat-tab-group>
