<mat-spinner *ngIf="isLoading"></mat-spinner>

<div [hidden]="isLoading">
  <h2 class="section-title">
    {{ 'USER.MY_SETTINGS.USER_SETTINGS' | translate }}
  </h2>

  <div class="form-container">
    <div class="form-column">
      <div class="avatarSection">
        <br />
        <div>
          <img class="avatar" [src]="avatarUrlToShow" fill alt="avatar" />
          <mat-icon matTooltip="Remove avatar" class="icon delete" (click)="removeImage()"
            >cancel
          </mat-icon>
        </div>
        <br />
        <button type="button" mat-raised-button (click)="fileInput.click()" color="primary">
          {{ 'USER.MY_SETTINGS.SELECT_AVATAR' | translate }}
        </button>
        <input hidden (change)="openImageCropDialog($event)" #fileInput type="file" id="file" />

        <button type="button" mat-raised-button (click)="openDialog()" color="primary">
          {{ 'USER.MY_SETTINGS.ADD_SKILLS' | translate }}
        </button>
      </div>
    </div>
    <div class="form-column">
      <mat-form-field color="accent" appearance="fill">
        <mat-label> {{ 'USER.MY_SETTINGS.FIRST_NAME_EN' | translate }}</mat-label>
        <input
          required
          matInput
          [formControl]="settingsForm.get('firstNameEn')"
          [(ngModel)]="currentUser.firstNameEn"
        />
        <mat-error *ngIf="settingsForm.get('firstNameEn').hasError('required')">{{
          'USER.MY_SETTINGS.FIRST_NAME_IS_REQUIRED' | translate
        }}</mat-error>
        <mat-error *ngIf="settingsForm.get('firstNameEn').hasError('pattern')">{{
          'USER.MY_SETTINGS.FIRST_NAME_LATIN' | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field color="accent" appearance="fill">
        <mat-label> {{ 'USER.MY_SETTINGS.LAST_NAME_EN' | translate }}</mat-label>
        <input
          required
          matInput
          [formControl]="settingsForm.get('lastNameEn')"
          [(ngModel)]="currentUser.lastNameEn"
        />
        <mat-error *ngIf="settingsForm.get('lastNameEn').hasError('required')">{{
          'USER.MY_SETTINGS.LAST_NAME_IS_REQUIRED' | translate
        }}</mat-error>
        <mat-error *ngIf="settingsForm.get('lastNameEn').hasError('pattern')">{{
          'USER.MY_SETTINGS.LAST_NAME_LATIN' | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field color="accent" appearance="fill">
        <mat-label>{{ 'USER.MY_SETTINGS.PHONE' | translate }}</mat-label>
        <input
          required
          matInput
          [formControl]="settingsForm.get('phone')"
          [(ngModel)]="currentUser.phone"
          placeholder="0XXXXXXXXX"
        />
        <mat-error *ngIf="settingsForm.get('phone').hasError('pattern')">{{
          'USER.MY_SETTINGS.PHONE_FORMAT_ERROR' | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field color="accent" appearance="fill">
        <mat-label> {{ 'USER.MY_SETTINGS.GENDER' | translate }}</mat-label>
        <mat-select [(value)]="currentUser.gender">
          <mat-option *ngFor="let gender of allGenders" [value]="gender">
            {{ gender }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field color="accent" appearance="fill">
        <mat-label>{{ 'USER.MY_SETTINGS.BIRTHDAY' | translate }}</mat-label>
        <input
          matInput
          [formControl]="settingsForm.get('birthday')"
          [(ngModel)]="currentUser.birthday"
          placeholder="dd.mm | dd.mm.yyyy"
        />
        <mat-error *ngIf="settingsForm.get('birthday').hasError('pattern')">{{
          'USER.MY_SETTINGS.BIRTH_DATE_FORMAT_ERROR' | translate
        }}</mat-error>
      </mat-form-field>
    </div>
    <div class="form-column">
      <mat-form-field color="accent" appearance="fill">
        <mat-label> {{ 'USER.MY_SETTINGS.FIRST_NAME' | translate }}</mat-label>
        <input
          [required]="currentUser.bgEmployee"
          matInput
          [formControl]="settingsForm.get('firstName')"
          [(ngModel)]="currentUser.firstName"
        />
        <mat-error *ngIf="settingsForm.get('firstName').hasError('required')">{{
          'USER.MY_SETTINGS.FIRST_NAME_IS_REQUIRED' | translate
        }}</mat-error>
        <mat-error *ngIf="settingsForm.get('firstName').hasError('pattern')">{{
          'USER.MY_SETTINGS.FIRST_NAME_CYRILLIC' | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field color="accent" appearance="fill">
        <mat-label> {{ 'USER.MY_SETTINGS.LAST_NAME' | translate }}</mat-label>
        <input
          [required]="currentUser.bgEmployee"
          matInput
          [formControl]="settingsForm.get('lastName')"
          [(ngModel)]="currentUser.lastName"
        />
        <mat-error *ngIf="settingsForm.get('lastName').hasError('required')">{{
          'USER.MY_SETTINGS.LAST_NAME_IS_REQUIRED' | translate
        }}</mat-error>
        <mat-error *ngIf="settingsForm.get('lastName').hasError('pattern')">{{
          'USER.MY_SETTINGS.LAST_NAME_CYRILLIC' | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field color="accent" appearance="fill">
        <mat-label> {{ 'USER.MY_SETTINGS.EMAIL' | translate }}</mat-label>
        <input readonly disabled matInput [(ngModel)]="currentUser.email" />
      </mat-form-field>

      <mat-form-field color="accent" appearance="fill">
        <mat-label> {{ 'USER.MY_SETTINGS.POSITION' | translate }}</mat-label>
        <input readonly disabled matInput [(ngModel)]="currentUser.positionName" />
      </mat-form-field>

      <mat-form-field color="accent" appearance="fill">
        <mat-label>{{ 'USER.MY_SETTINGS.DREAMIX_BIRTHDAY' | translate }}</mat-label>
        <input
          readonly
          disabled
          matInput
          [value]="currentUser.dreamixBirthday | date: 'dd.MM.yyyy'"
        />
      </mat-form-field>
    </div>

    <div class="form-column">
      <mat-label> {{ 'USER.MY_SETTINGS.PROJECTS' | translate }}</mat-label>
      <mat-chip-listbox>
        <mat-chip *ngFor="let project of currentUser.projects">
          {{ project.projectName }}
        </mat-chip>
      </mat-chip-listbox>
    </div>

    <div class="form-double-column">
      <mat-form-field color="accent" appearance="fill">
        <mat-label>{{ 'USER.MY_SETTINGS.HOBBIES' | translate }}</mat-label>
        <textarea
          matInput
          [(ngModel)]="currentUser.hobbies"
          name="hobbies"
          maxlength="512"
        ></textarea>
        <mat-hint align="end">{{ currentUser.hobbies?.length || 0 }}/512</mat-hint>
      </mat-form-field>
    </div>

    <div class="form-triple-column">
      <div class="mat-form-field-wrapper">
        <mat-label>{{ 'USER.MY_SETTINGS.DESCRIPTION' | translate }}</mat-label>
        <app-editor [(ngModel)]="currentUser.general" name="description"></app-editor>
      </div>
    </div>

    <mat-label class="mat-form-field">{{ 'USER.MY_SETTINGS.NOTIFICATIONS' | translate }}</mat-label>
    <div class="form-triple-column">
      <mat-checkbox [(ngModel)]="currentUser.subscribedForProject" color="primary">
        {{ 'USER.MY_SETTINGS.NOTIFICATION_EMAIL_FOR_APPROVING_LEAVE_REQUESTS' | translate }}
      </mat-checkbox>
    </div>

    <div class="form-triple-column">
      <mat-checkbox [(ngModel)]="currentUser.bdNotificationEnabled" color="primary">
        {{ 'USER.MY_SETTINGS.BD_NOTIFICATION' | translate }}
      </mat-checkbox>
    </div>

    <div class="form-triple-column" style="text-align: right">
      <button mat-raised-button (click)="updateEmployee()" color="primary">
        {{ 'USER.MY_SETTINGS.SAVE' | translate }}
      </button>
    </div>
  </div>
</div>
