import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../shared/services/login.service';
import { CalendarComponent } from './calendar/calendar.component';
import { PendingRequestsSectionComponent } from './pending-requests-section/pending-requests-section.component';
import { AbsenceRequestFormComponent } from './absence-request-form/absence-request-form.component';
import { VacationChartsComponent } from './vacation-charts/vacation-charts.component';
import { IEmployee } from '../shared/models/employee.model';
import { Subject, takeUntil } from 'rxjs';
import { TravelRequestService } from '../shared/services/travel-request.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { IInvalidTravelRequest } from '../shared/models/travel-request.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild(CalendarComponent) calendarComponent: CalendarComponent;
  @ViewChild(PendingRequestsSectionComponent)
  myRequestsSectionComponent: PendingRequestsSectionComponent;
  @ViewChild(AbsenceRequestFormComponent)
  requestFormComponent: AbsenceRequestFormComponent;
  @ViewChild(VacationChartsComponent)
  vacationChartsComponent: VacationChartsComponent;

  user: IEmployee;
  private destroy$ = new Subject<void>();

  constructor(
    public loginService: LoginService,
    private _snackBar: MatSnackBar,
    private translate: TranslateService,
    public travelRequestService: TravelRequestService,
  ) {}

  ngOnInit(): void {
    this.user = this.loginService.getUser();
    this.getMissingTripDocuments();
  }

  //Remove this, home shouldn't know about their children state
  public isLoading(): boolean {
    if (
      this.calendarComponent !== undefined &&
      this.myRequestsSectionComponent !== undefined &&
      this.requestFormComponent !== undefined
    ) {
      return this.requestFormComponent.isLoading;
    }

    return true;
  }

  private getMissingTripDocuments() {
    let invalidTrips = sessionStorage.getItem('invalidTrips');
    if (!invalidTrips) {
      this.travelRequestService
        .getMissingTripDocuments(this.user.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: IInvalidTravelRequest) => {
          if (res) {
            sessionStorage.setItem('invalidTrips', JSON.stringify(res));
            const fromCity = res.destinations[0].fromCity;
            const toCity = res.destinations[res.destinations.length - 1].toCity;
            this._snackBar.open(
              this.translate.instant('HOME.MISSING_TRIP_DOCUMENTS', {
                fromCity,
                toCity,
              }),
              'OK',
              { panelClass: ['error-snackbar'] },
            );
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
