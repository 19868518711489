<mat-spinner *ngIf="isLoading"></mat-spinner>

<div [hidden]="isLoading">
  <h2 class="section-title">
    {{ employee?.firstNameEn + ' ' + employee?.lastNameEn }}
  </h2>

  <div class="back-link">
    <mat-icon>keyboard_arrow_left</mat-icon>
    <a routerLink="/dreamix/page/employees" (click)="goToPreviousPage()">{{
      'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.BACK_TO_EMPLOYEES' | translate
    }}</a>
  </div>

  <div class="employee">
    <div class="employee-avatar">
      <img class="avatar" [src]="photoSrc" alt="avatar" />
      <button
        mat-raised-button
        color="primary"
        (click)="openDialog()"
        data-cy="ed-project-history-button"
      >
        {{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.PROJECT_HISTORY.TITLE' | translate }}
      </button>

      <button type="button" mat-raised-button (click)="openSkillDialog()" color="primary">
        {{ 'USER.MY_SETTINGS.SKILLS' | translate }}
      </button>
    </div>
    <div class="employee-details">
      <div>
        <label>{{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.FIRST_NAME_EN' | translate }}</label>
        <p>{{ employee?.firstNameEn | nullDefault }}</p>
      </div>
      <div>
        <label>{{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.LAST_NAME_EN' | translate }}</label>
        <p>{{ employee?.lastNameEn | nullDefault }}</p>
      </div>
      <div>
        <label>{{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.PHONE' | translate }}</label>
        <p>{{ employee?.phone | nullDefault }}</p>
      </div>
      <div *ngIf="employee?.birthday">
        <label>{{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.BIRTHDAY' | translate }}</label>
        <p>{{ employee?.birthday | nullDefault }}</p>
      </div>
      <div class="hobbies" *ngIf="employee?.hobbies">
        <div>
          <label>{{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.HOBBIES' | translate }}</label>
          <p>{{ employee?.hobbies | nullDefault }}</p>
        </div>
      </div>
    </div>
    <div class="employee-company-details">
      <div>
        <label>{{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.FIRST_NAME' | translate }}</label>
        <p>{{ employee?.firstName | nullDefault }}</p>
      </div>
      <div>
        <label>{{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.LAST_NAME' | translate }}</label>
        <p>{{ employee?.lastName | nullDefault }}</p>
      </div>
      <div>
        <label>{{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.EMAIL' | translate }}</label>
        <p>{{ employee?.email | nullDefault }}</p>
      </div>
      <div>
        <label>{{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.POSITION' | translate }}</label>
        <p>{{ employee?.positionName | nullDefault }}</p>
      </div>
      <div *ngIf="employee?.dreamixBirthday">
        <label>{{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.IN_DREAMIX_SINCE' | translate }}</label>
        <p>
          {{ employee?.dreamixBirthday | nullDefault | date: 'dd.MM.yyyy' }}
        </p>
      </div>
    </div>
    <div class="employee-projects" *ngIf="employee?.projects">
      <div>
        <label>{{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.PROJECTS' | translate }}</label>
        <div *ngFor="let project of employee?.projects">
          <span>{{ project.projectName }}</span>
        </div>
      </div>
    </div>
    <div class="employee-description">
      <div>
        <label>{{ 'DREAMIX.EMPLOYEES.EMPLOYEE_DETAILS.DESCRIPTION' | translate }}</label>
        <p [innerHTML]="employee?.general | nullDefault | safeHtml"></p>
      </div>
    </div>
  </div>
</div>
