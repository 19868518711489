<div>
  <table mat-table [dataSource]="leaves">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ 'HOME.CALENDAR.NAME' | translate }}</th>
      <td mat-cell *matCellDef="let item">{{ item.requester.fullName }}</td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>{{ 'HOME.REQUEST_FORM.LEAVE_TYPE' | translate }}</th>
      <td mat-cell *matCellDef="let item">
        {{ 'VACATION_TYPES.' + item.vacationType | translate }}
        <span *ngIf="item.vacationSubtype"
          >/ {{ 'VACATION_TYPES.' + item.vacationSubtype | translate }}</span
        >
      </td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>{{ 'ADMIN.HOLIDAYS.DATE' | translate }}</th>
      <td mat-cell *matCellDef="let item">
        {{ item.fromDate.format('YYYY.MM.DD') }} - {{ item.toDate.format('YYYY.MM.DD') }}
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item">
        <button mat-stroked-button color="accent" (click)="handleDecline(item)">
          {{ 'HOME.PENDING_REQUESTS_SECTION.DECLINE' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="handleEdit(item)">
          {{ 'HOME.PENDING_REQUESTS_SECTION.EDIT' | translate }}
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
