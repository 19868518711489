<div style="width: 100%">
  <h2 class="section-title">
    {{ 'USER.KNOW_BE.TITLE' | translate }}
  </h2>
  <div class="knowbe-container">
    <div class="cards-holder">
      <app-knowbe-risk-score-gauge
        *ngIf="personalRiskScore"
        [data]="personalRiskScore"
        [title]="'USER.KNOW_BE.PERSONAL_RISK_SCORE' | translate"
      ></app-knowbe-risk-score-gauge>
      <app-knowbe-risk-score-gauge
        *ngIf="organizationRiskScore"
        [data]="organizationRiskScore"
        [title]="'USER.KNOW_BE.ORGANIZATION_RISK_SCORE' | translate"
      ></app-knowbe-risk-score-gauge>
      <app-knowbe-phishing-result-pie
        *ngIf="phishPronePercentage >= 0"
        [data]="phishPronePercentage"
      ></app-knowbe-phishing-result-pie>
    </div>
    <app-knowbe-line-chart
      *ngIf="userRiskScoreChartData && organizationCurrentRiskScoreHistoryChartData"
      [userData]="userRiskScoreChartData"
      [organizationData]="organizationCurrentRiskScoreHistoryChartData"
    ></app-knowbe-line-chart>
  </div>
</div>
