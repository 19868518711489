<div class="tree-grid" (mousedown)="onMouseDown($event)" (wheel)="handleWheel($event)" #gridWrapper>
  <div #treeWrapper>
    <p-tree [value]="treeData" layout="horizontal">
      <ng-template let-node pTemplate="default">
        <div
          class="node"
          [class.expanded]="isNodeExpanded(node) && !isRootNode(node)"
          [class.leaf]="isLeafNode(node)"
          [class.root]="isRootNode(node)"
          [class.found-node]="node.data.found"
        >
          <img class="node-image" src="{{ node.data.photo }}" />
          <div class="node-info">
            <div class="node-text">
              <a class="name" [routerLink]="['/dreamix/page/employees/', node.data.userId]">
                {{ node.data.name }}
              </a>
              <p class="role">{{ node.data.role }}</p>
            </div>
            <span class="people" *ngIf="node.data.decendants">{{
              'DREAMIX.ORGANIZATION.PEOPLE' | translate: { count: node.data.decendants }
            }}</span>
          </div>
          <div
            class="node-arrow"
            (click)="nodeClick(node)"
            *ngIf="node.children && node.children.length"
          >
            <mat-icon>
              {{ isNodeExpanded(node) ? 'close' : 'keyboard_arrow_right' }}
            </mat-icon>
          </div>
        </div>
      </ng-template>
    </p-tree>
  </div>
  <div class="flex expand-buttons">
    <app-employee-input [handleSelect]="handleEmployeeSelect" />
    <p class="collapse" (click)="mutateExpansions(treeData, false)">
      {{ 'DREAMIX.ORGANIZATION.COLLAPSE' | translate }}
    </p>
  </div>
  <div class="grid-zoom-wrapper">
    <div class="grid-zoom" (click)="handleZoom(true)">
      <mat-icon> zoom_in </mat-icon>
    </div>
    <div class="grid-zoom" (click)="handleZoom(false)">
      <mat-icon> zoom_out </mat-icon>
    </div>
  </div>
</div>
