import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UtilizationRateExportOptions } from '../shared/enums/utilization-rate-export-option.enum';
import { AlertService, AlertType, EmployeeService, ProjectService } from '../shared/services';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { EmployeePartial, IProject } from '../shared/models';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { formatDateDto } from '../shared/services/helpers/date.helper';
import * as FileSaver from 'file-saver';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-utilization-rate',
  templateUrl: './utilization-rate.component.html',
  styleUrls: ['./utilization-rate.component.scss'],
})
export class UtilizationRateComponent implements OnInit, OnDestroy {
  isLoading = false;
  utilizationReportForm: FormGroup;
  minDate: Date;
  utilizationRateExportOptions = UtilizationRateExportOptions;
  private allProjects: Array<IProject>;
  public filteredProjects: Observable<IProject[]>;
  private allEmployees: Array<EmployeePartial>;
  public filteredEmployees: Observable<EmployeePartial[]>;
  private destroy$ = new Subject<void>();

  constructor(
    private projectService: ProjectService,
    private employeeService: EmployeeService,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.loadProjects();
    this.getAllEmployees();
    this.buildForm();
    this.optionChangeSubscription();
  }

  buildForm() {
    this.utilizationReportForm = new FormGroup({
      fromDate: new FormControl('', Validators.required),
      toDate: new FormControl({ value: '', disabled: true }, Validators.required),
      option: new FormControl(UtilizationRateExportOptions.EMPLOYEE, Validators.required),
      project: new FormControl('', this.projectNameValidator()),
      employee: new FormControl('', this.employeeNameValidator()),
    });
  }

  private optionChangeSubscription() {
    this.utilizationReportForm.controls.option.valueChanges.subscribe(() => {
      if (
        this.utilizationReportForm.controls.option.value === UtilizationRateExportOptions.EMPLOYEE
      ) {
        this.utilizationReportForm.controls.employee.enable();
        this.utilizationReportForm.controls.project.disable();
      } else {
        this.utilizationReportForm.controls.project.enable();
        this.utilizationReportForm.controls.employee.disable();
      }
    });
  }

  private loadProjects(): void {
    this.projectService
      .getAllProjects()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.allProjects = response.items;
        this.filteredProjects = this.utilizationReportForm.controls.project.valueChanges.pipe(
          map((project: any) => this.filterProjectsByName(project)),
        );
      });
  }

  private filterProjectsByName(projectName: string): IProject[] {
    if (!projectName || typeof projectName !== 'string') {
      return this.allProjects;
    }
    const filterValue = projectName.toLowerCase();
    return this.allProjects.filter((project) =>
      project.projectName.toLowerCase().includes(filterValue),
    );
  }

  private filterEmployeesByName(employeeName): EmployeePartial[] {
    if (!employeeName || typeof employeeName !== 'string') {
      return this.allEmployees;
    }
    const filterValue = employeeName.toLowerCase();
    return this.allEmployees.filter((employee) =>
      `${employee.firstNameEn} ${employee.lastNameEn}`.toLowerCase().includes(filterValue),
    );
  }

  private getAllEmployees() {
    this.employeeService
      .getPartialEmployees()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.allEmployees = response;
        this.filteredEmployees = this.utilizationReportForm.controls.employee.valueChanges.pipe(
          map((employee: any) => this.filterEmployeesByName(employee)),
        );
      });
  }

  getStartDate(event: MatDatepickerInputEvent<Date>) {
    this.minDate = event.value;
    this.utilizationReportForm.controls.toDate.enable();
    if (!this.minDate || this.minDate > this.utilizationReportForm.controls.toDate.value) {
      this.utilizationReportForm.controls.toDate.setValue(null);
    }
  }

  selectProject(event: MatAutocompleteSelectedEvent) {
    const project: IProject = event.option.value;
    this.utilizationReportForm.controls.project.setValue(project);
  }

  selectEmployee(event: MatAutocompleteSelectedEvent) {
    const employee: EmployeePartial = event.option.value;
    this.utilizationReportForm.controls.employee.setValue(employee);
  }

  displayProjectFn(project: IProject): string {
    return project ? `${project.projectName}` : null;
  }

  displayEmployeeFn(employee: EmployeePartial): string {
    return employee ? `${employee.firstNameEn} ${employee.lastNameEn}` : null;
  }

  private projectNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedProject: IProject = control.value;
      if (selectedProject && this.allProjects) {
        control.markAsTouched();
        const index = this.allProjects.findIndex(
          (project) => project.projectName === selectedProject.projectName,
        );
        return index === -1 ? { projectNameMatch: true } : null;
      }
    };
  }

  private employeeNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedEmployee: EmployeePartial = control.value;
      if (control.value && this.allEmployees) {
        control.markAsTouched();
        const index = this.allEmployees.findIndex(
          (employee) =>
            `${employee.firstNameEn} ${employee.lastNameEn}` ===
            `${selectedEmployee.firstNameEn} ${selectedEmployee.lastNameEn}`,
        );
        return index === -1 ? { employeeNameMatch: true } : null;
      }
    };
  }

  onSubmit() {
    this.isLoading = true;
    const fromDate = formatDateDto(this.utilizationReportForm.controls.fromDate.value);
    const toDate = formatDateDto(this.utilizationReportForm.controls.toDate.value);
    const employeeId = this.utilizationReportForm.controls.employee.value
      ? this.utilizationReportForm.controls.employee.value.id
      : null;
    const projectId = this.utilizationReportForm.controls.project.value
      ? this.utilizationReportForm.controls.project.value.id
      : null;
    if (
      this.utilizationReportForm.controls.option.value ===
      this.utilizationRateExportOptions.EMPLOYEE
    ) {
      this.employeeService
        .getUtilizationRatesExport(fromDate, toDate, employeeId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {
            this.isLoading = false;
            this.saveAsExcelFile(response, 'Report_employees_utilization_rates');
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            this.alertService.showAlert(error.message, AlertType.error);
          },
          complete: () => {},
        });
    } else {
      this.projectService
        .getUtilizationRatesExport(fromDate, toDate, projectId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {
            this.isLoading = false;
            this.saveAsExcelFile(response, 'Report_project_utilization_rates');
          },
          error: (error: HttpErrorResponse) => {
            this.isLoading = false;
            this.alertService.showAlert(error.message, AlertType.error);
          },
          complete: () => {},
        });
    }
  }

  saveAsExcelFile(excelData: ArrayBuffer, fileName: string): void {
    const data: Blob = new Blob([excelData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(data, fileName + '_' + formatDateDto(new Date()) + '.xlsx');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
