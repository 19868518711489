<div class="dialog-container">
  <h2 mat-dialog-title class="section-title">
    {{ 'USER.OVERTIME.OVERTIME_HISTORY.OVERTIME_INFORMATION.REQUEST_DETAILS' | translate }}
  </h2>

  <mat-dialog-content>
    <div class="flex-container">
      <div>
        <div>
          {{ 'USER.OVERTIME.OVERTIME_HISTORY.OVERTIME_INFORMATION.OCCURRED_ON' | translate }}
        </div>
        <div>{{ overtime.occurredOn | date: 'dd/MM/yyyy' }}</div>
      </div>
      <div>
        <div>
          {{ 'USER.OVERTIME.OVERTIME_HISTORY.OVERTIME_INFORMATION.CREATED_ON' | translate }}
        </div>
        <div>{{ overtime.createdOn | date: 'dd/MM/yyyy' }}</div>
      </div>
      <div>
        <div>
          {{ 'USER.OVERTIME.OVERTIME_HISTORY.OVERTIME_INFORMATION.HOURS' | translate }}
        </div>
        <div>{{ overtime.hours }}</div>
      </div>
      <div>
        <div>
          {{ 'USER.OVERTIME.OVERTIME_HISTORY.OVERTIME_INFORMATION.OVERTIME_TYPE' | translate }}
        </div>
        <div>
          {{ 'USER.OVERTIME.OVERTIME_HISTORY.' + overtime.overtimeType | translate }}
        </div>
      </div>
      <div *ngIf="overtime.overtimeType === 'WORKING'">
        <div>
          {{ 'USER.OVERTIME.OVERTIME_HISTORY.OVERTIME_INFORMATION.REQUESTED_BY_CLIENT' | translate }}
        </div>
        <div>
          <mat-slide-toggle
            class="form-item-full"
            color="primary"
            matTooltip="{{
          'USER.OVERTIME.OVERTIME_HISTORY.OVERTIME_INFORMATION.REQUESTED_BY_CLIENT' | translate
        }}"
            [checked]="overtime.requestedByClient"
            disabled
          ></mat-slide-toggle>
        </div>
      </div>
      <div *ngIf="overtime.overtimeType === 'WORKING'">
        <div>
          {{ 'USER.OVERTIME.OVERTIME_HISTORY.OVERTIME_INFORMATION.PROJECT' | translate }}
        </div>
        <div>{{ overtime.project.name }}</div>
      </div>
      <div>
        <div>
          {{ 'USER.OVERTIME.OVERTIME_HISTORY.OVERTIME_INFORMATION.COMPENSATION_TYPE' | translate }}
        </div>
        <div>
          {{ 'USER.OVERTIME.OVERTIME_HISTORY.' + overtime.compensationType | translate }}
        </div>
      </div>
      <div>
        <div>
          {{ 'USER.OVERTIME.OVERTIME_HISTORY.OVERTIME_INFORMATION.APPROVED_BY' | translate }}
        </div>
        <div>
          {{ overtime.approver.name }}
        </div>
      </div>
      <div *ngIf="overtime.description !== null && overtime.description !== ''">
        <div>
          {{ 'USER.OVERTIME.OVERTIME_HISTORY.OVERTIME_INFORMATION.DESCRIPTION' | translate }}
        </div>
        <div>{{ overtime.description }}</div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="button-holder">
    <button mat-raised-button color="primary" (click)="closeDialog()">
      {{ 'USER.OVERTIME.OVERTIME_HISTORY.OVERTIME_INFORMATION.CLOSE' | translate }}
    </button>
  </mat-dialog-actions>
</div>
